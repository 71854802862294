@import '../../../variables.scss';

.ovfeature-container{
    margin-top: 5rem;
    width: 100%;
    background-color: #F9F8F9;

    @include rowflex(center, center);
    .ovfeature-container-inner{
        width: 80%;
        @include rowflex(center,center);
        padding: 2rem;

        @include breakpoint(phablet){
            width: auto;
        }


        .left{
            @include rowflex(center,space-between);
            width: 90%;

            @include breakpoint(mobileonly){
                @include colflex(center, space-between); 
            }
            @include breakpoint(phablet){
                width: auto;
            }

            p{
                @include fontSemiBold(1.5em, 1.2em, $gray-800);
                margin-top: 2rem;
                
                @include breakpoint(phablet){
                    @include fontSemiBold(1em, 1.2em, $gray-800);
                }
            }

            .logos{
                width: 50%;
                @include rowflex(center, space-between);
                gap: 2rem;

                @include breakpoint(phablet){
                    width: 80%;
                }

                @include breakpoint(mobileonly){
                    @include colflex(center, space-between);
                    gap: 1.5rem;
                    margin-top: 1.5rem;
                }
                
            }
        }

    }
}