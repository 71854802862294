@import "../../../../variables.scss";

.fundcard {
    width: 100% !important;
    @include colflexx(flex-start);
    // gap: 20px;
    .vestirate {
        margin-bottom: 10px;
        
        @include breakpoint(mobileonly){
            margin-top: 2rem !important;
        }
    }
    &__col, &__bcol, &__scol {
        @include colflexx(flex-start);
    }
    &__bcol {
        gap: 10px;
        width: 100%;
        *.backcontinue {
            margin-top: 0px !important;
        }
    }
    &__col {
        gap: 25px;
        *.backcontinue {
            margin-top: 0px !important;
        }
    }
    &__scol {
        gap: 10px;
        width: 100%;
    }

    .inputfloat,.amountfloat {
        // width: 400px;
        width:100% !important;
        @include breakpoint(mobileonly){
            width:100% !important;
            // width:70vw !important;
        }
        @include breakpoint(phablet){
            width:100% !important;
            // width:350px !important;
        }
        @include breakpoint(tablet){
            width:100% !important;
            // width:400px !important;
        }
    }
   

    &__get {
        @include fontNormal(1em, 1em, $vesti-800);
        width: fit-content;
        height: fit-content;
        padding: 5px 10px 5px 10px;
        border-radius: 10px;
        background-color: $vesti-200;
        border: .5px solid $vesti-800;
    }
    &__error {
        @include fontNormal(1em, 1em, $red-900);
        width: fit-content;
        height: fit-content;
        padding: 5px 10px 5px 10px;
        border-radius: 2px;
        background-color: $red-100;
        border: .5px solid $red-700;
    }
    &__final {
        width: 100%;
        // width: 350px;

        // @include breakpoint(mobileonly){
        //     width: 75vw;
        // }

        // @include breakpoint(desktop){
        //     width: 400px;
        // }
    }
}