@import '../../../variables.scss';
.mainsubscription {
  z-index: 10;
}
.mainsubscription__sub li {
  font-size: 13px;
}

.mainsubscription__type {
  font-size: 18px;
  font-weight: 600;
}

// .mysubbgg {
//   background: url('../../../assets/chats/subBg.png') no-repeat right;
//   width: 100%;
//   // padding: 20px;
// }

.mainsubscription__title {
  font-size: 22px;
  font-weight: 700;
}

.mainsubscription__mainplanbox {
  background: #f7fff3;

  border: 1px solid rgb(0, 128, 0, 0.35);
  padding: 10px;

  // width: 20rem;
}

.mainsubscription__box {
  // background: rgb(247, 255, 243, 0.5);
  //   border: 1px solid green;
  padding: 20px;
  // width: 20rem;
}

@media (min-width: 1300px) {
  .mainsubscription__sub li {
    font-size: 14px;
  }

  .mainsubscription__type {
    font-size: 20px;
  }

  .mainsubscription__title {
    font-size: 24px;
  }

  .mainsubscription__mainplanbox {
    width: 20rem;
  }

  // .mysubbgg {
  //   padding: 10px;
  // }

  .mainsubscription__box {
    width: 20rem;
  }
}

@media (min-width: 1330px) {
  .mainsubscription__mainplanbox {
    padding: 10px;
    width: 19rem;
  }

  .mainsubscription__box {
    padding: 10px;
    width: 19rem;
  }
}

@media (min-width: 1340px) {
  .mainsubscription__mainplanbox {
    width: 20rem;
  }

  .mainsubscription__box {
    width: 20rem;
  }
}

@media (min-width: 1700px) {
  .mainsubscription__sub li {
    font-size: 14px;
  }

  .mainsubscription__type {
    font-size: 22px;
  }

  .mainsubscription__mainplanbox {
    width: 25rem;
  }
  // .mysubbgg {
  //   padding: 20px;
  // }
  .mainsubscription__box {
    width: 25rem;
  }
}

.ActiveTogglePlan {
  padding: 6px 0;
  background: #67a948;
  border-radius: 12px;
  width: 50%;
  text-align: center;
  color: white;

  cursor: pointer;
}

.inActiveTogglePlan {
  padding: 6px 0;

  background: #f7fff3;
  border-radius: 12px;
  width: 50%;
  text-align: center;
  color: #67a948;
  cursor: pointer;
}

.rightsideSubPayload {
  color: #67a948;
  font-weight: 500;
  font-size: 14px;
}

.leftsideSubPayload {
  font-size: 14px;
  font-weight: 500;
}

@media (min-width: 850px) {
  .recommendedSubPlan {
    height: 2rem;
    display: flex;
    justify-content: space-between;
  }
}

//  style={{

//                 background: '#F7FFF3',
//                 borderRadius: '12px',

//                 textAlign: 'center',
//                 color: '#67A948',
//                 cursor: 'pointer',
//               }}
.mainsubscription__section {
  display: flex;
  margin: 0;
  margin-top: -2rem;
  justify-content: center;
}

@media (min-width: 1000px) {
  .mainsubscription__section {
    margin-top: -4rem;
  }
}
