.ty-loan-container {
    padding: 1rem;
  
    @media (min-width: 325px) {
      margin-top: -5px;
    }
  
    @media (min-width: 768px) {
      padding: 2rem;
      margin-top: -50px;
    }
  
    &__header {
      display: flex;
      gap: 0.75rem;
      align-items: center;
      font-size: 1.125rem;
      justify-content: flex-start;
      cursor: pointer;
  
      @media (min-width: 640px) {
        font-size: 1.25rem;
      }
  
      @media (min-width: 768px) {
        font-size: 1.5rem;
      }
  
      &__title {
        font-weight: 600;
        color: #101828;
        font-size: 1.25rem;
  
        @media (min-width: 640px) {
          font-size: 1.625rem;
        }
      }
  
      &__applieddy {
        margin-top: 15px;
        color: #101828;
        font-weight: 600;
        font-size: 25px;
  
        p {
          color: #101828;
          font-size: 20px;
          font-family: 600;
        }
      }
    }
  }



  .loan-title {
    font-weight: 600;
    color: #101828;
    font-size: 20px;
  
    @media screen and (max-width: 640px) {
      font-size: 16px;
    }
  }
  
  .loan-info {
    border: 1px solid #2A3147;
    width: 100%;
    max-width: 100%;
    padding: 2px 24px;
    border-radius: 8px;
    margin-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  
    @media screen and (min-width: 768px) {
      max-width: 1040px;
      flex-direction: row;
    }
  
    img {
      width: 28px;
    }
  
    p {
      font-weight: 300;
      font-size: 20px;
  
      @media screen and (max-width: 640px) {
        font-size: 16px;
      }
  
      span {
        color: #67A948;
        font-weight: 700;
      }
    }
  }
  
  .interest-details {
    margin-top: 16px;
    display: flex;
    gap: 8px;
    align-items: center;
  
    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  
    img {
      width: 24px;
    }
  
    p {
      color: #2A3147;
      font-size: 14px;
  
      @media screen and (min-width: 768px) {
        font-size: 16px;
      }
  
      span {
        color: #67A948;
      }
    }
  }
  


  .button-containeryuc {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


  
    button {
      width: 100%;
      max-width: 300px; // Adjust this value as needed for desktop size
      padding: 10px 20px;
    }
  
    @media (max-width: 768px) {
      button {
        max-width: 100%; // On smaller screens, make the button full width
        margin-top: -10px;
      }
    }
  }
  