@import "../../../variables.scss";

.ovbenefits-container{
    margin-top: 5rem;
    width: 100%;
    height: fit-content;
    border-radius: 50px;
    @include colflex(center,center);

    .ovbenefits-inner {
        width: 80%;
        height: fit-content;
        @include colflex(center, center);

        &.top{
            width: 50%;
            
            @include breakpoint(laptop){     
                width: 70%;
            }

            p:nth-child(1){
                margin: 0;
                @include fontBold(2em, 1.5em, $vesti-902);

                // @include breakpoint(laptop){     
                //     @include fontBold(2em, 1.5em, $vesti-901);

                // }

                span{
                    color: $vesti-700;
                }
            }
            p:nth-child(2){
                margin-top: 10px;
                @include fontNormal(1.2em, 1.5em, $vesti-901);
                
                @include breakpoint(laptop){     
                    @include fontNormal(1.4em, 1.5em, $vesti-901);

                }
            }
        }
        &.bottom {
            width: 80%;
            margin-top: 50px;
            @include rowflexx(center);

            @include breakpoint(laptop){     
                width: 100%

            }
            .card-container{
                @include rowflex(flex-start, center);
                flex-wrap: wrap;

                @include breakpoint(phablet){            
                    @include rowflex(flex-start, center);
                }
                .card{
                    width: 19rem;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                    transition: all .5s ease;

                    &:hover{
                        background-color: $vesti-100;
                        // padding: 1rem;
                        .card-title{
                            color: $vesti-700;
                        }
                    }
                    
                    @include breakpoint(laptop){     
                        width: 22rem;
    
                    }
    
                    .card-content{
                        @include rowflexx(flex-start);
                        padding: 1rem;

    
                        img{
                            margin-top: 1.5rem;
                        }
                        // &:hover{
                        //     background-color: $vesti-100;
                        //     .card-title{
                        //         color: $vesti-700;
                        //     }
                        // }
                        .card-body{
    
                            .card-title{
                                @include fontSemiBold(1.5em, 1.5em, $vesti-902);
                                transition: all .5s ease;

                                @include breakpoint(mobileonly){
                                    @include fontSemiBold(1em, 1.5em, $vesti-902);
                                }
                                @include breakpoint(phablet){
                                    @include fontSemiBold(1.1em, 1.5em, $vesti-902);
                                }
                                // @include breakpoint(laptop){
                                //     @include fontSemiBold(1.8em, 1.5em, $vesti-902);
                                // }
                            }
                            .card-text{
                                @include fontNormal(1.25em, 1.5em, $vesti-901);
                                
                                @include breakpoint(mobileonly){            
                                    @include fontNormal(1em, 1.5em, $vesti-901);
                                }
                                @include breakpoint(phablet){            
                                    @include fontNormal(1em, 1.5em, $vesti-901);
                                }
                                // @include breakpoint(laptop){            
                                //     @include fontNormal(1.5em, 1.5em, $vesti-901);
                                // }
                            
                            }
                        }
        
                    }
    
                }
            }          

        }

    }
}
@media only screen and (min-width: 320px) and (max-width: 600px){
    .ovbenefits-container{
        width: 80%;
        margin: 0 auto;
        height: fit-content;
        border-radius: 20px;
        padding-bottom: 30px;
        @include colflex(center,center);
    
        .ovbenefits-inner {
            width: 100%;
            height: fit-content;
            @include colflexx(center);
            margin-top: -53px;
    
            &.top{
                width: 100%;
                p:nth-child(1){
                    margin: 0;

                    text-align: center;
                    @include fontBold(1.45em, 1em, $vesti-902);

                }
                p:nth-child(2){
                    margin-top: 10px;
                    @include fontNormal(1.1em, 1.5em, $vesti-902);
                }
            }
            &.bottom {
                width: 100%;
                margin-top: 30px;
                margin: 0 auto;
                padding: 0px !important;
                @include colflexx(flex-start);
                
                .card{
                    width: 100% !important;
                    border: none;
                    background-color: transparent;
                    padding-right: 0px;
                    margin-left: 0px;
    
                    .card-title{
                        @include fontSemiBold(1.1em, 1.5em, $vesti-902);
                    
                    }
                    .card-text{
                        @include fontNormal(1.1em, 1.5em, $vesti-901);
                    
                    }
    
                }
    
            }
    
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 800px){
    .ovbenefits-container{
        width: 100%;
        height: fit-content;
        border-radius: 20px;
        padding-bottom: 30px;
        @include colflex(center,center);
    
        .ovbenefits-inner {
            width: 100%;
            height: fit-content;
            @include colflexx(center);
    
            &.top{
                width: 100%;
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(1.8em, 1em, $vesti-902);
                }
                p:nth-child(2){
                    margin-top: 10px;
                    @include fontNormal(1.35em, 1.5em, $vesti-902);
                }
            }
            &.bottom {
                width: 100%;
                margin-top: 20px;
                @include colflexx(center);
    
                .card{
                    border: none;
                    background-color: transparent;
                    width: 15rem;
    
                }
    
            }
    
        }
    }
}
@media only screen and (min-width: 800px) and (max-width: 1224px){
    .ovbenefits-container{
        width: 100%;
        height: fit-content;
        margin-bottom: 100px;
        border-radius: 50px;
         padding-bottom: 50px;
        @include rowflex(center,center);
    
        .ovbenefits-inner {
            width: 90%;
            height: fit-content;
            @include colflexx(center);
    
            &.top{
                width: 100%;
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(2.25em, 1em, $vesti-902);
                }
                p:nth-child(2){
                    margin-top: 10px;
                    @include fontNormal(1.3em, 1.5em, $vesti-902);
                }
            }
            &.bottom {
                width: 100%;
                margin-top: 50px;
                @include rowflexx(center);
    
                .card{
                    border: none;
                    background-color: transparent;
    
                    .card-title{
                        @include fontSemiBold(1.5em, 1.5em, $vesti-902);
                    
                    }
                    .card-text{
                        @include fontNormal(1.25em, 1.5em, $vesti-901);
                    
                    }
    
                }
                
    
            }
    
        }
    }  
}

@media (min-width: 2560px)  {
    .ovbenefits-container{
        margin-top: 10rem;
        width: 100%;
        height: fit-content;
        border-radius: 50px;
        @include colflex(center,center);
    
        .ovbenefits-inner {
            width: 80%;
            height: fit-content;
            @include colflex(center, center);
    
            &.top{
                width: 70%;
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(4.5em, 1.5em, $vesti-902);
    
                }
                p:nth-child(2){
                    margin-top: 10px;
                    @include fontNormal(3.5em, 1.5em, $vesti-901);
                }
            }
            &.bottom {
                width: 100%;
                margin-top: 50px;
                @include rowflexx(center);
    
                .card-container{
                    @include rowflex(center, center);
                    flex-wrap: wrap;
    
                    .card{
                        width: 42rem;
        
                        .card-content{
                            @include rowflexx(flex-start);
        
                            img{
                                margin-top: 2rem;
                                width: 25%;
                            }
                            
                            .card-body{
        
                                .card-title{
                                    @include fontSemiBold(3.5em, 1.5em, $vesti-902);
                                    transition: all .5s ease;    
                                }
                                .card-text{
                                    @include fontNormal(3.1em, 1.5em, $vesti-901);
                                  
                                
                                }
                            }
            
                        }
        
                    }
                }
    
    
                
    
            }
    
        }
    }    
}