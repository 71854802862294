/* .mywebinarbackground {
  background: url('../../../assets/pathways/immigrationwebinar.svg') no-repeat;
  margin-top: 2rem;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px;
  color: azure;
  width: 100%;
  height: 20rem;
} */

.mywebinarPageA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 auto;
  width: 100%;
}

.mywebinarPageA__div {
  background: url('../../../assets/pathways/immigrationwebinar.svg')
    center/cover no-repeat;
  height: 15rem;
  width: 100%;
  border-radius: 10px;
  color: white;
  padding: 10px;
}

@media (min-width: 700px) {
  .mywebinarPageA {
    width: 100%;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(1, 1fr); /* Three columns with equal width */
    gap: 30px; /* Gap between grid items */
    padding: 20px;
    grid-template-columns: repeat(2, 1fr); /* Three columns with equal width */
  }
}

@media (min-width: 1600px) {
  .mywebinarPageA {
    grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  }
}
