.loan-available-card {
    padding: 16px;
    width: 100%;
    border-radius: 8px;
    @media (min-width: 768px) {
      // width: calc(50% - 20px);
      width: calc(50% - 20px);
    }
  
    &__icon {
      width: 28px;
      height: 28px;
    }
  
    &__content {
      margin-top: 12px;
  
      &__titley {
        color: #fff;
        font-weight: 700;
        font-size: 30px;
      
      }
  
      &__description {
        color: #667085;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }


  