.Mspinnercont {
  width: 100%;
  height: 100%;
}

.Mspinner {
  /* margin: 100px auto 0;
    margin: 100px auto 100px; */
  /* width: 70px; */
  /* margin: 0 auto; */
  display: flex;
  justify-content: left;
}

.Mspinner > div {
  width: 12px;
  height: 12px;
  background-color: #67a948;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.Mspinner .Mbounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.Mspinner .Mbounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*spinner*/
