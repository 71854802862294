@import '../../variables.scss';

.mytransactions {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  @include colflexx(flex-start);

  // overflow-x: auto !important;

  @include breakpoint(mobileonly) {
    @include colflexx(flex-start);
  }

  .mytransactions-container__title {
    margin: 0;
    font-size: 2.5em !important;
    color: $gray-300 !important;
    @include fontSemiBold(2.5em, 1em, $gray-300);
  }

  &__inner {
    // background-color: red !important;
    width: 100%;
    // overflow-x: auto !important;
    height: fit-content;
    @include colflexx(flex-start);

    @include breakpoint(mobileonly) {
      @include colflexx(flex-start);
    }

    @include breakpoint(phablet) {
      width: 100vw;
      @include colflexx(flex-start);
    }

    .pagination {
      margin-top: 50px;
      width: 100%;
      float: right;
      @include rowflex(center, flex-end);
      flex-wrap: wrap;

      @include breakpoint(mobileonly) {
        width: 90%;
        margin: 50px auto;
        @include rowflex(center, center);
      }

      @include breakpoint(phablet) {
        width: 100%;
        margin: 50px auto;
        @include rowflex(center, center);
      }
    }
  }

  &__tablecont {
    // width: 80%;
    // background-color: red;
    width: 100%;

    // overflow-x: auto !important;

    @include breakpoint(desktop) {
      width: 100%;
      display: grid;
      //   grid-template-columns: 100% 0%;
      grid-template-columns: 70% 27%;
      justify-content: space-between;
      gap: 2.5%;
    }

    @include breakpoint(mobileonly) {
      // width: 20em;
      width: 95vw;
      overflow-x: auto !important;
    }

    @include breakpoint(phablet) {
      width: 90vw;
      overflow-x: auto !important;
    }

    @include breakpoint(tablet) {
      // width: 90vw;
      width: 100%;
      overflow-x: auto !important;
    }

    // @include breakpoint(laptop){
    //     width: 100%;
    //     overflow-x: auto !important;
    // }
  }

  //   for savings transaction
  &__tablecontsavings {
    // width: 80%;
    // background-color: red;
    width: 100%;

    // overflow-x: auto !important;

    @include breakpoint(desktop) {
      width: 100%;
      display: grid;
      grid-template-columns: 100% 0%;
      //   grid-template-columns: 70% 27%;
      justify-content: space-between;
      gap: 2.5%;
    }

    @include breakpoint(mobileonly) {
      // width: 20em;
      width: 95vw;
      overflow-x: auto !important;
    }

    @include breakpoint(phablet) {
      width: 90vw;
      overflow-x: auto !important;
    }

    @include breakpoint(tablet) {
      // width: 90vw;
      width: 100%;
      overflow-x: auto !important;
    }

    // @include breakpoint(laptop){
    //     width: 100%;
    //     overflow-x: auto !important;
    // }
  }

  &__tabbox {
    width: 100%;
    overflow: scroll;
    max-width: 100%;
    max-height: 500px;
    padding-right: 10px;
    border-radius: 10px;
    border: 1px solid $gray-300;
    background-color: #fff;
    @include overflowScroll($vesti-200, $vesti-800);
  }

  &__table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid $gray-200;

    @include breakpoint(tablet) {
      width: 100vw;
    }

    @include breakpoint(phablet) {
      width: 100vw;
    }

    th,
    td {
      padding: 15px;
    }

    thead {
      // padding-bottom: 50px;
      width: 100%;

      tr {
        th {
          text-align: left;
          margin-bottom: 50px;
          @include fontBold(1em, 1em, $vesti-901);

          @include breakpoint(mobileonly) {
            @include fontBold(1em, 1em, $vesti-901);
          }
        }

        th:nth-child(1) {
          text-align: center;
        }

        &:after {
          content: '@';
          display: block;
          line-height: 2em;
          height: 100px;
          text-indent: -99999px;
        }
      }
    }

    tbody {
      width: 100%;
      height: fit-content;

      tr:not(:first-child) {
        margin-top: 0px;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid $gray-200;
      }

      tr {
        // height: 50px;
        border-top: 1px solid $gray-400;
      }

      tr {
        background-color: transparent;
        cursor: pointer;
        padding-left: 50px !important;
        transition: all 0.5s ease;
        border-radius: 10px !important;

        &:hover {
          background-color: $gray-200;

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }

        td {
          text-align: left;
          height: 70px !important;
          width: fit-content;
          max-width: 300px;
          // border-top: 1px solid $gray-300;
          text-transform: capitalize;
          white-space: nowrap;

          @include breakpoint(desktop) {
            width: fit-content;
            white-space: pre-wrap !important;
            max-width: 300px !important;
          }

          img {
            width: auto;

            @include breakpoint(mobileonly) {
              width: 30px;
            }

            @include breakpoint(phablet) {
              width: 30px;
            }
          }

          .status {
            height: fit-content;
            width: fit-content;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            text-transform: lowercase;

            @include breakpoint(mobileonly) {
              padding: 0px 3px 0px 3px;
            }

            @include fontMedium(1em, 1em, $vesti-900);
            @include rowflexx(center);
            flex-wrap: no-wrap;
            gap: 5px;

            .statusIcon {
              height: 15px;
              width: 15px;
              margin-right: 10px;
              border-radius: 100%;

              &.--success {
                background-color: #4dda89;
              }

              &.--completed {
                background-color: #4dda89;
              }

              &.--pending {
                background-color: $yellow-700;
              }

              &.--failed {
                background-color: #ff3636;
              }
            }
          }
        }

        td:nth-child(1) {
          @include fontNormal(1em, 1em, $vesti-901);
          @include rowflexx(center);
          margin: 0 auto;
        }

        td:nth-child(4) {
          @include fontSemiBold(1em, 1em, $vesti-901);
        }

        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(5) {
          @include fontNormal(1em, 1em, $vesti-901);

          @include breakpoint(mobileonly) {
            @include fontNormal(0.9em, 1em, $vesti-901);
          }
        }

        td.amount {
          width: 100%;
          height: 100%;

          &-green {
            color: $green-700;
            display: flex;
            justify-content: flex-end;
          }

          &-red {
            color: red;
            display: flex;
            justify-content: flex-end;
          }

          &-yellow {
            color: $yellow-600;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &__reftable {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid $gray-200;

    // @include breakpoint(tablet) {
    //   width: 100vw;
    // }
    // @include breakpoint(phablet) {
    //   width: 100vw;
    // }
    th,
    td {
      padding: 15px;
    }

    thead {
      // padding-bottom: 50px;
      width: 100%;

      tr {
        th {
          text-align: left;
          margin-bottom: 50px;
          @include fontBold(1em, 1em, $vesti-901);

          @include breakpoint(mobileonly) {
            @include fontBold(1em, 1em, $vesti-901);
          }
        }

        th:nth-child(1) {
          text-align: center;
        }

        &:after {
          content: '@';
          display: block;
          line-height: 2em;
          height: 100px;
          text-indent: -99999px;
        }
      }
    }

    tbody {
      width: 100%;
      height: fit-content;

      tr:not(:first-child) {
        margin-top: 0px;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid $gray-200;
      }

      tr {
        // height: 50px;
        border-top: 1px solid $gray-400;
      }

      tr {
        background-color: transparent;
        cursor: pointer;
        padding-left: 50px !important;
        transition: all 0.5s ease;
        border-radius: 10px !important;

        &:hover {
          background-color: $gray-200;

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }

        td {
          text-align: left;
          height: 70px !important;
          width: fit-content;
          max-width: 300px;
          // border-top: 1px solid $gray-300;
          text-transform: capitalize;
          white-space: nowrap;

          @include breakpoint(desktop) {
            width: fit-content;
            white-space: pre-wrap !important;
            max-width: 300px !important;
          }

          img {
            width: auto;

            @include breakpoint(mobileonly) {
              width: 30px;
            }

            @include breakpoint(phablet) {
              width: 30px;
            }
          }

          .status {
            height: fit-content;
            width: fit-content;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            text-transform: lowercase;

            @include breakpoint(mobileonly) {
              padding: 0px 3px 0px 3px;
            }

            @include fontMedium(1em, 1em, $vesti-900);
            @include rowflexx(center);
            flex-wrap: no-wrap;
            gap: 5px;

            .statusIcon {
              height: 10px;
              width: 10px;
              margin-right: 10px;
              border-radius: 100%;

              &.--success {
                background-color: #4dda89;
              }

              &.--completed {
                background-color: #4dda89;
              }

              &.--pending {
                background-color: #E99F0C;
                // background-color: $yellow-700;
              }

              &.--failed {
                background-color: #ff3636;
              }
            }
          }
        }

        td:nth-child(1) {
          @include fontNormal(1em, 1em, $vesti-901);
          @include rowflexx(center);
          margin: 0 auto;
        }

        td:nth-child(4) {
          @include fontSemiBold(1em, 1em, $vesti-901);
        }

        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(5) {
          @include fontNormal(1em, 1em, $vesti-901);

          @include breakpoint(mobileonly) {
            @include fontNormal(0.9em, 1em, $vesti-901);
          }
        }

        td.amount {
          width: 100%;
          height: 100%;

          &-green {
            color: $green-700;
            display: flex;
            justify-content: flex-end;
          }

          &-red {
            color: red;
            display: flex;
            justify-content: flex-end;
          }

          &-yellow {
            color: $yellow-600;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  //   for savings transaction
  &__savingstable {
    border-collapse: collapse;
    width: 80%;
    margin-top: 10px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid $gray-200;

    @include breakpoint(tablet) {
      width: 100vw;
    }

    @include breakpoint(phablet) {
      width: 100vw;
    }

    th,
    td {
      padding: 15px;
    }

    thead {
      // padding-bottom: 50px;
      width: 100%;

      tr {
        th {
          text-align: left;
          margin-bottom: 50px;
          @include fontBold(1em, 1em, $vesti-901);

          @include breakpoint(mobileonly) {
            @include fontBold(1em, 1em, $vesti-901);
          }
        }

        th:nth-child(1) {
          text-align: center;
        }

        &:after {
          content: '@';
          display: block;
          line-height: 2em;
          height: 100px;
          text-indent: -99999px;
        }
      }
    }

    tbody {
      width: 100%;
      height: fit-content;

      tr:not(:first-child) {
        margin-top: 0px;
      }

      tr:not(:last-child) {
        border-bottom: 1px solid $gray-200;
      }

      tr {
        // height: 50px;
        border-top: 1px solid $gray-400;
      }

      tr {
        background-color: transparent;
        cursor: pointer;
        padding-left: 50px !important;
        transition: all 0.5s ease;
        border-radius: 10px !important;

        &:hover {
          background-color: $gray-200;

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }

        td {
          text-align: left;
          height: 70px !important;
          width: fit-content;
          max-width: 300px;
          // border-top: 1px solid $gray-300;
          text-transform: capitalize;
          white-space: nowrap;

          @include breakpoint(desktop) {
            width: fit-content;
            white-space: pre-wrap !important;
            max-width: 300px !important;
          }

          img {
            width: auto;

            @include breakpoint(mobileonly) {
              width: 30px;
            }

            @include breakpoint(phablet) {
              width: 30px;
            }
          }

          .status {
            height: fit-content;
            width: fit-content;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            text-transform: lowercase;

            @include breakpoint(mobileonly) {
              padding: 0px 3px 0px 3px;
            }

            @include fontMedium(1em, 1em, $vesti-900);
            @include rowflexx(center);
            flex-wrap: no-wrap;
            gap: 5px;

            .statusIcon {
              height: 15px;
              width: 15px;
              margin-right: 10px;
              border-radius: 100%;

              &.--success {
                background-color: #4dda89;
              }

              &.--completed {
                background-color: #4dda89;
              }

              &.--pending {
                background-color: $yellow-700;
              }

              &.--failed {
                background-color: #ff3636;
              }
            }
          }
        }

        td:nth-child(1) {
          @include fontNormal(1em, 1em, $vesti-901);
          @include rowflexx(center);
          margin: 0 auto;
        }

        td:nth-child(4) {
          @include fontSemiBold(1em, 1em, $vesti-901);
        }

        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(5) {
          @include fontNormal(1em, 1em, $vesti-901);

          @include breakpoint(mobileonly) {
            @include fontNormal(0.9em, 1em, $vesti-901);
          }
        }

        td.amount {
          width: 100%;
          height: 100%;

          &-green {
            color: $green-700;
            display: flex;
            justify-content: flex-end;
          }

          &-red {
            color: red;
            display: flex;
            justify-content: flex-end;
          }

          &-yellow {
            color: $yellow-600;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  &__centercont {
    width: 100%;
    height: 35em;
    @include rowflex(center, center);
  }
}

.equal__get {
  @include fontNormal(1.2em, 1em, $vesti-902);
  width: fit-content;
  height: fit-content;
  padding: 5px 10px 5px 10px;
  border-radius: 10px;
  background-color: $vesti-200;
  border: 0.5px solid $vesti-700;

  &.--error {
    @include fontNormal(1.2em, 1em, red);
    background-color: rgb(255, 190, 190);
    border: 0.5px solid red;
  }
}

.transbox {
  height: fit-content;
  @include colflexx(center);
  gap: 20px;
  border-radius: 10px;
  border: 1px solid $gray-300;
  position: relative;

  &__signature {
    position: absolute;
    margin: 0 auto;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.8;
    @include fontMedium(3em, 1.2em, $vesti-800);
    transform: rotate(25deg);
  }

  &.--body {
    border: none;
  }

  @include breakpoint(mobileonly) {
    width: 80vw;
  }

  @include breakpoint(desktop) {
    width: 100%;
    @include colflexx(center);
  }

  button {
    border-top-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    background-color: $vesti-300 !important;
    color: $vesti-901 !important;
  }
}

.transdetail {
  height: fit-content;
  // display: none;
  @include colflexx(center);
  gap: 20px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid $gray-300;

  @include breakpoint(mobileonly) {
    width: 80vw;
  }

  @include breakpoint(desktop) {
    width: 100%;
    @include colflexx(center);
  }

  &__top {
    width: 100%;
    background-color: $vesti-200;
    // padding: 50px 10px;
    padding: 30px 10px;
    @include colflexx(center);
    gap: 13px;
    text-align: center;
    margin-bottom: 10px;

    p:nth-child(1),
    p:nth-child(3) {
      text-transform: uppercase;
      @include fontNormal(1em, 1em, $vesti-902);
    }

    p:nth-child(2) {
      @include fontSemiBold(2em, 1em, $vesti-800);
    }

    &.-green {
      background-color: $vesti-200;
    }

    &.-red {
      background-color: $red-200;

      p:nth-child(1),
      p:nth-child(3) {
        text-transform: uppercase;
        @include fontNormal(1em, 1em, $red-902);
      }

      p:nth-child(2) {
        @include fontSemiBold(2em, 1em, $red-800);
      }
    }

    &.-yellow {
      background-color: $yellow-200;

      p:nth-child(1),
      p:nth-child(3) {
        text-transform: uppercase;
        @include fontNormal(1em, 1em, $yellow-901);
      }

      p:nth-child(2) {
        @include fontSemiBold(2em, 1em, $yellow-800);
      }
    }

    &.-tumeric {
      background-color: $yellow-200;

      p:nth-child(1),
      p:nth-child(3) {
        text-transform: uppercase;
        @include fontNormal(1em, 1em, $yellow-902);
      }

      p:nth-child(2) {
        @include fontSemiBold(2em, 1em, $yellow-902);
      }
    }
  }

  &__list {
    width: 100%;
    padding: 0px 30px;
    padding-bottom: 30px;
    @include colflexx(center);
    gap: 39px;
  }

  &__item {
    width: 100%;
    // background-color: red;
    @include rowflex(center, space-between);

    p:nth-child(1) {
      text-align: left;
      text-transform: capitalize;
      @include fontNormal(0.8em, 1em, $vesti-902);
    }

    p:nth-child(2) {
      text-align: right;
      text-transform: capitalize;
      white-space: pre-wrap;
      @include fontMedium(0.8em, 1em, $vesti-902);
    }

    .status {
      @include rowflexx(center);
      flex-wrap: wrap;
      gap: 10px;

      .statusIcon {
        height: 15px;
        width: 15px;
        margin-right: 10px;
        border-radius: 100%;

        &.--success {
          background-color: #4dda89;
        }

        &.--pending {
          background-color: $yellow-700;
        }

        &.--failed {
          background-color: #ff3636;
        }
      }
    }
  }
}

.ant-pagination:not(:last-child) {
  margin-bottom: 24px;
  width: 50%;
}