@import "../../../variables.scss";

.prompt {

    width: 100%;
    height: fit-content;

    @include breakpoint(mobileonly) {
        // margin: 0 auto;
        padding: 0 !important;
        width: 100%;
    }

    &__inner {
        padding-top: 0px;
        width: 100%;
        @include colflexx(center);

        @include breakpoint(mobileonly) {
            width: 100% !important;
            // max-width: 70vw;
        }

        &__img {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            // z-index: -10;

            @include breakpoint(mobileonly) {
                width: 100%;
            }
        }

        p.information {
            margin: 0;
            margin-top: 10px;
            @include fontMedium(1.1em, 1.4em, $vesti-902);

            strong {
                color: $vesti-700;
            }
        }

        &__bottom {
            width: 100%;
            @include colflex(flex-start, flex-end);
            display: inline-block;
            position: inherit;
            margin-top: 170px;

            @include breakpoint(mobileonly) {
                // margin-top: 150px;
                // width: 120%;
                // margin-left: 50px;
            }
        }

        &__fair {
            width: 100%;
            @include colflex(flex-start, flex-end);
            display: inline-block;
            position: inherit;
            margin-top: 264px;

            @include breakpoint(mobileonly) {
                margin-top: 150px;
                width: 120%;
                margin-left: 50px;
            }
        }

    }

    &__btn {
        width: 100%;
        height: 50px;
        margin-top: 30px;
        background-color: $vesti-700;
        color: #fff;
        transition: all .5s ease;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color: $vesti-900;
        }

        &:disabled {
            background-color: $gray-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
}

.prompts {

    width: 100%;
    height: fit-content;

    @include breakpoint(mobileonly) {
        // margin: 0 auto;
        padding: 0 !important;
        width: 100%;
    }

    &__inner {
        padding-top: 0px;
        width: 100%;
        @include colflexx(center);

        @include breakpoint(mobileonly) {
            width: 100% !important;
            // max-width: 70vw;
        }

        &__img {
            // width: 100%;
            // position: absolute;
            top: 0;
            left: 0;
            right: 0;
            // z-index: -10;

            @include breakpoint(mobileonly) {
                width: 100%;
            }
        }

        p.information {
            margin: 0;
            margin-top: 10px;
            @include fontMedium(1.1em, 1.4em, $vesti-902);

            strong {
                color: $vesti-700;
            }
        }

        &__bottom {
            width: 100%;
            @include colflex(flex-start, flex-end);
            display: inline-block;
            position: inherit;
            // margin-top: 170px;

            @include breakpoint(mobileonly) {
                // margin-top: 150px;
                // width: 120%;
                // margin-left: 50px;
            }
        }

        &__fair {
            width: 100%;
            @include colflex(flex-start, flex-end);
            display: inline-block;
            position: inherit;
            margin-top: 264px;

            @include breakpoint(mobileonly) {
                margin-top: 150px;
                width: 120%;
                margin-left: 50px;
            }
        }

    }

    &__btn {
        width: 100%;
        height: 50px;
        margin-top: 30px;
        background-color: $vesti-700;
        color: #fff;
        transition: all .5s ease;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color: $vesti-900;
        }

        &:disabled {
            background-color: $gray-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
}

.extraprompt {

    width: 100%;
    height: fit-content;

    @include breakpoint(mobileonly) {
        // margin: 0 auto;
        padding: 0 !important;
        width: 100%;
    }

    &__inner {
        padding-top: 0px;
        width: 100%;
        @include colflexx(center);

        @include breakpoint(mobileonly) {
            width: 100% !important;
            // max-width: 70vw;
        }

        &__img {
            width: 50%;
            // position: absolute;
            top: 0;
            left: 0;
            right: 0;
            // z-index: -10;

            @include breakpoint(mobileonly) {
                width: 100%;
            }
        }

        p.information {
            margin: 0;
            margin-top: 10px;
            @include fontMedium(1.1em, 1.4em, $vesti-902);

            strong {
                color: $vesti-700;
            }
        }

        &__bottom {
            width: 100%;
            @include colflex(flex-start, flex-end);
            display: inline-block;
            position: inherit;
            margin-top: 1rem;

            @include breakpoint(mobileonly) {
                // margin-top: 150px;
                // width: 120%;
                // margin-left: 50px;
            }
        }

        &__fair {
            width: 100%;
            @include colflex(flex-start, flex-end);
            display: inline-block;
            position: inherit;
            margin-top: 264px;

            @include breakpoint(mobileonly) {
                margin-top: 150px;
                width: 120%;
                margin-left: 50px;
            }
        }

    }

    &__btn {
        width: 100%;
        height: 50px;
        margin-top: 30px;
        background-color: $vesti-700;
        color: #fff;
        transition: all .5s ease;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color: $vesti-900;
        }

        &:disabled {
            background-color: $gray-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
}