@import '../../variables.scss';

.header_box {
  // z-index: 270000;

  // @include breakpoint(mobileonly){
  //     // padding: 20px 10px 20px 10px !important;
  // }
  // width: 85%;
  z-index: 4 !important;
  &.--sticky {
    position: sticky !important;
    top: 0 !important;
    padding: 20px 30px !important;
    overflow-x: visible !important;
    // z-index: 100 !important;
  }
  &__info {
    cursor: pointer;
    background-color: $yellow-200;
    border-bottom: 1px solid $yellow-400;
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    width: 100%;
    padding: 10px 0px 10px 0px;
    @include rowflex(center, center);
    span {
      @include rowflexx(center);
      gap: 5px;
      p {
        text-align: center;
        margin: 0;
        // width: 60%;
        @include fontMedium(1em, 1.4em, $yellow-900);

        @include breakpoint(mobileonly) {
          width: 85%;
          @include fontNormal(0.8em, 1.4em, $yellow-900);
        }
      }
    }
  }
  @include breakpoint(mobileonly) {
    margin-bottom: -30px !important;
  }
  // &__content {

  //     // padding: 20px 50px 20px 50px !important;
  // }
  .header-icons {
    width: fit-content;
    @include rowflex(center, center);
    justify-content: center !important;
    align-items: center !important;
    .vestirate:nth-child(1) {
      margin-right: 20px;
    }
    .notif_cont {
      position: relative;
      background-color: #f8e5bf;
      z-index: 160;
      &.active {
        background-color: $vesti-200;
      }
      .notif_icon {
        filter: invert(50%) sepia(96%) saturate(927%) hue-rotate(11deg)
          brightness(108%) contrast(91%);
        transition: all 0.5s ease;

        &.active {
          filter: none;
        }
      }
      .notification-box {
        height: 400px;
        width: 250px;
        padding: 20px 10px 20px 10px;
        overflow-y: scroll;
        border-radius: 10px;
        background-color: #ffff;
        border: 1px solid $gray-300;
        position: absolute;
        top: -450px;
        left: -180px;
        scrollbar-width: thin;
        z-index: 270000 !important;
        transition: all 1s ease;
        @include colflex(flex-start, center);

        &.active {
          top: 90px;
        }
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-track {
          background: $vesti-200;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $vesti-700;
          border-radius: 6px;
          border: $vesti-900;
        }
        @include breakpoint(mobileonly) {
          top: -450px;
          left: -200px;
        }
        hr:last-child {
          display: none;
        }

        hr {
          width: 100%;
          background-color: $gray-300;
        }
        .notification-box_inner {
          width: 95%;
          height: 90%;
          @include colflexx(flex-start);

          img {
            width: 150px;
            opacity: 0.8;
            margin-bottom: 10px;
          }
          .empty {
            margin: 0;
            text-align: center;
            @include fontNormal(1em, 1em, $gray-500);
          }

          .clear-not {
            margin: 0px;
            transition: all 0.5s ease;
            @include fontMedium(1em, 1em, red);

            &:hover {
              text-decoration: underline;
            }
          }
          .singlenotification {
            @include rowflex(center, space-between);
            width: 100%;
            height: fit-content;
            // background-color: red;

            &__text {
              width: 85%;
              @include colflexx(flex-start);
              p:nth-child(1) {
                margin: 0;
                @include fontMedium(0.8em, 1em, $vesti-700);
              }
              p:nth-child(2) {
                width: 100%;
                margin-top: 5px;
                text-transform: capitalize;
                line-height: 0.5em;
                margin-bottom: 0px;
                @include fontNormal(1em, 1.4em, $vesti-901);
              }
            }

            i {
              font-size: 1.2em;
              z-index: 1;
              color: $vesti-901;
              transition: all 0.5s ease;

              &:hover {
                font-size: 1.4em;
                color: red;
              }
            }
          }
        }
      }
    }
  }
}

.backtrack {
  @include rowflexx(center);
  gap: 10px;
  cursor: pointer;
  p {
    margin: 0px;
    @include fontMedium(1.4em, 1em, $vesti-700);
  }
  i {
    color: $vesti-700;
    font-size: 1.4em;
  }
}

.hiname {
  color: #303030;
  font-size: 24px;
  font-weight: bolder;

  margin-right: 170px;
  margin-left: 20px;
  margin-top: 20px;

  @include breakpoint(mobileonly) {
    @include fontSemiBold(0.9rem, 1rem, $vesti-900);
  }

  p:nth-child(1) {
    @include fontSemiBold(1rem, 1.5rem, $gray-800);

    @include breakpoint(mobileonly) {
      @include fontSemiBold(0.8rem, 1rem, $gray-800);
    }
  }
}

.hinamesub {
  color: #7d7d7d;
  font-size: 14px;
  font-weight: 500;

  @include breakpoint(mobileonly) {
    display: none;
  }
  @include breakpoint(phablet) {
    width: 100% !important;
  }
}

.search_form_container {
  @include breakpoint(mobileonly) {
    display: none;
  }
  @include breakpoint(phablet) {
    display: none;
  }
}

.search_header {
  color: #b6bbc9;
  font-size: 16px;
}

@media screen and (max-width: 1353px) {
  .hiname {
    margin-right: 10px !important;

    font-size: 20px !important;
    margin: 0 !important;
  }

  .hinamesub {
    font-size: 10px;
    font-weight: 500;
    width: 100px;
  }
}

.wave-container {
  position: relative;
  width: 70px; /* Adjust the width of the container as needed */
  height: 70px; /* Adjust the height of the container as needed */
  margin: auto;
}

.center-image {
  position: absolute;
  cursor: pointer;

  top: 20%;
  left: 20%;
  // transform: translate(-50%, -50%);
  z-index: 2; /* Place the image above the waves */
}

.center-image img {
  width: 100%; /* Adjust the image size as needed */
  height: auto;
  border-radius: 50%; /* Make sure the image is circular */
}

.wave {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #050e42; /* Set border color as desired */
  border-radius: 50%;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  background-color: #c6f2ae;
}

.wave1 {
  animation-name: wave-animation-1;
}

.wave2 {
  animation-name: wave-animation-2;
}

.wave3 {
  animation-name: wave-animation-3;
}

@keyframes wave-animation-1 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0.15;
  }
}

@keyframes wave-animation-2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes wave-animation-3 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.alertContainer {
  // position: absolute;
  top: 100%;
  right: -5px;
  position: absolute;
  // width: 70vw;
  height: 100%;
}

.alert-box {
  background-color: #14290a;
  width: 200px;
  // height: 40px;
  padding: 10px;
  position: relative;
  border-radius: 12px;
  animation-duration: 2s;
  transform: scale(0.8);

  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-name: lazyload;
}

@keyframes lazyload {
  0% {
    transform: scale(0);
    opacity: 0.2;
  }

  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }

  100% {
    transform: scale(0.4);
    opacity: 1;
  }
}

.arrow-up {
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -10px; /* Adjust the margin-left to center the arrow */
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #66aa48 transparent; /* Set border color as desired */
}

// notification style
.notification-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  // margin-right: 10px;
  div {
    img {
      display: block;
    }

    .notification-counter {
      position: absolute;
      top: -10px;
      right: -10px;
      background-color: red;
      color: white;
      border-radius: 50%;
      padding: 5px 10px;
      font-size: 12px;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
    }
  }
}

.notification-container {
  display: flex;
  align-items: center;
}

.notification-icon {
  position: relative;
  margin-right: 20px;
}

.notification-counter {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
