@import '../../../variables.scss';

.form-ctrl {
  @include fontNormal(1.2em, 1em, $vesti-902);
  width: fit-content;
  line-height: 1.1;
  border: none !important;
  @include rowflexx(center);
  padding: 0px;

  @include breakpoint(mobileonly) {
    @include fontNormal(0.75em, 1em, $vesti-902);
    margin-bottom: 4rem;
  }

  input[type='checkbox'] {
    // margin-left: 20px;
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: $vesti-901;
    width: 1.3em;
    min-width: 1.2em;
    height: 1.2em;
    min-height: 1.3;
    border: 0.15em solid $vesti-901;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-right: 10px;
    transition: all 0.5s ease;

    &:checked {
      border: 0.15em solid $vesti-700;
    }
    &::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em $vesti-700;
    }
  }
  input[type='checkbox']:checked::before {
    transform: scale(1);
  }
}
