.student-loan {
  margin-top: -20px;
  .layout-container {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 1024px) {
      padding: 30px;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .contentn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 4rem;
    padding: 1rem;
    // padding: 0 4rem;
  
    img {
      width: 80%;
  
      @media (min-width: 640px) {
        width: auto;
      }
    }
  
    .text-containern {
      text-align: center;
      width: 100%;
      margin-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
  
      @media (min-width: 640px) {
        width: 700px;
        padding-left: 0;
        padding-right: 0;
      }
  
      p {
        color: #101828;
        font-size: 1.125rem;
        font-weight: 600;
  
        & + p {
          margin-top: 0.5rem;
          color: #667085;
          font-size: 1rem;
          font-weight: 200;
        }
      }
    }
  }
}


