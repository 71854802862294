@import "../../variables.scss";

.fundusd {
    width: 100% !important;
    @include colflexx(flex-start);
    gap: 30px;
    .titlesubtitle{
        &__steps{
            color: $cyan-700;
        }
    }
    .inputfloat, .amountfloat{
        // width: 400px !important;
        width: 100% !important;
        @include breakpoint(mobileonly){
            width: 100% !important;
            // width: 75vw !important;
        }
        @include breakpoint(phablet){
            width: 100% !important;
            // width: 350px !important;
        }
        // @include breakpoint(desktop){
        //     width: 100% !important;
        // }
        &__box__label{
            color: $cyan-700 !important;
        }
        input {
            &:focus {
                border: 1px solid $cyan-700 !important;
                background-color: $cyan-100 !important;
                color: $cyan-901 !important;
            }
        }
    }
    
    @include platform($cyan-800, $cyan-901);
    &__get {
        line-height: 1.4em !important;
        @include fontNormal(1em, 1.4em, $cyan-800);
        width: fit-content;
        height: fit-content;
        padding: 5px 10px 5px 10px;
        border-radius: 10px;
        background-color: $cyan-200;
        border: .5px solid $cyan-700;
    }
    &__bcol,&__col, &__scol{
        width: 100%;
        @include colflexx(flex-start);
        *.equal__get {
            background-color: $cyan-100 !important;
            @include fontMedium(.8em,1em,$cyan-900);
        }
    }
    &__bcol {
        gap: 30px;
    }
    &__col{
        gap: 10px;
    }
    &__scol{
        gap: 5px;
    }
    .radiooption{
        width: 100%;
        *.equal__get {
            background-color: $cyan-100 !important;
            @include fontMedium(.8em,1em,$cyan-900);
        }
    }

    @include radiooption($cyan-800, $cyan-100);
}
@include transreview($cyan-100, $cyan-901, $cyan-800);
.transfinancreview{
    @include transreview($cyan-100, $cyan-901, $cyan-800);
    width: 100% !important;
    @include breakpoint(mobileonly){
        width: 75vw !important;
    }
    &.--review {
        max-height: 400px;
        padding-right: 10px;
        @include overflowScroll($cyan-200, $cyan-800);
    }

    *.platform_mainbtn{
        background-color: $cyan-800;
    }
    *.platform_secbtn{
        border-color: $cyan-800 !important;
        color: $cyan-800;
    }
}