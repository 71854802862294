@import '../../../variables.scss';

.seeall-modal {
  max-width: 500px !important;
}

.testimonialname {
  font-weight: 500 !important;
}

.testimonialdescription {
  margin-top: 20px !important;
  text-overflow: ellipsis;
  width: 90%;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pathway-card {
  background-color: #fff !important;
  border: 1px solid $gray-300 !important;
  border-radius: 10px !important;
  transition: all 0.5s ease;
  cursor: pointer;
  width: 100% !important;

  &:hover {
    filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
  }

  .pathway-title {
    @include fontBold(1.5em, 1.5em, $vesti-901);
    height: 50px;
  }

  .pathway-amount {
    @include fontBold(1.5em, 1.4em, $vesti-700);
  }
}

.seemore-right {
  margin-top: 30px !important;
}

.seemorepathway-right {
  margin-left: 100px;
}

@media screen and (max-width: 600px) {
  .pathway-white {
    font-size: 16px !important;
    // margin-left:36px !important;
    margin-bottom: 70px !important;
  }
}

//27 inches
@media screen and (min-width: 2200px) {
  .merchant-card {
    width: 90%;
  }

  .services-grid {
    grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
  }

  .percentage-container-progress {
    height: 620px !important;
    width: 400px !important;
  }

  .aboutpathway__imgbox {
    height: 700px !important;
  }

  .progress-pathway-container-inner {
    width: 300px !important;
    height: 300px !important;
    margin-right: 20px !important;
  }

  .timespent-container,
  .percentage-container,
  .growth-progress {
    width: 100% !important;
  }

  .aboutpathway {
    width: 100% !important;
  }
}

.merchant-scroll {
  width: 100%;
  /* Set a fixed width for the container */
  overflow-x: scroll;
  /* Enable horizontal scrolling */
  scrollbar-width: none;
  /* Hide the default scrollbar */
  -ms-overflow-style: none;
  /* Hide the default scrollbar (IE/Edge) */
}

.merchant-scroll::-webkit-scrollbar {
  display: none;
  /* Hide the default scrollbar (Chrome/Safari) */
  width: 8px;
  color: #498d37 !important;
  background-color: #67a948 !important;
}

/* Track */
.merchant-scroll::-webkit-scrollbar-track {
  background-color: #67a948 !important;
}

/* Handle */
.merchant-scroll::-webkit-scrollbar-thumb {
  background-color: #67a948 !important;
  border-radius: 4px;
}

/* Handle on hover */
.merchant-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #498d37 !important;
}

.pathway-signup-background-new {
  background-image: url('../../../assets/pathways/signuppathwaybanner3.svg');

  background-size: cover;
  background-position: center;
  padding: 20px;
  height: 350px;
  border-radius: 10px;
  color: #fff !important;

  font-size: 20px;
  line-height: 30px;
  align-content: left;
  position: relative;
}

.scrollText {
  color: #67a948;
  font-size: 20px;
  font-weight: 400;
}

.pathway-signup-background {
  background-image: url('../../../assets/pathways/signuppathwaybanner2.svg');

  background-size: cover;
  background-position: center;
  padding: 20px;
  height: 350px;
  border-radius: 10px;
  color: #fff !important;

  font-size: 20px;
  line-height: 30px;
  align-content: left;
  position: relative;
}

.font-bold {
  font-size: 40px !important;
  line-height: 100% !important;
  align-content: left;
}

.pathway-white {
  color: #fff !important;

  font-size: 24px;
  font-weight: bolder;
}

.duration-pathway {
  background: rgba(247, 255, 243, 0.64);
  border: 0.5px solid #ffffff;
  backdrop-filter: blur(3.5px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;

  margin-left: 20px;
  margin-bottom: 20px;

  padding: 10px;

  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  /* vesti-902 */

  color: #14290a;
  font-weight: bolder;
}

.bottom-left {
  position: absolute;
  top: 174px;

  @include breakpoint(mobileonly) {
    top: 68px;
  }

  @include breakpoint(phablet) {
    top: 250px;
  }

  @include breakpoint(tablet) {
    top: 250px;
  }
}

.font-vesti {
  @include fontNormal(1.2em, 1.4em, $vesti-901);
}

.migration-date {
  font-size: 12px;
  line-height: 14px;
  font-weight: bolder;

  /* vesti-700 */

  color: #67a948;
}

.grey-date {
  font-size: 12px;
  line-height: 14px;
  color: #cccccc;
}

.bg-white {
  background-color: #fff !important;
}

.domore__content .domorecard,
.popupgrid__content .domorecard {
  background-color: #fff;
  color: #000 !important;

  font-size: 18px !important;
  height: 300px !important;
  width: 100% !important;
}

.popupgrid__content {
  display: grid;
  grid-template-columns: repeat(auto-fill, 415px);
  justify-content: center;
}

.popupgrid__content .growthcard {
  color: #000 !important;
  font-size: 18px !important;
  border: none !important;
  padding: 20px;
  width: 430px;
  height: 208.12px;
  transition: all 0.5s ease;
  border-radius: 10px;
  margin-right: 10px;
  /* Add the desired margin space between each growth card item */
}

.font-18 {
  font-size: 18px !important;
  width: 200px;
  height: 23px;

  font-size: 18px;
  line-height: 21px;

  /* vesti-902 */

  color: #14290a;
}

.font-14 {
  font-size: 14px !important;
  //width: 280px;
  // height: 100px;

  font-size: 14px;
  line-height: 140%;
  /* or 20px */

  /* vesti-902 */

  color: #14290a;
}

.creditreport__factors {
  margin-top: 50px !important;
}

.font-9 {
  font-size: 9px !important;
  height: 15px;
  font-size: 12px;
  line-height: 14px;
}

.left-testimonial {
  margin-right: 10px;
  /* Adjust margin as needed */
}

.right-testimonial {
  display: flex;
  flex-direction: column;
}

.font-growth {
  width: 364.59px;
  height: 75px;

  font-size: 14px;
  line-height: 180%;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

// scroll container
.loancredit {
  margin-top: 30px;
  width: 100%;
  // min-width: 1500px;
  @include rowflex(center, center);
  gap: 20px;
  overflow-x: auto;
  white-space: nowrap;

  @include breakpoint(mobile) {
    @include colflex(center, center);
  }

  .loanpathways {
    flex-basis: 0;
    flex: 0 0 auto;
    @include rowflex(center, space-between);
    gap: 1rem;
    padding: 20px;
    height: 150px;
    border-radius: 10px;
    background: $vesti-902;
    cursor: pointer;

    @include breakpoint(mobileonly) {
      width: 90%;
    }

    p:nth-child(1) {
      // width: 60%;
      @include fontBold(1.2rem, 1.5rem, $vesti-100);

      @include breakpoint(mobileonly) {
        // width: 100%;
        @include fontBold(1rem, 1.5rem, $vesti-100);
      }
    }

    p:nth-child(2) {
      @include fontBold(1.2rem, 1.5rem, $vesti-100);
    }
  }

  .creditjourneypathway {
    // flex-shrink: 0;
    flex-basis: 0;
    flex: 0 0 auto;
    @include rowflex(center, space-between);
    gap: 1rem;
    padding: 20px;
    // width: 90%;
    // width: 300px;
    height: 150px;
    border-radius: 10px;
    background: #195742;
    cursor: pointer;

    @include breakpoint(mobileonly) {
      width: 90%;
    }

    p:nth-child(1) {
      // width: 60%;
      @include fontBold(1.2rem, 1.5rem, $vesti-100);

      @include breakpoint(mobileonly) {
        // width: 100%;
        @include fontBold(1rem, 1.5rem, $vesti-100);
      }
    }

    p:nth-child(2) {
      @include fontBold(1.2rem, 1.5rem, $vesti-100);
    }
  }

  .referusers {
    // flex-shrink: 0;
    flex-basis: 0;
    flex: 0 0 auto;
    @include rowflex(center, space-between);
    gap: 1rem;
    padding: 20px;
    // width: 300px;
    height: 150px;
    border-radius: 10px;
    background: #3f1951;
    cursor: pointer;

    @include breakpoint(mobileonly) {
      width: 90%;
    }

    p:nth-child(1) {
      // width: 60%;
      @include fontBold(1.2rem, 1.5rem, $vesti-100);

      @include breakpoint(mobileonly) {
        // width: 100%;
        @include fontBold(1rem, 1.5rem, $vesti-100);
      }
    }

    p:nth-child(2) {
      @include fontBold(1.2rem, 1.5rem, $vesti-100);
    }
  }

  .oldcreditjourneypathway {
    background-image: url('../../../assets/pathways/creditjourneypathway.svg');
    background-size: cover;
    background-position: center;
    // display: flex;
    // justify-content: space-between;
    padding: 20px;
    width: 100%;
    height: 150px;
    border-radius: 10px;

    // background: #195742;
    p:nth-child(1) {
      // width: 60%;
      @include fontBold(1.2rem, 1.5rem, $vesti-100);

      @include breakpoint(mobileonly) {
        // width: 100%;
        @include fontBold(1rem, 1.5rem, $vesti-100);
      }
    }

    p:nth-child(2) {
      @include fontBold(1.2rem, 1.5rem, $vesti-100);
    }
  }

  // .oldcreditjourneypathway {
  //   background-image: url('../../../assets/pathways/creditjourneypathway.svg');
  //   background-size: cover;
  //   background-position: center;
  //   flex-basis: 0;
  //   flex: 0 0 auto;
  //   @include rowflex(center, space-between);
  //   gap: 1rem;
  //   padding: 20px;
  //   height: 150px;
  //   border-radius: 10px;
  //   background: $vesti-902;
  //   cursor: pointer;

  //   @include breakpoint(mobileonly) {
  //     width: 90%;
  //   }

  //   p:nth-child(1) {
  //     // width: 60%;
  //     @include fontBold(1.2rem, 1.5rem, $vesti-100);

  //     @include breakpoint(mobileonly) {
  //       // width: 100%;
  //       @include fontBold(1rem, 1.5rem, $vesti-100);
  //     }
  //   }

  //   p:nth-child(2) {
  //     @include fontBold(1.2rem, 1.5rem, $vesti-100);
  //   }
  // }
}

.button-pathway {
  width: 350px;
  height: 56px;
}

.join-now-group {
  font-size: 16px !important;
}

// this is strictly for webinar cards

.webinar {
  width: 100%;
  height: fit-content;
  position: relative !important;
  // overflow: scroll;
  @include colflexx(flex-start);

  &__inner {
    width: 100%;
  }

  &__content {
    margin-top: 10px;
    gap: 1rem;
    // width: 100vw;
    width: 100%;
    height: 100%;
    @include rowflexx(flex-start);
    overflow-y: auto;

    @include breakpoint(mobileonly) {
      width: 100vw;
      overflow: scroll;
      @include rowflex(flex-start, space-between);

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
        border: $vesti-900;
      }
    }

    .webinarcard {
      background: linear-gradient(
          180deg,
          rgba(21, 37, 50, 0.0085) 17.91%,
          rgba(21, 37, 50, 0.85) 49.92%
        ),
        url('../../../assets/pathways/immigrationwebinar.svg') no-repeat;
      background-size: cover;
      /* This ensures the background image covers the container */
      background-repeat: no-repeat;
      padding: 20px;
      border-radius: 10px;
      min-width: 500px;
      cursor: pointer;
      transition: all 0.5s ease;
      @include colflexx(flex-start);
      gap: 3rem;

      @include breakpoint(mobileonly) {
        min-width: 300px;
      }

      .webinar-main {
        width: 100%;
        height: inherit;
        @include rowflex(center, baseline);

        .webinar-left-title {
          margin: 0;
          width: 100%;

          p {
            @include fontBold(1.2em, 1em, $vesti-100);
            width: 100%;

            @include breakpoint(mobileonly) {
              width: 70%;
              @include fontMedium(0.9em, 1em, $vesti-100);
            }

            @include breakpoint(phablet) {
              @include fontMedium(1em, 1em, $vesti-100);
            }
          }
        }

        .webinar-right-title {
          margin-top: 0;

          p {
            @include fontBold(1em, 1.4em, $vesti-100);
            padding: 4px 25px;
            border: 0.5px solid $vesti-100;
            background-color: #cccccc;
            border-radius: 25px;

            @include breakpoint(mobileonly) {
              width: 100%;
              @include fontMedium(0.7em, 1.4em, $yellow-700);
            }

            @include breakpoint(phablet) {
              width: 100%;
              @include fontMedium(0.8em, 1.4em, $yellow-700);
              padding: 10px 11px;
            }
          }
        }
      }

      .webinar-date {
        margin: 0;
        @include fontNormal(1em, 1.5em, $gray-800);
        padding: 10px 30px;
        border: 0.5px solid $vesti-300;
        background-color: $vesti-300;
        border-radius: 25px;

        @include breakpoint(mobileonly) {
          padding: 4px 25px;
          @include fontMedium(0.8em, 1.4em, $yellow-700);
        }
      }

      .webinar-subtitle {
        margin: 0;
        @include fontNormal(1em, 1.5em, $vesti-100);

        @include breakpoint(mobileonly) {
          @include fontMedium(0.8em, 1.4em, $vesti-100);
        }
      }

      .webinar-bottom {
        width: 100%;
        @include rowflex(center, space-between);

        .watch-now-webinar {
          width: auto;

          a {
            color: $vesti-100;
          }

          .webinar-left-title {
            margin: 0;
            width: 100%;

            p {
              @include fontBold(1.2em, 1em, $vesti-100);

              @include breakpoint(mobileonly) {
                @include fontMedium(1em, 1em, $vesti-100);
              }

              @include breakpoint(phablet) {
                @include fontMedium(1em, 1em, $vesti-100);
              }
            }
          }
        }

        .webinar-right-down {
          width: auto;
        }
      }
    }
  }
}

.webinarmodal {
  // @include rowflex(flex-start, flex-start);
  // flex-wrap: wrap;
  // gap: 1rem;
  &__container {
    display: grid;
    grid-template-columns: auto;
    gap: 1rem;
  }

  @media (min-width: 1200px) {
    &__container {
      display: grid;
      grid-template-columns: auto auto;
      gap: 1rem;
    }
  }

  @media (min-width: 1420px) {
    &__container {
      display: grid;
      grid-template-columns: auto auto auto;
      gap: 1rem;
    }
  }

  .webinarcard {
    background: linear-gradient(
        180deg,
        rgba(21, 37, 50, 0.0085) 17.91%,
        rgba(21, 37, 50, 0.85) 49.92%
      ),
      url('../../../assets/pathways/immigrationwebinar.svg') no-repeat;
    background-size: cover;
    /* This ensures the background image covers the container */
    background-repeat: no-repeat;
    padding: 20px;
    border-radius: 10px;
    min-width: 425px;
    // min-width: 500px;
    cursor: pointer;
    transition: all 0.5s ease;
    @include colflexx(flex-start);
    gap: 3rem;

    @include breakpoint(mobileonly) {
      min-width: 275px;
    }

    .webinar-main {
      width: 100%;
      height: inherit;
      @include rowflex(center, baseline);

      .webinar-left-title {
        margin: 0;
        width: 100%;

        p {
          @include fontBold(1.2em, 1em, $vesti-100);
          width: 70%;

          @include breakpoint(mobileonly) {
            width: 70%;
            @include fontMedium(0.9em, 1em, $vesti-100);
          }

          @include breakpoint(phablet) {
            @include fontMedium(1em, 1em, $vesti-100);
          }
        }
      }

      .webinar-right-title {
        margin-top: 0;

        p {
          @include fontBold(1em, 1.4em, $vesti-100);
          padding: 4px 25px;
          border: 0.5px solid $vesti-100;
          background-color: #cccccc;
          border-radius: 25px;

          @include breakpoint(mobileonly) {
            width: 100%;
            @include fontMedium(0.7em, 1.4em, $yellow-700);
          }

          @include breakpoint(phablet) {
            width: 100%;
            @include fontMedium(0.8em, 1.4em, $yellow-700);
            padding: 10px 11px;
          }
        }
      }
    }

    .webinar-date {
      margin: 0;
      @include fontNormal(1em, 1.5em, $gray-800);
      padding: 10px 30px;
      border: 0.5px solid $vesti-300;
      background-color: $vesti-300;
      border-radius: 25px;

      @include breakpoint(mobileonly) {
        padding: 4px 25px;
        @include fontMedium(0.8em, 1.4em, $yellow-700);
      }
    }

    .webinar-subtitle {
      margin: 0;
      @include fontNormal(1em, 1.5em, $vesti-100);

      @include breakpoint(mobileonly) {
        @include fontMedium(0.8em, 1.4em, $vesti-100);
      }
    }

    .webinar-bottom {
      width: 100%;
      @include rowflex(center, space-between);

      .watch-now-webinar {
        width: auto;

        .webinar-left-title {
          margin: 0;
          width: 100%;

          p {
            @include fontBold(1.2em, 1em, $vesti-100);

            @include breakpoint(mobileonly) {
              @include fontMedium(1em, 1em, $vesti-100);
            }

            @include breakpoint(phablet) {
              @include fontMedium(1em, 1em, $vesti-100);
            }
          }
        }
      }

      .webinar-right-down {
        width: auto;
      }
    }
  }
}

.testimony {
  width: 100%;
  height: fit-content;
  position: relative !important;
  // overflow: scroll;
  @include colflexx(flex-start);

  &__inner {
    width: 100%;
  }

  &__content {
    margin-top: 10px;
    // width: 100vw;
    // width: 100%;
    // height: 100%;
    // @include rowflexx(flex-start);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;

    @include breakpoint(mobileonly) {
      grid-template-columns: repeat(1, 1fr);
      display: grid;
      gap: 10px;
      overflow: scroll;

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 6px;
        border: $vesti-900;
      }
    }

    overflow-y: auto;

    // @include breakpoint(mobileonly) {
    //   width: 100vw;
    //   overflow: scroll;
    //   @include rowflex(flex-start, space-between);

    //   &::-webkit-scrollbar-track {
    //     background: transparent;
    //   }
    //   &::-webkit-scrollbar-thumb {
    //     background-color: transparent;
    //     border-radius: 6px;
    //     border: $vesti-900;
    //   }
    // }
  }
}

.testimonymodal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;

  @include breakpoint(mobileonly) {
    grid-template-columns: repeat(1, 1fr);
    display: grid;
    gap: 10px;
  }
}

/* Desktop */
@media only screen and (min-width: 1930px) {
  /* CSS rules for desktop */

  .slick-prev:before {
    margin-right: 190px !important;
  }

  .titlesubtitle h6.titlesubtitle__subtitle {
    width: 500px !important;
  }

  .pathway-signup-background-new {
    background-image: url('../../../assets/pathways/signuppathwaybanner3.svg');

    background-size: cover;
    background-position: center;
    padding: 20px;
    height: 350px;
    border-radius: 10px;
    color: #fff !important;

    font-size: 20px;
    line-height: 30px;
    align-content: left;
    position: relative;
  }

  .pathway-signup-background {
    background-image: url('../../../assets/pathways/signuppathwaybanner2.svg');

    background-size: cover;
    background-position: center;
    padding: 20px;
    height: 350px;
    border-radius: 10px;
    color: #fff !important;

    font-size: 20px;
    line-height: 30px;
    align-content: left;
    position: relative;
  }

  .pathway-signup-no-background {
    background-size: cover;
    background-position: center;
    padding: 20px;
    height: 350px;
    border-radius: 10px;
    color: #fff !important;

    font-size: 20px;
    line-height: 30px;
    align-content: left;
    position: relative;
  }
}

/* Increase the size of the dots */
.slick-dots li button {
  margin-top: 40px !important;
  font-size: 60px !important;
  /* Adjust the font size as needed */
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* CSS rules for tablet */

  .slick-prev:before {
    margin-right: 190px !important;
  }

  .pathway-signup-background-new {
    background-image: url('../../../assets/pathways/signuppathwaybanner3.svg');

    background-size: cover;
    background-position: center;
    padding: 20px;
    height: 350px;
    border-radius: 10px;
    color: #fff !important;

    font-size: 20px;
    line-height: 30px;
    align-content: left;
    position: relative;
  }

  .pathway-signup-background {
    background-image: url('../../../assets/pathways/signuppathwaybanner2.svg');

    background-size: cover;
    background-position: center;
    padding: 20px;
    height: 350px;
    border-radius: 10px;
    color: #fff !important;

    font-size: 20px;
    line-height: 30px;
    align-content: left;
    position: relative;
  }

  .pathway-signup-no-background {
    background-size: cover;
    background-position: center;
    padding: 20px;
    height: 350px;
    border-radius: 10px;
    color: #fff !important;

    font-size: 20px;
    line-height: 30px;
    align-content: left;
    position: relative;
  }
}

.services-grid {
  overflow-y: scroll !important;

  @include breakpoint(mobileonly) {
    @include colflex(center, center);
  }
}

@media only screen and (max-width: 767px) {
  /* CSS rules for mobile */

  .pathway-signup-background-new {
    width: 100% !important;
    height: 450px !important;
    color: #fff !important;
  }

  .pathway-signup-background {
    width: 100% !important;
    height: 450px !important;
    color: #fff !important;
  }

  .carousel-container {
    height: 400px !important;
  }

  .pathway-video {
    width: 380px !important;
    height: 100% !important;
    border-radius: 10px;
  }

  .slick-slider {
    // width: 380px !important;
    height: 100% !important;
    position: relative !important;
    overflow: hidden !important;
  }
}
