@import '../../../variables.scss';

.userprofile {
  height: 85vh;
  width: 100%;
  //   overflow: scroll;
  margin: 0 auto;
  @include colflexx(flex-start);
  // background-color: red !important;
  // padding: 20px;

  @include breakpoint(desktop) {
    display: flex;
    justify-content: space-between;
  }

  &__inner {
    width: 100%;
    height: 90%;
    position: relative;
    // @include colflexx(flex-start);
    @include rowflexx(flex-start);
    flex-wrap: wrap;

    // @include breakpoint(mobileonly){
    //     @include colflexx(flex-start);
    // }

    @include breakpoint(desktop) {
      @include rowflex(flex-start, space-between);
    }
  }
  &__left {
    width: fit-content;
    height: fit-content;
    // min-width: 450px;
    // margin-right: 50px;
    flex: 1;
    position: relative;
    bottom: 0px;
    border: none;
    background-color: transparent;

    box-shadow: none !important;
    @include breakpoint(mobileonly) {
      min-width: 50vw;
    }

    @include breakpoint(desktop) {
      width: fit-content;
      height: fit-content;
      // margin-right: 50px;
      flex: 1;
      position: relative;
      bottom: 0px;
      border: none;
      // background-color: transparent;
      box-shadow: none !important;
    }
  }
  &__right {
    // flex: 4;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;

    overflow-y: scroll;

    @include breakpoint(desktop) {
      // width: fit-content;
      padding-left: 0px;
      padding-right: 0px;
      width: 70%;
      height: 100%;
      overflow-y: scroll;

      // flex: 4;
    }
  }
}
