@import "../../../variables.scss";


.testimonialcard {
    // width: 350px;
    width: 100%;
    height: 100%;
    // min-height: 400px;
    padding: 30px;
    background-color: #FAFAFA;
    border-radius: 15px;
    border: 1px solid $vesti-902;
    transition: all 1s ease;
    cursor: pointer;
    // flex: 1;
    // flex: auto;
    min-height: 250px;

    &:hover {
        filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
        border: 1px solid $vesti-700;
        background-color: $vesti-100;
    }

    @include breakpoint(mobileonly){
        width: 100%;
    }
    @include breakpoint(phablet){
        width: 300px;
    }
    @include breakpoint(tablet){
        width: 300px;
    }
    &__top{
        @include rowflexx(flex-start);
        img {
            margin-right:20px;
            width: 60px;
        }
        .title {
            margin: 0;
            @include fontSemiBold(1em,1em, $vesti-700);
        }

        .text-muted{
            display: block;
            margin-top: 5px;
            @include fontNormal(0.8em, 1em, $gray-500);
        }
    }
    &__text{
        margin-top: 20px;
        @include fontNormal(1em,1.6em, $vesti-901);
        text-overflow: ellipsis;
        // width: 95%;
        display: -webkit-box;
        -webkit-line-clamp: 25;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

// @media only screen and (min-width: 320px) and (max-width: 600px){
//     .card {

//         .card-body{
    
//             .card-body-top{
//                 display: flex;
//                 gap: 0.6rem;
             
    
//                 p:nth-child(1) {
//                     @include fontBlack(3.25em,1.3em, $vesti-700);
//                 }
    
//                 .text-muted{
//                     display: block;
//                 }
//             }
//             .card-text{
//                 p:nth-child(1) {
//                     @include fontBlack(3.25em,1.3em, $vesti-700);
//                 }
//             }
    
//         }
//     }
// }