@import '../../variables.scss';

.accountdetails {
  width: 100%;
  height: fit-content;
  @include rowflex(center, center);
  .success {
    width: 450px;
  }
  &__inner {
    width: 100%;
    height: fit-content;
    *.form-input {
      @include breakpoint(mobileonly) {
        display: flex;
        flex-direction: column !important;
      }
    }
  }
  &__picture {
    width: fit-content;
    position: relative;
    margin-bottom: 50px;
    img {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      border: 2px solid $vesti-700;
      object-fit: cover;

      @include breakpoint(mobileonly) {
        width: 80px;
        height: 80px;
      }
      @include breakpoint(phablet) {
        width: 100px;
        height: 100px;
      }
      @include breakpoint(tablet) {
        width: 124px;
        height: 125px;
      }
    }

    label {
      background-color: $vesti-700;
      color: white;
      // padding: .5rem 2rem .5rem 2rem ;
      width: 100px;
      height: 50px;
      font-family: sans-serif;
      border-radius: 50px;
      cursor: pointer;
      margin-top: 1rem;
      position: absolute;
      bottom: 0px;
      right: -30px;
      @include rowflex(center, center);
      @include fontBold(1em, 1em, #fff);
      transition: all 0.5s ease;

      &:hover {
        transform: scale(1.1);
      }

      @include breakpoint(mobileonly) {
        width: 80px;
        height: 30px;
        bottom: -20px;
      }
      @include breakpoint(phablet) {
        width: 100px;
        bottom: -10px;
      }
      @include breakpoint(tablet) {
        width: 125px;
        bottom: -10px;
      }
    }
  }
  &__form {
    width: 100% !important;
    height: fit-content;
    @include colflexx(flex-end);
    gap: 30px;

    .referal {
      width: 75%;

      @include breakpoint(mobileonly) {
        width: 90vw;
        overflow: hidden;
      }

      @include breakpoint(phablet) {
        width: 100%;
      }

      @include breakpoint(tablet) {
        width: 100%;
      }
      .referal {
        width: 100%;

        &__inner {
          width: 100%;

          p:nth-child(2) {
            font-size: 1.2em;
          }
        }
      }
    }
    .save-changes {
      width: 250px !important;
      height: 60px !important;
      margin-top: 100px;
      cursor: pointer;
      background-color: $vesti-700;
      color: #fff;
      border-radius: 10px !important;
      @include rowflex(center, center);
      @include fontBold(1em, 1em, #fff);
      transition: all 0.5s ease;

      &:hover {
        background-color: $vesti-900;
      }

      &:disabled {
        cursor: not-allowed;
        background-color: $gray-600;
      }
    }
  }
  .action-buttons {
    @include rowflex(center, space-between);
    gap: 1rem;
    .save-changes {
      width: 250px !important;
      height: 60px !important;
      cursor: pointer;
      background-color: $vesti-700;
      color: #fff;
      border-radius: 10px !important;
      @include rowflex(center, center);
      @include fontBold(1em, 1em, #fff);
      transition: all 0.5s ease;

      &:hover {
        background-color: $vesti-900;
      }

      &:disabled {
        cursor: not-allowed;
        background-color: $gray-600;
      }
    }
    .delete-account {
      width: 100% !important;
      height: 60px !important;
      margin-top: 100px;
      cursor: pointer;
      background-color: $red-100;

      border-radius: 10px !important;
      @include rowflex(center, center);
      @include fontBold(1em, 1em, $gray-800);
      transition: all 0.5s ease;

      &:hover {
        background-color: $red-900;
        color: $gray-100;
      }

      &:disabled {
        cursor: not-allowed;
        background-color: $gray-600;
      }
    }

    @include breakpoint(mobileonly) {
      @include colflex(center, center);
    }
  }
}

.deleteacct-container {
  @include colflex(center, center);
  background-color: red;
  width: 450px;
  .deleteacct-inner-container {
    .__top {
      @include rowflex(center, center);
      background-color: $vesti-100;
      margin-top: 1rem;
      padding: 2rem;
    }
    .__middle {
      @include colflex(center, center);

      p:nth-child(1) {
        @include fontTitleBold(2em, 1.5em, $vesti-901);
      }
      p:nth-child(2) {
        @include fontMedium(1.2em, 1.5em, $vesti-900);
        text-align: center;
      }

      .resend {
        @include fontMedium(1em, 1.5em, $vesti-700);
        cursor: pointer;
      }
    }
    .__bottom {
      @include rowflex(center, space-between);
      gap: 1rem;
      margin-top: -4rem;

      .cancel {
        width: 25% !important;
        height: 60px !important;
        margin-top: 100px;
        cursor: pointer;
        background-color: $gray-100;
        color: $vesti-700 !important;
        border-radius: 10px !important;
        border: 1px solid $vesti-700 !important;
        @include fontBold(1em, 1em, #fff);
        transition: all 0.5s ease;

        &:hover {
          background-color: $vesti-900;
          color: #fff !important;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: $gray-600;
        }
      }
      .delete-account {
        width: 25% !important;
        height: 60px !important;
        margin-top: 100px;
        cursor: pointer;
        background-color: $red-100;
        border-radius: 10px !important;
        @include fontBold(1em, 1em, $gray-800);
        transition: all 0.5s ease;

        &:hover {
          background-color: $red-900;
          color: $gray-100;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: $gray-600;
          color: #fff !important;
        }
      }
      .continue {
        width: 25% !important;
        height: 60px !important;
        margin-top: 100px;
        cursor: pointer;
        background-color: $vesti-700;
        border-radius: 10px !important;
        @include rowflex(center, center);
        @include fontBold(1em, 1em, $gray-100);
        transition: all 0.5s ease;

        &:hover {
          background-color: $vesti-900;
          color: $gray-100;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: $gray-600;
        }
      }
    }
  }
}
