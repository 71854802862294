@import '../../../variables.scss';

.cancelModal {
  max-width: 600px;

  &__article {
    margin: 2rem;

    & article {
      display: flex;
      flex-direction: column;
      gap: 10px;

      & li {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #2b521a;
      }
    }
  }

  &__title {
    color: #14280a;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 10px;
  }

  &__subtitle {
    color: #2b521a;
    font-weight: 600;
  }
}
