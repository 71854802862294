.student-loan {
  margin-top: -20px;
  .layout-container {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 1024px) {
      padding: 30px;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}



.containervb {
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 2rem;
  }

  .contentvb {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-top: 2rem;
    // margin-bottom: 2rem;

    @media (min-width: 768px) {
      // margin-top: 4rem;
    }

    p {
      margin-bottom: 1rem;
      color: #101828;
      font-weight: 600;
      font-size: 1.1rem;
    }

    img.credit-score-img {
      width: 100%;

      @media (min-width: 640px) {
        width: 80%;
      }

      @media (min-width: 768px) {
        width: auto;
      }

      max-width: 28rem;
    }

    .info-box {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      margin-top: 1.5rem;
      border: 1px solid #5261bc;
      padding: 16px;
      border-radius: 8px;
    


      img.info-icon {
        width: 1rem;
        height: 1rem;
      }

      p {
        color: #080d05;
        font-size: 16px;
        font-weight: 300;

        @media (min-width: 640px) {
          font-size: 0.75rem;
          // color: #667085;
        }
      }
    }
  }
}



.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    width: 100%;
    max-width: 300px; 
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    button {
      max-width: 100%; 
    }
  }
}