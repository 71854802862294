@import "../../../variables.scss";

.fileupload {
    width: 100%;
    height: fit-content;
    @include breakpoint(mobileonly){
        width: 100%;
        @include colflexx(flex-start);
    }
   
    &__container {
        width: 100%;
        height: 100px;
        margin-top: 10px;
        // width: 450px;
        // height: 180px;
        background: #F8FFF5;
        border: 1px dashed $vesti-700;
        box-sizing: border-box;
        border-radius: 10px;

        // @include colflex(center,center);
        @include rowflex(center,center);
        @include breakpoint(mobileonly){
            width: 100%;
            @include colflex(center,center);
        }
        .info{
            margin: 0px;
            margin-left: 10px;
            @include fontNormal(1em, 1em, $vesti-901);

            strong {
                font-weight: 500;
                color: $vesti-700 !important;
            }
        }
        label {
            background-color: $vesti-700;
            color: white;
            padding: 1rem 1rem 1rem 1rem ;
            width: fit-content;
            height: fit-content;
            font-family: sans-serif;
            border-radius: 5px;
            cursor: pointer;
            margin-top: .5rem;
            
            @include rowflex(center,center);
            @include fontMedium(1em,1em,#fff);
            transition: all .5s ease;

            @include breakpoint(mobileonly){
                width: 80%;
                text-align: center;
                @include fontBold(1em,1.2em,#fff);
            }
            &:hover {
                transform: scale(1.1);
            }

          }

        &__remove {
            background-color: $vesti-700;
            color: white;
            padding: 1rem 2rem 1rem 2rem ;
            width: fit-content;
            height: fit-content;
            font-family: sans-serif;
            border-radius: 20px;
            line-height: 20px !important;
            cursor: pointer;
            // white-space: normal !important;
            @include rowflex(center,center);
            @include fontBold(1em,1.4em,#fff);
            transition: all .5s ease;

            @include breakpoint(mobileonly){
                width: 60vw;
                text-align: center;
                @include fontBold(.8em,1.4em,#fff);
            }
            &:hover {
                transform: scale(1.1);
                background-color: red;
            }

        }

        
    }
    .overflow{
        margin-top: 20px;
        width: 100%;
        height: 150px;
        position: relative;
        overflow: hidden;
        border-radius: 10px;

        img {
            width: 100%;
            object-fit: 'cover';
            background-position: '30% 30%';
            background-size:' cover';
        }
    }

    &__btn{
        width: 100%;
        outline: none;
        border: none;
        margin-top: 30px;
        background-color: $vesti-700;
        @include fontMedium(1em,1em, #fff);
        transition: all .5s ease;
        &:hover {
            background-color: $vesti-800;
        }
        &:disabled {
            background-color: $gray-500;
            color: $gray-100;
            cursor: not-allowed;
        }
    }
   
}