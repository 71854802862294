@import '../../variables.scss';

.crop-container {
    position: relative;
    width: 100%;
    height: 200px;
    /* background: #333; */
    margin-bottom: 20px;
  }
  
  .crop-container button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
  }

  .newUploadContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 600px;
    height: 350px;

    @media (max-width: 768px){
      width: 450px;
      height: 350px;
  }
    @media (max-width: 550px){
      width: 360px;
      height: 280px;
  }
  @media (max-width: 550px){
    width: 280px;
    height: 220px;
  }
  @media (max-width: 350px){
    width: 220px;
    height: 200px;
  }
    .newUploadImg {
      width: 50px;
      height: 50px;
      border-radius: 50px;

      @media (max-width: 768px){
        width: 40px;
        height: 40px;
    }
    @media (max-width: 550px){
      width: 34px;
      height: 34px;
  }
    }

    .uploadName {
      font-size: 24px;

      @media (max-width: 768px){
        font-size: 18px;
    }
    }

    .largeModalText::placeholder {
      font-family: 14px;

      @media (max-width: 768px){
        font-size: 13px;
    }
    }

    .imgPreviewDiv {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;

      @media (max-width: 768px){
        gap: 2px;
    }
    }
    .imgPreview {
      width: 135px;
      height: 135px;
      object-fit: cover;
      border-radius: 5px;

      @media (max-width: 768px){
        width: 124px;
        height: 105px;
        margin-top: 30px;
    }
    }

    .imgIcon {
      width: 18px;
      height: 18px;
      cursor: pointer;
      opacity: 0.7;
    }

    .uploadEmojiDiv {
      right: 160%;
      top: -60%;

    }

    .largeModalPost {
      align-self: flex-end;
      border: 2px solid;
      border-radius: 10px;
      color: #FFFFFF;
      padding: 8px 24px;

      @media (max-width: 550px){
        padding: 6px 18px;
        font-size: 12px;
    }
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 2%;
  }

  }
  