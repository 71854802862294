.ty-loan-container {
  padding: 1rem;

  @media (min-width: 325px) {
    margin-top: -5px;
  }

  @media (min-width: 768px) {
    padding: 2rem;
    margin-top: -50px;
  }

  &__header {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    font-size: 1.125rem;
    justify-content: flex-start;
    cursor: pointer;

    @media (min-width: 640px) {
      font-size: 1.25rem;
    }

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    &__title {
      font-weight: 600;
      color: #101828;
      font-size: 1.25rem;

      @media (min-width: 640px) {
        font-size: 1.625rem;
      }
    }

    &__applieddy {
      margin-top: 15px;
      color: #101828;
      font-weight: 600;
      font-size: 25px;

      p {
        color: #101828;
        font-size: 20px;
        font-family: 600;
      }
    }
  }
}




.space__itemn {
  @media (min-width: 1024px) {
    gap: 20px;
  }
}


.space__UPAP {
  @media (min-width:1024px) {
    margin-top: 16px;
  }
}

.application__Optionap {
  width: 100%;
  background-color: #f9f8f9;
  margin-top: 20px;

  p {
    color: #9ca3af;
    height: 26px;
    text-align: left;
  }

  @media (max-width: 959px) {
    // margin-top: 30px;
  }
}

.input-container {
  width: 100%;

  @media (min-width: 1024px) {
    margin-top: -18px;
  }
}

// yes/no stylying
.containerfg {
  margin-top: 15px;
  .question {
    font-weight: 300; // font-light
  }

  .options {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    text-align: center;
    gap: 25px;

    .option {
      border: 2px solid #67a948;
      width: 50%;
      padding: 0.5rem;
      cursor: pointer;
      border-radius: 0.5rem; // rounded-lg
      color: #67a948;

      &.active {
        background-color: #67a948;
        color: white;
      }
    }
  }

  .option-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;

    .checkbox {
      width: 1rem; // w-4
    }

    .option-text {
      font-weight: 400; // font-normal
    }
  }
}

// clarity call
.containerzx {
  .description {
    font-weight: 300;

    span {
      font-weight: 700;
    }
  }

  .call-to-action {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.5rem;

    .call-text {
      color: #67a948;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

//upload stylying three
.containerthree {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .file-box {
    background-color: #f9f8f9;
    border: 0.5px solid #9094a0;
    height: 156px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;

    p {
      margin: 0.25rem 0;
      font-size: 0.875rem;
      color: #9ca3af;
    }

    .file-actions {
      color: #67a948;
      cursor: pointer;
    }
  }

  .file-group {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (min-width: 640px) {
      flex-direction: row;
      gap: 20px;
    }

    .file-box {
      width: 100%;

      @media (min-width: 640px) {
        width: 50%;
      }
    }
  }
}

// Upload stylying four
.application-details {
  .upload-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }

    .upload-box {
      background-color: #f9f8f9;
      border: 0.5px solid #9094a0;
      height: 156px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      text-align: center;

      img {
        margin-bottom: 0.5rem;
      }

      p {
        margin: 0.25rem 0;
        font-size: 0.875rem;
        color: #9ca3af;
      }

      .browse-text,
      .remove-text {
        color: #67a948;
        cursor: pointer;
        font-size: 0.875rem;
        margin-top: 0.5rem;
      }

      input[type='file'] {
        display: none;
      }
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2.5rem;

  .file-box {
    background-color: #f7f8fa;
    border: 1px solid #9094a0;
    height: 156px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;

    .file-actions {
      color: #67a948;
      cursor: pointer;
    }
  }

  .file-group {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 640px) {
      flex-direction: row;
      gap: 0.5rem;
    }

    .file-box {
      width: 100%;

      @media (min-width: 640px) {
        width: 50%;
      }
    }
  }
}

.button-containeryuup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -5px;
  @media(min-width: 1024px) {
    margin-top: 20px;
  }

  button {
    width: 100%;
    max-width: 300px; 
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    button {
      max-width: 100%;
      margin-top: -10px;
    }
  }
}
