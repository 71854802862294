@import '../../../variables.scss';

.myWalletTour {
  padding: 20px;
  // &__wallet {
  //   width: 30rem;
  //   margin-top: 6rem;
  //   height: 16rem;
  // }
  @include breakpoint(mobileonly) {
    padding: 5px;

    // @include colflexx(flex-start);
  }
}
.PathwayTourArticleCContainer {
  position: relative;
  padding: 10px;
  max-width: 500px;
  min-width: 300px;

  height: 100%;
  margin: 0px auto;

  &__tooltip {
    width: 18px;
    position: absolute;
    bottom: 0px;
    right: 50%;
    transform: rotate(180deg); /* Rotate the icon 90 degrees */
  }

  &__tooltipUp {
    width: 18px;
    position: absolute;
    top: 22px;
    right: 30%;
  }

  &__tracker {
    display: flex;
    gap: 2px;
  }
  &__inactive {
    background: #eaecf0;
    width: 10px;
    height: 10px;
    border-radius: 12px;
  }
  &__active {
    background: #67a948;
    width: 10px;
    height: 10px;
    border-radius: 12px;
  }
  &__next {
    background-color: #67a948;
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
  }
}

.TourArticle {
  background-color: white;
  // height: 15rem;
  margin-top: 20px;
  border-radius: 12px;
  // width: 100%;
  padding: 30px;
  @include breakpoint(mobileonly) {
    min-width: 350px;
    padding: 20px 5px;

    // @include colflexx(flex-start);
  }
}

.tourIcons {
  width: 40px;

  height: 40px;
  @include breakpoint(mobileonly) {
    width: 25px;

    height: 25px;

    // @include colflexx(flex-start);
  }
}
.BankTourFeaturetitle {
  font-size: 14px;
  font-weight: 500;
  @include breakpoint(mobileonly) {
    font-size: 12px;
  }
}
