@import "../../../variables.scss";

.amountinput{
    @include colflex(center,space-between);
    height:fit-content;
    width: 100%;
    @include breakpoint(mobileonly) {
        height:fit-content;
        @include colflex(center, space-between);
    }
    @include breakpoint(phablet) {
        width: 100%!important;
        
    }
    @include breakpoint(tablet) {
        width: 100%!important;
        
    }
   
    &__box {
        // @include colflexx(center);
        gap: 20px;
        position: relative;
        z-index: 10;
        input[type='text'] {
            height: 60px;
            background: transparent;
            outline: none;
            margin: none;
            border: none;
            padding: 0px !important;
            display: block !important;
            text-align: left !important;
            min-width: 50px !important;
            margin-left: 5px;
            transform-origin: center left !important;
            @include fontBold(1.5em, 1em, $vesti-500);
            @include breakpoint(mobileonly) {
                font-size: 2em !important;
            }
            @include breakpoint(desktop) {
                font-size: 3em !important;
            }
            &:focus {
                color: white !important;
                transform-origin: center left;
            }
        }
        #hide {
            position: absolute;
            opacity: 0;
            z-index: -100;
            white-space: pre;
            display: block;
            text-align: left;
            padding-right: 10px;
            padding-left: 10px;
            transform-origin: center left;
            @include fontBold(1.5em, 1em, $gray-600);

            @include breakpoint(mobileonly) {
                font-size: 1.5em;
            }
            @include breakpoint(desktop) {
                font-size: 3em;
            }
        }
    }
    
    &__label {
        width: fit-content;
        @include rowflexx(flex-end);
        gap: 10px;
        pointer-events: none;
        text-transform: uppercase;
        p{
            margin-bottom: 0px;
        }
    }
    
    
    &__currency {
        
        // padding-bottom: 12px;
        
        margin-right: 10px;
        margin: 0 !important;
        @include fontBold(1em,1em, $vesti-901);
        height: 25px;
        @include breakpoint(mobileonly){
            height: 30px;
        }
    }
    &__title {
        @include fontMedium(1.2em, 1em, $vesti-901);
    }
    &__bottom {
        position: relative;
        @include rowflexx(flex-end);
        width: fit-content;
        padding: 0px;
        

        &.--light{
            .amountinput__currency {
                color: $vesti-400;
            }
            .amountinput__box {
                input[type='text'] {
                    color: $vesti-400;

                    &:focus {
                        color: white;
                    }
                }
            }
        }
    }
    
}

.amountfloat{
    @include colflex(flex-start,space-between);
    height:fit-content;
    width: 100%;

    @include breakpoint(mobileonly) {
        height:fit-content;
        @include colflex(flex-start, space-between);
    }

    @include breakpoint(phablet) {
        width: 100%!important;
    }

    @include breakpoint(tablet) {
        width: 100%!important;
    }
   
    &__box {
        flex: 3;

        input:focus ~ &__label,
        input:not(:placeholder-shown) ~ &__label{
            top: 10px;
            left: 22px;
            font-size: 11px;
            opacity: 1;
            color:$vesti-700;
            margin-bottom: 10px;
        }
        
        input[type='text'], input[type='number'] {
            width: 100% !important;
            height: 60px;
            background: #F9F8F9;
            border: 1px solid $gray-400;
            box-sizing: border-box;
            border-radius: 5px;
            outline: none;
            // padding-left: 40px;
            padding-top: 0px;
            position: relative;
            font-size: 1em !important;
            @include fontNormal(1em, 1em, $gray-600);

            @include breakpoint(mobileonly) {
                width: 100%!important;
                height: 60px !important;
                font-size: 16px !important;
            }
            @include breakpoint(tablet) {
                width: 100% !important;
                height: 60px;
            }

            @include breakpoint(phablet) {
                width: 100% !important; //250PX
                height: 60px;
            }
            

            &:focus {
                border: 1px solid $vesti-700;
                background-color: $vesti-100;
                color: $vesti-901;
            }
            &:disabled {
                cursor: not-allowed;
                color: $gray-800;
                background-color: $gray-300;
            }

            &:invalid {
                border: 2px solid red;
                background-color: rgb(255, 211, 211);
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }

        }

        input[type=number] {
            -moz-appearance: textfield;
        }
    }

    &__label {
        position: absolute;
        pointer-events: none;
        left: 20px;
        top: 18px;
        opacity: 0;
        font-size: .8em !important;
        @include fontMedium(1em, 1em, $vesti-901);
        text-transform: uppercase;
        // background-color: red;
        transition: 0.2s ease all;
    }

    &__currency {
        position: absolute;
        left: 20px;
        top: 24px;
        max-width: 100px;
        border-right: 1px solid $vesti-700;
        padding-right: 5px;
        font-size: 1em !important;
        @include fontMedium(1em, 1em, $vesti-901);
        text-transform: uppercase;
    }
    
}