@import '../../variables.scss';

.new-modal {
  top: 30px !important;
  height: fit-content !important;
  position: relative !important;
  // width: 100% !important;
  // width: fit-content !important;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  // @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
  //   height: 90vh !important;
  // }

  // .ant-modal-content {
  //   // background-color: transparent;
  //   padding: 5px;
  //   // @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
  //   //   height: 90vh !important;
  //   // }
  // }
  .ant-modal-close {
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: -24px;
    z-index: 10 !important;
    text-align: center;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      width: 10%;
      @include rowflexx(flex-start);
    }
    @include breakpoint(phablet) {
      width: 6%;
      @include rowflexx(flex-start);
    }

    @include breakpoint(desktop) {
      width: fit-content;
      @include rowflexx(flex-start);
    }
  }
  .ant-modal-body {
    background-color: white;
    border-radius: 12px;
    width: 100%;
    .backcontinue.--bctop {
      margin-top: 0px;
      @include breakpoint(mobileonly) {
        margin-top: 80px;
        position: relative;
      }
      .backcontinue {
        @include breakpoint(mobileonly) {
          background-color: white;
          position: absolute;
          bottom: 20px;
          margin: 0 auto;
          width: 100%;
          height: 120px;
          left: 0px;
          right: 0px;
          button {
            margin: 0 auto;
            // margin-left: 10px;
            width: 100%;
          }
        }
      }
    }

    @include breakpoint(mobileonly) {
      // height: 90vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
      padding: 10px !important;
    }
  }
  .ant-modal-close-x {
    position: absolute;
    //    right: 50%;
    background-color: $vesti-400 !important;
    border: 2px solid $vesti-902;
    @include rowflex(center, center);
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}

.newCancel-modal {
  top: 30px !important;
  height: fit-content !important;
  position: relative !important;
  color: black;
  // width: 100% !important;
  // width: fit-content !important;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  .ant-modal-close {
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: 10px;
    z-index: 10 !important;
    text-align: center;
    color: black;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      width: 10%;
      @include rowflexx(flex-start);
    }
    @include breakpoint(phablet) {
      width: 6%;
      @include rowflexx(flex-start);
    }

    @include breakpoint(desktop) {
      width: fit-content;
      @include rowflexx(flex-start);
    }
  }
  .ant-modal-body {
    background-color: white;
    border-radius: 12px;
    width: 100%;
    .backcontinue.--bctop {
      margin-top: 0px;
      @include breakpoint(mobileonly) {
        margin-top: 80px;
        position: relative;
      }
      .backcontinue {
        @include breakpoint(mobileonly) {
          background-color: white;
          position: absolute;
          bottom: 20px;
          margin: 0 auto;
          width: 100%;
          height: 120px;
          left: 0px;
          right: 0px;
          button {
            margin: 0 auto;
            // margin-left: 10px;
            width: 100%;
          }
        }
      }
    }

    @include breakpoint(mobileonly) {
      // height: 90vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
      padding: 10px !important;
    }
  }
  .ant-modal-close-x {
    position: absolute;
    color: black;
    //    right: 50%;

    // border: 2px solid $vesti-902;
    // @include rowflex(center, center);
    // width: 60px;
    // height: 60px;
    // border-radius: 100%;
  }
}

.large-modalcancel {
  top: 30px !important;
  height: fit-content !important;
  position: relative !important;
  width: fit-content !important;
  border-radius: 20px;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  .ant-modal-close {
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: -80px;
    color: transparent;

    display: none;
  }
  .ant-modal-body {
    background-color: white;
    border-radius: 20px;

    .backcontinue.--bctop {
      margin-top: 0px;
      @include breakpoint(mobileonly) {
        margin-top: 80px;
      }
      .backcontinue {
        @include breakpoint(mobileonly) {
          background-color: white;
          position: absolute;
          bottom: 20px;
          margin: 0 auto;
          width: 90%;
          height: 120px;
          left: 0px;
          right: 0px;
          button {
            margin: 0 auto;
            margin-left: 10px;
            width: 90%;
          }
        }
      }
    }
    // top: 150px !important;

    @include breakpoint(mobileonly) {
      max-height: 70vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
    }
  }
  // .ant-modal-close-x {
  //   position: absolute;
  //   background-color: $vesti-400 !important;
  //   border: 2px solid $vesti-902;
  //   @include rowflex(center, center);
  //   width: 60px;
  //   height: 60px;
  //   border-radius: 100%;
  // }
}

.large-modal {
  top: 30px !important;
  height: fit-content !important;
  position: relative !important;
  width: fit-content !important;
  border-radius: 20px;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  // @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
  //   height: 90vh !important;
  // }

  // .ant-modal-content {
  //   // background-color: transparent;
  //   padding: 5px;
  //   border-radius: 20px;

  //   // @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
  //   //   height: 90vh !important;
  //   // }
  // }
  .ant-modal-close {
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: -24px;
    z-index: 10 !important;
    text-align: center;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      width: 10%;
      @include rowflexx(flex-start);
    }
    @include breakpoint(phablet) {
      width: 6%;
      @include rowflexx(flex-start);
    }

    @include breakpoint(desktop) {
      width: fit-content;
      @include rowflexx(flex-start);
    }
  }
  .ant-modal-body {
    background-color: white;
    border-radius: 20px;
    // height: 95vh;
    // .titlesubtitle.--tstop{
    //     z-index: 10;
    //     width: 100%;
    //     margin-bottom: 0px;
    //     background-color: white !important;
    //     @include breakpoint(mobileonly){
    //         width: 100%;
    //         margin-bottom: 120px;
    //         border-bottom: 1px solid $gray-400;
    //     }
    //     .titlesubtitle {

    //         @include breakpoint(mobileonly){
    //             background-color: white;
    //             position: absolute ;
    //             top: 0px ;
    //             margin: 0 auto;
    //             width: 90% !important ;
    //             height: 120px ;
    //             padding: 10px;
    //             padding-top: 30px;
    //             left: 0px ;
    //             right: 0px ;

    //         }
    //     }
    // }
    .backcontinue.--bctop {
      margin-top: 0px;
      @include breakpoint(mobileonly) {
        margin-top: 80px;
      }
      .backcontinue {
        @include breakpoint(mobileonly) {
          background-color: white;
          position: absolute;
          bottom: 20px;
          margin: 0 auto;
          width: 90%;
          height: 120px;
          left: 0px;
          right: 0px;
          button {
            margin: 0 auto;
            margin-left: 10px;
            width: 90%;
          }
        }
      }
    }
    // top: 150px !important;

    @include breakpoint(mobileonly) {
      max-height: 70vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
    }
  }
  .ant-modal-close-x {
    position: absolute;
    //    right: 50%;
    background-color: $vesti-400 !important;
    border: 2px solid $vesti-902;
    @include rowflex(center, center);
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}

.video-modal {
  top: 30px !important;
  height: fit-content !important;
  position: relative !important;
  width: fit-content !important;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  // @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
  //   height: 90vh !important;
  // }

  // .ant-modal-content {
  //   // background-color: transparent;
  //   padding: 5px;

  //   // @media screen and (min-width: 600px) and (max-width: 800px) and (orientation: landscape) {
  //   //   height: 90vh !important;
  //   // }
  // }
  .ant-modal-close {
    width: 100%;
    border-radius: 100%;
    position: absolute;
    top: -40px;
    z-index: 10 !important;
    text-align: center;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      width: 10%;
      @include rowflexx(flex-start);
    }
    @include breakpoint(phablet) {
      width: 6%;
      @include rowflexx(flex-start);
    }
    @include breakpoint(desktop) {
      width: fit-content;
      @include rowflexx(flex-start);
    }
  }
  .ant-modal-body {
    background-color: white;
    border-radius: 12px;
    padding: 5px;

    .backcontinue.--bctop {
      margin-top: 0px;
      @include breakpoint(mobileonly) {
        margin-top: 80px;
      }
      .backcontinue {
        @include breakpoint(mobileonly) {
          background-color: white;
          position: absolute;
          bottom: 20px;
          margin: 0 auto;
          width: 90%;
          height: 120px;
          left: 0px;
          right: 0px;
          button {
            margin: 0 auto;
            margin-left: 10px;
            width: 90%;
          }
        }
      }
    }
    // top: 150px !important;

    @include breakpoint(mobileonly) {
      max-height: 70vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
      padding: 0;
    }
    @include breakpoint(phablet) {
      max-height: 70vh !important;
      overflow-y: scroll;
      overflow-x: hidden;
      @include overflowScroll($vesti-200, $vesti-800);
      padding: 5px;
    }
  }
  .ant-modal-close-x {
    position: absolute;
    //    right: 50%;
    background-color: $vesti-400 !important;
    border: 2px solid $vesti-902;
    @include rowflex(center, center);
    width: 60px;
    height: 60px;
    border-radius: 100%;
  }
}

.tour-modal {
  top: 30px !important;
  height: 100vh !important;
  position: absolute !important;
  width: 100vw !important;
  border-radius: 20px;
  background-color: transparent;
  box-shadow: none;

  @include breakpoint(phablet) {
    height: fit-content !important;
  }

  .ant-modal-body {
    border-radius: 20px;
    box-shadow: none;

    @include breakpoint(mobileonly) {
      // max-height: 70vh !important;
      // overflow-y: scroll;
      // overflow-x: hidden;
      // @include overflowScroll($vesti-200, $vesti-800);
    }
  }

  .ant-modal-content {
    background: transparent; /* Set content background to transparent */
  }

  .ant-modal-body {
    padding: 0; /* Remove default padding */
    box-shadow: none;
  }
}
