.card {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: white;
    width: 303px;
    border-radius: 10px;
    


    @media (max-width: 1024px) {
      width: 312px; 
    }
  
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .card__header-content {
        display: flex;
        align-items: center;
        gap: 5px;
  
        img {
          width: 28px;
          height: 28px;
        }
  
        p {
          margin-left: 0.5rem;
          font-size: 16px;
        }
      }
  
    //   p.time {
    //     // Add styles for the time element if needed
    //   }
    }
  
    &__description {
      margin-top: 1rem;
      font-size: 14px;
      color: #14290A;
      font-weight: 300; // Light font weight
    }
  }
  