@import '../../../variables.scss';

.onboardrequestCard {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #aeb4be;
  background: #f9f8f9;
  height: 56px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  //   margin-bottom: 20px;
}

.onboardrequestCardinput {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #aeb4be;
  background: #f9f8f9;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
}

.GGdob {
  width: 100%;
  border-radius: 6px;
  border: 1px solid #bec1c4;
  background: #f9f8f9;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin-top: 15px;
}

.YesGG {
  margin-top: -30px;
}

.checked-green {
  background-color: green; /* Set the background color to green */
}

.tranOnboardingMobile {
  @include breakpoint(mobileonly) {
    margin-top: -10rem;
  }
}

.onBoardLeft {
  @include breakpoint(desktop) {
    width: 60vw;
  }
}
