.merchant-card {
  background: #fcfffa;
  border: 0.0005px solid #bfe7b0;
  border-radius: 20px;
}

/* #savings {

  max-width: 500px !important;

} */

.merchant-card__title {
  font-weight: bold;
  font-size: 16px;
  color: #3e6f26;
}

.merchant-card__amount {
  color: #67a948;
  font-weight: bold;
  font-size: 18px;
}

.merchant-card__abbr {
  font-weight: bold;
  font-size: 13px;
  background-color: #dfffd0;
  text-align: center;
  color: #3e6f26;
  width: 50px;
  border-radius: 100%;
}

.merchant-card__desc {
  font-weight: 300;
  font-size: 16px;
}

.mygrid {
  display: grid;
  grid-template-columns: auto;
  gap: 20px;
  width: 100%;
}

@media (min-width: 850px) {
  .mygrid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
}

@media (min-width: 1200px) {
  .mygrid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }
}

@media (min-width: 1330px) {
  .mygrid {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
  }
}

/* @media (max-width: 900) {
  .mygrid{
    display: grid;
   grid-template-columns: auto auto;
   gap: 20px;
  }
} */
/* .merchant-card-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
} */

.merchant-card__btn {
  width: fit-content !important;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
  transition: all 0.5s ease;
  font-size: 16px;
}

.merchant-card__btn:hover i {
  margin-left: 20px !important;
}
.merchant-card__btn button {
  width: fit-content !important;
}
.merchant-card__btn i {
  color: #67a948;
  margin-left: 10px !important;
  transition: all 0.5s ease;
}
.merchant-card__btn span {
  color: #67a948;
  transition: all 0.5s ease;
}

.member-card__title {
  font-weight: bold;
  font-size: 12px;
  color: #67a948;
  /* margin-left: 22px; */
}

.campaign-card__title {
  font-size: 16px;
  color: #67a948;
}

.black__title {
  font-weight: bold;
  font-size: 20px;
  color: #14290a;
}
.plan-card__title {
  font-size: 10px;
  color: #2b5219;
}

.green__title {
  font-weight: bold;
  font-size: 12px;
  color: #67a948;
}
.free_label {
  font-weight: bold;
  font-size: 16px;
  background-color: #fffaed;
  width: 50px;
}

.standard_label {
  font-weight: bold;
  font-size: 10px;
  color: #518c36;
  background-color: #f7fff3;
  width: 160px;
}

.date__title {
  font-weight: bold;
  font-size: 11px;
  color: #8b8c8c;
}

.member__title {
  font-weight: bold;
  font-size: 35px;
  color: #8b8c8c;
  margin-left: 2%;
}
.member-webinar__title {
  font-weight: bold;
  font-size: 35px;
  color: #67a948;
}

.more {
  justify-self: center;
  margin-left: 40%;
  margin-bottom: 10%;
}

.marginP {
  margin-right: -200px;
}

.marginPP {
  margin: 50px;
}

.margin30 {
  margin: 30px;
}

.plan-card {
  background: #fcfffa;
  position: relative;
  border-radius: 10px;
}

.check {
  background: #fcfffa;
  height: 4rem;
  width: 4rem;
}

.circleLeft {
  border-radius: 50%;
  position: absolute;
  left: -15%;
  right: 80%;
  top: -10%;
  bottom: 90%;
  background: #67a948;
  overflow: hidden !important;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.circleRight {
  border-radius: 50%;
  position: absolute;
  left: 80%;
  right: -15%;
  top: -10%;
  bottom: 90%;
  background: #67a948;
  overflow: hidden !important;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.backBtn {
  padding: 10px 20px;
  border-radius: 5px;
  color: #67a948 !important;
  border: 0.5px solid #67a948;
  font-weight: 400;
  font-size: 1.2rem;
  background: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.campaign-card {
  background: #f7fff3;
  border-radius: 20px;
  width: 620px;
  max-height: 82px;
  display: inline-flex;
  vertical-align: top;
}

.main {
  /* flex: 1; */
  text-align: left;
  margin-right: 280px;
}
.image {
  margin-top: 10px;
  margin-right: 15px;
  flex: 1;
  height: 100%;
}
.padlockIcon {
  max-width: 120px;
  max-height: 120px;
  background: #ffffff;
  border: 10px solid #ffffff;
  border-radius: 50%;

  margin-top: -40px;
  position: relative !important;
}

.padlock {
  float: right;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #14290a !important;
  font-weight: bold !important;
}

.MuiTabs-indicator {
  background-color: #67a948 !important;
  height: 3px !important;
}
@media (min-width: 600px) {
  .MuiTab-root {
    min-width: 260px !important;
  }
}
.PrivateTabIndicator {
  background-color: #67a948 !important;
}

.MuiTab-indicatorColorPrimary {
  color: #67a948 !important;
  background-color: #67a948 !important;
}

.MuiTab-root {
  padding: 6px 12px;
  overflow: hidden;
  position: relative;
  font-size: 15px !important;
  max-width: 264px;
  min-width: 72px;
  /* box-sizing: border-box; */
  min-height: 48px;
  /* text-align: center; */
  /* flex-shrink: 0; */
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  font-family: 'Lato', 'Helvetica', 'Arial', sans-serif;
  font-weight: bold !important;
  line-height: 29px !important;
  color: #14290a;
  white-space: normal;
  letter-spacing: 0em !important;
  text-transform: none !important;
}

.merchant-services {
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  /* align-items: center; */
  /* max-height: 110em; */
  max-height: 80em;
  gap: 0px;
  margin: 0 auto;
  /* background-color: red; */
}

@media (min-width: 360px) and (max-width: 768px) {
  .merchant-services {
    flex-direction: column;
    flex-wrap: nowrap !important;
    /* align-items: flex-start; */
    height: fit-content;
    max-height: fit-content !important;
  }
  .merchant-card {
    width: 90vw;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .merchant-services {
    max-height: 170em !important;
    /* background-color: red; */
  }
}
@media (min-width: 800px) and (max-width: 1280px) {
  .merchant-services {
    /* max-height:170em !important; */
    max-height: 120em !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .merchant-services {
    /* max-height:170em !important; */
    max-height: 120em !important;
    /* background-color: red; */
  }
}

/* .MuiCardContent-root {
  margin: 10px !important;
}

.makeStyles-membershipCard-3 {
  margin: 5px !important;
  max-width: 200px !important;
  max-height: 200px !important;
}

.makeStyles-card-2 {
  max-width: 200px !important;
  max-height: 200px !important;
} */
