.student-loan {
  margin-top: -20px;
  .layout-container {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 1024px) {
      padding: 30px;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .title {
    display: flex;
    gap: 2px;
    align-items: center;
    font-size: 24px;
    justify-content: flex-start;
    font-weight: 600;
    color: #101828;
  }

  .loan-section {
    height: calc(100vh - 80px);

    .no-loan {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: center;
      background-color: #f8f7fa;
      padding: 16px;
      margin-top: 12px;
      border-radius: 0.5rem;

      img {
        margin-bottom: 16px;
      }

      p {
        margin: 4px 0;
      }
    }

    .loan-available {
      .title {
        margin-top: 24px;
      }

      .cards-container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 12px;
        margin-bottom: 20px;
        cursor: pointer;
      }
    }

    .customer-reviews {
      overflow: hidden;
      width: 100%;
      position: relative;
    }

    .reviews-container {
      display: flex;
      // gap: 10px;
      animation: slide 10s linear infinite; 
      cursor: pointer;
    }

    .reviews-container:hover {
      animation-play-state: paused;
    }

    @keyframes slide {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-50%); 
      }
    }

    .customer-reviews-card {
      min-width: 20%;
      flex-shrink: 0;
      margin-right: 20px;
    }
  }
}



