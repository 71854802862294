@import "../../variables.scss";

.kyclvl, .kyclvl__waitlist {
    width: 100%;
    @include colflexx(flex-start);

  
    .select-input:nth-child(2){
        margin-bottom: 15px;
    }
    .inputfloat:nth-child(2){
        margin-bottom: 15px;
    }

   

    .titlesubtitle{
        margin-bottom: 20px;
    }
    
    &__location {
        width: 100%;
        @include rowflexx(flex-start);
        gap: 10px;
        margin-top: 20px;
        margin-bottom: 15px;

        @include breakpoint(mobileonly){
            @include colflexx(flex-start);
            gap: 10px;
            margin-top: 15px;
            margin-bottom: 10px;
        }
        @include breakpoint(phablet){
            @include colflex(flex-start,center);
            gap: 10px;
        }
    }

    &.--error {
        padding: 10px;
        background-color:  rgb(255, 234, 234);
        border: .5px solid red;
        border-radius: 5px;
        @include fontMedium(1em, 1.2em, red);

        @include breakpoint(mobileonly){
            padding: 5px;
            @include fontMedium(.8em, 1.2em, red);
        }
    }

    .inputfloat {

        width: 100% !important;

        @include breakpoint(mobileonly){
            width: 70vw !important;
        }
        @include breakpoint(phablet){
            width: 40vw !important;
        }
        @include breakpoint(tablet){
            width: 40vw !important;
        }
        .inputfloat__box {
            input {
                @include breakpoint(mobileonly) {
                    // width: 65vw!important;
                }
            }
            .shortinfo{
                margin-top: 10px !important;
            }
    
            .apto-error{
                margin: 0;
                @include fontNormal(1em, 1em , red);
            }
        }
        
    }
    &__btn {
        width: 100%;
        height: 50px;
        margin-top: 30px;
        background-color:  $vesti-700;
        color: #fff;
        transition: all .5s ease;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color:  $vesti-900;
        }

        &:disabled{
            background-color: $gray-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
}

.kyclvl__waitlist{
    .inputfloat:nth-child(3){
        margin-bottom: 15px;
    }
    .inputfloat {

        width: 350px;
    }
}

.kycustomer, .kycothers {
    width: 450px;
    @include breakpoint(mobileonly){
        width: 80vw;
    }
    @include breakpoint(desktop){
        width: 500px;
        // background-color: red;
    }

    &__country {
        width: 100%;
        @include colflexx(flex-start);
        gap: 30px;

        span {
            width: 100%;
            @include colflexx(flex-start);
            gap: 5px;
            p:nth-child(1){
                @include fontMedium(1em,1em,$vesti-900);
            }
        }

    }

    &__initiate{
        width: 100%;
        @include colflexx(center);
        gap: 40px;
    }

    &__content {
        @include colflexx(center);
        gap: 20px;

        img {
            width: auto;
        }

        span {
            @include colflexx(center);
            gap: 0px;
            max-width: 250px;
            p{
                text-align: center;
            }
            p:nth-child(1){
                @include fontMedium(1.4em,1em, $vesti-800);
            }
            p:nth-child(2){
                @include fontNormal(1em,1.4em, $vesti-902);
            }
        }
    }

    &__button {
        width: 100%;
        height: 60px !important;
        cursor: pointer;
        background-color: $vesti-700;
        color: #fff;
        margin-top: 10px;
        border-radius: 10px !important;
        @include rowflex(center,center);
        @include fontBold(1.2em, 1em, #fff);
        transition: all .5s ease;
        outline: none;
        &:hover {
            background-color: $vesti-900;
        }

        &:disabled{
            cursor: not-allowed;
            background-color: $gray-600;

        }

        @include breakpoint(mobileonly){
            font-size: 16px;
        }
        @include breakpoint(phablet){
            font-size: 12px;
        }
    }
}

.kycothers{
    background-color: transparent;
}