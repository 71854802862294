@import '../../../variables.scss';

.create-savings-box {
  //   height: 90%;
  width: 100%;

  h4 {
    margin: 0;
    @include fontBold(2em, 1em, $gray-400);
  }

  .datePicker {
    z-index: 9999 !important;
  }

  .dob {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border: none;
    box-sizing: border-box;
    margin-top: 0px;
    box-shadow: none;
    width: 100% !important;
    margin-top: 10px;
    height: 60px;
    background: #f9f8f9;
    border: 1px solid $gray-400;
    box-sizing: border-box;
    outline: none;
    padding-left: 20px;
    margin-bottom: 10px;
    position: relative;

    @include fontNormal(1em, 1em, $gray-600);

    @include breakpoint(mobileonly) {
      width: 100% !important;
      height: 55px;
    }

    @include breakpoint(tablet) {
      width: 100% !important;
      height: 55px;
    }

    @include breakpoint(phablet) {
      width: 100% !important;
    }

    &:focus {
      border: 1px solid $vesti-700;
      background-color: $vesti-100;
      color: $vesti-901;
    }

    &:disabled {
      cursor: not-allowed;
      color: $gray-800;
    }
  }

  .CalendarContainer-sc-1gorc7r-0 {
    z-index: 10 !important;
  }

  .create-savings-form {
    width: 100%;
    // @include colflex(center, center);

    .title {
      @include colflex(flex-start, flex-start);

      p:nth-child(1) {
        @include fontMedium(1rem, 1.5rem, $vesti-700);
      }

      p:nth-child(2) {
        @include fontBold(1.5rem, 1.5rem, $vesti-901);
      }

      p:nth-child(3) {
        margin-top: 1rem;
        @include fontNormal(1rem, 1.5rem, $vesti-901);
      }
    }

    .radios {
      width: 100%;
      @include colflex(center, space-between);
      gap: 1rem;
    }

    &:focus {
      border: 1px solid $vesti-700;
      background-color: $vesti-100;
      color: $vesti-901;
    }

    .expiry {
      @include rowflex(center, space-between);
      gap: 1rem;
    }
  }

  .new-savings-btn {
    background-color: $vesti-700;
    height: 50px;
    color: #fff;
    transition: all 0.5s ease;
    border-radius: 5px !important;

    &:hover {
      background-color: $vesti-800;
      color: #fff;
    }

    &:disabled {
      background-color: $gray-600;
      color: $gray-800;
      cursor: not-allowed;
    }
  }
}

.saving--container {
  height: 85vh;
  width: 100%;
  overflow: scroll;
  padding-left: 30px !important;

  @include breakpoint(mobileonly) {
    padding-left: 20px !important;
  }

  @include breakpoint(phablet) {
    padding-left: 20px !important;
  }

  .withdraw__savings {
    margin-top: 30px;
    background-color: $vesti-100;
    height: 50px;
    color: #fff;
    transition: all 0.5s ease;
    border-radius: 5px !important;
    @include fontMedium(1em, 1em, $vesti-901);

    &:hover {
      background-color: $vesti-800;
      color: #fff;
    }

    &:disabled {
      background-color: $gray-600;
      color: $gray-800;
      cursor: not-allowed;
    }
  }
}

.savingType-container {
  // width: 95%;
  // padding: 1rem;
  @include colflex(center, center);

  @include breakpoint(mobileonly) {
    padding: 0;
  }

  .savingType-inner {
    width: 100%;
    gap: 1rem;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      gap: 0;
      width: 100%;
      @include colflex(center, center);
    }
  }

  .savingTypesmall-inner {
    width: 100%;
    gap: 1rem;
    @include colflex(center, center);

    @include breakpoint(mobileonly) {
      gap: 0;
      width: 100%;
      @include colflex(center, center);
    }
  }
}

.savings-main {
  padding: 1rem;
  @include rowflex(stretch, center);
  gap: 1rem;

  @include breakpoint(mobileonly) {
    @include colflex(center, center);
    gap: 0;
  }

  @include breakpoint(phablet) {
    @include rowflex(center, center);
    gap: 1rem;
  }

  .savings-main-inner {
    width: 100%;
    gap: 1rem;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      @include colflex(center, center);
      gap: 0;
    }
  }
}

.topup-transreview {
  @include breakpoint(mobileonly) {
    margin-bottom: 10rem;
  }
}

.newsavings-backcontinue {
  @include breakpoint(mobileonly) {
    margin-top: 10rem;
  }
}

.step2-backcontinue {
  @include breakpoint(mobileonly) {
    margin-top: 10rem;
  }
}

.step3-backcontinue {
  @include breakpoint(mobileonly) {
    margin-top: 10rem;
  }
}

.paystackbutton {
  width: 100%;
  background-color: $vesti-700;
  color: $vesti-100;
  @include rowflex(center, center);
  padding: 1rem;
}

.addcardsafelock {
  width: 100%;
  @include colflex(center, center);
}

.pincode-input-container {
  @include rowflex(center, flex-start);
  @include breakpoint(mobileonly) {
    max-width: 300px !important;
    width: fit-content !important;
    display: row !important;
    flex-wrap: wrap !important;
  }

  gap: 10px !important;

  .pincode-input-text {
    width: 50px !important;
    height: 50px !important;
  }
  .pincode-input-text:nth-child(5) {
    @include breakpoint(mobileonly) {
      margin-top: 20px !important;
    }
  }
}
