@import "../../variables.scss";

.cards {
    width: 100%;
    height: fit-content;
    gap: 0px;
    @include rowflexx(flex-start);
    flex-wrap: nowrap;
    gap: 10px;
    @include breakpoint(mobileonly){
        width: 90vw;
        @include colflexx(flex-start);
    }
   
    /* Landscape */
    @media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: landscape) 
    and (-webkit-min-device-pixel-ratio: 2) {
        // @include colflexx(flex-start);
    }
    &__top {
        width: 100%;
        @include colflex(center,center);

        @include breakpoint(mobileonly){
            @include colflex(center,center);
        }
        
    }
    &__boxes {
        @include rowflexx(flex-start);
        width: fit-content;
        margin-top: 15px;
        gap: 10px;
        flex-wrap: wrap !important;
        transition: all .5s ease;
        
        @include breakpoint(mobileonly){
            @include rowflex(flex-start,center);
            max-width: 80%;
        }
        
       
    }
    &__box {
        width: 15px;
        height: 15px;
        background-color: $vesti-400;
        border-radius: 50%;
        transition: all .5s ease;

        &.--active {
            background-color: $vesti-700;
            width: 50px;
            border-radius: 10px;
        }

        &:hover {
            background-color: $vesti-700;
        }
    }
    &__createcard{
        background-color: $vesti-200;
        border: 1px dashed $vesti-700;
        border-radius: 20px;
        height: 100%;
        // width: 80px;
        padding: 30px;
        cursor: pointer;
        transition: all .5s ease;
        @include rowflex(center,center);

        @include breakpoint(mobileonly){
            width: 100%;
            height: 100px;
            margin-left:0px;
            margin-top: 30px;
        }
        @include breakpoint(phablet){
            // width: 50px;
            // max-width: 50px;
            height: 250px;
            margin-left:0px;
            margin-top: 0px;
        }
        @include breakpoint(tablet){
            // width: 50px;
            // max-width: 50px;
            // height: 100px;
            margin-left:0px;
        }
        @include breakpoint(desktop){
            height:350px;
            // height: 92%;
            margin-left:0px;
        }
        p{
            text-align: center;
            white-space: nowrap;
            @include colflexx(center);
            gap: 10px;
            @include fontMedium(1em, 1em, $vesti-700);

            @include breakpoint(mobileonly){
                @include rowflex(center,center);
            }
        }

        &:hover {
            background-color: $vesti-300;
            border: 2px dashed $vesti-700;
        }
    }
}

.cardaction {

    width: 100%;
    @include colflexx(flex-start);
    gap: 20px;
    .vestirate {
        margin-bottom: 10px;
    }
    &__col, &__bcol, &__scol {
        width: 400px !important;
        @include colflexx(flex-start);

        @include breakpoint(mobileonly){
            width: 75vw !important;
        }
    }
    &__bcol {
        gap: 30px;
        *.backcontinue {
            margin-top: 0px !important;
        }
    }
    &__col {
        gap: 25px;
        *.backcontinue {
            margin-top: 0px !important;
        }
    }
    &__scol {
        gap: 10px;
    }
}