@import "../../variables.scss";

.becomeprovider-container{
    width: 21em;
    height: fit-content;
    @include rowflex(center,center);
    text-align: center;
    margin-top: 50px;

    .becomeprovider-inner {
        @include colflexx(center);

        img {
            width: auto;

        }
        p:nth-child(2){
            margin: 0;
            margin-top: 30px;
            @include fontBold(1.5em, 1em, $vesti-800);
        }
        p:nth-child(3){

            width: 90%;
            margin-top: 10px;
            @include fontNormal(1.1em, 1.7em, $vesti-901);
        }

       
        .becomeprovider-btn {
            background-color: $vesti-700;
            height: 50px;
            color: #fff;
            transition: all 0.5s ease;
            border-radius: 5px !important;
    
            &:hover {
                background-color: $vesti-800;
                color: #fff;
            }
           
        }
    }
}

.pathusers {
    width: fit-content;
    height: fit-content;
    @include rowflexx(center);

    &__content{
        @include rowflexx(center);
        gap: -20px;
    }
    &__single:not(:first-child){
        margin-left: -15px !important;
    }
    &__single {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        @include rowflex(center,center);
        text-transform: uppercase;
        @include fontSemiBold(1em,1em,$vesti-800);
        cursor: pointer;
        transition: all ease-in .5s;
        border: 3px solid #fff;
        &.--green {
            background-color: $vesti-300;
            color: $vesti-800;
        }
        &.--orange {
            background-color: $yellow-300;
            color: $yellow-800;
        }
        &.--purple {
            background-color: $vpurp-300;
            color: $vpurp-800;
        }
        &.--cyan {
            background-color: $cyan-300;
            color: $cyan-800;
        }

        &:hover {
            transform: scale(1.5);
        }
    }
    &__plus{
        width: fit-content;
        @include fontMedium(1em,1em,$vesti-902);
    }
    &__number {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-color: $gray-200;
        @include rowflex(center,center);
        border: 3px solid #fff;
        @include fontSemiBold(1em,1em,$vesti-902);
        margin-left: -15px !important;
    }
}

.pathdays{
    width: 100%;
    background-color: $yellow-700;
    border: 1px solid $yellow-900;
    border-radius: 20px;
    padding: 0px 30px;
    padding-bottom: 25px;
    padding-top: 25px;
    &__inner {
        width: 100%;
        @include rowflex(center,space-between);
    }
    &__left {
        @include colflexx(flex-start);
        gap: 10px;

        p:nth-child(1){
            text-transform: uppercase;
            @include fontNormal(1em,1em,$yellow-100);
        }

        p:nth-child(2){
            text-transform: capitalize;
            @include fontMedium(1.2em,1em,$yellow-200);
        }

    }

    &__right {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        background-color: $yellow-900;
        @include rowflex(center,center);
        @include fontSemiBold(1.5em,1em,$yellow-200);
    }
}

.userusing{
    @include colflexx(center);
    gap: 20px;
    .pathusers__single{
        width: 100px;
        height: 100px;
    }
    &__bottom {
        @include colflexx(center);
        gap: 10px;
    }
    &__name {
        @include fontSemiBold(1.2em,1em,$vesti-900);
    }

    &__info {
        @include rowflexx(center);
        gap: 10px;

        p{
            @include fontMedium(1em,1em,$gray-500)
        }
    }
}