@import "../../../variables.scss";

.pagetop {

    width: 100%;
    height: 50vh;
    background-color: #F7FFF3;
    @include colflex(center,center);
    p{
        margin: 0;
        @include fontBold(2.8em, 1.5em, $vesti-902);

        @include breakpoint(mobileonly){
            text-align: center;
        }
    }

    &__links {
        margin-top:30px;
        @include rowflexx(center);
        transition: all .5s ease;
        
        p{
            margin: 0;
            color: $vesti-901 !important;

            @include fontMedium(1.5em, 1em, $vesti-901);

            .link {
                font-size: 1em;
                color:$vesti-901;
                &:hover {
                    color:$vesti-700;
                }
            }
            
        }
         p:not(:first-child) {
             margin-left: 10px;
         }
        
    }
}