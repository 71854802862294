@import '../../variables.scss';

.mypostInput {
  width: 100%;
  background-color: $gray-200;
  border-radius: 60px;
  p {
    margin: 0;
    @include fontNormal(1em, 1em, $vesti-901);
  }

  label {
    margin: 0;
    @include fontNormal(1em, 1em, $vesti-901);
  }

  input[type='text'] {
    width: 100% !important;
    border: none;
    padding: 0.5rem 0px 0.5rem 20px;
    border-radius: 60px;
    box-sizing: border-box;
    outline: none;
    // padding-left: 20px;
    // background-color: $gray-200;
    @include fontNormal(1em, 1em, $gray-600);

    @include breakpoint(tablet) {
      height: 20px;
    }
  }

  &__input {
    width: 100% !important;
    border: none;
    padding: 0.5rem 0px 0.5rem 20px;
    margin: 0;
    // margin-top: 10px;
    border-radius: 60px;
    box-sizing: border-box;
    outline: none;
    // padding: 20px;
    background-color: $gray-200;
    @include fontNormal(1em, 1em, $gray-600);

    // @include breakpoint(tablet) {
    //     height: 60px;
    // }

    &__input:hover {
      border: 1px solid $vesti-700;
      // background-color: $vesti-100;
      color: $vesti-901;
    }
  }
}

.mypostInput:hover {
  border: 1px solid #b6bbc9;
  // background-color: $vesti-100;
  color: $vesti-901;
  border-radius: 60px;
}
