@import "../../../variables.scss";

.listcheck {
    margin-top: 20px;
    @include colflexx(flex-start);
    gap: 10px;
    p:not(:first-child){
        margin-top: 5px;
    }
    @include breakpoint(mobileonly){
        width: 100%;
    }
    p{
        // margin: 0;
        @include rowflexx(flex-start);
        @include fontNormal(1em, 1.4em, $vesti-901);

        @include breakpoint(mobileonly){
            width: 85%;
            @include fontNormal(1em, 1.4em, $vesti-901);
        }
        img {
            margin-right: 10px;
        }
    }
}

.listnocheck {
    @include colflexx(flex-start);
    padding: 0px;
    list-style: none;

    p:not(:first-child){
        margin-top: 5px;
    }
    @include breakpoint(mobileonly){
        width: 100%;
    }
    li{
        // margin: 0;
        @include rowflexx(flex-start);
        @include fontNormal(1em, 1.4em, $vesti-901);

        @include breakpoint(mobileonly){
            width: 85%;
            @include fontNormal(1em, 1.4em, $vesti-901);
        }
    }
}

.numcheck {
    @include colflexx(flex-start);
    gap: 5px;
    margin-bottom: 0px;
    height: fit-content;
    width: 100%;
    &__list {
        @include rowflexx(flex-start);
        gap: 10px;
    }
    &__body {
        @include fontNormal(1em,1.2em,$vesti-901);
        margin: 0px;
        width: 80%;

        strong {
            color: $vesti-700;
        }
        a {
            color: $vesti-700;
            text-decoration: underline;
        }
    }
    &__num{
        width: 30px;
        height: 30px;
        // padding: 5px;
        margin: 0px;
        @include rowflex(center,center);
        @include fontMedium(.8em,1em,#fff);
        background-color: $vesti-700;
        border-radius: 100%;
        white-space: pre;
    }
}