@import "../../variables.scss";

.security,
.ref {
    width: 100%;
    height: fit-content;

    // @include rowflex(center,center);
    .new-modal .ant-modal-content .ant-modal-body form {
        width: 100% !important;
        height: fit-content;
        @include colflexx(flex-start);
        gap: 20px;

        .updateprofile-btn-box {
            width: 100%;
            margin-top: 0px;
        }
    }

    &__container {
        width: 100%;
        height: fit-content;
        @include colflexx(flex-start);
        gap: 20px;
    }

    &__bottom {
        width: 100%;
        height: fit-content;
        @include colflexx(flex-start);
        gap: 50px;

        .titleinfo {
            width: 100%;
            @include rowflex(flex-start, space-between);

            @include breakpoint(mobileonly) {
                @include colflexx(flex-start);
                gap: 20px;
            }

            span {
                @include colflexx(flex-start);
                gap: 15px;

                p:nth-child(1) {
                    @include fontBold(1.2em, 1em, $vesti-902);
                }

                p:nth-child(2) {
                    white-space: nowrap;
                    @include fontMedium(1.2em, 1em, $vesti-800);
                }
            }

            &__link {
                @include rowflexx(center);
                flex-wrap: wrap;
                gap: 10px;
                @include fontSemiBold(1.2em, 1em, $vesti-700);
                font-style: italic;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }

    &__box {
        width: 100%;
    }
}

