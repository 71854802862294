@import '../../variables.scss';

.sidebar {
  background-color: $sec-902;
  @include rowflexx(flex-start);

  &__content {
    width: 100%;
    height: fit-content;
    padding-bottom: 100px;
    position: relative;
    @include colflexx(flex-end);
    gap: 30px;
  }
  &__vestilogo {
    z-index: 2;
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    margin: 0 0 10px 0;
    background-color: $sec-902;

    // background-color: aquamarine;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 10%;

    //    @include breakpoint(mobileonly) {
    //   padding-top: 30px;
    // }

    @include breakpoint(mobileonly) {
      margin: 0 auto;
      margin-left: -10px;
      padding: 30px 100px 50px 0px;
      @include rowflex(center, center);
    }
  }

  &__logo {
    width: 100px;
    margin: 0 auto;
    @include breakpoint(mobileonly) {
      margin-top: -90px;
    }
  }

  .coming-soon {
    margin: 0;
    opacity: 1;
    @include fontNormal(1.2em, 1em, $vesti-700);
  }
  hr {
    width: 100%;
    // height: fit-content;
    background-color: $gray-300;
    border-top: 1px solid $gray-400 !important;
  }
  &__links {
    width: 90%;
    overflow: hidden;
    height: fit-content;
    overflow-y: scroll;
    margin-bottom: 20px;
    position: relative;
    float: right;
    max-height: 85vh;
    @include colflexx(flex-start);
    gap: 10px;
    font-size: 14px;

    &::-webkit-scrollbar {
      width: 0px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: none;
      border-radius: 6px;
      border: none;
    }

    @include breakpoint(mobileonly) {
      padding: 0px;
      padding-bottom: 50px;
    }
    @include breakpoint(phablet) {
      padding: 0px;
      padding-bottom: 50px;
    }
    a {
      width: 100%;
    }
    *.sidebar-links {
      @include rowflexx(center);
      flex-wrap: nowrap;
      gap: 10px;
      height: 60px;
      margin: 0;
      width: 100%;
      padding: 15px 10px;
      position: relative;

      img {
        width: 24px;
        height: 24px;
        transition: all 0.5s ease;
        // opacity: .9 !important;

        @media only screen and (max-width: 600px) {
          width: 20px;
          height: 10px;
        }
      }
      p {
        margin: 0;
        // @include fontNormal(1.2em, 1.2em, $gray-500);
        @include fontNormal(1.2em, 1.2em, #fff);
        transition: all 0.5s ease;
        opacity: 0.9 !important;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        //display: none;
        // @media only screen and (max-width: 600px) {
        //     display: none;
        // }
        @include breakpoint(desktop) {
          display: flex;
        }

        @media only screen and (max-width: 600px) {
          font-size: 12px;
        }
      }

      &.logout {
        p {
          color: red;
        }
        img {
          filter: invert(25%) sepia(96%) saturate(6308%) hue-rotate(355deg)
            brightness(98%) contrast(120%);
        }
      }
      &.provider {
        img {
          filter: brightness(0%) saturate(0%) invert(100%);
        }
      }
    }
    .active {
      background-color: $sec-900;
      border-radius: 5px 0px 0px 5px;
      p {
        font-weight: 500;
      }
      .--sidebar {
        position: absolute;
        height: 100%;
        width: 15px;
        background-color: $vesti-700;
        right: 0;
      }
      // p{
      //     // color: #fff;
      // }
    }
  }

  @media only screen and (max-width: 600px) {
    // background-color: red;
    // width: fit-content !important;
    z-index: 5 !important;
    // background-color: transparent;
  }

  @media only screen and (min-width: 900px) {
    .sidebar__logo {
      width: 100px;
      margin: 0;
      margin-right: 0px !important;
    }
  }

  @media only screen and (min-width: 300px) {
    .sidebar__logo {
      width: 100px;

      margin-left: 70px !important;
    }
  }

  @media only screen and (min-width: 600px) {
    .sidebar__logo {
      width: 100px;

      margin-left: 20px !important;
    }
  }

  @media only screen and (min-width: 700px) {
    .sidebar__logo {
      width: 100px;
      margin: 0 auto;
      margin-right: 40px !important;
    }
  }
}
