@import '../../../variables.scss';

.aboutpathway {
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  height: fit-content;
  @include colflex(flex-start, center);

  @include breakpoint(mobileonly) {
    padding: 17px;
  }
  @include breakpoint(phablet) {
    padding: 17px;
  }
  @include breakpoint(tablet) {
    padding: 17px;
  }

  &__inner {
    width: 100%;
    height: fit-content;
    @include colflex(flex-start, center);

    // @include breakpoint(desktop) {
    //   width: 80%;
    // }

    .pathwayheader {
      width: 100%;
      margin-top: 1rem;

      @include rowflex(baseline, space-between);

      .pathwaytitle {
        .titlesubtitle {
          .titlesubtitle {
            .titlesubtitle {
              &__title {
                @include breakpoint(mobileonly) {
                  @include fontBold(1rem, 1rem, $vesti-902);
                }
              }
            }
          }
        }
      }
      .seemorepathway {
        // width: auto;
        display: none;

        @include breakpoint(phablet) {
          display: inline-flex !important;
        }
        @include breakpoint(desktop) {
          display: inline-flex !important;
        }
        @include breakpoint(tabket) {
          display: inline-flex !important;
        }
      }
    }

    .innercore {
      width: 100%;
      height: fit-content;
      display: grid;
      grid-template-columns: 3fr 2fr; /* 5 columns by default */
      gap: 20px; /* Adjust spacing between items */
      margin-top: 30px;

      @include breakpoint(mobileonly) {
        margin-top: -2rem !important;
        @include colflex(flex-start, center);
      }
      @include breakpoint(phablet) {
        // @include colflex(flex-start, center);
        margin-top: 0;
      }

      .leftpathway-container {
        @include colflexx(flex-start);
        gap: 30px;
        width: fit-content;
        // width: 100%;

        .description {
          width: 100%;
          .aboutpathwayimgbox {
            width: 100%;
            position: relative;
            overflow: hidden;

            @include breakpoint(mobileonly) {
              margin-bottom: 1.5rem;
            }

            .video-wrapper {
              top: 0;
              left: 0;
              // width: 100%;
              width: '100vw';
              height: 100%;
              border-radius: 25px;

              @include breakpoint(mobileonly) {
                height: 350px;
                border-radius: 15px;
              }
            }
          }
        }

        .content-stages-bottom {
          width: 50%;
          padding: 30px;
          height: fit-content;

          div:not(:first-child) {
            margin-top: 10px;
          }
        }
      }

      .rightpathway-container {
        display: grid;
        grid-template-rows: repeat(2, minmax(0, 1fr));
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 1rem;

        @include breakpoint(phablet) {
          gap: 0.5rem;
        }

        .progress-pathway-container-inner2 {
          border-radius: 10px;
          background: #dfffd0;
          width: 100%;
          height: auto;
          padding: 5% 10%;
          color: $vesti-700;
          @include colflex(right, center);
          gap: 1rem;

          // @include breakpoint(mobile) {
          //   @include colflex(right, right);
          // }
          @include breakpoint(tablet) {
            gap: 0;
            padding: 0% 9%;
            @include colflex(center, center);
            // justify-content: center;
          }
          .estimated-time {
            @include fontMedium(12px, 1rem, $vesti-902);
            display: inline-flex;
            gap: 5px;
            .circle-green {
              width: 20px; /* Adjust width for responsiveness */
              height: 20px; /* Adjust height for responsiveness */
              min-width: 20px;
              min-height: 20px;
              border-radius: 50%; /* Change to 50% for a perfect circle */
              background: $vesti-700;
              color: #fff; /* Change text color for better contrast */
            }

            @include breakpoint(tablet) {
              @include rowflex(center, center);
              gap: 5px;
              @include fontSemiBold(0.7rem, 1rem, $vesti-902);
              .circle-green {
                width: 10px;
                height: 10px;
                min-width: 10px;
                min-height: 10px;
              }
            }
            @include breakpoint(phablet) {
              @include rowflex(center, center);
              gap: 5px;
              @include fontSemiBold(0.7rem, 1rem, $vesti-902);
              .circle-green {
                width: 10px;
                height: 10px;
                min-width: 10px;
                min-height: 10px;
              }
            }
          }
          .spent-time-value {
            @include fontBold(1.5rem, 1.5rem, #2b5219);

            @include breakpoint(mobileonly) {
              @include fontBold(18px, 1.5rem, #2b5219);
            }
            @include breakpoint(phablet) {
              @include fontBold(1rem, 1.5rem, #2b5219);
            }

            @include breakpoint(tablet) {
              @include fontBold(1.2rem, 1.5em, #2b5219);
            }
          }
        }

        .pathway-container-progress-right {
          grid-row: span 2 / span 2;
          .percentage-container-progress {
            border-radius: 10px;
            background: #f8f6f5;
            border: 1px solid #f8f6f5;
            width: 100%;
            height: 100%;
            padding: 5% 10%;
            color: $vesti-700;
            @include colflex(center, center);
            gap: 2rem;

            @include breakpoint(tablet) {
              gap: 1rem;
              padding: 0% 10%;
            }
            @include breakpoint(phablet) {
              gap: 1rem;
              padding: 0% 8%;
            }

            .estimated-time {
              display: inline-flex;
              gap: 5px;
              @include fontMedium(12px, 1rem, $vesti-902);
              .circle-green {
                width: 20px; /* Adjust width for responsiveness */
                height: 20px; /* Adjust height for responsiveness */
                min-width: 20px;
                min-height: 20px;
                border-radius: 50%; /* Change to 50% for a perfect circle */
                background: $vesti-700;
                color: #fff; /* Change text color for better contrast */
              }

              @include breakpoint(phablet) {
                @include rowflex(center, center);
                gap: 5px;
                @include fontSemiBold(0.7rem, 1rem, $vesti-902);
                .circle-green {
                  width: 10px;
                  height: 10px;
                  min-width: 10px;
                  min-height: 10px;
                }
              }
              @include breakpoint(tablet) {
                @include rowflex(center, center);
                gap: 5px;
                @include fontSemiBold(0.7rem, 1rem, $vesti-902);
                .circle-green {
                  width: 10px;
                  height: 10px;
                  min-width: 10px;
                  min-height: 10px;
                }
              }
            }
            .progress-percentage {
              @include fontBold(5rem, 1.7rem, #2b5219);

              @include breakpoint(phablet) {
                @include fontSemiBold(2rem, 1rem, #2b5219);
              }
              @include breakpoint(tablet) {
                @include fontSemiBold(2rem, 1rem, #2b5219);
              }
            }
            .text-percentage-sub {
              font-weight: 300;
              @include fontMedium(13px, 1rem, #2b5219);

              @include breakpoint(phablet) {
                @include fontMedium(0.9em, 1.3rem, $vesti-902);
              }
            }
          }
        }
      }
    }
    .aboutpathwayclaim {
      margin-top: 2rem;
      width: 100%;
      @include rowflex(center, center);
      .progressdetailsbutton {
        width: 100%;
        .progressoverview {
          width: 100%;
        }
        .progressprocedure {
          width: 100%;
        }
      }
    }

    .back_container_pathway {
      cursor: pointer;
      margin-bottom: 1rem;

      .icon-and-titles {
        display: flex;
        align-items: center;
        @include rowflex(center, center);
        @include fontNormal(1rem, 1rem, $gray-800);

        i {
          margin-right: 10px;
        }
      }
    }
  }
}

// index pathway journey styling
.pathwayprogresscontainer {
  width: 100%;
  .progress-aboutpathway {
    display: grid;
    grid-template-columns: repeat(
      8,
      1fr
    ); /* Creates 8 columns with equal width */
    grid-gap: 10px; /* Optional gap between grid items */

    @include breakpoint(mobileonly) {
      grid-template-columns: 1fr; /* Switch to a single column on mobile */
      grid-gap: 0;
    }
    @include breakpoint(phablet) {
      grid-template-columns: 1fr; /* Switch to a single column on mobile */
    }

    .leftpathway-container {
      grid-column: span 6; /* Div 1 spans 6 columns */
      padding: 10px; /* Optional padding for Div 1 */

      .back_container_pathway {
        display: none;

        @include breakpoint(mobileonly) {
          display: block;
          margin-bottom: 20px;
        }
        @include breakpoint(phablet) {
          display: none;
        }
      }

      .progress-wrapper {
        display: none;
      }

      @include breakpoint(mobileonly) {
        .progress-wrapper {
          display: block;
          width: 100%;
          margin-bottom: 1rem;
          .stage-header {
            @include fontBold(1.5em, 1.5em, $gray-800);
          }
          .timespentcontainer {
            display: grid;
            grid-column: span 2;
            padding: 10px;
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 20px;
            background: $vesti-700;
            color: #fff;
            @include breakpoint(mobileonly) {
              padding: 20px 20px;
            }

            .duration-time {
              @include rowflex(center, space-between);
              @include fontSemiBold(0.8em, 2.5em, $vesti-100);
              .circle-white {
                display: inline-flex;
                align-items: center;
                width: 15px !important;
                height: 15px !important;
                border-radius: 50%;
                background: #fff;
                padding: 10px;
              }
            }
          }
          .duration-time-value {
            @include rowflex(center, space-between);
            @include fontBold(1em, 1em, $vesti-100);
          }
          .percentagecontainer {
            border-radius: 10px;
            background: $vesti-902;
            padding: 30px;
            color: #fff;

            @include breakpoint(mobileonly) {
              padding: 20px 20px;
            }

            .circle-white {
              display: inline-block;
              width: 24px !important;
              height: 24px !important;
              border-radius: 24px;
              background: #fff;
              color: #fff;
            }

            .progress-percentage {
              @include fontBold(2.5em, 1.5em, $vesti-100);
            }
          }
        }
      }

      @include breakpoint(phablet) {
        .progress-wrapper {
          display: block;
          width: 100%;
          margin-bottom: 1rem;
          .stage-header {
            @include fontBold(1.5em, 1.5em, $gray-800);
          }
          .timespentcontainer {
            display: grid;
            grid-column: span 2;
            padding: 10px;
            border-radius: 10px;
            margin-top: 20px;
            margin-bottom: 20px;
            background: $vesti-700;
            color: #fff;

            .duration-time {
              @include rowflex(center, space-between);
              @include fontSemiBold(0.8em, 2.5em, $vesti-100);
              .circle-white {
                display: inline-flex;
                align-items: center;
                width: 15px !important;
                height: 15px !important;
                border-radius: 50%;
                background: #fff;
              }
            }
          }
          .duration-time-value {
            @include rowflex(center, space-between);
            @include fontBold(1em, 1em, $vesti-100);
          }
          .percentagecontainer {
            border-radius: 10px;
            background: $vesti-902;
            padding: 30px;
            color: #fff;

            .circle-white {
              display: inline-block;
              width: 24px !important;
              height: 24px !important;
              border-radius: 24px;
              background: #fff;
              color: #fff;
            }

            .progress-percentage {
              @include fontBold(2.5em, 1.5em, $vesti-100);
            }
          }
        }
      }

      .aboutpathway-inner {
        @include colflex(flex-start, center);
        width: 100%;
        height: fit-content;

        .back_container_pathway {
          cursor: pointer;
          margin-bottom: 1rem;

          .icon-and-titles {
            display: flex;
            align-items: center;
            @include rowflex(center, center);
            @include fontNormal(1rem, 1rem, $gray-800);

            i {
              margin-right: 10px;
            }
          }

          @include breakpoint(mobileonly) {
            display: none;
          }
          @include breakpoint(mobileonly) {
            display: none;
          }
        }
        .aboutpathway-content {
          margin-top: 10px;
          gap: 1rem;
          // width: 100vw;
          width: 100%;
          height: 100%;
          @include rowflexx(flex-start);
          overflow-y: auto;

          @include breakpoint(mobileonly) {
            overflow: scroll;
            @include rowflex(flex-start, space-between);

            &::-webkit-scrollbar-track {
              background: transparent;
            }
            &::-webkit-scrollbar-thumb {
              background-color: transparent;
              border-radius: 6px;
              border: $vesti-900;
            }
          }
          .aboutpathway-description {
            width: 100%;
            .aboutpathwayimgbox {
              width: 100%;
              position: relative;
              overflow: hidden;

              @include breakpoint(mobileonly) {
                margin-bottom: 1.5rem;
              }

              .video-wrapper {
                top: 0;
                left: 0;
                // width: 100%;
                width: '100vw';
                height: 100%;
                border-radius: 25px;

                @include breakpoint(mobileonly) {
                  height: 350px;
                  border-radius: 15px;
                }
              }
            }
            .daysbutton_container {
              margin-top: 10px;
              margin-bottom: 10px;
              @include rowflex(center, space-between);
            }

            .aboutpathway {
              &__bottom {
                margin-top: 50px;
                width: 100%;
                height: fit-content;

                @include breakpoint(mobileonly) {
                  width: 100%;
                }
                .stage-header {
                  margin-top: 10px;
                  margin-bottom: 10px;
                  @include fontBold(1.3em, 1.5em, $gray-800);
                }
                .pathway-roll {
                  @include colflex(center, space-between);
                  gap: 10px;
                }
              }
              &__bcontent {
                @include fontNormal(1.2em, 1.7em, $vesti-901);
                text-align: justify;
                width: 100%;

                @include breakpoint(mobileonly) {
                  @include fontNormal(1em, 1.5em, $vesti-901);
                }
                @include breakpoint(phablet) {
                  @include fontNormal(1.1em, 1.5em, $vesti-901);
                }
              }
            }
          }

          .content-stages-bottom {
            width: 100%;
            // padding: 30px;
            height: fit-content;

            @include breakpoint(mobileonly) {
              @include colflex(center, center);
              // overflow: scroll;
            }

            // div:not(:first-child) {
            //   margin-top: 10px;
            // }
          }
        }
      }
    }

    .rightpathway-container {
      grid-column: span 2; /* Div 2 spans 2 columns */
      padding: 10px; /* Optional padding for Div 2 */

      @include breakpoint(phablet) {
        gap: 0.5rem;
      }

      .progress-wrapper {
        width: 100%;
        .stage-header {
          @include fontBold(1.5em, 1.5em, $gray-800);
        }
        .timespentcontainer {
          display: grid;
          grid-column: span 2;
          padding: 10px;
          border-radius: 10px;
          margin-top: 20px;
          margin-bottom: 20px;
          background: $vesti-700;
          color: #fff;

          .duration-time {
            @include rowflex(center, space-between);
            @include fontSemiBold(1em, 2.5em, $vesti-100);
            .circle-white {
              display: inline-flex;
              align-items: center;
              width: 15px !important;
              height: 15px !important;
              border-radius: 50%;
              background: #fff;
            }
          }
        }
        .duration-time-value {
          @include rowflex(center, space-between);
          @include fontBold(1.5em, 1em, $vesti-100);
        }
        .percentagecontainer {
          border-radius: 10px;
          background: $vesti-902;
          padding: 30px;
          color: #fff;

          .circle-white {
            display: inline-block;
            width: 24px !important;
            height: 24px !important;
            border-radius: 24px;
            background: #fff;
            color: #fff;
          }

          .progress-percentage {
            @include fontBold(2.5em, 1.5em, $vesti-100);
          }
        }
        @include breakpoint(mobileonly) {
          display: none;
        }
        @include breakpoint(phablet) {
          display: none;
        }
      }

      .growth-community {
        margin-top: 60px;

        @include breakpoint(mobileonly) {
          margin-top: 0;
        }
        @include breakpoint(phablet) {
          margin-top: 0;
        }
        .stage-header {
          @include fontBold(1.3em, 1.5em, $gray-800);
        }

        .growth-progress {
          margin-bottom: 20px;
          margin-top: 30px;
          border-radius: 10px;
          background: #d9d9d9;
          padding: 15px;

          .growth-progress-inner {
            @include colflex(flex-start, center);
            gap: 2rem;

            .growth-title {
              width: 100%;
              @include fontBold(1.2em, 1.5em, $gray-800);
              @include rowflex(center, space-between);
              .growth-title-progress {
                // flex-shrink: 0;
                width: 70%;
              }
              .growth-plan-progress {
                @include fontBold(0.7em, 1.5em, $vesti-100);
                background: #303030;
                padding: 6px 20px;
                text-align: center;

                @include breakpoint(mobileonly) {
                  @include fontBold(0.5em, 1.5em, $vesti-100);
                }
              }
            }
            // .join-nowbtn {
            // }
          }
        }
        .merchant-progress {
          margin-top: 20px;
          .merchant-box {
            padding: 12px;
            @include colflex(center, center);
            margin-bottom: 15px;
            background-color: #ffffff;
            border: 1px solid #eaeaea;
            box-shadow: 0px 0px 5px #f5f5f5;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

.overall {
  width: 100%;
  @include breakpoint(mobileonly) {
    @include colflex(center, center);
  }
  .daysbutton_container {
    margin-top: 2rem;
    width: 100%;
    @include rowflex(center, space-between);

    @include breakpoint(mobileonly) {
      @include rowflex(center, center);
      margin-bottom: 1rem;
    }
    @include breakpoint(phablet) {
      margin-top: 0;
      padding: 17px;
    }
    .left_daysbutton {
      width: 30%;

      @include breakpoint(mobileonly) {
        display: none;
      }
    }
    .claim30days {
      width: 50%;
      @include rowflex(center, center);

      @include breakpoint(mobileonly) {
        width: 95%;
        @include fontMedium(1rem, 1.2rem, $vesti-100);
      }
      @include breakpoint(phablet) {
        width: 70%;
        @include fontMedium(0.9rem, 1.2rem, $vesti-100);
      }
      @include breakpoint(tablet) {
        width: 70%;
        @include fontMedium(1rem, 1.2rem, $vesti-100);
      }
      .aboutpathway__claim {
        width: 100%;

        @include breakpoint(mobileonly) {
          width: 100%;
        }
        .platform.claim30days {
          @include breakpoint(mobileonly) {
            margin: auto;
            // @include fontSemiBold(1rem, 1.2rem, $vesti-100);
          }

          .claim30days {
            @include breakpoint(mobileonly) {
              @include fontSemiBold(1rem, 1.2rem, $vesti-100);
            }
            // @include breakpoint(mobileonly) {
            //   @include fontSemiBold(1rem, 1.2rem, $vesti-100);
            // }
          }
        }
      }
    }
  }
  .seemorepathway-button {
    display: none;
    @include breakpoint(mobileonly) {
      width: 90%;
      margin: 0 auto;
      @include fontMedium(1rem, 1.2rem, $vesti-700);
      display: inline-flex !important;
      align-items: center !important;
      width: 90% !important;
    }
  }
}

.section-pathway {
  margin-top: 2rem;
  width: 100%;
  @include breakpoint(mobileonly) {
    padding: 17px;
    .titlesubtitle {
      @include fontBold(1em, 1em, $vesti-901);
      width: 100% !important;
      margin-bottom: 1rem !important;
    }
  }
  @include breakpoint(phablet) {
    padding: 17px;
    .titlesubtitle {
      @include fontBold(1.2em, 1em, $vesti-901);
      width: 100% !important;
      margin-bottom: 1rem !important;
    }
  }
  @include breakpoint(tablet) {
    padding: 17px;
    .titlesubtitle {
      @include fontBold(1.3em, 1.2em, $vesti-901);
      width: 100% !important;
      margin-bottom: 1rem !important;
    }
  }

  .aboutpathway {
    &__bottom {
      margin-top: 50px;
      width: 100%;
      height: fit-content;

      @include breakpoint(mobileonly) {
        width: 90%;
      }
    }
    &__bottomcontent {
      margin-top: 20px;
      @include fontNormal(1.2em, 1.7em, $vesti-901);
      text-align: justify;
      width: 100%;

      @include breakpoint(mobileonly) {
        @include fontNormal(1em, 1.5em, $vesti-901);
      }
      @include breakpoint(phablet) {
        @include fontNormal(1.1em, 1.5em, $vesti-901);
      }
    }
  }
}

.sectionpathway {
  width: 100%;
  padding: 17px;
  .about-sectionpathway {
    margin-top: 2rem;
    width: 100%;
    @include rowflex(flex-start, space-between);

    @include breakpoint(mobileonly) {
      margin-top: 2rem;
    }

    // .titlesubtitle-left {
    // }
    .seemore-right {
      display: none;

      @include breakpoint(phablet) {
        display: inline-flex !important;
      }
      @include breakpoint(desktop) {
        display: inline-flex !important;
      }
      @include breakpoint(tabket) {
        display: inline-flex !important;
      }
    }
  }
  .growthcom {
    // width: 100%;
    min-width: 550px;
    height: fit-content;
    position: relative !important;
    // overflow: scroll;
    @include colflexx(flex-start);

    @include breakpoint(mobileonly) {
      min-width: 350px;
    }
    &__inner {
      width: 100%;
    }
    &__content {
      margin-top: 10px;
      gap: 1rem;
      // width: 100vw;
      width: 100%;
      height: 100%;
      @include rowflexx(flex-start);
      overflow-y: auto;

      @include breakpoint(mobileonly) {
        width: 100vw;
        overflow: scroll;
        @include rowflex(flex-start, space-between);

        &::-webkit-scrollbar-track {
          background: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: transparent;
          border-radius: 6px;
          border: $vesti-900;
        }
      }

      .domorecard {
        padding: 20px;
        border-radius: 10px;
        cursor: pointer;
        transition: all 0.5s ease;
        @include colflexx(flex-start);
        gap: 3rem;
        // height: 400px;
        // min-height: 400px;

        &.color-free {
          background-color: $yellow-100;
          // border: 0.5px solid $yellow-700;

          .growthcom-title {
            width: 100%;
            height: inherit;
            @include rowflex(center, baseline);

            .left-title-growth {
              margin: 0;
              width: 100%;
              p {
                @include fontBold(1.2em, 1em, $yellow-700);

                @include breakpoint(mobileonly) {
                  @include fontMedium(1em, 1em, $yellow-700);
                }
                @include breakpoint(phablet) {
                  @include fontMedium(1em, 1em, $yellow-700);
                }
              }
            }

            .right-title-growth {
              margin-top: 0;
              // width: 100%;
              p {
                @include fontBold(1em, 1.4em, $yellow-700);
                padding: 10px 30px;
                border: 1px solid $yellow-700;
                border-radius: 5px;

                @include breakpoint(mobileonly) {
                  width: 100%;
                  @include fontMedium(0.7em, 1.4em, $yellow-700);
                }
                @include breakpoint(phablet) {
                  width: 100%;
                  @include fontMedium(0.8em, 1.4em, $yellow-700);
                  padding: 10px 11px;
                }
              }
            }
          }

          .main-body {
            width: 70%;
            p:nth-child(1) {
              margin: 0;
              @include fontNormal(1.2em, 1.5em, $yellow-700);

              @include breakpoint(mobileonly) {
                @include fontMedium(1em, 1.4em, $yellow-700);
              }
            }
          }

          .joinnow-container {
            width: 100%;
            @include rowflex(center, space-between);

            // @include breakpoint(mobileonly) {
            //   width: max-content;
            // }
            // @include breakpoint(phablet) {
            //   width: max-content;
            // }
            // @include breakpoint(tablet) {
            //   width: max-content;
            // }

            .webinar-left-title {
              @include rowflex(center, flex-start);

              .group-member-growth {
                margin-left: -16px;
                @include fontMedium(1em, 1.2em, $gray-800);
              }
            }
            .join-group-container {
              width: auto;

              @include breakpoint(mobileonly) {
                width: max-content;
                @include fontBold(1em, 1em, $yellow-700);
              }
              .join-now-group {
                @include fontBold(1.5em, 1em, $yellow-700);

                @include breakpoint(mobileonly) {
                  width: max-content;
                  @include fontBold(1em, 1em, $yellow-700);
                }
                @include breakpoint(phablet) {
                  width: max-content;
                  @include fontBold(1em, 1em, $yellow-700);
                }
              }
            }
          }
        }

        &.color-business {
          background-color: $sec-903;
          // border: 0.5px solid $sec-903;

          .growthcom-title {
            width: 100%;
            height: inherit;
            @include rowflex(center, baseline);

            .left-title-growth {
              margin: 0;
              width: 100%;
              p {
                @include fontBold(1.2em, 1em, $sec-904);

                @include breakpoint(mobileonly) {
                  @include fontMedium(1em, 1em, $sec-904);
                }
                @include breakpoint(phablet) {
                  @include fontMedium(1em, 1em, $sec-904);
                }
              }
            }

            .right-title-growth {
              margin-top: 0;
              // width: 100%;
              p {
                @include fontBold(1em, 1.4em, $sec-904);
                padding: 10px 30px;
                border: 1px solid $sec-904;
                border-radius: 5px;

                @include breakpoint(mobileonly) {
                  width: 100%;
                  @include fontMedium(0.7em, 1.4em, $sec-904);
                }
                @include breakpoint(phablet) {
                  width: 100%;
                  @include fontMedium(0.8em, 1.4em, $sec-904);
                  padding: 10px 11px;
                }
              }
            }
          }

          .main-body {
            width: 70%;
            p:nth-child(1) {
              margin: 0;
              @include fontNormal(1.2em, 1.5em, $sec-904);

              @include breakpoint(mobileonly) {
                @include fontMedium(1em, 1.4em, $sec-904);
              }
            }
          }

          .joinnow-container {
            width: 100%;
            @include rowflex(center, space-between);

            // @include breakpoint(mobileonly) {
            //   width: max-content;
            // }
            // @include breakpoint(phablet) {
            //   width: max-content;
            // }
            // @include breakpoint(tablet) {
            //   width: max-content;
            // }
            .webinar-left-title {
              @include rowflex(center, flex-start);

              .group-member-growth {
                margin-left: -16px;
                @include fontMedium(1em, 1.2em, $gray-800);
              }
            }
            .join-group-container {
              width: auto;
              .join-now-group {
                @include fontBold(1.5em, 1em, $sec-904);

                @include breakpoint(mobileonly) {
                  width: max-content;
                  @include fontBold(1em, 1em, $sec-904);
                }
                @include breakpoint(phablet) {
                  width: max-content;
                  @include fontBold(1em, 1em, $sec-904);
                }
              }
            }
          }
        }

        &.color-premium {
          background-color: $green-902;
          // border: 0.5px solid $sec-903;

          .growthcom-title {
            width: 100%;
            height: inherit;
            @include rowflex(center, baseline);

            .left-title-growth {
              margin: 0;
              width: 100%;
              p {
                @include fontBold(1.2em, 1em, $green-903);

                @include breakpoint(mobileonly) {
                  @include fontMedium(1em, 1em, $green-903);
                }
                @include breakpoint(phablet) {
                  @include fontMedium(1em, 1em, $green-903);
                }
              }
            }

            .right-title-growth {
              margin-top: 0;
              // width: 100%;
              p {
                @include fontBold(1em, 1.4em, $green-903);
                padding: 10px 30px;
                border: 1px solid $green-903;
                border-radius: 5px;

                @include breakpoint(mobileonly) {
                  width: 100%;
                  @include fontMedium(0.7em, 1.4em, $green-903);
                }
                @include breakpoint(phablet) {
                  width: 100%;
                  @include fontMedium(0.8em, 1.4em, $green-903);
                  padding: 10px 11px;
                }
              }
            }
          }

          .main-body {
            width: 70%;
            p:nth-child(1) {
              margin: 0;
              @include fontNormal(1.2em, 1.5em, $green-903);

              @include breakpoint(mobileonly) {
                @include fontMedium(1em, 1.4em, $green-903);
              }
            }
          }

          .joinnow-container {
            width: 100%;
            @include rowflex(center, space-between);

            .webinar-left-title {
              @include rowflex(center, flex-start);

              .group-member-growth {
                margin-left: -16px;
                @include fontMedium(1em, 1.2em, $gray-800);
              }
            }
            .join-group-container {
              width: auto;
              .join-now-group {
                @include fontBold(1.5em, 1em, $green-903);

                @include breakpoint(mobileonly) {
                  width: max-content;
                  @include fontBold(1em, 1em, $green-903);
                }
                @include breakpoint(phablet) {
                  width: max-content;
                  @include fontBold(1em, 1em, $green-903);
                }
              }
            }
          }
        }

        @include breakpoint(mobileonly) {
          // width: 350px;
          height: 100% !important;
          min-height: 266px;
        }
      }
    }
  }
  .seemore-down {
    display: none;
    @include breakpoint(mobileonly) {
      width: 90%;
      margin: 0 auto;
      @include fontMedium(1rem, 1.2rem, $vesti-700);
      margin-top: 1rem;
      display: inline-flex !important;
      align-items: center !important;
      width: 90% !important;
    }
    // @include breakpoint(desktop) {
    //   display: none;
    // }
    // @include breakpoint(phablet) {
    //   display: none;
    // }
    // @include breakpoint(tablet) {
    //   display: none;
    // }
  }
}

.pathwaysubscription__button {
  background-color: $vesti-700;
  height: 50px;
  color: #fff;
  transition: all 0.5s ease;
  border-radius: 5px !important;

  &:hover {
    background-color: $vesti-800;
    color: #fff;
  }
  &:disabled {
    background-color: $gray-600;
    color: $gray-100;
    cursor: not-allowed;
  }
}

.growthmodal {
  @include rowflex(flex-start, center);
  flex-wrap: wrap;
  gap: 1rem;

  .growthcom {
    // width: 100%;
    width: 45%;
    height: fit-content;
    position: relative !important;
    // overflow: scroll;
  }
  .domorecard {
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.5s ease;
    @include colflexx(flex-start);
    gap: 3rem;
    // height: 400px;
    // min-height: 400px;

    &.color-free {
      background-color: $yellow-100;
      // border: 0.5px solid $yellow-700;

      .growthcom-title {
        width: 100%;
        height: inherit;
        @include rowflex(center, baseline);

        .left-title-growth {
          margin: 0;
          width: 100%;
          p {
            @include fontBold(1.2em, 1em, $yellow-700);

            @include breakpoint(mobileonly) {
              @include fontMedium(1em, 1em, $yellow-700);
            }
            @include breakpoint(phablet) {
              @include fontMedium(1em, 1em, $yellow-700);
            }
          }
        }

        .right-title-growth {
          margin-top: 0;
          // width: 100%;
          p {
            @include fontBold(1em, 1.4em, $yellow-700);
            padding: 10px 30px;
            border: 1px solid $yellow-700;
            border-radius: 5px;

            @include breakpoint(mobileonly) {
              width: 100%;
              @include fontMedium(0.7em, 1.4em, $yellow-700);
            }
            @include breakpoint(phablet) {
              width: 100%;
              @include fontMedium(0.8em, 1.4em, $yellow-700);
              padding: 10px 11px;
            }
          }
        }
      }

      .main-body {
        width: 70%;
        p:nth-child(1) {
          margin: 0;
          @include fontNormal(1.2em, 1.5em, $yellow-700);

          @include breakpoint(mobileonly) {
            @include fontMedium(1em, 1.4em, $yellow-700);
          }
        }
      }

      .joinnow-container {
        width: 100%;
        @include rowflex(center, space-between);
        .webinar-left-title {
          @include rowflex(center, flex-start);

          .group-member-growth {
            margin-left: -16px;
            @include fontMedium(1em, 1.2em, $gray-800);
          }
        }
        .join-group-container {
          width: auto;

          @include breakpoint(mobileonly) {
            width: max-content;
            @include fontBold(1em, 1em, $yellow-700);
          }
          .join-now-group {
            @include fontBold(1.5em, 1em, $yellow-700);

            @include breakpoint(mobileonly) {
              width: max-content;
              @include fontBold(1em, 1em, $yellow-700);
            }
            @include breakpoint(phablet) {
              width: max-content;
              @include fontBold(1em, 1em, $yellow-700);
            }
          }
        }
      }
    }

    &.color-business {
      background-color: $sec-903;
      // border: 0.5px solid $sec-903;

      .growthcom-title {
        width: 100%;
        height: inherit;
        @include rowflex(center, baseline);

        .left-title-growth {
          margin: 0;
          width: 100%;
          p {
            @include fontBold(1.2em, 1em, $sec-904);

            @include breakpoint(mobileonly) {
              @include fontMedium(1em, 1em, $sec-904);
            }
            @include breakpoint(phablet) {
              @include fontMedium(1em, 1em, $sec-904);
            }
          }
        }

        .right-title-growth {
          margin-top: 0;
          // width: 100%;
          p {
            @include fontBold(1em, 1.4em, $sec-904);
            padding: 10px 30px;
            border: 1px solid $sec-904;
            border-radius: 5px;

            @include breakpoint(mobileonly) {
              width: 100%;
              @include fontMedium(0.7em, 1.4em, $sec-904);
            }
            @include breakpoint(phablet) {
              width: 100%;
              @include fontMedium(0.8em, 1.4em, $sec-904);
              padding: 10px 11px;
            }
          }
        }
      }

      .main-body {
        width: 70%;
        p:nth-child(1) {
          margin: 0;
          @include fontNormal(1.2em, 1.5em, $sec-904);

          @include breakpoint(mobileonly) {
            @include fontMedium(1em, 1.4em, $sec-904);
          }
        }
      }

      .joinnow-container {
        width: 100%;
        @include rowflex(center, space-between);
        .webinar-left-title {
          @include rowflex(center, flex-start);

          .group-member-growth {
            margin-left: -16px;
            @include fontMedium(1em, 1.2em, $gray-800);
          }
        }
        .join-group-container {
          width: auto;
          .join-now-group {
            @include fontBold(1.5em, 1em, $sec-904);

            @include breakpoint(mobileonly) {
              width: max-content;
              @include fontBold(1em, 1em, $sec-904);
            }
            @include breakpoint(phablet) {
              width: max-content;
              @include fontBold(1em, 1em, $sec-904);
            }
          }
        }
      }
    }

    &.color-premium {
      background-color: $green-902;
      // border: 0.5px solid $sec-903;

      .growthcom-title {
        width: 100%;
        height: inherit;
        @include rowflex(center, baseline);

        .left-title-growth {
          margin: 0;
          width: 100%;
          p {
            @include fontBold(1.2em, 1em, $green-903);

            @include breakpoint(mobileonly) {
              @include fontMedium(1em, 1em, $green-903);
            }
            @include breakpoint(phablet) {
              @include fontMedium(1em, 1em, $green-903);
            }
          }
        }

        .right-title-growth {
          margin-top: 0;
          // width: 100%;
          p {
            @include fontBold(1em, 1.4em, $green-903);
            padding: 10px 30px;
            border: 1px solid $green-903;
            border-radius: 5px;

            @include breakpoint(mobileonly) {
              width: 100%;
              @include fontMedium(0.7em, 1.4em, $green-903);
            }
            @include breakpoint(phablet) {
              width: 100%;
              @include fontMedium(0.8em, 1.4em, $green-903);
              padding: 10px 11px;
            }
          }
        }
      }

      .main-body {
        width: 70%;
        p:nth-child(1) {
          margin: 0;
          @include fontNormal(1.2em, 1.5em, $green-903);

          @include breakpoint(mobileonly) {
            @include fontMedium(1em, 1.4em, $green-903);
          }
        }
      }

      .joinnow-container {
        width: 100%;
        @include rowflex(center, space-between);
        .webinar-left-title {
          @include rowflex(center, flex-start);

          .group-member-growth {
            margin-left: -16px;
            @include fontMedium(1em, 1.2em, $gray-800);
          }
        }
        .join-group-container {
          width: auto;
          .join-now-group {
            @include fontBold(1.5em, 1em, $green-903);

            @include breakpoint(mobileonly) {
              width: max-content;
              @include fontBold(1em, 1em, $green-903);
            }
            @include breakpoint(phablet) {
              width: max-content;
              @include fontBold(1em, 1em, $green-903);
            }
          }
        }
      }
    }

    @include breakpoint(mobileonly) {
      height: 100% !important;
      min-height: 266px;
      width: 100%;
    }
  }
}

.services-grids {
  @include rowflex(center, flex-start);
  flex-wrap: wrap;
  gap: 1rem;
}

// .merchant-random{
//   @include rowflex(center, center);
//   padding: 12px;
//   background: #FFFFFF;
//   border: 1px solid #EAEAEA;
//   box-shadow: 0px 0px 5px #F5F5F5;
//   border-radius: 10px;
//   // height: 250px;
//   // min-width: 235px;
// }

// .aboutpathway {
//   display: grid;
//   grid-template-columns: 70% 30%;
// }

// .domore__content .domorecard:hover,
// .popupgrid__content .domorecard:hover {
//   background-color: none !important;
// }

// .rightpathway-container {
//   background: #f6f7ff;

//   margin-left: 20px;

//   padding: 30px;

//   margin-top: 20px;
// }

// .about-container-white {
//   height: 200px !important;
//   margin-top: 130px !important;
// }

.bg-white {
  background: #fff !important;
}

// @media only screen and (min-width: 700px) and (max-width: 1280px) {
//   .pathway-container-progress {
//     margin-left: -20px;
//   }
// }

// @media only screen and (min-width: 1280px) {
//   .pathway-container-progress {
//     margin-left: -170px;
//   }
// }

// .duration-time {
//   color: #fff;

//   font-size: 14px;

//   letter-spacing: 0.28px;
// }

// .duration-time-value {
//   color: #fff;

//   font-size: 18px;

//   font-weight: bolder;

//   letter-spacing: 0.28px;
// }

// .spent-time {
//   color: #67a948;

//   font-size: 14px;

//   letter-spacing: 0.28px;
// }

// .percentage-container {
//   border-radius: 10px;
//   background: #3e6f26;
//   width: 300px;
//   height: 360px;

//   padding: 30px;

//   color: #fff;
// }

// .timespentcontainer {
//   margin-top: 20px;
//   margin-bottom: 20px;
//   border-radius: 10px;
//   background: #7ec65c;
//   width: 300px;
//   height: 110px;

//   padding: 30px;

//   color: #fff;
// }

// .circle-white {
//   display: inline-block;
//   width: 24px !important;
//   height: 24px !important;

//   border-radius: 24px;
//   background: #fff;
//   color: #fff;
// }

// .growth-progress {
//   margin-bottom: 20px;
//   border-radius: 10px;
//   background: #d9d9d9;
//   width: 300px;
//   height: 272px;
//   padding: 30px;
// }

// .growth-title-progress {
//   display: flex;
//   width: 180px;
//   flex-direction: column;
//   flex-shrink: 0;
//   color: #000;

//   font-size: 20px;

//   line-height: 132%;
// }

// .growth-plan-progress {
//   background: #303030;
//   width: 64px;
//   height: 24px;
//   padding: 6px 20px;
//   justify-content: center;
//   align-items: center;
//   gap: 10px;
//   color: #fff;

//   color: #fff;
//   text-align: center;
//   font-size: 10px;
//   line-height: 100%;
//   letter-spacing: 0.2px;

//   font-weight: bold;
// }

// .join-nowbtn {
//   width: 120px;
//   height: 2% !important;
//   text-align: center;
//   font-size: 15px;
//   font-weight: bold;
//   line-height: 100%;
//   letter-spacing: 0.2px;
// }

// .growth-title {
//   margin-bottom: 30px;
// }

// .growth-community {
//   margin-top: 60px;
// }
// .groupmember-percentage {
//   margin-bottom: 30px;
// }

// .growth-title-progress {
//   color: #000;
//   font-size: 20px;
//   line-height: 132%;
// }

// .merchant-progress {
//   margin-top: 20px;
// }

.pay-nowbtn {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  width: 150px;
  letter-spacing: 0.2px;
}

.platform.daysbutton {
  width: 50%;
  background-color: #67a948;
  transition: all 0.5s ease;
  font-size: 1.2em;
  white-space: nowrap;

  flex-direction: row;

  justify-content: center;
}

.platform.claim30days {
  margin-left: auto;
  margin-right: 30px;

  width: 50%;
  background-color: #67a948;
  transition: all 0.5s ease;
  font-size: 1.2em;
  white-space: nowrap;

  flex-direction: row;

  justify-content: center;
}

.rightpathway-credit {
  position: absolute;

  right: 10px; /* Adjust this value to position the div as per your requirement */
  transform: translateY(-10%);
  /* Other styles for the div if needed */
}

.pathway-credit-progress-right {
  margin-left: 10px;
}

.platform.seeall {
  width: 100%;
  background-color: #67a948;
  transition: all 0.5s ease;
  font-size: 1.2em;
  white-space: nowrap;
  flex-direction: row;
  justify-content: center;
}

.seequalify {
  margin-left: 15px;
}

// @media only screen and (max-width: 780px) {
//   .popup-container-video {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     backdrop-filter: blur(8px); /* Apply a blurry effect to the overlay */
//     background-color: rgba(
//       0,
//       0,
//       0,
//       0.5
//     ); /* Adjust the opacity and color as needed */
//     z-index: 9999; /* Set a higher value if necessary */
//   }

//   .popup-video {
//     width: 90%;
//     height: 40% !important;
//     max-width: 900px; /* Optional: Add a maximum width to limit the popup's width */
//     background-color: #000;
//     padding: 20px;

//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
//     overflow: auto;
//   }

//   .popup-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 20px;
//   }

//   .popup-close-btn {
//     background-color: transparent;
//     color: #000;
//     border: none;
//     cursor: pointer;

//     margin-bottom: 25px;
//   }

//   .popup-close-btn-white {
//     background-color: transparent;
//     color: #fff !important;
//     border: none;
//     cursor: pointer;

//     margin-bottom: 30px;
//   }

//   .platform.daysbutton {
//     width: 50%;
//     background-color: #67a948;
//     transition: all 0.5s ease;
//     font-size: 1.2em;
//     justify-content: center;
//     margin-top: 30px;
//   }

//   .platform.overview {
//     width: 100%;
//     background-color: #67a948;
//     transition: all 0.5s ease;
//     font-size: 1.2em;
//     justify-content: center;
//     margin-top: 30px;
//   }

//   .daysbutton_container {
//     flex-wrap: wrap !important;
//   }
//   .left_daysbutton,
//   .platform.daysbutton {
//     flex-basis: 100% !important;
//   }

//   .aboutpathway {
//     overflow: auto;
//     max-height: 100vh;
//     display: flex;
//     flex-direction: column-reverse; /* Reverse the order of the containers */
//   }

//   .leftpathway-container {
//     order: 2; /* Set the order of leftpathway-container to 2 */
//   }

//   .rightpathway-container {
//     order: 1; /* Set the order of rightpathway-container to 1 */
//     margin-top: 20px; /* Add some margin to separate the two containers */
//   }
// }

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px); /* Apply a blurry effect to the overlay */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity and color as needed */
  z-index: 9999; /* Set a higher value if necessary */
  .popup {
    width: 80%;
    height: 100%;
    max-width: 1400px; /* Optional: Add a maximum width to limit the popup's width */
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: auto;

    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .popup-close-btn {
        background-color: transparent;
        color: #000;
        border: none;
        cursor: pointer;
      }
    }
    .popupgrid__content {
      background-color: #fff;
      color: #000 !important;

      font-size: 18px !important;
      height: 300px !important;
      width: 100% !important;
    }
  }
}

.popup-container-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px); /* Apply a blurry effect to the overlay */
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the opacity and color as needed */
  z-index: 9999; /* Set a higher value if necessary */
}

.popup-video {
  width: 70%;
  height: 65%;
  max-width: 900px; /* Optional: Add a maximum width to limit the popup's width */
  background-color: #000;
  padding: 20px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
}

.popup-close-btn {
  background-color: transparent;
  color: #000;
  border: none;
  cursor: pointer;
}

.popup-close-btn-white {
  background-color: transparent;
  color: #fff;
  border: none;
  cursor: pointer;
}

// rightpathway-container was styled by Fola and since he didn't consider making this look like the mobile design i had to add this extra style for just mobile screen
.rightpathway-containermobile {
  margin-top: 3rem;
  margin-bottom: -3rem;
}

.rightpathway-containerbigscreen {
  display: none;
}

.pathwaytitleMobile {
  font-weight: 600;
  font-size: 20px;
}

.pathwaytitlebigscreen {
  display: none;
}

@media (min-width: 500px) {
  .rightpathway-containermobile {
    margin-bottom: -2rem;
  }
}

@media (min-width: 600px) {
  .rightpathway-containermobile {
    display: none;
  }

  .rightpathway-containerbigscreen {
    display: grid;
  }

  .pathwaytitleMobile {
    display: none;
  }

  .pathwaytitlebigscreen {
    display: block;
    font-size: 20px;
  }

  .pathwayheader {
    margin-bottom: -2rem;
  }
}

.pathwaytitle__title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
}

.pathwayMod {
  ul {
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  li {
    font-size: 14px;
    margin: 10px 0px;
    display: flex;
    align-items: center;
  }

  li .icon {
    color: #67a849;
    font-size: 18px;
    margin-right: 4px;
    font-weight: 500;
  }
}
