@import '../../../variables.scss';

.inputfloat {
  @include colflex(flex-start, space-between);
  height: fit-content;
  width: 100% !important;
  @include breakpoint(mobileonly) {
    height: fit-content;
    @include colflex(flex-start, space-between);
  }
  @include breakpoint(phablet) {
    width: 100% !important;
  }
  @include breakpoint(tablet) {
    width: 100% !important;
  }

  &__box {
    // width: 100%;
    flex: 3;
    position: relative;
    input:focus ~ .inputfloat__labelVtwo,
    input:not(:placeholder-shown) ~ .inputfloat__labelVtwo {
      width: fit-content !important;
      top: -15px;
      left: 22px;
      font-size: 8px;
      opacity: 1;
      color: $vesti-700;
      margin-bottom: 10px;

      @include breakpoint(mobileonly) {
        top: 10px;
      }
    }

    textarea:focus ~ .inputfloat__labelVtwo,
    textarea:not(:placeholder-shown) ~ .inputfloat__labelVtwo {
      width: fit-content !important;
      top: -15px;
      left: 22px;
      font-size: 8px;
      opacity: 1;
      color: $vesti-700;
      margin-bottom: 10px;

      @include breakpoint(mobileonly) {
        top: 10px;
      }
    }

    input[name='firstName'],
    input[name='lastName'] {
      text-transform: capitalize;
    }

    input[type='text'],
    input[type='password'],
    input[type='number'],
    input[type='email'],
    textarea[type='text'] {
      width: 100% !important;
      // margin-top: 30px;
      height: 60px;
      background: #f9f8f9;
      border: 1px solid $gray-400;
      box-sizing: border-box;
      border-radius: 5px;
      outline: none;
      padding-left: 20px;
      padding-top: 5px;
      position: relative;
      font-size: 1em !important;
      @include fontNormal(1em, 1em, $gray-600);

      @include breakpoint(mobileonly) {
        height: 60px !important;
        font-size: 16px !important;
      }
      @include breakpoint(tablet) {
        height: 60px;
      }

      @include breakpoint(phablet) {
        height: 60px;
      }

      &:focus {
        border: 1px solid $vesti-700;
        background-color: $vesti-100 !important;
        color: $vesti-901;
        padding-top: 8px;
      }
      &:disabled {
        cursor: not-allowed;
        color: $gray-800;
        background-color: $gray-300;
      }

      &:invalid {
        border: 2px solid red;
        background-color: rgb(255, 211, 211);
      }

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    // input[type=number] {
    //     -moz-appearance: textfield;
    // }
  }
  &__labelVtwo {
    position: absolute;
    pointer-events: none;
    left: 20px;
    top: 28px;
    opacity: 0;
    // font-size: 0.8em !important;
    font-size: 10px !important;

    @include fontBold(1em, 1em, $vesti-901);
    text-transform: uppercase;
    background-color: $vesti-300;
    padding: 5px 15px;
    transition: 0.2s ease all;
  }
  &__error {
    font-size: 0.7em;
  }
}
.eyes {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20;
  height: 20;
  color: $gray-600;
}
