@import '../../../variables.scss';

.MigrationTourArticleCContainer {
  position: relative;
  padding: 10px;
  max-width: 600px;

  &__tooltipUp {
    width: 16px;
    position: absolute;
    top: 22px;
    right: 90%;
  }
}
