@import '../../../variables.scss';

.upgradesubmodal {
  width: 300px;
}

@media (min-width: 700px) {
  .upgradesubmodal {
    width: 400px;
  }
}
