@import '../../../variables.scss';

.pymnt {
  width: 100%;
  margin-top: 50px;
  @include colflexx(flex-start);
  gap: 10px;

  @include breakpoint(mobileonly) {
    width: 100%;
    margin-top: 0;
    gap: 0;
    margin-bottom: 2rem;
  }

  &__main {
    width: 100%;
    margin-top: -30px;
    @include rowflex(center, center);

    @include breakpoint(mobileonly) {
      width: 90vw;
      overflow-x: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      display: block;
    }
    ::-webkit-scrollbar,
    ::-webkit-scrollbar {
      display: none;
    }

    &__cardrow {
      width: 100%;
      @include rowflex(center, center);
      gap: 1.5rem;

      @include breakpoint(mobileonly) {
        width: 500px;
        @include rowflex(center, space-between);
        // white-space: nowrap;
        gap: 0.5rem;
      }

      .card_body {
        width: 100%;
        border-radius: 10px;
        position: relative;
        border: 1px solid $vesti-100;
        cursor: pointer;

        @include breakpoint(mobileonly) {
          overflow: visible;
          // height: 22.5vh;
        }

        .card_top {
          width: 100%;
          padding: 30px;
          @include colflexx(flex-start);

          @include breakpoint(mobileonly) {
            padding: 16px;
            font-weight: 600;
            font-size: 1em;
            height: 20vh;
          }

          p:nth-child(1) {
            width: 50%;
            font-weight: 600;
            font-size: 18px;

            @include breakpoint(mobileonly) {
              width: 100%;
              font-weight: 600;
              font-size: 1em;
            }
            @include breakpoint(phablet) {
              width: 100%;
              font-weight: 600;
              font-size: 1.2em;
            }
            @include breakpoint(tablet) {
              width: 100%;
              font-weight: 600;
              font-size: 1.2em;
            }
          }
          p:nth-child(2) {
            width: 75%;
            font-weight: 500;
            font-size: 15px;

            @include breakpoint(mobileonly) {
              width: 100%;
              font-weight: 400;
              font-size: 0.8em;
            }
            @include breakpoint(phablet) {
              width: 100%;
              font-weight: 400;
              font-size: 1.2em;
            }
            @include breakpoint(tablet) {
              width: 100%;
              font-weight: 400;
              font-size: 1.2em;
            }
          }
        }
        .card-bottom {
          @include rowflex(flex-end, space-between);

          // p:nth-child(1) {
          //   @include breakpoint(mobileonly) {
          //     margin-top: 1rem;
          //   }
          // }
          .card-image {
            @include breakpoint(mobileonly) {
              width: 25%;
              // margin-top: 1rem;
            }
          }
          p:nth-child(1) {
            font-weight: 500;
            font-size: 1.2em;
            padding: 30px;

            @include breakpoint(mobileonly) {
              font-weight: 400;
              font-size: 1em;
              padding: 19px;
            }
            @include breakpoint(phablet) {
              font-weight: 400;
              font-size: 1em;
              padding: 19px;
            }

            i {
              transform: rotate(45deg);
            }
          }
        }
      }
    }

    // @include breakpoint(phablet){
    //     width: 85vw;
    // }
    // @include breakpoint(tablet){
    //     width: 85vw;
    // }

    // @include breakpoint(desktop){
    //     width: 1190px;
    // }
    // @media screen and (min-width: 1024px) and (min-height: 1320px) {
    //     width: 100vw;
    // }

    // @media screen and (min-width: 1024px) and (max-height: 1310px) {
    //     width: 90%;
    // }
  }
}
