@import '../../variables.scss';

.referal {
  // width: fit-content;
  width: 100%;

  &__inner {
    width: fit-content;
    width: 100%;
    @include colflexx(flex-start);
    gap: 20px;

    .referal-info {
      @include rowflexx(center);
      @include fontNormal(1em, 1em, $yellow-800);

      img {
        width: auto;
        margin-right: 10px;
        filter: invert(73%) sepia(93%) saturate(313%) hue-rotate(354deg)
          brightness(96%) contrast(97%);
      }
    }
  }

  &__title {
    margin: 0;
    @include fontBold(1.5em, 1em, $vesti-700);

    @include breakpoint(mobileonly) {
      @include fontBold(1.2em, 1em, $vesti-700);
    }
  }

  &__link {
    margin-top: 20px;
    width: 100%;
    padding: 15px 15px 15px 15px;
    border-radius: 5px;
    border: 1px dashed $yellow-700;
    background-color: $yellow-100;
    transition: all 0.5s ease;
    text-align: center;
    @include rowflex(center, center);
    white-space: pre-wrap !important;
    @include fontNormal(1.5em, 1.2em, $yellow-800);

    @include breakpoint(mobileonly) {
      @include fontNormal(1.2em, 1.2em, $yellow-800);
    }

    @include breakpoint(phablet) {
      @include fontNormal(1.2em, 1.2em, $yellow-800);
    }

    &.copied {
      border: 1px dashed $vesti-700;
      background-color: $vesti-100;

      @include fontNormal(1.5em, 1.4em, $vesti-700);

      @include breakpoint(mobileonly) {
        @include fontNormal(1.2em, 1.2em, $vesti-700);
      }

      @include breakpoint(phablet) {
        @include fontNormal(1.2em, 1.2em, $vesti-700);
      }
    }
  }

  &__copied {
    @include fontNormal(1em, 1em, $green-700);
  }

  &__extra {
    @include fontNormal(1.1em, 1.7em, $vesti-901);

    strong {
      color: $vesti-700;
      font-weight: 600;
    }
  }
}

.referall {
  // width: fit-content;
  cursor: pointer;
  width: 100%;

  &__inner {
    width: fit-content;
    width: 100%;
    @include colflexx(flex-start);
    gap: 20px;

    .referall-info {
      @include rowflexx(center);
      @include fontNormal(1em, 1em, $yellow-800);

      img {
        width: auto;
        margin-right: 10px;
        filter: invert(73%) sepia(93%) saturate(313%) hue-rotate(354deg)
          brightness(96%) contrast(97%);
      }
    }
  }

  &__title {
    margin: 0;
    @include fontBold(1.5em, 1em, $vesti-700);

    @include breakpoint(mobileonly) {
      @include fontBold(1.2em, 1em, $vesti-700);
    }
  }

  &__link {
    margin-top: 20px;
    width: 100%;
    padding: 15px 15px 15px 15px;
    border-radius: 5px;
    border: 1px dashed $yellow-700;
    background-color: $yellow-100;
    transition: all 0.5s ease;
    text-align: center;
    @include rowflex(center, center);
    white-space: pre-wrap !important;
    @include fontNormal(1.2em, 1.2em, $yellow-800);

    @include breakpoint(mobileonly) {
      @include fontNormal(1.2em, 1.2em, $yellow-800);
    }

    @include breakpoint(phablet) {
      @include fontNormal(1.2em, 1.2em, $yellow-800);
    }

    &.copied {
      border: 1px dashed $vesti-700;
      background-color: $vesti-100;

      @include fontNormal(1.5em, 1.4em, $vesti-700);

      @include breakpoint(mobileonly) {
        @include fontNormal(1.2em, 1.2em, $vesti-700);
      }

      @include breakpoint(phablet) {
        @include fontNormal(1.2em, 1.2em, $vesti-700);
      }
    }
  }

  &__copied {
    @include fontNormal(1em, 1em, $green-700);
  }

  &__extra {
    @include fontNormal(1.1em, 1.7em, $vesti-901);

    strong {
      color: $vesti-700;
      font-weight: 600;
    }
  }
}

.refbox {
  width: 100%;
  background-color: $vesti-800;
  padding: 40px 30px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  @include rowflex(center, center);

  &__inner {
    // background-color: red;
    width: 100%;
    @include rowflex(center, space-between);
  }

  &__data {
    // @include colflexx(flex-start);
    @include rowflexx(flex-start);
    flex-wrap: wrap;
    gap: 30px;
  }

  &__rdata {
    @include rowflexx(flex-start);
    flex-wrap: wrap;
    gap: 10px;
  }

  &__dimg {
    width: auto;
  }

  &__right {
    @include colflexx(flex-start);
    gap: 5px;
  }

  &__title {
    @include fontNormal(1em, 1em, #fff);
  }

  &__subtitle {
    @include fontSemiBold(2em, 1.2em, #fff);
  }

  &__img {
    width: 100px;
    display: flex;
    position: absolute;
    bottom: -50px;
    right: 0;

    @include breakpoint(mobileonly) {
      display: none;
    }

    @include breakpoint(desktop) {
      width: 180px;
      display: flex;
    }
  }
}

.refboxx {
  width: 100%;
  background-color: $vesti-800;
  padding: 40px 30px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  @include rowflex(center, center);

  &__inner {
    // background-color: red;
    width: 100%;
    @include colflex(flex-start, space-between);
    gap: 1rem;
  }

  &__data {
    // @include colflexx(flex-start);
    @include rowflexx(flex-start);
    flex-wrap: wrap;
    gap: 30px;
  }

  &__rdata {
    @include rowflexx(flex-start);
    flex-wrap: wrap;
    gap: 10px;
  }

  &__dimg {
    width: auto;
  }

  &__right {
    @include colflexx(flex-start);
    gap: 5px;
  }

  &__title {
    @include fontNormal(1em, 1em, #fff);
  }

  &__subtitle {
    @include fontSemiBold(2em, 1.2em, #fff);
  }

  &__link {
    @include rowflex(flex-start, space-between);
    cursor: pointer;
    gap: 1rem;

    p {
      @include fontSemiBold(1em, 1.2em, #fff);
    }
  }

  &__img {
    width: 100px;
    display: flex;
    position: absolute;
    bottom: -20px;
    right: 0;

    @include breakpoint(mobileonly) {
      display: none;
    }

    @include breakpoint(desktop) {
      width: 180px;
      display: flex;
    }
  }
}

.extrarefbox {
  width: 100%;
  background-color: $vesti-800;
  padding: 40px 30px;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  @include rowflex(center, center);

  &__inner {
    // background-color: red;
    width: 100%;
    @include rowflex(flex-start, space-between);
  }

  &__data {
    // @include colflexx(flex-start);
    @include rowflexx(flex-start);
    // flex-wrap: wrap;
    gap: 10px;
  }

  &__rdata {
    @include rowflexx(flex-start);
    // flex-wrap: wrap;
    gap: 5px;
  }

  &__dimg {
    width: auto;
  }

  &__right {
    @include colflexx(flex-start);
    gap: 5px;
  }

  &__title {
    @include fontNormal(1em, 1em, #fff);
  }

  &__subtitle {
    @include fontSemiBold(2em, 1.2em, #fff);
  }

  &__exsubtitle {
    @include fontSemiBold(1em, 1.2em, #fff);
  }

  &__img {
    width: 100px;
    display: flex;
    position: absolute;
    bottom: -50px;
    right: 0;

    @include breakpoint(mobileonly) {
      display: none;
    }

    @include breakpoint(desktop) {
      width: 180px;
      display: flex;
    }
  }

  &__claimbutton {
    padding: 0.5rem 1rem;
    @include fontSemiBold(1em, 1.2em, $vesti-700);
    border: 1px solid $vesti-100;
    background-color: $vesti-100;
    border-radius: 2rem;
    cursor: pointer;
  }
}

.innerprompt {
  width: 100%;
  @include colflex(center, center);
  gap: 1rem;

  .innerprompt-top {
    width: 100%;
    @include colflex(center, center);
    margin-top: 1rem;

    p:nth-child(1) {
      @include fontBold(1.2em, 1.5em, $vesti-700);
    }

    p:nth-child(2) {
      @include fontMedium(1em, 1.5em, $vesti-902);
    }
  }
}

.innerprompts {
  width: 100%;
  @include colflex(center, center);
  gap: 1rem;

  .innerprompts-tops {
    width: 100%;
    @include colflex(center, center);
    margin-top: 1rem;

    p:nth-child(1) {
      @include fontBold(1.5em, 1.5em, $vesti-700);
    }

    p:nth-child(2) {
      @include fontMedium(1.2em, 1.5em, $vesti-902);
    }
  }
}
