@import "../../variables.scss";

.currencyamount {
    width: 100%;
    margin-top: 30px;
    @include colflexx(center);

    @include breakpoint(mobileonly){
        width: 75vw;
    }
    &__inputs{
        @include colflexx(center);
        
        width: 100%;

        span{
            width: 100%;
            display: flex;
            flex-direction:row;
            gap: 0px;
            select {
                background: $vesti-700;
                // width: 100px;
                padding: 10px;
                flex: 1;
                outline: none;
                height: 60px;
                color: #fff;
                border: none;
                border-radius: 5px 0px 0px 5px;
            }
            // input[type='text'], input[type='number'] {
            //     // width: 100% !important;
            //     flex: 3;
            //     height: 60px;
            //     border: none;
            //     padding: 0;
            //     margin: 0;
            //     // margin-top: 10px;
            //     border-radius: 0px 5px 5px 0px;
            //     box-sizing: border-box;
            //     outline: none;
            //     padding-left: 20px;
            //     background-color: $gray-200;
            //     border: 1px solid $gray-300;
            //     @include fontNormal(1em, 1em, $gray-600);

            //     @include breakpoint(tablet) {
            //         height: 60px;
            //     }

            //     &:focus {
            //         background-color: $vesti-100;
            //         color: $vesti-901;
            //         border: 1px solid $vesti-700;
            //     }
            // }
        }
    }
    .currencyamount__btn{
        width: 100%;
        height: 60px !important;
        margin-top: 30px;
        border-radius: 10px;
        background-color:  $vesti-700;
        color: #fff;
        transition: all .5s ease;

        i {
            margin-left: 10px;
        }

        &:hover {
            background-color:  $vesti-900;
        }
        &:disabled {
            background-color: $gray-500;
            color: #fff;
            cursor: not-allowed;
        }
    }
    select {
        z-index: 1;
    }
    .amountfloat{
        // margin-left: -2px;

        input {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }
}