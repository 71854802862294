@import '../../../variables.scss';

.my-input-class {
  width: 100%;
  padding: 0px 0px;
  padding-left: 0px;
  background-color: transparent !important;
  border: 1px solid $gray-400;
  box-sizing: border-box;
  border-radius: 10px !important;
  

  @include rowflexx(flex-start);
  flex-wrap: wrap;
  gap: 0px !important;
  background-color: $gray-300;
  .my-input-class .input {
    width: 100% !important;
    height: 60px !important;
    position: relative;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    @include fontNormal(1em, 1em, $gray-600);

    // @include breakpoint(mobileonly) {
    //     height: 50px;
    // }
    // @include breakpoint(tablet) {
    //     height: 50px;
    // }

    // @include breakpoint(phablet) {
    //     width: 100% !important; //250PX
    //     // height: 50px;
    // }

    &:focus {
      border: 1px solid $vesti-700;
      background-color: $vesti-100;
      color: $vesti-901;

      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &:disabled {
      cursor: not-allowed;
      color: $gray-800;
    }
  }
  *.PhoneInputCountry {
    width: 60px !important;
    padding: 10px !important;
    padding-left: 15px;
    padding-right: 15px;
    background-color: $vesti-200 !important;
    background-color: $gray-200 !important;
    border: 1px solid $gray-400;
    // color: #fff !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
    margin-right: 20px;
  }
  &.PhoneInput *.PhoneInputInput {
    margin-left: -10px !important;
    width: 100% !important;
    height: 60px;
    position: relative;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    @include fontNormal(1em, 1em, $gray-600);
    padding: 1rem;
  }
  *.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
    width: 30px !important;
    height: 30px !important;
    overflow: hidden !important;
    display: flex !important;
    align-items: center !important;
    background-color: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;

    svg {
      width: 100px !important;
      height: 100px !important;
      margin-top: 5px !important;
      margin-right: 10px !important;
    }
  }
  *select {
    min-height: 50px !important;
  }
}
