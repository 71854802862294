@import '../../variables.scss';

.singlepathway-container {
  width: 100%;
  // width: 820px;
  // height: fit-content;
  height: 282px;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid $gray-400;
  position: relative;
  transition: all 0.5s ease-out;
  @include rowflex(center, center);

  @include breakpoint(mobileonly) {
    width: 100%;
  }

  @include breakpoint(phablet) {
    width: 100%;
  }

  &:hover {
    background-color: $vesti-100;
    border: 1px solid $vesti-700;
    filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2));
    .singlepathway-number {
      background-color: $vesti-100;
      border: 1px solid $vesti-700;
    }
  }

  .singlepathway-number {
    width: 70px;
    height: 70px;
    z-index: 2;
    padding: 10px 10px 10px 10px;
    border-radius: 50%;
    background-color: #fff;
    border: 4px solid $gray-300;
    position: absolute;
    left: -25px;
    transition: all 0.5s ease-out;
    @include rowflex(center, center);
    @include fontNormal(1.1em, 1.4em, $vesti-901);
  }
  .singlepathway-inner {
    width: 100%;
    height: 100%;
    position: relative;

    font-size: 20px;
    @include colflexx(flex-start);

    &.singlepathway-content {
      margin: 0;
      width: 100%;
      // height: fit-content !important;
      @include colflexx(flex-start);
      p:nth-child(1) {
        margin: 0;
        @include fontBold(1.5em, 2em, $vesti-700);
      }
      p:nth-child(2) {
        margin-top: 0px;
        text-overflow: ellipsis;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include fontNormal(1.1em, 2.7em, $vesti-700);
      }
    }
    &.link {
      text-decoration: none;
      cursor: pointer;
      transition: all 0.5s ease-out;
      width: fit-content;
      @include rowflexx(center);
      @include fontBold(0.9em, 2em, $vesti-700);

      i {
        margin-left: 0px;
      }

      &:hover {
        color: $vesti-700;
      }
    }
  }
}

.singlepathwayimageleft {
  width: 40%;
  padding: 0;
  margin: 0;
}
.singlepathwayright {
  width: 60%;
  padding: 0;
  margin: 0;
}

.singlepathwayimageleft img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 0;
  margin: 0;
  // margin-top: -5px;
}

@media (max-width: 780px) {
  // .stage-header {
  //   font-size: 15px !important;

  //   margin-top: 30px !important;

  //   font-weight: bolder;

  //   margin-top: 20px !important;
  // }

  // .singlepathwayimageleft {
  //   width: 240px !important;
  //   height: 20px !important;

  //   margin-right: 5px !important;

  //   margin-bottom: 220px !important;
  // }

  // .singlepathway-container {
  //   width: 390px !important;

  //   height: 600px !important;
  // }

  .long-text-description-four {
    text-align: justify;

    color: #838079;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 174%;
  }

  .stagetitle-container {
    font-size: 13px !important;
    font-weight: bolder !important;
  }
}

@media only screen and (max-width: 600px) {
  .pathwaybox-container {
    display: absolute;
  }

  .singlepathwayimageleft {
    width: 27% !important;
  }

  // .singlepathway-container {
  //   height: 300px !important;
  // }

//   .singlepathwayimageleft img {
//     width: 190px;
//     height: 300px;
//     object-fit: cover;
//     padding: 0;
//     margin: 0;
//     margin-top: -5px;
//   }
}

.singlepathwayimageleft {
  width: 224px;
  height: 280px;
  flex-shrink: 0;
  margin-right: 30px; /* Adjust the spacing as needed */
}

.stagedays {
  color: #303030;
  text-align: center;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: 0.2px;
}

.stagedays-container {
  border-radius: 100px;
  border: 1px solid #000;
  background: #f4f4f6;

  width: 104px;
  height: 32px;
  padding: 10px 10px;
  justify-content: center;
  align-items: center;

  display: absolute;
  margin-right: 0;
}

.share-stagesicon {
  margin-right: 0;
}

.stagetitle-container {
  color: #000;

  font-size: 20px;

  margin-bottom: 40px;
}

// .stage-header {
//   color: #000;

//   font-size: 32px;

//   margin-top: 30px;

//   font-weight: bolder;
// }
