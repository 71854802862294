@import '../../../variables.scss';

.ngnwithdrawal {
  width: 100%;
  @include colflexx(flex-start);

  @include breakpoint(mobileonly) {
    // width: 75vw;
  }

  @include breakpoint(phablet) {
    // width: 350px;
  }
  @include breakpoint(tablet) {
    // width: 400px;
  }
  *.backcontinue {
    width: 100%;
  }
  &__col {
    width: 100%;
    @include colflexx(flex-start);
    gap: 25px;

    @include breakpoint(mobileonly) {
      gap: 10px;
      margin-bottom: 4rem;
    }

    &.--list {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 20px;
      max-height: 300px;
      @include overflowScroll($vesti-200, $vesti-700);
      overflow-y: scroll;
      overflow-x: auto;

      @include breakpoint(mobileonly) {
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
        max-height: fit-content;
        @include overflowScroll($vesti-200, $vesti-700);
        overflow-y: auto;
        overflow-x: auto;
        margin-bottom: 3rem;
      }
    }
  }
  &__smallcol {
    width: 100%;
    @include colflexx(flex-start);
    gap: 5px;

    p:nth-child(1) {
      @include fontMedium(1em, 1em, $vesti-901);
    }
  }
  &__get {
    @include fontNormal(1.2em, 1em, $vesti-800);
    width: fit-content;
    height: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    background-color: $vesti-200;
    border: 0.5px solid $vesti-700;

    &.--error {
      @include fontNormal(1.2em, 1em, red);
      background-color: rgb(255, 190, 190);
      border: 0.5px solid red;
    }
  }

  .select-input {
    * {
      margin-bottom: 0px !important;
    }
  }

  .inputfloat,
  .amountfloat,
  &__review {
    // width: 400px !important;
    width: 100% !important;
    @include breakpoint(mobileonly) {
      width: 100% !important;
    }
  }
  &__review {
    // width: 400px;
    width: 100%;
    @include breakpoint(mobileonly) {
      width: 100% !important;
    }
  }

  &__update {
    width: fit-content;
    height: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    &.ngnwithdrawal__update_success {
      @include fontNormal(1.2em, 1em, $vesti-800);

      background-color: $vesti-200;
      border: 0.5px solid $vesti-700;
    }
    &.ngnwithdrawal__update_error {
      @include fontNormal(1.2em, 1em, red);
      background-color: rgb(255, 190, 190);
      border: 0.5px solid red;
    }
  }
  &__review {
    @include breakpoint(desktop) {
      width: 100% !important;
      // width: 425px !important;
    }
  }
}

