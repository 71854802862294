@import "../../../variables.scss";

.backcontinue {
    width: 100%;
    @include rowflex(center, center);
    margin-top: 30px;
    gap: 10px;

    @include breakpoint(mobileonly){
        @include colflexx(flex-start);
        display: flex;
        flex-direction: column-reverse !important;
        justify-content: flex-start;
        width: 100%;
    }
    @include breakpoint(phablet){
        @include colflexx(flex-start);
        display: flex;
        flex-direction: column-reverse !important;
        justify-content: flex-start;
        width: 100%;
    }
    @include breakpoint(tablet){
        @include colflexx(flex-start);
        display: flex;
        flex-direction: column-reverse !important;
        justify-content: flex-start;
        width: 100%;
    }

    
    &__back{
        outline: none;
        padding:  10px 30px 10px 30px;
        height: 55px;
        border: 1px solid $vesti-700 !important;
        background-color: transparent;
        @include fontMedium(1em,1em, $vesti-901);
    }

    &__continue, .backcontinue__continue{
        outline: none;
        border: none;
        height: 55px;
        background-color: $vesti-700;
        @include fontMedium(1em,1em, #fff);
        transition: all .5s ease;
        &:hover {
            background-color: $vesti-800;
        }
        &:disabled {
            background-color: $gray-500;
            color: $gray-100;
            cursor: not-allowed;
        }

        @include breakpoint(mobileonly){
            height: 50px;
        }
    }
    
}