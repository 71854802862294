@import "../../../variables.scss";

.smallbox {
    width: fit-content;
    height: fit-content;
    padding: 20px;
    background-color: $vesti-100;
    border: 1px solid $vesti-700;
    border-radius: 10px;
    @include rowflex(center,center);
    &__container{
        width: 100%;
        height: fit-content;
        @include rowflex(flex-start,space-between);
        img {
            width: auto;
            margin-right: 10px;
        }
        p{
            margin: 0;
            @include fontNormal(1.2em, 1.7em, $vesti-902);

            @include breakpoint(mobileonly){
                @include fontNormal(1em, 1.7em, $vesti-902);
            }
            strong {
                color: $vesti-700;
            }
        }

    }
}