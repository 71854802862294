@import '../../../variables.scss';

// Header Optimisation

.DesktopMaiHeadernNav {
  display: none;
  justify-content: space-between;
  align-items: center;
  @include breakpoint(desktop) {
    display: flex;
    width: 100%;
  }
}

.MobileMaiHeadernNav {
  @include breakpoint(desktop) {
    display: none;
  }
}

.MiaiBacktoVesti {
  position: relative;
  & p {
    opacity: 0;
    background: #518c36;
    padding: 5px 10px;
    border-radius: 8px;
    margin-top: 10px;
    font-size: 13px;
    display: none;
    position: absolute;
    width: 15rem;
    text-align: center;
    // @include breakpoint(desktop) {
    //   display: block;
    // }
  }
}

.MiaiBacktoVesti:hover p {
  opacity: 1;
  display: block;
}

// Header Optimisation

.DesktopMaiHeadernNav {
  display: none;
  justify-content: space-between;
  align-items: center;
  @include breakpoint(desktop) {
    display: flex;
    width: 100%;
  }
}

.MobileMaiHeadernNav {
  @include breakpoint(desktop) {
    display: none;
  }
}

.MiaiBacktoVesti {
  & p {
    opacity: 0;
    background: #518c36;
    padding: 5px 10px;
    border-radius: 8px;
    margin-top: 10px;
    font-size: 13px;
    display: none;
    // whiteSpace: 'nowrap',
    @include breakpoint(desktop) {
      display: block;
    }
  }
}

.MiaiBacktoVesti:hover p {
  opacity: 1;
}

.helpcolor {
  color: #9ea1ae;
}

.iconbg {
  background-color: #e7f4e2;
  padding: 2px;
  border-radius: 3px;
}

.activeLi {
  background-color: #e7f4e2;
  border: 1px solid rgb(214, 236, 182);
  padding: 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.Lightdisclaimer {
  font-weight: 600;
  color: #333;
  transition: color 0.3s;
}
.Darkdisclaimer {
  font-weight: 600;
  color: #d9d9d9;
  transition: color 0.3s;
}

.Mheader {
  @include breakpoint(desktop) {
    display: none;
  }
}

.introduction {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__introHeader {
    width: 100%;
    margin: 0px auto;
    text-align: center;
    @include breakpoint(desktop) {
      width: 400px;
    }
    // & p {
    //   font-size: 18px;

    //   color: #9ea1ae;
    //   padding: 10px 20px;
    //   @include breakpoint(mobileonly) {
    //     font-size: 14px;
    //   }
    //   @include breakpoint(phablet) {
    //     font-size: 14px;
    //   }
    // }
  }
}

.notactiveLi {
  padding: 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.light {
  background-color: #f8f8f8;
  transition: background-color 0.3s;
}

.greyBg {
  background-color: #f8f8f8;
  border: 1.5px solid #d9d9d9;
  transition: background-color 0.3s;
  @include breakpoint(mobileonly) {
    background-color: #f8f8f8;
    transition: background-color 0.3s;
  }
  @include breakpoint(phablet) {
    background-color: #f8f8f8;
    transition: background-color 0.3s;
  }
}

.lightModecolor {
  color: #333;
  transition: color 0.3s;
}

.dark {
  background-color: #333;
  transition: background-color 0.3s;
}

.darksidebar {
  background-color: #000;
  transition: background-color 0.3s;
}

.darkModecolor {
  color: #d4c7c7;
  transition: color 0.3s;
}

.MiaiIndexContainer {
  width: 100%;
  height: 100svh;
  overflow-y: hidden;

  @include breakpoint(desktop) {
    display: flex;
  }
  @include breakpoint(mobileonly) {
    height: 100svh;
    overflow-y: hidden;
  }

  &__sidebar {
    width: 20%;
    height: 100svh;
    padding: 40px 20px;
    display: none;
    @include breakpoint(desktop) {
      display: block;
    }
    @include breakpoint(mobileonly) {
      height: 100svh;
      overflow-y: scroll;
    }
  }

  &__main {
    width: 100%;
    height: 100svh;
    padding: 40px 10px;
    @include breakpoint(mobileonly) {
      padding: 10px 10px;
      height: 100svh;
      overflow-y: hidden;
    }
    @include breakpoint(phablet) {
      padding: 40px 10px;
    }
    @include breakpoint(desktop) {
      width: 80%;
      padding: 20px 10px;
    }

    &__page {
      height: 100%;
      border-radius: 5px;
      padding: 20px;
      @include breakpoint(mobileonly) {
        padding: 20px 10px;
      }
      @include breakpoint(phablet) {
        padding: 20px 10px;
      }
    }
  }
}

.MiaiSidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  &__top {
    height: 10%;

    &__nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      & img {
        width: 40px;
        height: 40px;
        @include breakpoint(mobileonly) {
          width: 30px;
          height: 30px;
        }
      }

      & p:nth-last-child(1) {
        font-size: 12px;
        color: #9ea1ae;
        @include breakpoint(mobileonly) {
          font-size: 9px;
        }
      }

      & p:nth-last-child(2) {
        font-weight: 600;
        font-size: 14px;
        @include breakpoint(mobileonly) {
          font-size: 11px;
        }
      }
    }

    &__Header {
      display: flex;

      align-items: center;
      gap: 10px;
      border-radius: 8px;
      cursor: pointer;
      padding: 8px;

      @include breakpoint(desktop) {
        display: none;
      }
    }
  }

  //end of top ... beginning of title container

  &__ChatTitlesContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 0px;
    // border: 2px solid red;
    padding: 10px 0px;
    height: 40%;

    & ul {
      display: flex;
      flex-direction: column;
      // gap: 10px;
      font-size: 13px;
      font-weight: 600;
      height: 100%;
      overflow-y: auto;

      & li {
        cursor: pointer;
      }
    }
  }

  &__Bottom {
    height: 45%;
    // border: 2px solid red;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &__helpContainer {
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      padding: 20px 0px 20px 0px;
      font-size: 14px;
      font-weight: 600;

      & p {
        color: #9ea1ae;
        padding-left: 15px;
      }

      & ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;

        & li {
          display: flex;
          align-items: center;
          gap: 4px;

          & img {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    &__BottomContainer {
      // margin-top: 20px;
      background-color: #060e42;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 30px;

      & p {
        color: #fff;
        font-size: 13px;
        text-align: center;
        font-weight: 600;
      }

      & p:nth-last-child(1) {
        border: 2px solid #fff;
        padding: 5px;
        border-radius: 5px;
        font-size: 11px;
      }
    }
  }
}

.Miaimain {
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  padding: 0px 0px;
  @include breakpoint(mobileonly) {
    padding: 0px;
    gap: 0px;
  }
  @include breakpoint(phablet) {
    padding: 10px;
  }

  @include breakpoint(desktop) {
    justify-content: space-between;
  }

  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    & img {
      width: 60px;
      height: 60px;
    }

    & p:nth-last-child(1) {
      font-size: 13px;
      color: #9ea1ae;
    }

    & p:nth-last-child(2) {
      font-weight: 600;
    }
  }

  &__inputInfo {
    border: 1px solid #d9d9d9;
    color: #9ea1ae;
    padding: 10px 20px;
    text-align: center;
    width: fit-content !important;
    margin: 20px auto;
    font-size: 14px;
    border-radius: 6px;
  }
}

.introcard {
  display: flex;
  justify-content: center;
  width: 100%;
  &__info {
    // border: 1px solid #000;
    width: 100%;
    padding: 10px;
    height: fit-content !important;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 12px;

    @include breakpoint(mobileonly) {
      width: 100%;
      padding: 10px;
    }

    @include breakpoint(phablet) {
      width: 100%;
      padding: 10px;
    }

    & p:nth-last-child(2) {
      font-weight: 600;
    }

    & p:nth-last-child(1) {
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
    }
  }

  & img {
    // position: absolute;
    // left: -20px;
    width: 20px;
    height: 20px;
    // top: 30%;

    @include breakpoint(mobileonly) {
      width: 40px;
      height: 40px;
    }

    @include breakpoint(phablet) {
      width: 40px;
      height: 40px;
    }
  }
}

.PostMiaiInput {
  width: 80%;
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 0px auto;

  @include breakpoint(mobileonly) {
    width: 100%;
    gap: 5px;
    margin: -10px auto 0px auto;
  }

  @include breakpoint(phablet) {
    width: 100%;
    gap: 5px;
  }
  &__input {
    width: 100%;
    border: 1px solid #d9d9d9;
    padding: 9px 20px;
    border-radius: 8px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  &__img {
    background-color: #d9d9d9;
    padding: 8px;
    border-radius: 5px;
    height: 30px;
    width: 30px;

    & img {
      width: 100%;

      height: 100%;
    }
  }
}

// @keyframes smoothLoader {
//   0% {
//     opacity: 0;
//   }
//   25% {
//     opacity: 0.75;
//   }

//   100% {
//     opacity: 1;
//   }
// }

.ChatHistory {
  position: relative;
  margin-top: 10px;

  height: 100%;
  overflow-y: auto;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;

  &__QestionContainer {
    display: flex;
    justify-content: right;
    // color: green
  }

  &__QA {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid #d9d9d9;
    padding: 10px;
    width: fit-content;
    max-width: 45%;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 20px;
    background: #d9d9d9;

    @include breakpoint(mobileonly) {
      width: 75%;
      margin-bottom: 10px;
      font-size: 12px;
    }
    @include breakpoint(phablet) {
      width: 75%;
      margin-bottom: 10px;
    }
  }

  &__absolute {
    border-radius: 30px;
    // padding: 10px;
    // position: absolute;
    // right: 15px;
    // bottom: -20px;
    width: 40px;
    height: 40px;
    margin-left: 10px;

    &__img {
      width: 40px;
      height: 40px;
      @include breakpoint(mobileonly) {
        height: 30px;
        width: 30px;
      }
    }
  }

  &__AnswerContainer {
    display: flex;
    justify-content: left;
    margin-top: 20px;

    &__Ans {
      // border-radius: 4px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background: #518c36;
      padding: 0px 10px;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 40px;
      display: flex;
      gap: 10px;
      align-items: flex-start;
      // animation: smoothLoader 5s ease-in-out forwards;

      @include breakpoint(mobileonly) {
        width: 100%;
        margin-bottom: 40px;
        font-size: 12px;
      }
      @include breakpoint(phablet) {
        width: 85%;
        margin-bottom: 40px;
      }
      @include breakpoint(desktop) {
        max-width: 90%;
      }
    }

    &__absolute {
      border-radius: 30px;
      background: #d9d9d9;
      height: 40px;
      width: 40px;
      margin-right: 10px;
      @include breakpoint(mobileonly) {
        height: 30px;
        width: 30px;
      }
    }

    &__absoluteIcons {
      position: absolute;
      left: 5px;
      bottom: -30px;
      color: white;
    }
  }
}

/* Add these styles to your CSS file */
.fadeInText {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/// dark mode switch
/* App.css */

/* ... existing styles ... */

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;

  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: -2px;
  right: -4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* ... additional dark mode styles ... */
.sun,
.moon {
  width: 20px;
  height: 20px;
  margin-bottom: 8px;
  transition: opacity 0.4s; /* Added transition for opacity */
}

.moon {
  width: 20px;
  height: 20px;
}

.animated-border {
  height: 70%;
  padding: 20px 0px;
  margin-bottom: 10px;
  border-radius: 8px;
  // animation: borderAnimation 4s linear infinite;
  @include breakpoint(mobileonly) {
    height: 80%;
    padding: 20px 0px;
    margin-top: 10px;
  }
  @include breakpoint(phablet) {
    height: 90%;
  }
}

.darkmainBorder {
  // border: 2px solid #5d7527b3;
  @include breakpoint(mobileonly) {
    border: none;
  }
  @include breakpoint(phablet) {
    border: none;
  }
}

.lightmainBorder {
  // border: 2px solid #d9d9d9;
  @include breakpoint(mobileonly) {
    border: none;
  }
  @include breakpoint(phablet) {
    border: none;
  }
}

.refContainer {
  height: 100%;
  overflow-y: scroll;
}

.nonanimated-border {
  height: 70%;
  overflow-y: scroll;
  padding: 20px 0px;
  margin-bottom: 10px;
  border-radius: 8px;
  @include breakpoint(mobileonly) {
    height: 80%;
    padding: 20px 0px;
    margin-top: 10px;
  }
  @include breakpoint(phablet) {
    height: 90%;
  }
}

@keyframes borderAnimation {
  0% {
    border: 1px solid #ff0000; /* Red */
  }
  25% {
    border: 1px solid #00ff00; /* Green */
  }
  50% {
    border: 1px solid #0000ff; /* Blue */
  }
  75% {
    border: 1px solid #ffff00; /* Yellow */
  }
  100% {
    border: 1px solid #ff00ff; /* Magenta */
  }
}

.MobileSidebar {
  &__Mmain {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    height: 100svh;
    z-index: 999;
    -webkit-z-index: 999;

    display: flex;
    width: 15rem;
    padding: 20px 10px;
    overflow-y: auto;
    @include breakpoint(phablet) {
      width: 20rem;
    }
  }
  @include breakpoint(desktop) {
    display: none;
  }
}

.mobilebars {
  padding: 5px;
  @include breakpoint(desktop) {
    display: none;
  }
}

// .tooltiptext{

// }

.tooltipIcon {
  width: 25px;
  height: 25px;
  padding: 0px;
  margin: 0px;
}

.tooltipIcon .tooltiptext {
  visibility: hidden;
  width: 90px;
  background-color: #3e6f26;
  color: #fff;
  padding: 5px;
  text-align: center;
  border-radius: 6px;
  /* padding: 5px 0; */
  z-index: 1;
  // margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.tooltipIcon .tooltiptext::after {
  content: '';
  margin-top: 10px;
  // margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #3e6f26 transparent transparent transparent;
}

.tooltipIcon:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.HideIemMessage {
  display: none;
}

.myMiaipostContainer {
  height: 20%;
  // border: 2px solid red;
  @include breakpoint(desktop) {
    padding-bottom: 5px;
    height: 20%;
  }
}

.MiaiSharePage {
  background: #333;
  height: 100svh;
  width: 100%;
  padding: 20px 10px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  @include breakpoint(desktop) {
    padding: 40px;
  }
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin-bottom: 40px;
    & h1 {
      color: white;
      color: #fff;
      font-family: Söhne;
      font-size: 26px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 56px */
      text-transform: capitalize;
    }

    & p {
      color: #000;
      font-family: Söhne;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%; /* 24px */
      background-color: white;
      padding: 8px 10px;
      border-radius: 10px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    margin-top: 40px;

    &__text {
      width: 100%;

      @include breakpoint(desktop) {
        width: 40%;
      }
    }
    // & h1 {
    //   color: white;
    //   color: #fff;
    //   font-family: Söhne;
    //   font-size: 26px;
    //   font-style: normal;
    //   font-weight: 500;
    //   line-height: 100%;
    //   text-transform: capitalize;
    // }

    // & p {
    //   color: #000;
    //   font-family: Söhne;
    //   font-size: 14px;
    //   font-style: normal;
    //   font-weight: 300;
    //   line-height: 100%;
    //   background-color: white;
    //   padding: 8px 10px;
    //   border-radius: 10px;
    // }
  }

  &__QestionContainer {
    display: flex;
    justify-content: right;
    // color: green
  }

  &__QA {
    border-radius: 4px;
    padding: 10px;
    width: 35%;
    position: relative;
    font-weight: 500;
    font-size: 14px;
    background-color: #67a948;

    @include breakpoint(mobileonly) {
      width: 75%;
      margin-bottom: 10px;
      font-size: 12px;
    }
    @include breakpoint(phablet) {
      width: 75%;
      margin-bottom: 10px;
    }

    & p {
      color: #fff;
      font-weight: 300;
    }
  }

  &__absolute {
    border-radius: 30px;
    // padding: 10px;
    position: absolute;
    right: 15px;
    bottom: -20px;
    width: 40px;
    height: 40px;

    &__img {
      width: 40px;
      height: 40px;
      @include breakpoint(mobileonly) {
        height: 30px;
        width: 30px;
      }
    }
  }

  &__AnswerContainer {
    display: flex;
    justify-content: left;
    margin-top: 40px;

    &__flexAns {
      display: flex;
      gap: 10px;

      &__formattedshareres {
        background: #fff;
        padding: 10px;
        padding-bottom: 20px;
        max-width: 100%;
        position: relative;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 40px;
        color: #333;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    // &__Ans {
    //   border-radius: 4px;
    //   background: #b7b5b5;
    //   padding: 10px;
    //   padding-bottom: 20px;
    //   max-width: 80%;
    //   position: relative;
    //   font-weight: 500;
    //   font-size: 14px;
    //   margin-bottom: 40px;
    //   display: flex;

    //   @include breakpoint(mobileonly) {
    //     width: 85%;
    //     margin-bottom: 40px;
    //     font-size: 12px;
    //   }
    //   @include breakpoint(phablet) {
    //     width: 85%;
    //     margin-bottom: 40px;
    //   }
    // }
  }
}
.FormattedTextComponent p {
  margin-top: 20px !important; /* Force the style */
  color: #dadbe0;
  font-size: 16px;
}

.MiaiSignUp {
  position: relative;
}

.gearIcon1 {
  position: absolute;
  font-size: 3rem;
  font-weight: 300;
  top: 30px;
  right: 20px;
  animation: rotate 4s infinite linear; /* Adjust the duration and animation properties as needed */
}

// .gearIcon2 {
//   position: absolute;
//   font-size: 2rem;
//   font-weight: 300;
//   top: 50px;
//   right: 60px;
// }

.gearIcon3 {
  position: absolute;
  font-size: 1rem;
  font-weight: 300;
  top: 30px;
  right: 70px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.signInTitleSubtitle {
  display: flex;
  flex-direction: column;
  gap: 1px;
  &__title {
    font-size: 24px;
    font-weight: 600;
    color: #14290a;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 300;
    color: #2b5219;
  }
}

.miaiFlexButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  &__secondary {
    @include breakpoint(mobileonly) {
      margin-top: 1rem;
    }
  }
}

.MydisclaimerbtmText {
  padding-top: 10px;
  @include breakpoint(mobileonly) {
    padding-top: 2px;
  }
}

/// Mobile Nav
.MobileMiaiNav {
  @include breakpoint(desktop) {
    display: none;
  }
}

// mic effect

.Miaiwave-container {
  position: relative;
  width: 40px; /* Adjust the width of the container as needed */
  height: 40px; /* Adjust the height of the container as needed */
  margin: 4px auto 0px auto;
}

.Miaicenter-image {
  position: absolute;
  cursor: pointer;

  top: 23%;
  left: 25%;
  // transform: translate(-50%, -50%);
  z-index: 2; /* Place the image above the waves */
}

.Miaicenter-image img {
  width: 100%; /* Adjust the image size as needed */
  height: auto;
  border-radius: 50%; /* Make sure the image is circular */
}

.Miaiwave {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #050e42; /* Set border color as desired */
  border-radius: 50%;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  // background-color: #c6f2ae;
}

.Miaiwave1 {
  animation-name: wave-animation-1;
}

.Miaiwave2 {
  animation-name: wave-animation-2;
}

.Miaiwave3 {
  animation-name: wave-animation-3;
}

@keyframes wave-animation-1 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0.15;
  }
}

@keyframes wave-animation-2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes wave-animation-3 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.introcardContainer {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;

  @include breakpoint(mobileonly) {
    grid-template-columns: 1fr;
    height: 50svh;
    overflow-y: auto;
  }
}
