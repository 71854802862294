@import '../../../../variables.scss';


.message-container:hover .reply-icon {
    opacity: 1;
}

.backIconDiv {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 10%;

    @media (max-width: 1300px){
        margin-top: 0px;
    }

    @media (max-width: 991px){
        padding-left: 14px;
    }

    .backIcon {
        color: #67A948;
        font-size: 30px;
        cursor: pointer;

        @media (min-width: 400px) and (max-width: 991px){
            font-size: 26px;
        }
        @media (max-width: 768px){
            font-size: 22px;
        }
    }

    .backP {
        color: #67A948;
        font-size: 20px;
        cursor: pointer;

        @media (min-width: 400px) and (max-width: 991px){
            font-size: 18px;
        }
        @media (max-width: 768px){
            font-size: 15px;
        }
    }
}

.inChatContainer {
    display: flex;
    gap: 8px;
    margin-top: 10px;

    @media (min-width: 400px) and (max-width: 991px){
        padding: 0px 18px 0px 18px;
    }

    .immgViewDiv {
        max-height: 65vh;
        max-width: 100%;
        object-fit: cover;

        @media (max-width: 650px){
            max-height: 10vh;
            max-width: 80%;
        }
    
    }
    // .imgViewModal {
    //     height: 65vh;
    //     width: 100%;
    //     object-fit: cover;
    // }
}

.inChatMain {
    position: relative;
    width: 70%;
    height: 70vh;
    background-color: #ffffff;

    @media (max-width: 1300px){
        width: 100%;
        height: 100%;
    }
}



.nameContainerDiv {
    background-color: #F6F7FF;
    padding: 8px 16px;
    border-radius: 10px;

    @media (max-width: 1300px){
        padding: 8px 26px;
    }

    .nameContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .nameImgDiv {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    .ImgDotDiv {
        position: relative;
        display: block;
    }
    .profileImg {
        width: 54px;
        height: 54px;
        border-radius: 50%;

        @media (max-width: 991px){
            width: 44px;
            height: 44px;
        }
        @media (max-width: 768px){
            width: 40px;
            height: 40px;
        }
    }
    .onlineDotIcon {
        position: absolute;
        color: #67A948;
        right: 0px;
        left: 42px;
        top: 25px;
        font-size: 20px;
    }
    .profileNameDiv {
        display: block;
    }
    .profileName {
        font-size: 18px;
        font-weight: bold;

        @media (max-width: 768px){
            font-size: 16px;
        }
    }
    .onlineStatus {
        font-size: 12px;

        @media (max-width: 768px){
            font-size: 10px;
        }
    }

   
}

/* chat body */
.chatBodyDiv {
    overflow-y: scroll;
    margin-top: 2%;
    height: 72vh;

    @media (max-width: 1300px){
        height: 59vh;
    }
    @media (max-width: 991px){
        height: 60.5vh;
    }
    @media (max-width: 750px){
        height: 62vh;
    }
    @media (max-width: 600px){
        height: 65vh;
    }
    @media (max-width: 480px){
        height: 66vh;
    }
    @media (max-width: 300px){
        height: 63vh;
    }

    .dateLabelDiv {
        display: flex;
        justify-content: center;
        margin: 16px 0;
    }

    .dateLabel {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        text-align: center;
        border-bottom: 1px solid #ddd;
        border-top: 1px solid #ddd;
        padding: 4px 16px;
        background-color: #f5f5f5;
        border-radius: 10px;
        font-size: 12px;

        @media (min-width: 400px) and (max-width: 991px){
            font-size: 8px;
        }
    }

    .chatMesStylingDiv {
        display: flex;
        margin: 10px 0;
        padding-right: 8px;
    }

    .message-container {
        position: relative;
        max-width: 60%;
        padding: 4px 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        @media (min-width: 400px) and (max-width: 1330px){
            padding: 4px 6px;
            // max-width: 56%;
        }
    }
    .reciever-chat-border {
        border-top-left-radius: 0px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    
    .sender-chat-border {
        border-top-left-radius: 10px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .inChatImage {
        width: 400px;
        max-width: 600px;
        max-height: 300px;
        margin-bottom: 0.5rem;
        border-radius: 10px;
        object-fit: cover;
        cursor: pointer;

        @media (min-width: 400px) and (max-width: 1330px){
            width: 300px;
            max-width: 400px;
            max-height: 250px;
        }
        @media (max-width: 1075px){
            width: 200px;
            max-width: 300px;
            max-height: 150px;
        }
        @media (max-width: 768px){
            width: 250px;
            max-width: 350px;
            max-height: 200px;
        }
        @media (max-width: 480px){
            width: 200px;
            max-width: 300px;
        }
        @media (max-width: 358px){
            width: 170px;
            max-width: 200px;
        }
        @media (max-width: 310px){
            width: 150px;
            max-width: 180px;
        }
        @media (max-width: 275px){
            width: 120px;
            max-width: 150px;
        }
    }

    .chatMessage {
        font-size: 13px;
        padding: 0px 4px;
        @media (max-width: 1300px){
            font-size: 14px;
        }
        @media (max-width: 430px){
            font-size: 12px;
        }
    }

    .reply-icon {
        position: absolute;
        top: 1px; /* Adjust as needed */
        left: 6px; /* Adjust as needed to position the icon outside the div */
        opacity: 0;
        transition: opacity 0.3s ease;
        color: #999;
        padding-bottom: 6px;
        font-size: 20px;
    }

    .replyingToDiv {
        font-size: 12px;
        color: #555;
        border-left: 2px solid #ccc;
        padding-left: 8px;
        margin-bottom: 4px;
    }
    .replyText {
        margin: 0;
        font-size: 14px;
    }
    .TimeAndCheck {
        display: flex;
        align-items: center;
        gap: 2px;
    }
    .createdAtStyle {
        font-size: 9px;
        color: #999;
    }
}

// .textContainer {
//     margin-right: 6%;
//     padding: 10px 0px;
//     @media (max-width: 1200px){
//         padding-right: 6%;
//     }
// }

.textDivContainer {

    position: fixed;
    bottom: 20px;
    left: 19.3%;
    right: calc(30% + 20px);
    // left: 0; /* Align the container to the right edge of the 70% section */
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    background-color: #F5F5F5;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(70% - 40px); /* Adjust the width calculation to fit within the 70% area, accounting for padding */
    max-width: 53.5%; /* Ensure it doesn't exceed the container width */
    box-sizing: border-box; /* Include padding and border in the element's total width */

    @media (max-width: 1600px){
        left: 18.3%;
        max-width: 54.9%;
    }
    @media (max-width: 1470px){
        left: 19%;
        max-width: 54.5%;
    }
    @media (max-width: 1372px){
        left: 20%;
        max-width: 54.2%;
    }
    @media (max-width: 1300px){
        left: 22%;
        right: 0;
        max-width: 75.2%;
        width: calc(100% - 40px);
    }
    @media (max-width: 1180px){
        left: 24%;
        max-width: 74%;
    }
    @media (max-width: 1080px){
        left: 26%;
        max-width: 72%;
    }
    @media (max-width: 991px){
        left: 9.5%;
        max-width: 88%;
    }
    @media (max-width: 880px){
        left: 10.8%;
        max-width: 86%;
    }
    @media (max-width: 780px){
        left: 12%;
        max-width: 86%;
    }
    @media (max-width: 767px){
        left: 2%;
        max-width: 100%;
    }
    @media (max-width: 650px){
        width: calc(100% - 28px);
    }
    @media (max-width: 500px){
        left: 3%;
    }
    @media (max-width: 380px){
        width: calc(100% - 22px);
    }


    .textDivField {
        position: relative;
        width: 100%;
    }


    .replyToDiv {
        display: flex;
        align-items: center;
        gap: 2px;
        font-size: 12px;
        color: #555;
        padding-bottom: 4px;
        width: 90%;
    }

    .replyCancelIcon {
        font-size: 20px;
    }

    .imgPreviewContainer {
        position: absolute;
        left: 0;
        bottom: 92%;
        display: flex;
        gap: 8px;
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 100%;
        /* max-width: 120%; */
        max-height: 100px;
        padding: 5px;
        overflow-x: scroll;
        margin-bottom: 10px;
        background-color: #F5F5F5;
        border-radius: 4px;
    }

    .imgPreviewDiv {
        position: relative;
        display: inline-block;
        /* width: 92%; */
        max-height: 100px;
        width: auto; /* Maintain image aspect ratio */
        height: auto;
    }

    .imgPreview {
        width: 100px;
        height: 100px;
        border-radius: 4px;
    }   
    
    .imgPreviewSpan {
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: #ff0000b3;
    color: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    padding: 0px 6px;
    }

    .textAreaStyle {
        position: relative;
        width: 92%;
        outline: none;
        background-color: #F5F5F5;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    .textAreaImgIcon {
        position: absolute;
        border: 2px;
        right: 0px;
        left: 97%;
        top: 16px;
        bottom: 40px;
        cursor: pointer;
        width: 24px;
        height: 24px;
        opacity: 0.5;

        @media (max-width: 780px){
            left: 96%;
        }
        @media (max-width: 650px){
            left: 95.5%;
        }
        @media (max-width: 500px){
            left: 94.5%;
        }
        @media (max-width: 430px){
            left: 93.8%;
            width: 22px;
            height: 22px;
        } 
        @media (max-width: 380px){
            left: 93%;
        }
        @media (max-width: 335px){
            left: 91.5%;
        }
        @media (max-width: 280px){
            left: 90%;
        }

    }

    .emojiPickerDiv {
        position: absolute;
        right: 0px;
        left: 94%;
        top: 20px;
        bottom: 40px;
        font-size: 16px;
        cursor: pointer;
        width: 20px;
        height: 20px;

        @media (max-width: 1280px){
            left: 93%;
        }
        @media (max-width: 1137px){
            left: 92%;
        }
        @media (max-width: 991px){
            left: 92.5%;
        }
        @media (max-width: 927px){
            left: 91.4%;
        }
        @media (max-width: 839px){
            left: 91%;
        }
        @media (max-width: 1180px){
            left: 94%;
            top: 19px;
        }
        @media (max-width: 780px){
            left: 92.5%;
        }
        @media (max-width: 650px){
            left: 91.5%;
        }
        @media (max-width: 500px){
            left: 89.5%;
        }
        @media (max-width: 430px){
            left: 88%;
        }
        @media (max-width: 380px){
            left: 86.5%;
        }
        @media (max-width: 335px){
            left: 83.5%;
        }
        @media (max-width: 280px){
            left: 81%;
        }
    }

    .VcsendSend {
        padding: 6px;
        color: #FFFFFF;
        font-size: 30px;
        border-radius: 5px;

        @media (max-width: 430px){
            font-size: 26px;
        }
    }

}

.feedsChatInChat {
    width: 30%;
    height: 70vh;
    display: block;
        @media (max-width: 1300px) {
            display: none;
        }
}



// .textDiv-fixed  {
//     @media (min-width: 1000px) and (max-width: 1400px) {
//         width: 40%;
//         // border: blue;
//         // background-color: red;
//     }

// }