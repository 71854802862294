@import "../../../variables.scss";


.coin-page{
    max-height: 100vh; 
    
    .container{
        position: relative;
        bottom: 775px;
        z-index: 1;
        max-height: 85vh;
        text-align: center;
        .coin-texts{
            margin-top: 30px;
            width: fit-content;
            @include colflexx(center);
            p:nth-child(1){
                margin: 0;
                @include fontBold(2em, 1em, $vesti-700);
                
            }
            p:nth-child(2){
                width: 50%;
                margin-top: 10px;
                @include fontNormal(1.2em, 1.7em, $vesti-901);
            }
        }
        .waitlist-btn {
            width: 300px;
            height: 60px !important;
            @include fontMedium(1.3em, 1em, #fff);
            @include rowflex(center,center);
            border: none;
            outline: none;
            margin-left: 400px;
            cursor: pointer;
            border-radius: 10px;
            background-color: $vesti-700;
            transition: all 0.3s ease;
            &:hover {
                background-color: $vesti-900;
            }
        }
    }  
} 

@media screen and (min-width:320px) and (max-width:600px) {
    .coin-page{
        max-height: 60vh; 
        .container{
            position: relative;
            bottom: 960px;
            z-index: 1;
            max-height: 85vh;
            text-align: center;
            
            .coin-texts{
                margin-top: 10px;
                p:nth-child(1){
                    @include fontBold(2em, 1em, $vesti-902);
                    
                }
                p:nth-child(2){
                    @include fontBold(1.2em, 1em, $vesti-700);
                }
            }
            .waitlist-btn {
                width: 50%;
                height: 50px !important;
                @include fontMedium(0.8em, 1em, #fff);
                @include rowflex(center,center);
                border: none;
                outline: none;
                margin-left: 80px;
                cursor: pointer;
                background-color: $vesti-700;
                transition: all 0.3s ease;
                &:hover {
                    background-color: $vesti-900;
                }
            }
        }  
    }
}
@media screen and (min-width:728px) and (max-width:800px) {
    .coin-page{
        max-height: 60vh; 
        .container{
            position: relative;
            bottom: 1652px !important;
            z-index: 1;
            max-height: 85vh;
            text-align: center;
            
            .coin-texts{
                margin-top: 10px;
                p:nth-child(1){
                    @include fontBold(2em, 1em, $vesti-902);
                    
                }
                p:nth-child(2){
                    @include fontBold(1.2em, 1em, $vesti-700);
                }
            }
            .waitlist-btn {
                // width: 50%;
                height: 50px !important;
                @include fontMedium(0.8em, 1em, #fff);
                @include rowflex(center,center);
                border: none;
                outline: none;
                margin-left: 250px;
                cursor: pointer;
                background-color: $vesti-700;
                transition: all 0.3s ease;
                &:hover {
                    background-color: $vesti-900;
                }
            }
        }  
    }
}
@media screen and (min-width:800px) and (max-width:1224px) {
    .coin-page{
        max-height: 60vh; 
        .container{
            position: relative;
            bottom: 1652px !important;
            z-index: 1;
            max-height: 85vh;
            text-align: center;
            
            .coin-texts{
                margin-top: 10px;
                p:nth-child(1){
                    @include fontBold(2em, 1em, $vesti-902);
                    
                }
                p:nth-child(2){
                    @include fontBold(1.2em, 1em, $vesti-700);
                }
            }
            .waitlist-btn {
                // width: 50%;
                height: 50px !important;
                @include fontMedium(1.4em, 1em, #fff);
                @include rowflex(center,center);
                border: none;
                outline: none;
                margin-left: 350px;
                cursor: pointer;
                background-color: $vesti-700;
                transition: all 0.3s ease;
                &:hover {
                    background-color: $vesti-900;
                }
            }
        }  
    }
}