@import "../../../variables.scss";

.startjapajourney-container{
    width: 100%;
    height: fit-content;
    margin-bottom: 100px;
    border-radius: 50px;
    padding-bottom: 50px;
    @include rowflex(center,center);

    .startjapajourney-inner {
        width: 75%;
        height: fit-content;
        @include colflexx(center);

        .top {
            width: 100%;
            @include rowflex(center, space-between);
            margin-bottom: 20px ;
            gap: 14rem;
            .top-left {
                img {
                    width: 600px;

                    @include breakpoint(mobileonly){
                        width: 350px;
                    }
                    @include breakpoint(phablet){
                        width: 350px;
                    }
                }
            }
            .top-right{
                width: 30%;
                margin-right: 6rem;
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(1.5em, 1em, $vesti-902);
                }
                p:nth-child(2){
                    margin-top: 1rem;
                    @include fontNormal(1.2em, 1.5em, $vesti-901);
                }
                p:nth-child(3){
                    @include fontNormal(1.2em, 1.5em, $vesti-901);
                }
                a:nth-child(4){
                    margin-top: 10px;
                    @include fontMedium(1.2em, 1.5em, $vesti-700);
                }
            }           
        }
        .bottom {
            width: 100%;
            margin-top: 5rem;
            margin-bottom: 5rem;
            @include rowflex(center, space-between);
            gap: 8rem;
            .bottom-right{
                @include rowflex(center, space-between);
                .inner-bottom-right{
                    @include rowflex(center, space-between);
                    gap: 2rem;
                    img {
                        width: 600px;
    
                        @include breakpoint(mobileonly){
                            width: 350px;
                        }
                        @include breakpoint(phablet){
                            width: 350px;
                        }
                    }
                }
            }
            .bottom-left{
                width: 50%;
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(1.5em, 1em, $vesti-902);
                }
                p:nth-child(2){
                    margin-top: 10px;
                    @include fontNormal(1.2em, 1.5em, $vesti-901);
                }
                a:nth-child(3){
                    @include fontMedium(1.2em, 1.5em, $vesti-700);
                }
            }           

        }

    }
}
@media only screen and (min-width: 320px) and (max-width: 600px){
    .startjapajourney-container{
        width: 100%;
        height: fit-content;
        padding-bottom: 0;
        margin-bottom: 30px;
        @include colflex(center,center);
    
        .startjapajourney-inner {
            height: fit-content;
            @include colflexx(center);
            margin-top: -53px;
            font-size: 11px;
            padding-top: 50px;
            width: 90%;
            
            .top {
                width: 100%;
                @include revcolflex(center, space-between);
                margin-bottom: 20px ;
                gap: 2rem;


                
                .top-right{
                    text-align: left;
                    width: 95%;
                    margin-right: 0;
                    margin-top: -42px;
                    height: fit-content;
                    p:nth-child(1){
                        margin: 0;
                        @include fontBold(1.5em, 1em, $vesti-902);
                    }
                    p:nth-child(2){
                        margin-top: 1rem;
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    p:nth-child(3){
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    a:nth-child(4){
                        margin-top: 10px;
                        @include fontMedium(1.2em, 1.5em, $vesti-700);
                    }
                }           
            }
            .bottom {
                width: 100%;
                margin-top: 1rem;
                @include colflex(center, space-between);
                gap: 2rem;
                .bottom-right{
    
                    .inner-bottom-right{
                        @include colflex(center, space-between);
                        gap: 2rem;
                    }
                }
                .bottom-left{
                    width: 95%;
                    p:nth-child(1){
                        margin: 0;
                        @include fontBold(1.5em, 1em, $vesti-902);
                    }
                    p:nth-child(2){
                        margin-top: 10px;
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    a:nth-child(3){
                        @include fontMedium(1.2em, 1.5em, $vesti-700);
                    }
                }           
    
            }
    
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 800px){
    .startjapajourney-container{
        width: 100%;
        height: fit-content;
        @include colflex(center,center);
        margin-top: -5rem;
    
        .startjapajourney-inner {
            width: 85%;
            height: fit-content;
            @include colflexx(center);
    
            .top {
                width: 100%;
                @include revcolflex(flex-start, space-between);
                margin-bottom: 20px ;
                gap: 2rem;
                .top-right{
                    width: 100%;
                    margin-right: 0;
                    p:nth-child(1){
                        margin: 0;
                        text-align: left;
                        @include fontBold(1.5em, 1em, $vesti-902);
                    }
                    p:nth-child(2){
                        margin-top: 1rem;
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    p:nth-child(3){
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    a:nth-child(4){
                        margin-top: 10px;
                        @include fontMedium(1.2em, 1.5em, $vesti-700);
                    }
                }
                .top-left {
                    img {
                        width: 100%;
                    }
                }           
            }
            .bottom {
                width: 100%;
                margin-top: 1rem;
                margin-bottom: 3rem;
                @include colflex(flex-start, space-between);
                gap: 2rem;
                .bottom-right{
                    width: 100%;
                    .inner-bottom-right{
                        width: 100%;
                        @include rowflexx(center);
                        // flex-wrap: wrap;
                        gap: 10px;
                        img {
                            width: 100%;
                        }
                    }
                }
                .bottom-left{
                    width: 100%;
                    
                    p:nth-child(1){
                        margin: 0;
                        text-align: left;
                        @include fontBold(1.5em, 1em, $vesti-902);
                    }
                    p:nth-child(2){
                        margin-top: 10px;
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    a:nth-child(3){
                        @include fontMedium(1.2em, 1.5em, $vesti-700);
                    }
                }           
    
            }
    
        }
    }
}
@media only screen and (min-width: 800px) and (max-width: 1224px){
    .startjapajourney-container{
        width: 100%;
        height: fit-content;
        @include rowflex(center,center);
    
        .startjapajourney-inner {
            width: 80%;
            height: fit-content;
            @include colflexx(center);
    
            .top {
                width: 100%;
                @include revcolflex(flex-start, space-between);
                margin-bottom: 20px ;
                
                .top-right{
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: -12rem;
                    p:nth-child(1){
                        margin: 0;
                        text-align: left;
                        @include fontBold(1.5em, 1em, $vesti-902);
                    }
                    p:nth-child(2){
                        margin-top: 1rem;
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    p:nth-child(3){
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    a:nth-child(4){
                        margin-top: 10px;
                        @include fontMedium(1.2em, 1.5em, $vesti-700);
                    }
                }           
            }
            .bottom {
                width: 100%;
                margin-top: 2rem;
                @include colflex(flex-start, center);
                .bottom-right{
                    width: 100%;
                    @include colflex(flex-start, center);
                    .inner-bottom-right{
                        @include rowflex(center, space-between);
                    }
                }
                .bottom-left{
                    margin-bottom: -6rem;
                    width: 95%;
                    p:nth-child(1){
                        margin: 0;
                        text-align: left;
                        @include fontBold(1.5em, 1em, $vesti-902);
                    }
                    p:nth-child(2){
                        margin-top: 10px;
                        @include fontNormal(1.2em, 1.5em, $vesti-901);
                    }
                    a:nth-child(3){
                        @include fontMedium(1.2em, 1.5em, $vesti-700);
                    }
                }           
    
            }
    
        }
    }  
}