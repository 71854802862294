.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    height: 0;
    
    iframe {
        height: 300px;
        width: 250px;
        border-radius: 30px;
    }      
}
@media only screen and (min-width: 320px) and (max-width: 600px){
    .video-responsive {
        overflow: hidden;
        padding-bottom: 106%;
        height: 0;
        
        iframe {
            height: 300px;
            width: 285px !important;
            border-radius: 20px;
        }      
    }
}
@media only screen and (min-width: 600px) and (max-width: 800px){
    .video-responsive {
        overflow: hidden;
        padding-bottom: 50%;
        height: 0;
        
        iframe {
            height: 300px;
            width: 600px !important;
            border-radius: 20px;
        }      
    }
}
@media only screen and (min-width: 800px) and (max-width: 1224px){
    .video-responsive {
        overflow: hidden;
        padding-bottom: 50%;
        height: 0;
        
        iframe {
            height: 300px;
            width: 415px !important;
            border-radius: 20px;
        }      
    }
}