@import '../../../variables.scss';

.emojiDivContainer {
    position: absolute;
    max-width: 20rem;
    right: 28px;
    top: -180px;

    .epickerbg{
        background-color: #000;
        z-index: 5;
        width: 18rem; 
        padding: 10px 0px;
        // width: 20rem; 
        top: 80px;
        // padding: 1rem;
        border-radius: 10px;
        height: 150px;
        overflow-y: visible;
        overflow-x: hidden;
    
        @media (max-width: 650px){
            width: 16rem; 
            padding: 10px 0px;
        }
        @media (max-width: 550px){
            width: 12rem; 
            height: 120px;
            margin-top: 36px;
        }
    
        
    }
    .emojiCloseButton {
        position: absolute;
        right: 8px;
        top: 8px;
        color: #66A848;
        cursor: pointer;

        @media (max-width: 550px){
            top: 40px;
        }
    }
    .emojiGanGan {
        cursor: pointer;
        margin: 0px 24px;
        font-size: 20px;
        cursor: pointer;
        z-index: 10%;

        @media (max-width: 650px){
            margin: 0px 20px;
        }
        @media (max-width: 550px){
            margin: 0px 10px;
            font-size: 16px;
        }
    }

    .emojiIcon {
        margin-right: 0px;
        cursor: pointer;
    }
}
//  className='emojiIcon' style={{marginRight:'0px', cursor: 'pointer'}}