@import '../../../../../../../variables.scss';

.Hb1paymentSection {
  border: 1px solid #d0d5dd;
  margin: 10px 0px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  //   gap: 10px;
  font-size: 13px;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(10%);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
    display: none;
  }
}

.h1bOptioonalFee {
  animation: slideIn 4s ease-in-out;
}

// .h1bOptioonalFeeFalse {

//   animation: slideOut 4s ease-in-out 4s forwards;

// }

.VisaCustomScroll {
  /* Customize scrollbar */
  &::-webkit-scrollbar {
    width: 5px; /* Adjust width as needed */
  }

  &::-webkit-scrollbar-thumb {
    background-color: green; /* Set scrollbar thumb color */
  }

  &::-webkit-scrollbar-track {
    background-color: #efffe8; /* Optional: Set track color */
  }
}
