.Glink {
  width: fit-content;
  // @include rowflexx(center);
  gap: 20px;

  p:nth-child(1),
  p:nth-child(2) {
    margin: 0;
    z-index: 10;
    transition: all 0.5s ease-out;
    // @include fontMedium(1.5em, 3em, $gray-300);
    cursor: pointer;
  }
  p:nth-child(2) {
    margin-left: 50px;
  }
}

.groupPathwayArticleAstn {
  padding: 5px 3rem 10px 3rem;
}

.mygroupFeedminiNavAstn {
  padding: 0px 3rem 0px 3rem;
  font-size: 18px;
  font-weight: 600;
}

@media (min-width: 168px) and (max-width: 800px) {
  .groupPathwayArticleAstn {
    padding: 5px 1rem 10px 0rem;
  }

  .mygroupFeedminiNavAstn {
    padding: 8px 1rem 8px 1rem;
  }
}

@media (min-width: 1301px) {
  .groupPathwayArticleAstn_text {
    display: none;
  }
}

// .active {
//   color: $vesti-700;
//   @include fontBold(1.5em, 3em, $vesti-700);
//   border-bottom: 5px solid $vesti-700;
// }

//    className=" cohort  pt-1 pb-3"
//  style={{ paddingRight: '3rem', paddingLeft: '3rem' }}
