@import '../../../variables.scss';

.ovwhatis-container{
    width: 100%;
    margin-top: 5rem;
    @include colflex(center, center);
    .ovwhatis-inner{
        width: 80%;
        @include rowRevflex(center, space-between);
        
        @include breakpoint(mobileonly){ 
            @include revcolflex(center, space-between);
        }
        @include breakpoint(phablet){ 
            width: 90%;
        }
        @include breakpoint(tablet){ 
            width: 95%;
        }
        @include breakpoint(laptop){ 
            width: 90%;
            gap: 2rem;
        }

        .__left{
           width: 100%;

           @include breakpoint(phablet){     
            width: 90%;

        }

        //    img{
        //     width: 80%;
        //    }
        }
        .__right{
            width: 100%;
            @include colflex(center, center);
            gap: 10rem;
            
            @include breakpoint(mobileonly){    
                margin-top: 2rem; 
                gap: 2rem;
                               
            }
            @include breakpoint(phablet){    
                margin-top: 2rem; 
                gap: 0rem;
                               
            }

            .__top{
                margin-bottom: 2rem;
                width: 75%;
                @include colflex(flex-start, center);
                gap: 1rem;
                
                @include breakpoint(mobileonly){     
                    width: 100%;                    
                }
                @include breakpoint(phablet){     
                    width: 80%;                    
                }
                @include breakpoint(laptop){     
                    width: 80%;                    
                }
                .__title{                
                    p:nth-child(1){
                        @include fontBold(2em, 1.5em, $vesti-902);

                        @include breakpoint(mobileonly){     
                            @include fontBold(1.45em, 1.5em, $vesti-901);        
                        }
                        @include breakpoint(phablet){     
                            @include fontBold(1.2em, 1.5em, $vesti-901);        
                        }
                        // @include breakpoint(laptop){     
                        //     @include fontBold(2.25em, 1.5em, $vesti-901);        
                        // }
                    }
                    span{
                        color: $vesti-700;
                    }
    
                }
    
                .__body{
                    p:nth-child(1){
                        @include fontNormal(1.35em, 1.5em, $vesti-901);

                        @include breakpoint(mobileonly){     
                            @include fontNormal(1em, 1.5em, $vesti-901);
        
                        }
                        @include breakpoint(phablet){     
                            @include fontNormal(1.1em, 1.5em, $vesti-901);
        
                        }
                        @include breakpoint(tablet){     
                            @include fontNormal(1.5em, 1.5em, $vesti-901);
        
                        }
                        // @include breakpoint(laptop){     
                        //     @include fontNormal(1.35em, 1.5em, $vesti-901);
        
                        // }
                    }
                }
            }
        }
    }
}

@media (min-width: 2560px)  {
    .ovwhatis-container{
        width: 100%;
        margin-top: 15rem;
        @include colflex(center, center);
        .ovwhatis-inner{
            width: 90%;
            @include rowRevflex(center, space-between);
            gap: 5rem;

    
            .__left{
               width: 100%;
        
               img{
                width: 100%;
               }
            }
            .__right{
                width: 100%;
                @include colflex(center, center);
    
                .__top{
                    margin-bottom: 2rem;
                    width: 100%;
                    
                    .__title{                
                        p:nth-child(1){
                            @include fontBold(4.5em, 1.5em, $vesti-902);
    
                        }
                        span{
                            color: $vesti-700;
                        }
        
                    }
        
                    .__body{
                        p:nth-child(1){
                            @include fontNormal(3.5em, 1.5em, $vesti-901);
    
                        }
                    }
                }
            }
        }
    }
}