@import '../../../variables.scss';

.newft {
  width: 100%;
  margin-top: 50px;
  @include colflexx(flex-start);
  gap: 10px;

  &__seeall {
    width: 20%;
    text-align: right;
    display: flex;
    gap: 5px;
    color: #67a948;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    @include breakpoint(mobileonly) {
      width: 40%;
    }
  }

  @include breakpoint(mobileonly) {
    width: 100%;
    margin-top: 0;
    gap: 0;
    margin-bottom: 2rem;
  }

  &__bottom {
    width: 100%;
    margin-top: -30px;

    @include breakpoint(mobileonly) {
      overflow-x: auto;
      // margin-right: 10px;
    }

    @include breakpoint(phablet) {
      overflow-x: auto;
    }

    @include rowflex(center, flex-start);

    &__cardrow {
      width: 100%;
      @include rowflex(center, center);
      gap: 20px;

      @include breakpoint(mobileonly) {
        @include rowflex(center, center);
        width: 95%;
        white-space: wrap !important;
      }

      @include breakpoint(phablet) {
        @include rowflex(center, center);
      }

      .card_body {
        width: 100%;
        border-radius: 10px;
        position: relative;
        padding: 30px;
        overflow: hidden;
        border: 1px solid $vesti-100;
        cursor: pointer;

        @include breakpoint(mobileonly) {
          padding: 10px;
        }

        // @include breakpoint(phablet) {
        //   padding: 0;
        // }
        .card_top {
          width: 100%;
          @include rowflex(center, space-between);

          @include breakpoint(mobileonly) {
            @include revcolflex(center, space-between);
          }

          p:nth-child(1) {
            width: 50%;
            @include fontSemiBold(1.6em, 1.5em, #fff);

            @include breakpoint(mobileonly) {
              width: 100%;
              @include revcolflex(center, space-between);
              @include fontSemiBold(0.9em, 1.5em, #fff);
              text-align: center;
            }

            @include breakpoint(phablet) {
              width: 100%;
              @include fontSemiBold(1.1em, 1.5em, #fff);
            }

            @include breakpoint(tablet) {
              width: 100%;
              @include fontSemiBold(1.2em, 1.5em, #fff);
            }
          }

          img {
            // max-width: 50%;
            margin-bottom: 1rem;

            @include breakpoint(mobileonly) {
              max-width: 50%;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    // @include breakpoint(phablet){
    //     width: 85vw;
    // }
    // @include breakpoint(tablet){
    //     width: 85vw;
    // }

    // @include breakpoint(desktop){
    //     width: 1190px;
    // }
    // @media screen and (min-width: 1024px) and (min-height: 1320px) {
    //     width: 100vw;
    // }

    // @media screen and (min-width: 1024px) and (max-height: 1310px) {
    //     width: 90%;
    // }
  }
}

// .icon {
//   font-size: 24px;
//   color: #f39c12;
//   animation: bounce 2s infinite;
// }

.quickaction {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  width: 100%;

  @include breakpoint(mobileonly) {
    gap: 8px;
  }

  &__vesticard {
    border-radius: 8px;
    cursor: pointer;

    padding: 15px 20px;
    position: relative;

    @include breakpoint(mobileonly) {
      padding: 15px 5px;
    }

    & p {
      font-size: 16px;

      @include breakpoint(mobileonly) {
        font-size: 10px;
        font-weight: 800;
      }
    }

    &__cards {
      margin-bottom: 10px;
      border-radius: 71.429px;
      height: 45px;
      width: 45px;
      padding: 12px;

      @include breakpoint(mobileonly) {
        height: 25px;
        width: 25px;
        padding: 5px;
      }

      @include breakpoint(desktop) {
        animation: bounce 4s ease-in-out 1s infinite;
      }

      @keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateY(0);
        }

        40% {
          transform: translateY(-20px);
        }

        60% {
          transform: translateY(-10px);
        }
      }
    }

    &__bgcards {
      position: absolute;
      right: 0px;
      bottom: 0px;
      height: 90%;
    }

    &__newIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 20%;
    }

    p {
      color: #1d2939;
      font-weight: 500;
    }
  }
}

.seeallFeatures {
  &__header {
    margin-bottom: 50px;

    &__title {
      font-size: 28px;
      font-weight: 500;
      color: #14290a;
    }

    &__subtitle {
      color: #2b5219;
    }
  }

  &__subsection {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

.quickfinancialaction {
  width: 100%;

  @include breakpoint(desktop) {
    width: 800px;
  }

  &__top {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;

    @include breakpoint(mobileonly) {
      overflow-x: auto;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    margin-top: 20px;

    @include breakpoint(mobileonly) {
      overflow-x: auto;
    }
  }

  &__vesticard {
    border-radius: 8px;
    padding: 20px;
    position: relative;
    cursor: pointer;

    @include breakpoint(mobileonly) {
      width: 300px;
    }

    @include breakpoint(desktop) {
      width: 100%;
    }

    &__cards {
      margin-bottom: 10px;
      border-radius: 71.429px;

      padding: 12px;
      animation: bounce 4s ease-in-out 1s infinite;

      @keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateY(0);
        }

        40% {
          transform: translateY(-20px);
        }

        60% {
          transform: translateY(-10px);
        }
      }
    }

    &__bgcards {
      position: absolute;
      right: 0px;
      bottom: 0px;
      height: 90%;
    }

    &__newIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 20%;
    }

    p {
      color: #1d2939;
      font-weight: 500;
    }
  }
}

.quickmigrationaction {
  width: 100%;

  &__top {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;

    @include breakpoint(mobileonly) {
      overflow-x: auto;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    margin-top: 20px;

    @include breakpoint(mobileonly) {
      overflow-x: auto;
    }
  }

  &__vesticard {
    border-radius: 8px;
    padding: 20px;
    position: relative;
    cursor: pointer;

    @include breakpoint(mobileonly) {
      width: 300px;
    }

    @include breakpoint(desktop) {
      width: 100%;
    }

    &__cards {
      margin-bottom: 10px;
      border-radius: 71.429px;

      padding: 12px;
      animation: bounce 4s ease-in-out 1s infinite;

      @keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateY(0);
        }

        40% {
          transform: translateY(-20px);
        }

        60% {
          transform: translateY(-10px);
        }
      }
    }

    &__bgcards {
      position: absolute;
      right: 0px;
      bottom: 0px;
      height: 90%;
    }

    &__newIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 20%;
    }

    p {
      color: #1d2939;
      font-weight: 500;
    }
  }
}

.savingsaction {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  width: 100%;

  @include breakpoint(mobileonly) {
    gap: 8px;
  }

  &__vesticard {
    border-radius: 8px;
    cursor: pointer;

    padding: 5px 8px;
    position: relative;

    @include breakpoint(mobileonly) {
      padding: 7px 7px;
    }

    @include breakpoint(phablet) {
      padding: 4px 8px;
    }

    & p {
      font-size: 16px;

      @include breakpoint(mobileonly) {
        font-size: 10px;
        font-weight: 800;
      }
    }

    &__cards {
      margin-bottom: 10px;
      border-radius: 71.429px;
      height: 30px;
      // height: 45px;
      width: 45px;
      padding: 12px;

      @include breakpoint(mobileonly) {
        height: 25px;
        width: 25px;
        padding: 5px;
      }

      @include breakpoint(desktop) {
        animation: bounce 4s ease-in-out 1s infinite;
      }

      @keyframes bounce {

        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateY(0);
        }

        40% {
          transform: translateY(-20px);
        }

        60% {
          transform: translateY(-10px);
        }
      }
    }

    &__bgcards {
      position: absolute;
      right: 0px;
      bottom: 0px;
      // height: 90%;
    }

    &__newIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 20%;
    }

    p:nth-child(1) {
      @include fontNormal(1em, 1.5em, $vesti-700);

      @include breakpoint(phablet) {
        @include fontNormal(0.8em, 1.2em, $vesti-901);

      }
    }

    p:nth-child(2) {
      @include fontBold(1em, 1.5em, $vesti-901);


      @include breakpoint(phablet) {
        @include fontBold(0.8em, 1.2em, $vesti-901);

      }
    }
  }

  &__autosavecard {
    border-radius: 8px;
    cursor: pointer;
    @include rowflex(center, space-between);
    width: 100%;
    background: transparent;
    border: 0.5px solid #d1d5e0;
    gap: 1rem;

    padding: 5px 8px;
    position: relative;

    @include breakpoint(mobileonly) {
      padding: 7px 7px;
    }

    @include breakpoint(phablet) {
      padding: 4px 8px;
    }

    & p {
      font-size: 16px;

      @include breakpoint(mobileonly) {
        font-size: 10px;
        font-weight: 800;
      }
    }

    &__cards {
      margin-bottom: 10px;
      border-radius: 71.429px;
      height: 30px;
      // height: 45px;
      width: 45px;
      padding: 12px;

      @include breakpoint(mobileonly) {
        height: 25px;
        width: 25px;
        padding: 5px;
      }
    }

    &__bgcards {
      position: absolute;
      right: 0px;
      bottom: 0px;
      // height: 90%;
    }

    &__newIcon {
      position: absolute;
      right: 10px;
      top: 10px;
      height: 20%;
    }

    p:nth-child(1) {
      @include fontBold(1em, 1.5em, $vesti-700);
    }

    p:nth-child(2) {
      @include fontNormal(0.8em, 1.5em, $vesti-902);


    }

    .autoswitch {
      button {
        height: 22px !important;
        padding: 0 !important;
      }

      .ant-switch.ant-switch-checked {
        background: $vesti-700;
      }
    }
  }
}