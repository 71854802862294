@import "../../variables.scss";

.kycupload-container {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    .kycupload-inner-container {
        // width: 70%;
        // @include rowflex(flex-start,space-between);

        @include breakpoint(mobileonly){
            // width: 95%;
            @include colflexx(flex-start);
        }

        @include breakpoint(phablet){
            @include colflexx(flex-start);
            width: 100% !important;
        }

        @include breakpoint(tablet){
            @include colflexx(flex-start);
            
        }

        &.left {
            height: auto;
            // width: 100%;
            @include colflexx(flex-start);
    
            p:nth-child(1){
                margin: 0;
                @include fontBold(1.2em,1em, $vesti-902);
            }
            p:nth-child(2){
                margin-top: 10px;
                @include fontMedium(1.2em,1em, $vesti-700);
            }
            @include breakpoint(mobileonly) {
                height: 20px;
                margin-bottom: 5px;
                @include fontMedium(.8em,1em, $vesti-902);
            }
        }

        &.right {
            // width: fit-content;
            width: 100%;
            @include colflexx(flex-start);

            .disapproved {
                margin: 0;
                @include fontBold(1em, 1em, rgb(199, 0, 0));
            }
            .kycdoc-container:not(:first-child){
                margin-top: 30px;
            }

            .select-input {
                // width: 450px ;
                width: 100% ;
                
                @include breakpoint(mobileonly){
                    width: 100% ;
                    // width: 80vw;
                }
                
                @include breakpoint(phablet){
                    width: 100% ;
                    // width: 450px;
                }
                @include breakpoint(laptop){
                    width: 100% ;
                    // width: 450px !important;
                }
            }

            .validdoc{
                margin-top: 50px;
                @include colflexx(flex-start);

                &__title{
                    margin: 0;
                    @include fontBold(1.5em, 1em, $vesti-700);
                }
                &__subt{
                    margin-top: 10px;
                    @include fontNormal(1.2em, 1.4em, $vesti-901);
                }

                &__list {
                    margin-top: 20px;
                    @include colflexx(flex-start);
                    p{

                        @include rowflexx(center);
                        @include fontNormal(1.2em, 1em, $vesti-901);

                        img {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .kycdoc-container {
                // width: 450px;
                width: 100%;
                height: 180px;
                background: #F8FFF5;
                border: 1px dashed $vesti-700;
                box-sizing: border-box;
                border-radius: 10px;

                @include rowflex(center,center);

                @include breakpoint(mobileonly){
                    width: 100%;
                }
        
                @include breakpoint(phablet){
                    // width: 350px;
                    width: 100%;
                }
                
                @include breakpoint(tablet){
                    width: 100%;
                    // width: 350px;
                }
                .kycdoc-inner-container {
                    width: 90%;
                    height: 90%;
                    @include colflex(center,center);

                    .info{
                        margin: 0px;
                        @include fontNormal(1em, 1em, $vesti-901);
                        @include breakpoint(mobileonly){
                            @include fontNormal(0.8em, 1em, $vesti-901);
                        }
                        strong {
                            font-weight: 500;
                            color: $vesti-700 !important;
                        }
                    }
                    label {
                        background-color: $vesti-700;
                        color: white;
                        padding: 1rem 2rem 1rem 2rem ;
                        width: fit-content;
                        height: fit-content;
                        font-family: sans-serif;
                        border-radius: 20px;
                        cursor: pointer;
                        margin-top: 1rem;
                        
                        @include rowflex(center,center);
                        @include fontBold(1em,1em,#fff);
                        transition: all .5s ease;
                        
                        &:hover {
                            transform: scale(1.1);
                        }
                        
                        @include breakpoint(mobileonly){
                            @include fontBold(0.8em,1em,#fff);

                        }
        
                      }
                      &__remove {
                        background-color: $vesti-700;
                        color: white;
                        padding: 1rem 2rem 1rem 2rem ;
                        width: fit-content;
                        height: fit-content;
                        font-family: sans-serif;
                        border-radius: 20px;
                        margin-top: 20px;
                        line-height: 20px !important;
                        cursor: pointer;
                        // white-space: normal !important;
                        @include rowflex(center,center);
                        @include fontBold(1em,1.4em,#fff);
                        transition: all .5s ease;
            
                        @include breakpoint(mobileonly){
                            width: 60vw;
                            text-align: center;
                            @include fontBold(.8em,1.4em,#fff);
                        }
                        &:hover {
                            transform: scale(1.1);
                            background-color: red;
                        }
            
                    }
                }
            }

            .short-info{
                margin-top: 20px;
                width:100%;
                @include rowflexx(flex-start);
               

                @include breakpoint(mobileonly){
                    width: 100%;
                }
                img {
                    width: auto;
                    margin-right: 10px;
                }
                p{
                    margin: 0;
                    width: 100%;
                    @include fontNormal(1em, 1.5em, $gray-700);

                    @media only screen and (max-width: 600px) {
                        @include fontNormal(1em, 1.5em, $gray-700);
                    }                    
                }
                
                
            }
            .updateprofile-btn-box {
                width: 100%;
                margin-top: 30px;
                margin-bottom: 50px;

                .save-changes {
                    width: 200px !important;
                    height: 60px !important;
                    cursor: pointer;
                    background-color: $vesti-700;
                    color: #fff;
                    border-radius: 10px !important;
                    @include rowflex(center,center);
                    @include fontBold(1em, 1em, #fff);
                    transition: all .5s ease;
                    float: right;
    
                    &:hover {
                        background-color: $vesti-900;
                    }
    
                    &:disabled{
                        cursor: not-allowed;
                        background-color: $gray-600;
    
                    }
                }
            }
        }  
        
    }
}