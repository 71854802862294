@import '../../../../../variables.scss';

.EligibilitySection {
  margin: 30px 0px;
  background-color: rgba(43, 82, 25, 0.5);
  backdrop-filter: blur(100px);
  border: 4px solid #67a948;
  width: 100%;
  padding: 30px 40px;
  color: #fff;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  @include breakpoint(mobileonly) {
    flex-direction: column;
    gap: 20px;
  }

  &__firstcont {
    display: flex;
    align-items: center;
    gap: 15px;
    z-index: 10;

    @include breakpoint(mobileonly) {
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      text-align: center;
    }
  }
}

.radioFlexEligibilityTest {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
  margin: 10px 0px 10px 0px;
  @include breakpoint(mobileonly) {
    flex-direction: column;
  }
}

// .eligsubtitle {
//   @include breakpoint(mobileonly) {
//     width: 70%;
//   }
// }

.eligibilityPtag {
  color: #2b5219;
  font-size: 14px;
  font-weight: 600;
  // @include breakpoint(mobileonly) {
  //   width: 70%;
  // }
}

.testSectionOne {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
  @include breakpoint(mobileonly) {
    grid-template-columns: 1fr;
  }
}

.textcontnew {
  width: 500px;
  @include breakpoint(mobileonly) {
    width: 350px;
  }
}
