@import '../../../variables.scss';

.ovbanner-container {
  background-color: $vestisec-902;
  width: 100%;
  height: 37em;
  @include rowflex(center, center);

  .ovbanner-inner-container {
    width: 90%;
    height: 100%;
    @include rowflex(center, space-between);

    &.left {
      width: 100%;
      height: 75%;
      @include colflexx(flex-start);

      .top {
        width: 100%;
        height: fit-content;

        p:nth-child(1) {
          width: 68%;
          margin: 0;
          @include fontBold(2.5em, 1.5em, $gray-100);

          span {
            color: $vesti-700;
          }
        }
        p:nth-child(2) {
          width: 80%;
          margin: 0;
          @include fontNormal(1.5em, 1.5em, $gray-100);          
          margin-top: 2rem;

        }
      }

      .ov-bottom {
        width: 100%;
        @include rowflexx(flex-start);
        gap: 20px;
        @include breakpoint(mobileonly){
          @include colflexx(flex-start);
          gap: 0;
        }
      }
    }

    &.right {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 600px) {
  .ovbanner-container {
    width: 100%;
    height: fit-content;
    @include colflex(center, center);
    margin-bottom: 1rem;

    .ovbanner-inner-container {
      width: 80%;
      margin-bottom: 2rem;
      @include colflex(center, center);

      &.left {
        width: 100%;
        height: 100%;
        @include colflexx(flex-start);

        .top {
          width: 100%;
          height: fit-content;
          margin-bottom: 4rem;

          p:nth-child(1) {
            @include fontBlack(2em, 1.4em, $gray-100);
            span {
              color: $vesti-700;
            }
          }

          p:nth-child(2) {
            width: 100%;
            margin: 0;
            margin-top: 1rem;
            @include fontNormal(1em, 1.5em, $gray-100);
          }
        }
      }

      &.right {
        @include rowflex(center, center);
      }
    }
  }
}


@media (min-width: 600px) and (max-width: 800px) {
  .ovbanner-container {
    width: 100%;
    height: 100%;
    @include colflex(center, center);

    .ovbanner-inner-container {
      width: 90%;
      @include rowflex(flex-start, space-between);
      margin-bottom: 2rem;

      &.left {
        height: 100%;
        @include colflexx(flex-start);

        .top {
          width: 100%;
          height: fit-content;
          margin-bottom: 1rem;

          p:nth-child(1) {
            width: 100%;
            @include fontBlack(1.5em, 1.3em, $gray-100);
          }

          p:nth-child(2) {
            margin: 0;
            margin-top: 1rem;
            @include fontNormal(1em, 1.5em, $gray-100);
          }
        }
      }
    }
    &.right {
      @include rowflex(flex-start,center);
    }
  }
}
@media (min-width: 800px) and (max-width: 1224px) {
  .ovbanner-container {
    width: 100%;
    height: 100%;
    @include colflex(center, center);

    .ovbanner-inner-container {
      width: 90%;
      @include rowflex(flex-start, space-between);

      &.left {
        height: 100%;
        @include colflexx(flex-start);

        .top {
          width: 100%;
          height: fit-content;

          p:nth-child(1) {
            width: 100%;
            @include fontBlack(2em, 1.5em, $gray-100);
          }

          p:nth-child(2) {
            margin: 0;
            margin-top: 1rem;
            @include fontNormal(1.5em, 1.5em, $gray-100);
          }
        }
      }

    }
    &.right {
      @include rowflex(flex-start,center);
    }
  }
}
@media (min-width: 2560px)  {
  .ovbanner-container {
    width: 100%;
    height: 100%;
    @include colflex(center, center);

    .ovbanner-inner-container {
      width: 90%;
      @include rowflex(center, space-between);

      &.left {
        height: 100%;
        @include colflexx(flex-start);

        .top {
          width: 100%;
          height: fit-content;

          p:nth-child(1) {
            width: 100%;
            @include fontBlack(4em, 1.5em, $gray-100);
          }

          p:nth-child(2) {
            margin: 0;
            margin-top: 1rem;
            @include fontNormal(2.5em, 1.5em, $gray-100);
          }
        }
        
      }

      *.ov-bottom {
        width: 400px;
        margin-top: 2rem !important;
        @include rowflexxx(center);
        gap: 50px;
        margin-bottom: 3rem;
        background-color: red !important;

      }
    }
    &.right {
      @include rowflex(flex-start,center);
    }
  }
}
