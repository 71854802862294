.student-loan {
  margin-top: -20px;
  .layout-container {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 1024px) {
      padding: 30px;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}






.ty-loan-container {
  // padding: 1rem;


  // @media (min-width: 325px) {
  //   margin-top: -5px;
  // }

  // @media (min-width: 768px) {
  //   padding: 2rem;
  //   margin-top: -50px;
  // }

  &__header {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    font-size: 1.125rem;
    justify-content: flex-start;
    cursor: pointer;

    @media (min-width: 640px) {
      font-size: 1.25rem;
    }

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    &__title {
      font-weight: 600;
      color: #101828;
      font-size: 1.25rem;

      @media (min-width: 640px) {
        font-size: 1.625rem;
      }
    }
  }

  &__content {
    background-color: #f7fff3;
    width: 100%;
    // height: 100%;
    // margin-top: 2.5rem;
    border-radius: 0.5rem;
    padding: 1.5rem 1rem;

    @media (min-width: 325px) {
      margin-top: 15px;
    }

    &__tabs {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      justify-content: space-evenly;
      align-items: flex-start;
      border: 1px solid #67a948;
      padding: 0.25rem 0.5rem;
      border-radius: 0.5rem;
      color: #fff;

      &__tab {
        padding: 0.5rem 0;
        width: 100%;
        text-align: center;
        border-radius: 0.5rem;
        cursor: pointer;

        @media (min-width: 640px) {
          width: 220px;
        }

        @media (min-width: 768px) {
          width: 460px;
        }

        &--active {
          background-color: #67a948;
        }

        &--inactive {
          background-color: #f7fff3;
          color: #2b5219;
        }
      }
    }

    &__scrollable-content {
      color: #333;
      height: 100%;
      overflow-y: scroll;
      margin-top: 1rem;

      // @media (min-width: 640px) {
      //   height: 450px;
      // }

      // @media (min-width: 768px) {
      //   height: 550px;
      // }

      /* Hide the scrollbar */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, and Opera */
      }

      &__sectionn {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        color: #667085;
        font-weight: 300;
        height: 100%;
        &-titleet {
            font-weight: 500;

        }

        & + p {
          margin-bottom: 1rem;
        }

        hr {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}



.checkbox-container {
  margin-top: 1rem;
}

.checkbox-content {
  display: flex;
  align-items: center;
  gap: 5px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
}

.checkbox-text {
  color: #2b5219;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
}

.terms-link {
  color: #67a948;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
}






.button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    width: 100%;
    max-width: 300px; // Adjust this value as needed for desktop size
    padding: 10px 20px;
  }

  @media (max-width: 768px) {
    button {
      max-width: 100%; // On smaller screens, make the button full width
    }
  }
}





