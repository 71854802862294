@import "../../../variables.scss";

.thisworks-container{
    width: 100%;
    height: fit-content;
    border-radius: 50px;
    padding-bottom: 50px;

    @include rowflex(center,center);

    .thisworks-inner {
        width: 80%;
        height: fit-content;
        @include colflexx(center);

        &.top{
            // width: 50%;
            margin-bottom: 3rem;
            p:nth-child(1){
                margin: 0;
                @include fontBold(2.25em, 1em, $vesti-902);
            }
            p:nth-child(2){
                margin-top: 10px;
                @include fontNormal(1.5em, 1.5em, $vesti-901);
            }
        }
        &.bottom {
            width: 80%;
            margin-top: 50px;
            @include rowflex(flex-start, space-between);
            // flex-wrap: wrap;
            gap: 30px;
            margin: 0 auto;
            text-align: center;
            
            .card{
                width: 18rem;
                border: none;
                background-color: transparent;
                img {
                    margin-bottom: 20px;
                }
                .card-body{
                    @include colflex(center,center);

                    .card-title{
                        @include fontSemiBold(1.2em, 1.5em, $vesti-902); 
                    }
                    .card-text{
                        @include fontNormal(1.1em, 1.5em, $vesti-902);                        
                    }
                }
            }          

        }
        .button{
            margin-top: 20px;
            background-color: $vesti-700;
            height: 60px !important;
            padding: 1em !important;
            border: none !important;
            border-radius: 15px !important;
            cursor: pointer;
            @include fontMedium(1.2em, 0.7em, #fff);    
            @include rowflex(center,center);
            transition: all 0.3s ease;
            &:hover{
              background: $vesti-900;
            }
        }

    }
}
@media only screen and (min-width: 320px) and (max-width: 600px){
    .thisworks-container{
        width: 100%;
        height: fit-content;
        margin-bottom: 40px;
        border-radius: 20px;
        padding-bottom: 30px;
        @include colflex(center,center);
    
        .thisworks-inner {
            width: 90%;
            height: fit-content;
            @include colflexx(center);
            // margin-top: -160px;
            font-size: 11px;
            margin: 0 auto;
    
            &.top{
                width: 90%;
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(1.45em, 1em, $vesti-902);
                }
                p:nth-child(2){
                    margin-top: 10px;
                    @include fontNormal(1.2em, 1.5em, $vesti-902);
                }
            }
            &.bottom {
                width: 100%;
                margin-top: 30px;
                @include colflexx(center);
    
                .card{
                    border: none;
                    background-color: transparent;
                    img {
                        margin-bottom: 20px;
                    }
                    .card-body{
                        @include colflex(center,center);
    
                        .card-title{
                            @include fontSemiBold(1.5em, 1.5em, $vesti-902); 
                        }
                        .card-text{
                            @include fontNormal(1.25em, 1.5em, $vesti-902); 
                            width: 80%;                       
                        }
                    }
                }
    
            }
            .button{
                width: 80%;
                margin-top: 20px;
                background-color: $vesti-700;
                height: 60px !important;
                padding: 1em !important;
                border: none !important;
                border-radius: 15px !important;
                margin-left: 0px;
                cursor: pointer;
                @include fontMedium(1.2em, 0.7em, #fff);    
                @include rowflex(center,center);
                transition: all 0.3s ease;

                &:hover{
                  background: $vesti-900;
                }
            }
        }
    }
}
@media only screen and (min-width: 600px) and (max-width: 800px){
    .thisworks-container{
        width: 80%;
        height: fit-content;
        margin-bottom: 100px;
        margin-left: 80px;
        border-radius: 20px;
        padding-bottom: 30px;
        @include colflex(center,center);
    
        .thisworks-inner {
            width: 100%;
            height: fit-content;
            @include colflexx(center);
            margin-top: -53px;
    
            &.top{
                width: 100%;
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(2em, 1em, $vesti-902);
                }
                p:nth-child(2){
                    margin-top: 10px;
                    @include fontNormal(1.5em, 1.5em, $vesti-902);
                }
            }
            &.bottom {
                width: 100%;
                margin-top: 20px;
                @include colflexx(center);
    
                .card{
                    border: none;
                    background-color: transparent;
    
                    .card-body{
                        @include colflex(center,center);
    
                        .card-title{
                            @include fontSemiBold(1.5em, 1.5em, $vesti-902); 
                        }
                        .card-text{
                            @include fontNormal(1.25em, 1.5em, $vesti-902);                        
                        }
                    }
    
                }
    
            }
            .button{
                margin-top: 20px;
                background-color: $vesti-700;
                height: 60px !important;
                padding: 1em !important;
                border: none !important;
                border-radius: 15px !important;
                cursor: pointer;
                @include fontMedium(1.2em, 0.7em, #fff);    
                @include rowflex(center,center);
                transition: all 0.3s ease;
                &:hover{
                  background: $vesti-900;
                }
            }
        }
    }
}
@media only screen and (min-width: 800px) and (max-width: 1224px){
    .thisworks-container{
        width: 100%;
        height: fit-content;
        margin-bottom: 100px;
        border-radius: 50px;
        // padding-top: 30px;
         padding-bottom: 50px;
        @include rowflex(center,center);
    
        .thisworks-inner {
            width: 90%;
            height: fit-content;
            @include colflexx(center);
    
            &.top{
                width: 50%;
                p:nth-child(1){
                    margin: 0;
                    @include fontBold(2.25em, 1em, $vesti-902);
                }
                p:nth-child(2){
                    margin-top: 10px;
                    @include fontNormal(1.2em, 1.5em, $vesti-902);
                }
            }
            &.bottom {
                width: 100%;
                margin-top: 50px;
                @include rowflexx(center);
    
                .card{
                    border: none;
                    background-color: transparent;
    
                    .card-body{
                        @include colflex(center,center);
    
                        .card-title{
                            @include fontSemiBold(1.5em, 1.5em, $vesti-902); 
                        }
                        .card-text{
                            @include fontNormal(1.25em, 1.5em, $vesti-902);                        
                        }
                    }
    
                }                  
            }
            .button{
                margin-top: 20px;
                background-color: $vesti-700;
                height: 60px !important;
                padding: 1em !important;
                border: none !important;
                border-radius: 15px !important;
                cursor: pointer;
                @include fontMedium(1.2em, 0.7em, #fff);    
                @include rowflex(center,center);
                transition: all 0.3s ease;
                &:hover{
                  background: $vesti-900;
                }
            }
        }
    }  
}