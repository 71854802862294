.notification {
  &__container {
    margin-top: 10px;

    @media (min-width: 640px) {
      margin-top: -15px;
    }

    @media (min-width: 768px) {
      margin-top: -25px;
    }

    @media (min-width: 1024px) {
      margin-top: -35px;
    }
  }
  &__header {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 1.125rem;
    justify-content: flex-start;
    cursor: pointer;
    margin-left: 40px;

    @media (min-width: 325px) {
      margin-left: 26px;
    }

    @media (min-width: 640px) {
      font-size: 1.25rem;
    }

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    &-icon {
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
  }

  &__body {
    background-color: #fff;
    // background-color: #f8f7fa;
    border-radius: 0.75rem;
    margin-top: 1rem;
    height: 600px;
    overflow-y: auto;

    &--empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      &-image {
        height: 16rem;
      }

      &-text {
        width: 260px;
        text-align: center;
        margin-top: 2rem;

        &-title {
          font-weight: 500;
          font-size: 1.8125rem;
        }

        &-subtitle {
          font-weight: 300;
          font-size: 1rem;
          color: #9e9e9e;
        }
      }
    }
  }

  &__notification {
    padding: 0.5rem 1rem;

    @media (min-width: 640px) {
      padding: 1rem 2rem;
    }

    &-card {
      padding: 0.75rem;
      // border-radius: 0.5rem;
      // border: 1px solid #d1d1d1;

      border-bottom: 1px solid #d1d1d1;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      cursor: pointer;

      @media (min-width: 640px) {
        gap: 1rem;
      }

      &-icon {
        width: 0.5rem;
        height: 0.5rem;

        @media (min-width: 640px) {
          // If the viewport is 640 pixels wide or more, the condition evaluates to true, and the styles within the block will be applied.
          width: 0.5rem;
          height: 0.5rem;
        }
      }

      &-text {
        flex: 1;

        &-title {
          font-size: 0.875rem;
          font-weight: 600;

          @media (min-width: 640px) {
            font-size: 1.125rem;
          }
        }

        &-subtitle {
          font-size: 0.75rem;

          @media (min-width: 640px) {
            font-size: 0.875rem;
          }
        }
        &-dateu {
          color: #999999;
          font-weight: 300;
          font-size: 14px;
        }
      }

      &-date {
        font-size: 0.75rem;

        @media (min-width: 640px) {
          font-size: 0.875rem;
        }
      }
    }
  }

  &__modal {
    position: fixed;
    inset: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    &-content {
      background-color: #fff;
      border-radius: 0.75rem;
      padding: 1.2rem;
      width: 400px;
      margin-top: 100px;

      @media (min-width: 1024px) {
        margin-left: 270px;
      }

      @media (min-width: 640px) {
        max-width: 28rem;
      }

      &-close {
        display: flex;
        margin-bottom: 1rem;

        &-button {
          margin-left: auto;
          font-size: 1.25rem;
          font-weight: 700;
          cursor: pointer;

          &-icon {
            width: 1rem;
          }
        }
      }

      &-image {
        width: 100%;
      }

      &-details {
        margin-top: 1rem;

        &-title {
          font-weight: 500;
          font-size: 1.25rem;
        }

        &-text {
          margin-top: 0.5rem;
          font-weight: 300;
          font-size: 0.875rem;
          max-height: 200px;
          overflow-y: auto;
        }
        &-buttonx {
          margin-top: 1rem;
          padding: 1.5rem;
          width: 100%;
          background-color: #67a948;
          border-radius: 0.5rem;
          color: #fff;

          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          // color: inherit;
        }
      }
    }
  }
}
