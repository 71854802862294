@import '../../variables.scss';

.profileside {
  width: fit-content;
  height: fit-content;
  @include rowflex(center, center);
  margin: 0 auto;
  width: 100%;
  max-width: 350px;

  &__inner {
    width: 100%;
    height: fit-content;
    @include colflexx(flex-start);
    gap: 12px;

    .singleside {
      background-color: transparent;
      width: 100%;

      height: fit-content;
      padding: 20px 50px 20px 30px;
      border: none;
      transition: all 0.5s ease;
      @include breakpoint(desktop) {
        width: 80%;
      }

      &.active {
        background-color: #f7fff3;
        border-style: solid;
        border-width: 0px 10px 0px 0px;
        border-color: $vesti-700;
        border-bottom-left-radius: 10px;
        border-top-left-radius: 10px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); /* Horizontal offset, vertical offset, blur radius, shadow color */

        @include breakpoint(desktop) {
          border-width: 0px 10px 0px 0px;
        }

        img {
          filter: invert(58%) sepia(36%) saturate(640%) hue-rotate(57deg)
            brightness(95%) contrast(88%);
        }
        p {
          @include fontBold(16px, 1em, $vesti-700);

          //   font-weight: 700;
        }
      }

      &.notactive {
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2); /* Horizontal offset, vertical offset, blur radius, shadow color */

        // background-color: #f6f7ff;
        // border-style: solid;
        // border-width: 0px 10px 0px 0px;
        // border-color: $vesti-700;
        // border-bottom-left-radius: 10px;
        // border-top-left-radius: 10px;

        @include breakpoint(desktop) {
          border-width: 0px 10px 0px 0px;
        }

        // img {
        //   filter: invert(58%) sepia(36%) saturate(640%) hue-rotate(57deg)
        //     brightness(95%) contrast(88%);
        // }
        // p {
        //   @include fontMedium(1.2em, 1em, $vesti-700);
        // }
      }

      &__inner {
        width: fit-content;
        cursor: pointer;
        @include rowflexx(center);
        flex-wrap: wrap;
        gap: 10px;
        img {
          width: 30px;
          @include breakpoint(desktop) {
            width: auto;
          }
        }

        p {
          margin: 0;
          white-space: nowrap;
          @include fontNormal(1.5em, 1em, $vesti-901);

          @include breakpoint(desktop) {
            text-align: left;
            @include fontNormal(16px, 1em, $vesti-901);
            font-weight: 700;
          }
        }
      }
    }
  }
}
