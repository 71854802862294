@import '../variables.scss';

.TourGuideHome {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 8rem;

  &__article {
    margin-top: 12rem;
    display: flex;
    flex-direction: column;
    gap: 6px;

    & h1 {
      font-size: 20px;
      font-weight: 500;
      color: #14290a;
    }

    & p {
      color: #2b5219;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__myguides {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__guide {
    border-radius: 5px;
    border: 1px solid #e2e7f5;
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    /* FX */
    box-shadow: 0px 4px 20px 0px rgba(224, 224, 224, 0.2);
  }

  &__startBtn {
    border-radius: 4px;
    border: 1px solid #67a948;
    padding: 3px 15px;
    color: #67a948;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
  }
}
