@import '../../variables.scss';

.educontainer{
    width: 100%;
    height: 100vh;
    
    .edu-inner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .edustep {
            // max-width: 450px;
            width: 100%;
            @include colflexx(flex-start);
            gap: 40px;
        
            &__row {
                width: 100% !important;
                @include colflexx(flex-start);
                gap: 20px;
                @include breakpoint(desktop){
                    @include rowflexx(flex-start);
                }
                *.PhoneInput{
                    width: 100%;
                }
            }
            *.PhoneInput{
                width: 100%;
            }
        
            &__col, &__scol, &__bcol{
                width: 100%;
                gap: 20px;
                @include colflexx(flex-start);
            }
            .inputfloat {
                margin-top: 0px;
                width: 100%;
            }
            &__scol{
                gap: 15px;
            }
            &__bcol{
                margin-top: 1rem;
                gap: 20px;
            }
            &__btns {
                width: 100%;
            }
            &__form{
                max-height: 500px;
                width: 100%;
                @include overflowScroll($vesti-100, $vesti-800);
            }
        }
    }

}







// .educontainer{
//     outline: none;
//     display: flex;
//     justify-content: center;
//     margin: auto;
//     height: 100vh;
// }