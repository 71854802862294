@import '../../../variables.scss';

.sharedpost-container {
  width: 100%;
  height: 100%;

  //   @include colflex(flex-start, center);
  .sharedpost-inner {
    width: 60%;
    height: 80%;
    margin: 0 5%;
    // @include colflex(flex-start, center);
    @include breakpoint(mobileonly) {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      width: 80%;
    }
  }
}
