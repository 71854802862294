@import "../../../variables.scss";

.singleuser{
    width: fit-content;
    height: fit-content;
    background-color: $vesti-100;
    border: 1px solid $vesti-800;
    border-radius: 10px;
    padding: 20px 40px 20px 20px;
    transition: all .5s ease;
    cursor: pointer;
    @include rowflex(center,center);
    @include breakpoint(desktop){
        padding: 20px 60px 20px 20px;
    }
    &:hover {
        background-color:  $vesti-800;
        border: 1px solid $vesti-700;
        .singleuser__right {
            P{
                color: white !important;
            }
        }
    }
    &__inner {
        width:  100%;
        height: 100%;
        @include rowflexx(flex-start);
    }
    &__left {
        width: fit-content;
        height: fit-content;
        margin-right: 10px;
        img {
            width: 50px;
            height: 50px;
            border-radius: 100%;
            object-fit: cover; 
        }
    }

    &__right {
        @include colflexx(flex-start);
        gap: 10px;
        width: fit-content;

        p:nth-child(1){
            @include fontBold(1.3em, 1em, $vesti-901);
        }

        p:nth-child(2){
            @include fontMedium(1em, 1em, $vesti-700);

            i {
                margin-left: 5px;
            }
        }
    }
}