.vcardSection {
  /* justify-content: space-between; */
  /* align-items: flex-end; */
  position: relative;
  gap: 25px;
  margin-bottom: 5rem;
  width: 65%;

  display: flex;
  gap: 10px;
  align-items: center;
  /* width: 60%; */
}

@media (max-width: 800px) {
  .vcardSection {
    width: 90%;
    margin-bottom: 5rem;
  }
}
/* style={{ marginBottom: '5rem', width: '65%', gap: '14%', position: 'relative' }} */

/* .lesscard {
  width: 25rem;
  border-radius: 10px;
  background-color: #f7f8fa;
  position: relative;
  border: 2px solid red;
} */

.slidercontCardRevamp {
  width: 25rem;
  height: 15rem;
}

.createVirtualCard {
  border-radius: 5px;
  border: 2px dashed #67a948;
  outline-offset: 8px;
  background: #f7f8fa;
  width: 10%;
  padding-right: 4px;
  padding-left: 4px;
  /* padding: 113px 15px 107px 15px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  cursor: pointer;
  display: none;
  /* height: 16% */
}

.smallTitle__mobile {
  font: 1px;
  font-weight: 600;
}

.nextPrevButtonMobile {
  display: flex;
  align-items: center;
  gap: 6px;
}

.nextPrevButtondesktop {
  cursor: pointer;
}
@media (max-width: 600px) {
  .nextPrevButtondesktop {
    display: none;
  }
}

@media (min-width: 600px) {
  .nextPrevButtonMobile {
    display: none;
  }
}

.createVirtualCard__mobile {
  border-radius: 5px;
  border: 2px dashed #67a948;
  background: #f7f8fa;
  display: flex;
  font-size: 12px;
  height: 2rem;
  max-width: 40%;
  padding-right: 4px;
  padding-left: 4px;
  margin-bottom: 1rem;
  /* padding: 113px 15px 107px 15px; */

  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  cursor: pointer;
}

@media (min-width: 900px) {
  .createVirtualCard__mobile {
    display: none;
  }
  .createVirtualCard {
    display: flex;
  }

  .cardD {
    display: flex;
    width: 25rem;
    height: 15rem;
    border-radius: 10px;
    background-color: #f7f8fa;
    padding: 0px 10px 0px 10px;
  }

  .ccard {
    position: relative;
    height: 15rem;
    width: 25rem;
    /* max-width: 100%; */
    border-radius: 12px;
    overflow: hidden;
    padding: 1rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ccard {
  position: relative;
  /* background-image: url('../../../assets/vcards/virtualCard.png');
  background-size: cover;
  background-position: center;
  animation: myzoom 6s ease-in-out infinite; */
}

.myccardgreen {
  background-image: url('../../../assets/vcards/virtualCard.png');
  background-size: cover;
  background-position: center;
  animation: myzoom 10s ease-in-out infinite;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 2; */
  position: absolute;
}

.myccardlightGreen {
  background-image: url('../../../assets/vcards/vcardLightGreen.png');
  background-size: cover;
  background-position: center;
  animation: myzoom 10s ease-in-out infinite;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 2; */
  position: absolute;
}

.myccardyellow {
  background-image: url('../../../assets/vcards/vcardYellow.png');
  background-size: cover;
  background-position: center;
  animation: myzoom 10s ease-in-out infinite;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 2; */
  position: absolute;
}

.myccardskyblue {
  background-image: url('../../../assets/vcards/vcardLightBlue.png');
  background-size: cover;
  background-position: center;
  animation: myzoom 10s ease-in-out infinite;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 2; */
  position: absolute;
}

.myccardblue {
  background-image: url('../../../assets/vcards/vcardLightBlue.png');
  background-size: cover;
  background-position: center;
  animation: myzoom 10s ease-in-out infinite;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 2; */
  position: absolute;
}

.myccardorange {
  background-image: url('../../../assets/vcards/vcardOrange.png');
  background-size: cover;
  background-position: center;
  /* animation: myzoom 10s ease-in-out infinite; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 2; */
  position: absolute;
}

.myccardpurple {
  background-image: url('../../../assets/vcards/vcardpurple.png');
  background-size: cover;
  background-position: center;
  /* animation: myzoom 10s ease-in-out infinite; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* z-index: 2; */
  position: absolute;
  height: 700px;
}

@keyframes myzoom {
  0%,
  100% {
    transform: translateX(0) scale(1); /* Start at center and normal size */
  }
  25% {
    transform: translateX(-60px) scale(1.32); /* Move left and zoom in slightly */
  }
  75% {
    transform: translateX(-5px) scale(1.05); /* Move right and zoom in slightly */
  }
  50% {
    transform: translateX(5) scale(1); /* Center and zoom in more */
  }
}

@media (min-width: 1000px) {
  .createVirtualCard {
    width: 7%;
  }
}

.cardD {
  display: flex;
  height: 15rem;
  border-radius: 10px;
  background-color: #f7f8fa;
  padding: 0px 10px 0px 10px;
}
/* .slidercard {
    display: flex;
    height: 15rem;
    border-radius: 10px;
     background-color: #F7F8FA;
    padding: 0px 10px 0px 10px;  
  } */

@media (min-width: 200px) and (max-width: 800px) {
  .ccard {
    position: relative;
    width: 80%;
    height: 14rem;
    border-radius: 10px;
    /* overflow: hidden; */
    padding: 1rem 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.ccard {
  position: relative;
  height: 15rem;

  border-radius: 12px;
  overflow: hidden;
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ccard:hover {
  cursor: pointer;
}
/* .card img {
    max-width: 100%;
    max-height: 100%;
  } */

.slick-slider {
  margin-bottom: 10px;
}

.slick-dots {
  /* position: absolute; */
  bottom: -86px;
  text-align: center;
  /* width: 100%;
  display: flex !important;
  justify-content: center; */
}

.slick-dots li button:before {
  color: #67a948;
  font-size: 12px;
}

.slick-dots li.slick-active button:before {
  color: #67a948;
}

.slick-prev:before,
.slick-next:before {
  color: #888;
}

.slick-prev:hover:before,
.slick-next:hover:before {
  color: #555;
}

.clickPrev {
  position: absolute;
  width: 4rem;
  height: 4rem;
  margin-right: -1rem;
  top: 38%;
  z-index: 5;
}

/*  */
.fundCardF {
  font-size: 24px;
  font-weight: bold;
}
@media (max-width: 650px) {
  .fundCardF {
    font-size: 20px;
    font-weight: bold;
  }
}

.imgDivBtw {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.imgBal {
  display: flex;
  align-items: center;
  gap: 10%;
  background-color: #67a948;
  justify-content: space-between;
  border-radius: 10px;
  padding: 8px 8px 8px 8px;
  margin-bottom: 8px;
  margin-top: 5%;
}

@media (max-width: 650px) {
  .imgBal {
    padding: 4px 6px 4px 6px;
    margin-top: 2%;
    margin-bottom: 4px;
    gap: 4%;
  }
}
@media (max-width: 400px) {
  .imgBal {
    padding: 2px 4px 2px 4px;
    gap: 3%;
  }
}

.imgBal img {
  width: 30px;
}
@media (max-width: 400px) {
  .imgBal img {
    width: 25px;
  }
}
.imgbalM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 650px) {
  .imgbalM {
    padding: 5px;
    margin-top: 2%;
    margin-bottom: 4px;
  }
}

.imgbalM p {
  color: #ffffff;
}
.balP {
  font-size: 12px;
}
@media (max-width: 650px) {
  .balP {
    font-size: 10px;
  }
}
.pBalB {
  font-weight: bold;
  font-size: 24px;
}
@media (max-width: 650px) {
  .pBalB {
    font-size: 16px;
  }
}
.amountDiv {
  position: relative;
  margin-top: 28px;
}
.amountDivAbF {
  position: 'absolute';
  background-color: #ffffff;
  color: #67a948;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  top: -12px;
  left: 10px;
  border-radius: 10px;
}
.amountDivAbS {
  position: 'absolute';
  background-color: #ffffff;
  color: #67a948;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  top: 55px;
  left: 10px;
  border-radius: 10px;
}
.amountDivAbFIn {
  border: 0.5px solid #98a2b3;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 24px;
  background-color: #f9fafb;
  outline: none;
}
.exchangeRateF {
  position: absolute;
  /* border: 1px solid ;
  background-color: #ffffff;
  color: #000000; */
  padding: 4px 15px 4px 15px;
  font-weight: bold;
  font-size: 14px;
  top: 37px;
  left: 28%;
  border-radius: 20px;
}

@media (max-width: 650px) {
  .exchangeRateF {
    padding: 4px 12px 4px 12px;
    font-size: 10px;
    top: 44px;
  }
}
@media (max-width: 650px) {
  .exchangeRateF {
    padding: 2px 8px 2px 8px;
    font-size: 8px;
    top: 44px;
    z-index: 5;
  }
}
.fFundCardBut {
  /* text-align: center; */
  display: block;
  margin-top: 8%;
}
@media (max-width: 650px) {
  .fFundCardBut {
    margin-top: 6%;
  }
}
/* fundCardButDiv' style={{textAlign: 'center', display: 'block', marginTop: '8%'} */
.fFundCardBut {
  display: inline-block;
  background-color: #67a948;
  color: #ffffff;
  font-weight: bold;
  border-radius: 10px;
  padding: 3% 26% 3% 26%;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
}
.proFee {
  display: inline-block;
  background-color: #f2f4f7;
  padding: 4px 12px 4px 12px;
  color: #3e6f26;
  border-radius: 20px;
  margin-top: 4%;
  font-size: 10px;
}
@media (max-width: 650px) {
  .proFee {
    margin-top: 2%;
  }
}

.amountDivAbSIn {
  border: 0.5px solid #98a2b3;
  border-radius: 5px;
  padding: 12px;
  background-color: #f9fafb;
  outline: none;
}

/* Review Transaction */
.reviewTransH {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 6px;
}
@media (max-width: 450px) {
  .reviewTransH {
    font-size: 24px;
    margin-bottom: 4px;
  }
}

.reviewTrans {
  border-bottom: 1px;
  border-style: dashed;
  border-color: #97e373;
  padding: 12px 2px 12px 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .reviewTrans {
    padding: 8px 2px 8px 2px;
  }
}
.rTranP {
  margin-bottom: 6px;
}
@media (max-width: 450px) {
  .rTranP {
    margin-bottom: 2px;
    font-size: 12px;
    width: 100%;
  }
}
.reviewTransD {
  font-size: 14px;
}
@media (max-width: 450px) {
  .reviewTransD {
    font-size: 10px;
  }
}
.reviewTransDiv {
  border: 1px solid;
  background-color: #f7fff3;
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  border: #97e373;
  /* padding-top: 30px;
  margin-top: 30px; */
}
@media (max-width: 450px) {
  .reviewTransDiv {
    padding: 10px 15px 10px 15px;
    margin-top: 14px;
  }
}
.reviewTransP {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #2b5219;
}
@media (max-width: 450px) {
  .reviewTransP {
    font-size: 24px;
  }
}
.reTranbutDiv {
  display: block;
  text-align: center;
  margin-top: 8%;
}
.reTranbutDiv p {
  display: inline-block;
  font-weight: bold;
  background-color: #67a948;
  border-radius: 10px;
  padding: 3% 26% 3% 26%;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
.cardButOpt {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-bottom: 5%;
  gap: 30px;
  /* width: 100%; */
}

@media (max-width: 600px) {
  .cardButOpt {
    /* margin-right: -20px; */
    margin-top: 10px;
    gap: 10px;
    width: 20rem;
  }
  .revampTransSection {
    margin-top: 8rem;
  }
}

.cardButtomDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardButtomDiv img {
  width: 50px;
  cursor: pointer;
}
.cardButtomDivP {
  font-size: 14px;
}

.dotDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 6%;
  border: 2px solid red;
}
@media (max-width: 380px) {
  .dotDiv {
    gap: 6px;
  }
}

.vcardDot {
  border: 1px solid #67a948;
  padding: 30px 28px;
  border-radius: 10px;
  background-color: #f7fff3;
}
@media (max-width: 450px) {
  .vcardDot {
    padding: 18px 16px;
  }
}
@media (max-width: 380px) {
  .vcardDot {
    padding: 12px 10px;
    border-radius: 6px;
  }
}

.cardDetH {
  color: #67a948;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 4%;
}
.cardDetDiv {
  border: 1px solid #936710;
  border-style: dashed;
  background-color: #fff9f0;
  border-radius: 5px;
  padding: 10px;
  margin: 8px 0px;
  text-align: center;
}
.cardDetDiv p {
  font-size: 14px;
}

/* freeze card */
.FreezeCardDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.freezeImg {
  width: 50px;
}
.freezePF {
  font-size: 16px;
  font-weight: bold;
  padding-top: 6px;
}
.freezePS {
  font-size: 14px;
  padding: 10px 0px;
}
.freezeButDiv {
  display: flex;
  gap: 8px;
  font-size: 16px;
}
.freezeCancel {
  background-color: '#F2F4F7';
  padding: 10px 28px;
  border-radius: 10px;
  cursor: pointer;
}
.freezeYes {
  background-color: #67a948;
  padding: 10px 18px;
  border-radius: 10px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .clickPrev {
    left: 50%;
    z-index: 5;
  }

  .clickRight {
    right: -40px;
    z-index: 5;
  }
}

@media (min-width: 768px) {
  .clickPrev {
    left: 88px;
    z-index: 5;
  }

  .clickRight {
    right: -100px;
    z-index: 5;
  }
}

.gg {
  z-index: 1;
  /* filter: blur(4px); */
  opacity: 0.25;
}

.hh {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.whiteText p {
  color: white;
}

.blackText p {
  color: black;
}

.bluebg {
  background: #4d5ef7;
}

.cardBtm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 1300px) {
  .cardBtm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.cardMonoDetailsA {
  width: 100%;
}

@media (min-width: 1240px) {
  .cardMonoDetailsA {
    width: 50%;
  }
}

.deepLinkContainer {
  display: flex;
  align-items: center;
  margin-top: 12px;
  color: #66a848;
  font-size: 16px;
}

.deepLinktext {
  margin-left: 4px;
  color: #66a848;
  transition: transform 0.3s ease-in-out;
}

.deepLinkContainer:hover .deepLinktext {
  transform: translateX(10px);
  color: #66a848;
  font-size: 16px;
  font-weight: 500;
}
