@import '../../../../variables.scss';
 .emojiPickerBG{
    /* background: url('../../../assets/feedPageAssets/emojiBgFeeds.jpg') ; */
    background-color: #4C5366;
   z-index: 5;
    width: 20rem; 
    top: 80px;
    padding: 1rem;
    border-radius: 10px;
    /* height: 10rem;
    /* background: rgba(124, 121, 121, 0.5); */

}