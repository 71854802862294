@import '../../../variables.scss';

.feespayment {
  // width: 450px;
  width: 100%;
  @include colflexx(flex-start);

  @include breakpoint(mobileonly) {
    width: 100%;
  }

  @include breakpoint(phablet) {
    // width: 350px;
    width: 100%;
  }
  @include breakpoint(tablet) {
    // width: 400px;
    width: 100%;
  }
  *.backcontinue {
    width: 100%;
  }
  &__row {
    width: 100%;
    @include colflexx(flex-start);
    gap: 25px;

    @include breakpoint(desktop) {
      @include rowflexx(flex-start);
      gap: 10px;
    }
  }
  &__col {
    width: 100%;
    @include colflexx(flex-start);
    gap: 25px;

    // &.--list{
    //     @include breakpoint(mobileonly){
    //         padding-top: 10px;
    //         padding-bottom: 10px;
    //         padding-right: 20px;
    //         max-height: 450px;
    //         @include overflowScroll($vesti-200, $vesti-700);
    //         overflow-y: scroll;
    //         overflow-x: hidden;
    //     }

    //     // background-color: #f5fcfd;
    // }
    @include breakpoint(mobileonly) {
      margin-bottom: 5rem;
    }
  }
  *.inputfloat,
  .amountinput,
  .amountfloat,
  .my-input-class,
  input {
    width: 100% !important;
    @include breakpoint(mobileonly) {
      // width: 75vw !important;
      // margin-bottom: 6rem;
    }
    @include breakpoint(phablet) {
      // width: 350px !important;
    }
  }
  &__smallcol {
    width: 100%;
    @include colflexx(flex-start);
    gap: 5px;

    .platform_withiconnobg {
      width: fit-content !important;
      height: fit-content !important;
      padding: 5px 0px !important;
    }
    p:nth-child(1) {
      @include fontMedium(1em, 1em, $vesti-901);
    }
    .dob.dob {
      border-radius: 5px;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border: none;
      box-sizing: border-box;
      margin-top: 0px;
      box-shadow: none;
      width: 100% !important;
      margin-top: 10px;
      height: 60px;
      background: #f9f8f9;
      border: 1px solid $gray-400;
      box-sizing: border-box;
      outline: none;
      padding-left: 20px;
      margin-bottom: 10px;
      position: relative;

      @include fontNormal(1em, 1em, $gray-600);

      @include breakpoint(mobileonly) {
        width: 100% !important;
        height: 55px;
      }
      @include breakpoint(tablet) {
        width: 100% !important;
        height: 55px;
      }

      @include breakpoint(phablet) {
        width: 100% !important;
      }

      &:focus {
        border: 1px solid $vesti-700;
        background-color: $vesti-100;
        color: $vesti-901;
      }
      &:disabled {
        cursor: not-allowed;
        color: $gray-800;
      }
    }
    .CalendarContainer-sc-1gorc7r-0 {
      z-index: 10 !important;
    }
  }
  &__get {
    @include fontNormal(1.2em, 1em, $vesti-800);
    width: fit-content;
    height: fit-content;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    background-color: $vesti-200;
    border: 0.5px solid $vesti-700;

    &.--error {
      @include fontNormal(1.2em, 1em, red);
      background-color: rgb(255, 190, 190);
      border: 0.5px solid red;
    }
  }

  .select-input {
    width: 100%;
    * {
      margin-bottom: 0px !important;
    }
    input {
      width: 100%;
      @include breakpoint(mobileonly) {
        width: 70vw !important;
      }
    }
  }
  &__review {
    width: 400px !important;
    @include breakpoint(mobileonly) {
      width: 100% !important;
    }
  }
}
