@import '../../../variables.scss';

.btn-container {
  // @include colflexx(center);
  @include rowflexx(center);
  display: flex;
  flex-direction: column-reverse !important;
  gap: 10px;
  div.btn-left,
  button.btn-right {
    min-width: none !important;
    width: 100% !important;
    margin-left: 0px;
  }

  div.btn-left {
    margin-right: 0px !important;
  }
  @include breakpoint(desktop) {
    @include rowflexx(center);
    gap: 20px;
  }
  // @include breakpoint (mobileonly){
  //     display: flex;
  //     flex-direction: column !important;
  //     align-items: flex-start;
  //     gap:10px;
  //     @include colflexx(flex-start);

  //     div.btn-left, button.btn-right {
  //         min-width: none !important;
  //         width: 100% !important;
  //         margin-left: 0px;
  //     }

  //     div.btn-left {
  //         margin-right: 0px !important;
  //     }
  // }
}

form {
  .pincode-input-container {
    @include rowflex(center, flex-start);
    @include breakpoint(mobileonly) {
      max-width: 300px !important;
      width: fit-content !important;
      display: row !important;
      flex-wrap: wrap !important;
    }

    gap: 10px !important;

    .pincode-input-text {
      width: 50px !important;
      height: 50px !important;
      @include breakpoint(mobileonly) {
        margin-top: 10px !important;
      }
    }
    .pincode-input-text:nth-child(4) {
      @include breakpoint(mobileonly) {
        margin-top: 10px !important;
      }

      @include breakpoint(phablet) {
        margin-top: 10px !important;
      }
    }
  }
}

.mrgn-bttm {
  margin-bottom: 0;
  @include breakpoint(mobileonly) {
    margin-bottom: 10rem;
  }
}
