@import '../../../variables.scss';

.subFeature {
  min-width: 300px;

  &__maintitle {
    font-size: 24px;
    font-weight: 600;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
  }

  & span {
    color: $vesti-700;
    margin-left: 10px;
  }

  & article {
    margin-top: 30px;
  }

  & ul {
    display: grid;
    margin-top: 16px;
    gap: 10px;
    column-gap: 40px;
    grid-template-columns: auto auto;
    @include breakpoint(mobileonly) {
      grid-template-columns: auto;
    }
  }

  & li {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #858ca0;
    font-size: 15px;
  }

  &li img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  &__button {
    display: flex;
    gap: 10px;
    margin-top: 30px;

    &__right {
      width: 70%;
    }
  }
}
