@import '../../../../variables.scss';

.mycard {
  width: 100%;
  @include colflexx(flex-start);
  // padding: 50px;

  @include breakpoint(mobileonly) {
    width: 100vw;
    padding: 20px;
  }
  .shortinfo {
    width: 350px;
    @include breakpoint(mobileonly) {
      width: 95vw;
    }
  }

  &__empty {
    width: 100%;
    height: 60vh;
    @include rowflex(center, center);
  }
  &__inner {
    width: 100%;
    height: 100%;
    @include rowflex(flex-start, space-between);
    flex-wrap: wrap;
    gap: 50px;
    .singlecard {
      height: fit-content;
    }
  }
  &__left {
    // width: fit-content;
    width: 100%;
    height: fit-content;
    // padding: 40px;
    border-radius: 20px;
    @include colflexx(flex-start);

    @include breakpoint(mobileonly) {
      width: 100%;
      padding: 10px;
    }
    @include breakpoint(desktop) {
      width: fit-content;
    }
  }

  &__right {
    width: 50%;
    // height: fit-content;
    height: 100%;
    position: relative;
    scrollbar-width: thin;
    @include colflexx(flex-start);

    @include breakpoint(mobileonly) {
      width: 100%;
    }
    @include breakpoint(tablet) {
      width: 90%;
    }
    @include breakpoint(phablet) {
      width: 98%;
    }
  }
  &__title {
    width: 100%;
    height: fit-content;
    .shortinfo {
      width: 60%;
    }
  }

  &__transactions {
    padding: 0px 40px 0px 40px;
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    overflow-y: scroll;
    margin: 0 auto;
    padding: 15px 20px;
    border: 0.5px solid #eaeaea;
    box-shadow: 0px 0px 5px rgba(245, 245, 245, 0.7);
    border-radius: 20px;
    @include breakpoint(desktop) {
      padding: 40px;
      @include colflex(flex-start, flex-start);

      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background: $vesti-200;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $vesti-700;
        border-radius: 6px;
        border: $vesti-900;
      }
      @include colflex(flex-start, flex-start);

      hr:last-child {
        display: none;
      }
      .singletransaction {
        width: 100%;
        height: fit-content;
        @include colflex(flex-start, space-between);
        gap: 15px;
        &__title {
          margin: 0;
          @include fontBold(1em, 1em, $vesti-901);
        }
        &__trans {
          width: 100%;
          @include colflexx(flex-start);
          gap: 30px;
        }
        &__single {
          width: 100%;
          @include rowflex(center, space-between);
          @include breakpoint(mobileonly) {
            gap: 10px;
          }
        }

        &__left {
          @include rowflexx(flex-start);
          img {
            width: auto;
            margin-right: 15px;
            display: none;
            @include breakpoint(desktop) {
              display: flex;
            }
          }

          p {
            width: 70%;
            text-transform: capitalize;
            @include fontNormal(1em, 1.4em, $vesti-901);
            @include breakpoint(mobileonly) {
              width: 60vw;
              @include fontNormal(0.9em, 1.4em, $vesti-901);
            }
            strong {
              color: $vesti-700;
            }
            strong:nth-child(2) {
              color: $vesti-900;
            }
          }
        }
        &__desc {
          @include colflexx(flex-start);
          gap: 15px;
        }
        &__right {
          @include colflexx(flex-end);
          gap: 10px;
        }
        &__charges {
          @include fontMedium(1em, 1em, $red-900);
        }
        &__amount {
          width: fit-content;
          @include fontBold(1.1em, 1em, $vesti-800);
          &.--debit {
            color: red;
          }

          &.--credit {
            color: $vesti-800;
          }
        }
        .status {
          height: fit-content;
          width: fit-content;
          padding: 5px 10px 5px 10px;
          border-radius: 5px;
          text-transform: lowercase;
          @include breakpoint(mobileonly) {
            padding: 0px 3px 0px 3px;
          }
          @include fontMedium(1em, 1em, $vesti-900);
          @include rowflexx(center);
          flex-wrap: no-wrap;
          gap: 5px;
          .statusIcon {
            height: 15px;
            width: 15px;
            margin-right: 10px;
            border-radius: 100%;
            background-color: red;

            &.--success {
              background-color: #4dda89;
            }

            &.--pending {
              background-color: $yellow-700;
            }

            &.--failed {
              background-color: #ff3636;
            }
          }
        }
      }
      hr {
        width: 100%;
        background-color: $gray-300;
        margin-bottom: 30px;
      }
      &::-webkit-scrollbar {
        width: 15px;
        border-radius: 15px;
      }
      &::-webkit-scrollbar-track {
        background: $vesti-200;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $vesti-700;
        border-radius: 10px;
        border: $vesti-900;
      }
    }

    .singlecard {
      height: 250px;
    }
  }
}
form {
  .inputfloat {
    width: 90%;
    margin-top: 20px;
  }
  .backcontinue {
    @include rowflex(flex-start, flex-start);
  }
}

.freezecard {
  @include colflexx(center);

  img {
    width: auto;
    margin-bottom: 30px;
  }

  span {
    height: fit-content;
    margin-bottom: 10px;
    text-align: center;
    @include colflexx(center);

    p:nth-child(1) {
      margin: 0;
      @include fontBold(1.5em, 1em, $vesti-700);
    }
    h6 {
      margin: 0;
      width: 80%;
      @include fontNormal(1.2em, 1.4em, $vesti-902);
      strong {
        color: $vesti-700;
      }
      @include breakpoint(mobileonly) {
        width: 100%;
      }
    }
  }
}
.ordercard {
  width: 100%;
  @include colflexx(flex-start);

  .select-input:nth-child(2) {
    margin-bottom: 15px;
  }

  .titlesubtitle {
    margin-bottom: 10px;
  }

  &__location {
    width: 100%;
    @include rowflexx(flex-start);
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 15px;

    @include breakpoint(mobileonly) {
      @include colflexx(flex-start);
      gap: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
    }
    @include breakpoint(phablet) {
      @include colflex(flex-start, center);
      gap: 10px;
    }
  }

  .inputfloat {
    .inputfloat__box {
      .shortinfo {
        margin-top: 10px !important;
      }

      .apto-error {
        margin: 0;
        @include fontNormal(1em, 1em, red);
      }
    }
  }

  .backcontinue {
    width: 100%;
    margin-top: 40px;
    width: 100%;
    outline: none;
    border: none;
    margin-top: 30px;
    background-color: $vesti-700;
    @include fontMedium(1em, 1em, #fff);
    transition: all 0.5s ease;
    &:hover {
      background-color: $vesti-800;
    }
    &:disabled {
      background-color: $gray-500;
      color: $gray-100;
      cursor: not-allowed;
    }
  }
}

.cardinfo {
  width: 400px;
  height: fit-content;
  @include colflexx(flex-start);
  gap: 20px;
  background-color: #f7fdf5;
  padding: 20px;
  border-radius: 10px;
  border: 0.5px solid $vesti-700;
  @include breakpoint(mobileonly) {
    width: 75vw;
  }
  @include breakpoint(phablet) {
    width: 75vw;
  }
  span:not(:last-child) {
    border-bottom: 0.5px dashed $vesti-700;
    // border-top: 1px solid $vesti-700;
  }
  &__top {
    width: 100%;
    margin-bottom: 10px;
    border-bottom: none !important;
    @include rowflex(flex-start, space-between);

    p:nth-child(1) {
      @include fontSemiBold(1em, 1em, $vesti-901);
    }
    p:nth-child(2) {
      @include fontSemiBold(1em, 1em, $vesti-901);
    }
  }
  &__detail {
    padding: 5px 0px;
    width: 100%;
    @include rowflex(flex-start, space-between);

    p:nth-child(1) {
      @include fontMedium(1em, 1em, $vesti-901);
    }
    p:nth-child(2) {
      @include fontBold(1em, 1em, $vesti-700);
    }
  }
}

*.cardactions {
  // margin: 0 auto;

  &__btns {
    @include rowflexx(center);
    gap: 30px;
    margin: 0 auto !important;
    @include breakpoint(mobileonly) {
      gap: 15px;
    }
    span {
      @include colflexx(center);
      gap: 10px;

      p {
        text-transform: capitalize;
        @include fontMedium(0.9em, 1em, $vesti-900);
      }
    }
  }
}
*.carddetail {
  margin-top: 20px;
  width: 100%;
  height: fit-content;
  // padding: 30px 30px 30px 30px;
  //   background-color: #fff;
  // border: 1px solid $gray-300;
  //   border-radius: 15px;
  @include colflexx(flex-start);
  gap: 20px;
  // box-shadow: 0px 2px 4px rgba(117, 131, 142, 0.04), 0px 8px 16px rgba(97, 113, 129, 0.1);
  max-height: 400px;
  overflow-y: scroll;

  @include breakpoint(mobileonly) {
    width: 100%;
    padding: 20px;
  }

  &__bottom {
    width: 100%;
    @include colflexx(flex-start);
    // gap: 20px;
  }
  &__bsingle {
    width: 100%;
    margin-bottom: 10px;
    .--title {
      @include fontMedium(0.8em, 1em, $vesti-800);
      text-transform: uppercase;
    }
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  .clipboard {
    &__box {
      width: 100% !important;
    }
  }
  &::-webkit-scrollbar-track {
    background: $vesti-200;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $vesti-700;
    border-radius: 6px;
    border: $vesti-900;
  }
}

*.card {
  &__table {
    // max-height:500px ;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: $vesti-200;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $vesti-700;
      border-radius: 6px;
      border: $vesti-900;
    }
  }
}
