@import "../../../variables.scss";

.grid-container {
  height: 85vh;
  width: 90%;
  margin: auto;
  overflow-y: scroll;
  
  .advice-card {
    @include colflexx(center);
    position: relative;
    margin-bottom: 1.2rem;
    overflow: hidden;
    font-size: 1.4rem;
    font-weight: 700;
    color: #67a948;
    border-radius: 5px;
    border: 1px solid $gray-300;
    transition: all 0.5s ease;
    cursor: pointer;
    padding: 20px !important;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 20px;

    &:hover {
        filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
    }
    img {
      width: 70px !important;
      height:70px !important;
    }
    .advice-card-subtitle {
      @include fontNormal(.8em, 1.5em, $vesti-901);
    }
  
    .advice-card-title {
      margin: 0;
      margin-top: 20px;
      text-align: center;
      text-transform: uppercase;
      @include fontBold(1em, 1em, $vesti-700);
      strong {
        color:$vesti-700 !important;
      }
    }
    
  }

}

.open-comment {
  
  cursor: pointer;
  transition: all 0.5s ease;

  &.active {
    @include fontBold(1.2em, 1em, $vesti-700);
  }
  &.disable {
    @include fontBold(1.2em, 1em, $vesti-902);
  }
}


.comments-holder{
  width: fit-content;
  max-height: 0;
  transition: max-height 1s ease-out;
  overflow: hidden;
  margin-bottom: 0 !important;

  &.active {
    max-height: 100em;
  }
  
}

.comment-box {
  width: 80%;
  
  .comment-title {
    @include fontBold(2em, 1em, $vesti-902);
  }

  .comment-textarea {
    width: 100%;
    padding: 20px;
    background-color: $gray-200;
    border: 1px solid $gray-300;
    outline: none;
    resize: none;
    transition: all 0.5s ease;
    border-radius: 10px;
    height: 300px;
    margin-bottom: 30px;
    @include fontNormal(1.5em, 1.5em, $gray-600);

    &:focus {
      background-color: $vesti-100;
      border: 1px solid $vesti-700;
      @include fontNormal(1.5em, 1.5em, $vesti-901);
    }
  }
}

.advice-detail-top {

  width: 100%;
  @include rowflex(center,space-between);
  .steps-box {
    width: fit-content;
    @include rowflexx(center);

    .advice-detail-steps {
      width: 30px;
      height: 30px;
      border-radius: 100%;
      transition: all .5s ease-out;
      margin-left: 10px;
      background-color: red;
  
      &.active {
        background-color: $vesti-700;
      }

      &.disabled {
        background-color: $gray-300;
      }
    }
  }
  
}

.advice-detail-content {
  margin: 0;
  @include fontNormal(1.5em, 1.7em, $vesti-901);
}

.advice-publisher {
  @include rowflexx(center);

  p:nth-child(2){
    margin: 0;
    margin-left: 5px;
    @include fontNormal(1.2em,1em, $vesti-901);

    strong {
        color: $vesti-700;
    }
  }
}

.advice-detail-title {
  margin: 0;
  margin-top: 20px;
  text-align: left;
  width: 100% !important;
  text-transform: uppercase;
  @include fontBold(2.5em, 1em, $gray-400);
  strong {
    color:$vesti-700 !important;
  }
}