@import "../../../variables.scss";


.signin-container {
    width: 100%;
    height: fit-content;

    @include rowflex(center,center);

    .signin-inner-container {
        @include colflexx(flex-start);
        width: 90%;

        form {
            width: 100%;
            height: fit-content;
            // margin-top: 30px;
            .inputfloat {
                width: 100%;
            }
            .form-input:not(:first-child){
                margin-top: 0px;

                @include breakpoint(mobileonly){
                    margin-top: 20px;
                }
            }

            .form-bottom {
                @include colflexx(flex-start);
                width: 100%;
                margin-top: 30px;
                .signin-account {
                    width: 100%;
                    height: 60px !important;
                    cursor: pointer;
                    background-color: $vesti-700;
                    color: #fff;
                    border-radius: 10px !important;
                    @include rowflex(center,center);
                    @include fontBold(1em, 1em, #fff);
                    transition: all .5s ease;
    
                    &:hover {
                        background-color: $vesti-900;
                    }
    
                    &:disabled{
                        cursor: not-allowed;
                        background-color: $gray-600;
    
                    }
                }

                p{
                    margin: 0;
                    width: 100%;
                    cursor: pointer;
                    margin-top: 10px;
                    @include fontNormal(1em, 1em, $vesti-901);

                    strong {
                        color: $vesti-700;
                        
                    }
                }
            }

        }
    }
}