.myGroupfeedsContainerAstn {
  width: 70%;
}

.myChatfeedsContainerAstn {
  width: 30%;
}

@media (min-width: 168px) and (max-width: 1300px) {
  .myChatfeedsContainerAstn {
    display: none;
  }

  .myGroupfeedsContainerAstn {
    width: 100%;
    /* width: 70%; */
  }
}

@media (min-width: 1301px) {
  .myfeedspageactionmenubtm {
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 905px) {
  .myGroupfeedsContainerAstn {
    padding: 0 2%;
  }
}
