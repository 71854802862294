.student-loan {
  margin-top: -20px;
  .layout-container {
    position: relative;
    height: 100%;
    overflow-y: auto;

    @media (max-width: 1024px) {
      padding: 30px;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.ty-loan-container {
  // padding: 1rem;

  // @media (min-width: 325px) {
  //   margin-top: -5px;
  // }

  // @media (min-width: 768px) {
  //   padding: 2rem;
  //   margin-top: -50px;
  // }

  &__header {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    font-size: 1.125rem;
    justify-content: flex-start;
    cursor: pointer;

    @media (min-width: 640px) {
      font-size: 1.25rem;
    }

    @media (min-width: 768px) {
      font-size: 1.5rem;
    }

    &__title {
      font-weight: 600;
      color: #101828;
      font-size: 1.25rem;

      @media (min-width: 640px) {
        font-size: 1.625rem;
      }
    }

    &__applied {
      margin-top: 20px;
      color: #101828;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 10px;
      width: 100%;

      @media (min-width: 1024px) {
        width: 500px;
      }
    }
    &__mpower {
      margin-top: 25px;
    }
    &__titttle {
      margin-top: 400px;
    }
  }
}

.ty-loan-container__header__applied a {
  text-decoration: none;
  color: inherit;
}

.ty-loan-container__header__applied a:hover {
  text-decoration: none;
}








