@import '../../../variables.scss';

.chatListContainer {
    position: relative;

    .chatListHeading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 768px){
            gap: 3px;
        }
    }

    .chatP {
        color: #000;
        font-size: 18px;
        font-weight: 600;

        @media (max-width: 768px){
            font-size: 16px;
        }
        @media (max-width: 839px){
            padding-right: 3px;
        }
    }

    .chatSearchIconDiv {
        display: flex;
        align-items: center;
        gap: 8px;

        @media (max-width: 768px){
            gap: 4px;
        }
    }

    .imgSearchIcon {
        width: 18px;
        cursor: pointer;

        @media (max-width: 839px){
            width: 14px;
        }
        @media (max-width: 768px){
            width: 14px;
        }
    }
    .searchIconInputDiv {
        display: flex;
        flex-direction: column;

        @media (max-width: 991px){
            width: 60%;
        }
        @media (max-width: 927px){
            width: 60%;
        }
        @media (max-width: 839px){
            width: 64%;
        }
        @media (max-width: 768px){
            width: 60%;
        }
    }
    .searchIconInput {
        padding: 4px;
        margin-bottom: 4px;
        outline: none;

        @media (max-width: 768px){
            padding: 2px;
        }
    }
    .searchIconInput::placeholder {
        font-size: 14px;
        @media (max-width: 768px){
            font-size: 12px;
        }
        @media (max-width: 839px){
            font-size: 12px;
        }
    }
    .searchIconResultText {
        font-size: 12px;
        color: red;
    }

    .chatListMain {
        display: block;
        margin-top: 8px;
    }
    .NoMatchingUser {
        font-size: 14px;
    }
    .chatListUsersProfile {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .chatListUsers {
        display: flex;
        align-items: center;
        gap: 6px;
        padding-bottom: 16px;
    }
    .chatListUserImg {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        cursor: pointer;
        object-fit: cover;

        @media (min-width: 400px) and (max-width: 991px){
            width: 38px;
            height: 38px;
        }
        @media (max-width: 768px){
            width: 30px;
            height: 30px;
        }
    }
    .nameAndMessage {
        display: block;
    } 
    .chatListName {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 14px;

        @media (max-width: 839px){
            font-size: 12px;
        }
        @media (max-width: 768px){
            font-size: 12px;
        }
    }
    .chatListNameP {
        font-weight: 600;
        cursor: pointer;
    }
    .truncateMessage {
        font-size: 12px;
        cursor: pointer;

        @media (max-width: 839px){
            font-size: 10px;
        }
        @media (max-width: 768px){
            font-size: 10px;
        }
    }
    .chatListTime {
        font-size: 10px;

        @media (max-width: 839px){
            font-size: 7px;
        }
        @media (max-width: 768px){
            font-size: 7px;
        }
    }
}


// ChatlistSecond 
.secondChatListContainer {
    padding: 20px;
    // position: relative;

    .secondbackIconDiv {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #67A948;

        .secondbackIcon {
            font-size: 40px;

            @media (max-width: 420px){
                font-size: 30px;
            }
        }
        .secondbackP {
            font-size: 30px;

            @media (max-width: 700px){
                font-size: 26px;
            }

            @media (max-width: 420px){
                font-size: 20px;
            }
        }
    }

    .secondChatListHeading {
            display: flex;
            align-items: center;
            justify-content: space-between;
    
    }

    .secondChatP {
        color: #000;
        font-size: 40px;
        font-weight: 600;

        @media (max-width: 700px){
            font-size: 30px;
        }

        @media (max-width: 420px){
            font-size: 24px;
        }
    }

    .secondChatSearchIconDiv {
        display: flex;
        align-items: center;
        gap: 8px;

    }

    .secondImgSearchIcon {
        width: 100%;
        cursor: pointer;

        @media (max-width: 700px){
            width: 70%;
        }
    }
    .secondsearchIconInputDiv {
        display: flex;
        flex-direction: column;
    }
    .secondsearchIconInput {
        padding: 4px;
        margin-bottom: 4px;
        outline: none;

    }
    .secondsearchIconInput::placeholder {
        font-size: 16px;

        @media (max-width: 700px){
            font-size: 14px;
        }
    }

    .secondchatListMain {
        display: block;
        margin-top: 8px;
    }

    .noChatAva {
        font-size: 16px;
    }

    .secondNoMatchingUser {
        font-size: 14px;
    }
    .secondchatListUsersProfile {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .secondchatListUsers {
        display: flex;
        align-items: center;
        gap: 6px;
        padding-bottom: 16px;
    }
    .secondchatListUserImg {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        cursor: pointer;
        object-fit: cover;
        
        @media (max-width: 700px){
            width: 52px;
            height: 52px;
        }
        @media (max-width: 420px){
            width: 45px;
            height: 45px;
        }
    }
    .secondnameAndMessage {
        display: block;
    } 
    .secondchatListName {
        display: flex;
        gap: 4px;
        align-items: center;
        font-size: 22px;

        @media (max-width: 700px){
            font-size: 20px;
        }

        @media (max-width: 420px){
            font-size: 16px;
        }
    }
    .secondchatListNameP {
        font-weight: 600;
        cursor: pointer;
    }
    .secondtruncateMessage {
        font-size: 16px;
        cursor: pointer;

        @media (max-width: 700px){
            font-size: 14px;
        }

        @media (max-width: 420px){
            font-size: 12px;
        }

        .shortMessage {
            display: none;

            @media (max-width: 470px){
                display: block;
            }
        }
        .longMessage {
            display: none;
            @media (min-width: 471px) {
                    display: block; 
                }
        }
       
    }
    .secondchatListTime {
        font-size: 16px;

         @media (max-width: 700px){
            font-size: 14px;
        }
        @media (max-width: 420px){
            font-size: 12px;
        }
    }
}