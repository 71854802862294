@import '../../variables.scss';

.ordercard-top {
  width: 100%;
  @include colflex(flex-start, flex-start);

  p:nth-child(1) {
    margin: 0;
    @include fontBold(2em, 1em, $gray-500);
    @include breakpoint(mobileonly) {
      @include fontBold(1.5em, 1em, $gray-500);
    }
  }
  p:nth-child(2) {
    width: 90%;
    margin-top: 10px;
    @include fontNormal(1em, 1.5em, $vesti-901);
  }
}
.selectcard-container {
  width: 100%;
  height: 100%;
  // width: 400px;
  // background-color: red;
  @include colflex(center, center);

  @include breakpoint(mobileonly) {
    // width: 73vw;
    margin: 0 auto;
  }
  @include breakpoint(phablet) {
    // width: 80vw;
    margin: 0 auto;
  }
  @media screen and (min-width: 600px) and (max-width: 800px) {
    // width: 45vw;
  }

  .selectcard-inner {
    width: 27rem;
    height: fit-content;
    @include colflexx(center);

    @include breakpoint(mobileonly) {
      width: 100%;
    }

    &.center {
      width: 100%;

      @include colflexx(center);
      img {
        width: 300px;

        @include breakpoint(mobileonly) {
          width: 200px;
        }
        @include breakpoint(phablet) {
          width: 230px;
        }
      }

      .center-controls {
        width: fit-content;
        max-width: 150px;
        margin-top: 20px;
        height: fit-content;
        // max-height: 60px;
        @include rowflexx(center);

        div:not(:first-child) {
          margin-left: 10px;
        }
        .control {
          width: 20px;
          height: 20px;
          border-radius: 100%;
          background-color: $gray-300;
          transform-origin: 50% 50%;
          transition: all 0.5s ease;
          @include breakpoint(mobileonly) {
            width: 15px;
            height: 15px;
          }
          &.active {
            width: 50px;
            height: 15px;
            border-radius: 20px;
            background-color: $vesti-700;
          }
          &:hover {
            background-color: $vesti-700;
          }
        }
      }
    }

    &.description {
      text-align: center;
      // width: fit-content;
      margin-top: 30px;
      width: 100% !important;
      height: fit-content;
      @include colflexx(center);

      p:nth-child(1) {
        margin: 0;
        white-space: pre-line !important;
        @include fontBold(1.2em, 1em, $vesti-700);
      }
      p:nth-child(2) {
        width: 100%;
        margin-top: 10px;
        white-space: pre-line !important;
        @include fontNormal(1em, 1.7em, $vesti-901);

        @include breakpoint(mobileonly) {
          width: 100%;
          margin: 0;
          @include fontNormal(1em, 1.7em, $vesti-901);
        }
        @media screen and (min-width: 600px) and (max-width: 800px) {
          @include fontNormal(1em, 1.7em, $vesti-901);
        }
      }
    }
    form {
      margin-top: -20px;
      div {
        .pincode-input-container {
          @include rowflex(center, flex-start);
          @include breakpoint(mobileonly) {
            max-width: 300px !important;
            width: fit-content !important;
            display: row !important;
            flex-wrap: wrap !important;
          }

          gap: 10px !important;

          .pincode-input-text {
            width: 50px !important;
            height: 50px !important;
          }
          .pincode-input-text:nth-child(5) {
            @include breakpoint(mobileonly) {
              margin-top: 20px !important;
            }
          }
        }
      }
    }
  }
  &__button {
    width: fit-content;
    height: 50px;
    margin-top: 20px;
    padding: 0px 20px 0px 20px;
    background-color: $vesti-700;
    transition: all 0.5s ease;
    color: #fff;

    i {
      margin-left: 10px;
    }
    &.disabled {
      color: #fff;
      background-color: $gray-800;
      cursor: not-allowed;
    }
    &:hover {
      background-color: $vesti-900;
    }

    @include breakpoint(mobileonly) {
      width: 100%;
      margin-top: 20px;
    }
  }
}

iframe {
  width: 450px;
  height: 600px;

  &::-webkit-scrollbar-thumb {
    background: $vesti-700;
    border-radius: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $vesti-800;
  }

  @include breakpoint(mobileonly) {
    width: 350px !important;
    height: 450px;
  }
}

.billing-container {
  width: 100% !important;
  height: fit-content;

  @include rowflex(center, center);

  .billing-inner-container {
    @include colflexx(flex-start);
    width: 100%;
    @include breakpoint(mobileonly) {
      width: 80vw;
    }
    form {
      width: 100%;
      height: fit-content;
      margin-top: 10px;

      .form-input:not(:first-child) {
        margin-top: 15px;

        @include breakpoint(mobileonly) {
          margin-top: 20px;
        }
      }
      .backcontinue {
        margin-top: 5px;
        @include breakpoint(mobileonly) {
          margin-top: 10px;
          @include colflexx(flex-start);
          width: 100%;
        }
      }
      .billing-account {
        width: 100%;
        height: 60px !important;
        cursor: pointer;
        background-color: $vesti-700;
        color: #fff;
        border-radius: 10px !important;
        @include rowflex(center, center);
        @include fontBold(1em, 1em, #fff);
        transition: all 0.5s ease;

        &:hover {
          background-color: $vesti-900;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: $gray-600;
        }
      }
    }
  }
}

.cardtype {
  cursor: pointer;

  padding: 15px 20px 15px 20px !important;
  width: 100%;
  // width: 350px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid $gray-300;
  transition: all 0.5s ease;
  @include breakpoint(mobileonly) {
    width: 100%;
  }
  // @include breakpoint(desktop){
  //     width: 420px;
  // }
  @include rowflexx(flex-start);
  gap: 20px;

  &__img {
    width: 80px;
  }
  &__right {
    @include colflexx(flex-start);
    transition: all 0.5s ease;
    p:nth-child(1) {
      margin: 0;
      @include fontBold(1.5em, 1em, $vesti-901);
    }
    p:nth-child(2) {
      margin-top: 10px;
      @include fontNormal(1em, 1.4em, $vesti-901);
    }
  }

  &:hover,
  &.--active {
    background-color: $vesti-700;
    border: 1px solid $vesti-700;
    .cardtype__right {
      p {
        color: #fff;
      }
    }
  }
}

.globalgeng__status {
  width: fit-content;
  height: fit-content;
  padding: 15px 30px;
  background-color: $vesti-100;
  border: 1px dashed $vesti-800;
  border-radius: 5px;
  margin-top: 10px;
  @include fontMedium(1em, 1em, $vesti-901);
  font-weight: 500 !important;
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;

  p:nth-child(1) {
    @include fontMedium(1em, 1em, $vesti-901);
    font-weight: 500 !important;
    background-color: none;
    border: none;
    padding: 0;
  }
  p:nth-child(2) {
    margin-top: 5px !important;
    @include fontMedium(1em, 1.4em, $vesti-901);
    font-weight: 500 !important;

    strong {
      color: $vesti-700;
    }
  }
  &.--failed {
    background-color: $red-100;
    border: 1px dashed $red-800;
    color: $red-901 !important;
    @include fontMedium(1em, 1em, $red-901);
  }
  &.--pending {
    background-color: $yellow-100;
    border: 1px dashed $yellow-800;
    color: $yellow-900 !important;
    @include fontMedium(1em, 1em, $yellow-901);
  }
}

.requestaccountArticle {
  margin-top: 15rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include breakpoint(mobileonly) {
    margin-top: 12rem;
  }
}

.flexButton {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 30px;
  @include breakpoint(mobileonly) {
    flex-direction: column;
    gap: 5px;
  }

  &__cancel {
    width: 40%;
    @include breakpoint(mobileonly) {
      width: 100%;
    }
  }
}
// slider
.slick-dots li button:before {
  font-size: 10px;
  color: lightgreen;
}

.slick-dots li.slick-active button:before {
  color: green;
}
@media (max-width: 768px) {
  .slick-dots {
    position: relative;
    bottom: -10px;
    display: block !important;
  }

  .slick-dots li button:before {
    font-size: 8px;
    color: #c7ffad;
  }

  .slick-dots li.slick-active button:before {
    font-size: 8px;
    color: #67a948;
  }
}

.fundCardF {
  font-size: 24px;
  font-weight: bold;
}
@media (max-width: 650px) {
  .fundCardF {
    font-size: 20px;
    font-weight: bold;
  }
}

.imgBal {
  display: flex;
  align-items: center;
  gap: 10%;
  background-color: #67a948;
  border-radius: 10px;
  padding: 8px 8px 8px 8px;
  margin-bottom: 8px;
  margin-top: 5%;
}

@media (max-width: 650px) {
  .imgBal {
    padding: 4px 6px 4px 6px;
    margin-top: 2%;
    margin-bottom: 4px;
    gap: 4%;
  }
}
@media (max-width: 400px) {
  .imgBal {
    padding: 2px 4px 2px 4px;
    gap: 3%;
  }
}

.imgBal img {
  width: 30px;
}
@media (max-width: 400px) {
  .imgBal img {
    width: 25px;
  }
}
.imgbalM {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 650px) {
  .imgbalM {
    padding: 5px;
    margin-top: 2%;
    margin-bottom: 4px;
  }
}

.imgbalM p {
  color: #ffffff;
}
.balP {
  font-size: 16px;
}
@media (max-width: 650px) {
  .balP {
    font-size: 10px;
  }
}
.pBalB {
  font-weight: bold;
  font-size: 24px;
}
@media (max-width: 650px) {
  .pBalB {
    font-size: 16px;
  }
}
.amountDiv {
  position: relative;
  margin-top: 28px;
}
.amountDivAbF {
  position: 'absolute';
  background-color: #ffffff;
  color: #67a948;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  top: -12px;
  left: 10px;
  border-radius: 10px;
}
.amountDivAbS {
  position: 'absolute';
  background-color: #ffffff;
  color: #67a948;
  padding: 4px;
  font-weight: bold;
  font-size: 10px;
  top: 55px;
  left: 10px;
  border-radius: 10px;
}
.amountDivAbFIn {
  padding: 8px;
  margin-bottom: 24px;
  background-color: #f9fafb;
  outline: none;
}
.exchangeRateNF {
  position: absolute;
  padding: 4px 15px 4px 15px;
  font-weight: bold;
  font-size: 14px;
  top: 37px;
  left: 28%;
  border-radius: 20px;
}
@media (max-width: 650px) {
  .exchangeRateF {
    padding: 4px 12px 4px 12px;
    font-size: 10px;
    top: 44px;
  }
}
@media (max-width: 650px) {
  .exchangeRateF {
    padding: 2px 8px 2px 8px;
    font-size: 8px;
    top: 44px;
    z-index: 5;
  }
}
.fFundCardBut {
  /* text-align: center; */
  display: block;
  margin-top: 8%;
}
@media (max-width: 650px) {
  .fFundCardBut {
    margin-top: 6%;
  }
}
.fFundCardBut {
  display: inline-block;
  background-color: #67a948;
  color: #ffffff;
  font-weight: bold;
  border-radius: 10px;
  padding: 3% 26% 3% 26%;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
}
.proFee {
  display: inline-block;
  background-color: #f2f4f7;
  padding: 4px 12px 4px 12px;
  color: #3e6f26;
  border-radius: 20px;
  margin-top: 4%;
  font-size: 10px;
}
@media (max-width: 650px) {
  .proFee {
    margin-top: 2%;
  }
}

.amountDivAbSIn {
  padding: 12px;
  background-color: #f9fafb;
  outline: none;
}

.reviewTransH {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 6px;
}
@media (max-width: 450px) {
  .reviewTransH {
    font-size: 24px;
    margin-bottom: 4px;
  }
}

.reviewTrans {
  border-bottom: 1px;
  border-style: dashed;
  border-color: #97e373;
  padding: 12px 2px 12px 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .reviewTrans {
    padding: 8px 2px 8px 2px;
  }
}
.rTranP {
  margin-bottom: 6px;
}
@media (max-width: 450px) {
  .rTranP {
    margin-bottom: 2px;
    font-size: 12px;
    width: 100%;
  }
}
.reviewTransD {
  font-size: 14px;
}
@media (max-width: 450px) {
  .reviewTransD {
    font-size: 10px;
  }
}
.reviewTransDivN {
  border: 1px solid;
  width: 100%;
  background-color: #f7fff3;
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  border: #97e373;
  padding-top: 30px;
  margin-top: 30px;
}
@media (max-width: 450px) {
  .reviewTransDiv {
    padding: 10px 15px 10px 15px;
    margin-top: 14px;
  }
}
.reviewTransP {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  color: #2b5219;
}
@media (max-width: 450px) {
  .reviewTransP {
    font-size: 24px;
  }
}
.reTranbutDiv {
  display: block;
  text-align: center;
  margin-top: 8%;
}
.reTranbutDiv p {
  display: inline-block;
  font-weight: bold;
  background-color: #67a948;
  border-radius: 10px;
  padding: 3% 26% 3% 26%;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
}
// .cardButOpt {
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-right: 9%;

//   padding-bottom: 5%;
//   gap: 30px;
// }

// @media (max-width: 1300px) {
//   .cardButOpt {
//     margin-right: -2%;
//   }
// }

// @media (max-width: 1100px) {
//   .cardButOpt {
//     margin-right: -7%;
//   }
// }

// @media (max-width: 899px) {
//   .cardButOpt {
//     margin-right: 8%;
//     margin-top: 2rem;
//   }
// }

.cardButtomDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cardButtomDiv img {
  width: 50px;
  cursor: pointer;
}
.cardButtomDivP {
  font-size: 14px;
}

.dotDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 6%;
  /* text-align: center; */
}
@media (max-width: 380px) {
  .dotDiv {
    gap: 6px;
  }
}

.vcardDot {
  border: 1px solid #67a948;
  padding: 30px 28px;
  border-radius: 10px;
  background-color: #f7fff3;
}
@media (max-width: 450px) {
  .vcardDot {
    padding: 18px 16px;
  }
}
@media (max-width: 380px) {
  .vcardDot {
    padding: 12px 10px;
    border-radius: 6px;
  }
}

.cardDetH {
  color: #67a948;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 4%;
}
.cardDetDiv {
  border: 1px solid #936710;
  border-style: dashed;
  background-color: #fff9f0;
  border-radius: 5px;
  padding: 10px;
  margin: 8px 0px;
  text-align: center;
}
.cardDetDiv p {
  font-size: 14px;
}

@media (max-width: 768px) {
  .slick-prev,
  .slick-next {
    display: block !important;
    font-size: 24px;
    z-index: 1;
  }

  .slick-dots li button:before {
    font-size: 10px;
  }

  .slick-dots {
    bottom: 10px;
  }
}
.cardSlider {
  text-align: center;
  width: 70%;
  max-width: 600px;
  margin: 0 auto;
  gap: 20px;
  margin-top: -20px;
}
@media (max-width: 600px) {
  .cardSlider {
    margin-top: 20px;
    margin-bottom: -25px;
  }
}
@media (max-width: 400px) {
  .cardSlider {
    margin-bottom: -15px;
  }
}
.gloOpt {
  display: block;
  gap: 12px;
  margin-top: -5%;
}
@media (max-width: 600px) {
  .gloOpt {
    margin-top: 10px;
  }
}
