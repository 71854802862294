.loaderContainer {
  position: relative;
  /* width: 100%;
    height: 100%; */
  margin: 0 auto;
  background-color: red;
}

.loaderContainer > div {
  position: absolute;
  z-index: 9999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.spinnercont {
  width: 100%;
  height: 100%;
  /* height: 100vh; */
  /* height: inherit; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin: 0 auto;
}
.justspinnercont {
  width: 100%;
  height: 100%;
  /* height: 100vh; */
  /* height: inherit; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
  /* margin: 0 auto; */
}
p {
  font-size: 1em;
  color: #101f09;
}
.spinner {
  /* margin: 100px auto 0;
    margin: 100px auto 100px; */
  width: 70px;
  text-align: center;
  margin: 0 auto;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #67a948;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*spinner*/
.lds-spinner {
  color: #d8d8d8;
  display: inline-block;
  position: relative;
  width: 28px;
  height: 28px;
}
.lds-spinner div {
  transform-origin: 31px 31px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 23px;
  left: 30px;
  width: 1px;
  height: 6px;
  border-radius: 20%;
  background: #1461b0;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
