@import '../../../variables.scss';

.ovcount-container{
    margin-top: 5rem;
    width: 100%;
    @include rowflex(center, center);
    .ovcount-container-inner{
        width: 90%;
        background-color: $vestisec-902;
        @include rowflex(center,space-between);
        padding: 4rem;
        border-radius: 22px;
        
        @include breakpoint(mobileonly){
            @include colflex(center,space-between);

        }

        .left{
            @include rowflex(center,center);
            gap: 1rem;
            width: 60%;

            @include breakpoint(mobileonly){
                @include colflex(center, center);
                width: 100%;
    
            }

            p{
                @include fontSemiBold(1.5em, 1.2em, $gray-100);
                
                @include breakpoint(mobileonly){
                    @include fontSemiBold(1em, 1.2em, $gray-100);
                    text-align: center;        
                }
                @include breakpoint(phablet){
                    @include fontSemiBold(1em, 1.2em, $gray-100);     
                }
            }

        }

        .right{
            width: 100%;
            @include rowflex(flex-end, flex-end);
            @include breakpoint(mobileonly){     
                margin-top: 1rem;
            }
            button {
                width: 50% !important;
                height: 60px !important;
                background-color: #67a948;
                transition: all 0.5s ease;
                @include fontMedium(1em, 1em, $gray-100);
                cursor: pointer;
            }
        }
    }
}