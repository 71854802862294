@import '../../../variables.scss';

.platform {
  cursor: pointer;
  @include colflexx(flex-start);
  min-width: 30px;
  width: fit-content;
  padding: 0px 40px 0px 40px;
  outline: none;
  border: none;
  height: 56px !important;
  border-radius: 5px !important;
  @include fontMedium(1.1em, 1em, #fff);

  &::first-letter {
    text-transform: uppercase;
    // background-color: red;
  }

  &.platform_mainbtn {
    width: 100%;
    background-color: $vesti-700;
    transition: all 0.5s ease;
    font-size: 1.2em;
    white-space: nowrap;
    @include rowflex(center, center);

    &:hover {
      color: #fff;
      background-color: $vesti-900;
    }

    &:disabled {
      background-color: $gray-500;
      color: #fff;
      cursor: not-allowed;
    }

    @include breakpoint(mobileonly) {
      width: 100%;
      margin-top: 20px;
      font-size: 1em;
    }

  }

  &.platform_withicon,
  &.platform_refusers,
  &.platform_withiconnobg {
    width: 100%;
    // background-color:  transparent;
    background-color: $vesti-700;
    transition: all 0.5s ease;
    white-space: nowrap;
    @include rowflex(center, center);
    gap: 10px;

    &.--rounded {
      border-radius: 100px !important;
      height: 50px !important;
    }

    @include breakpoint(mobileonly) {
      width: 100%;
      // margin-top: 20px;
    }
  }

  &.platform_addwallet {
    // width: 50%;
    background-color: transparent;
    color: $vesti-700;
    transition: all 0.5s ease;
    white-space: nowrap;
    @include rowflex(center, center);
    border: 2px solid $vesti-700 !important;
    gap: 10px;

    &.--rounded {
      border-radius: 100px !important;
      height: 35px !important;
    }

    @include breakpoint(mobileonly) {
      width: 50%;
      // margin-top: 20px;
      height: 35px !important;
      @include fontMain(0.8em, 1.3em, $vesti-700);
    }

    @include breakpoint(phablet) {
      // width: 50%;
      margin-top: 20px;
      height: 35px !important;
      @include fontMain(0.9em, 1.3em, $vesti-700);
    }

    @include breakpoint(tablet) {
      width: 25%;
      margin-top: 20px;
      height: 35px !important;
      @include fontMain(0.9em, 1.3em, $vesti-700);
    }
  }

  &.platform_withiconnobg {
    background-color: transparent;
    color: $vesti-700;
  }
  &.platform_refusers {
    border: 1px solid $vesti-700 !important;
    background-color: transparent;
    color: $vesti-700;
  }

 


  &.platform_secbtn {
    width: 100%;
    background-color: transparent;
    border: 2px solid $vesti-700 !important;
    transition: all 0.5s ease;
    font-size: 1.2em;
    white-space: nowrap;
    @include rowflex(center, center);
    @include fontMedium(1.1em, 1em, $vesti-700);

    &:hover {
      color: $vesti-700;
      background-color: transparent;
      border: 1px solid $vesti-700;
    }

    &.--red {
      border: none !important;
      background-color: rgb(255, 235, 235);
      @include fontMedium(1.1em, 1em, red);

      &:hover {
        color: red;
        background-color: rgb(255, 219, 219);
      }
    }

    &:disabled {
      background-color: $gray-500;
      color: #fff;
      cursor: not-allowed;
    }
  }

  &.platform_overview {
    width: 100%;
    background-color: $vesti-700;
    // border: 2px solid $vesti-700 !important;
    border-top: 2px solid $vesti-700 !important;
    border-bottom: 2px solid $vesti-700 !important;
    border-left: 2px solid $vesti-700 !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    // border-radius: 0px !important;
    transition: all 0.5s ease;
    font-size: 1.2em;
    white-space: nowrap;
    @include rowflex(center, center);
    @include fontMedium(1.1em, 1em, $vesti-100);

    &:hover {
      color: $vesti-100;
      background-color: $vesti-902;
      border: 1px solid $vesti-100;
    }

    &.--red {
      border: none !important;
      background-color: rgb(255, 235, 235);
      @include fontMedium(1.1em, 1em, red);

      &:hover {
        color: red;
        background-color: rgb(255, 219, 219);
      }
    }

    &:disabled {
      background-color: $gray-500;
      color: #fff;
      cursor: not-allowed;
    }
  }

  &.platform_procedures {
    width: 100%;
    background-color: transparent;
    border-top: 2px solid $vesti-700 !important;
    border-bottom: 2px solid $vesti-700 !important;
    border-right: 2px solid $vesti-700 !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    // border-radius: 0px !important;
    transition: all 0.5s ease;
    font-size: 1.2em;
    white-space: nowrap;
    @include rowflex(center, center);
    @include fontMedium(1.1em, 1em, $vesti-700);

    &:hover {
      color: $vesti-100;
      background-color: $vesti-902;
      border: 1px solid $vesti-100;
    }

    &.--red {
      border: none !important;
      background-color: rgb(255, 235, 235);
      @include fontMedium(1.1em, 1em, red);

      &:hover {
        color: red;
        background-color: rgb(255, 219, 219);
      }
    }

    &:disabled {
      background-color: $gray-500;
      color: #fff;
      cursor: not-allowed;
    }
  }

  &.platform_slant,
  &.platform_link {
    width: fit-content;
    height: fit-content;
    transition: all 0.5s ease;
    white-space: nowrap;
    padding: 0;
    margin: 0;
    @include rowflexx(center);
    gap: 5px;
    @include fontMedium(1.1em, 1em, $vesti-900);
    // font-style: italic;

    &:disabled {
      color: $gray-500;
      cursor: not-allowed;
    }

    strong {
      color: $vesti-700;
      white-space: nowrap;
      margin: 0;
    }
  }

  &.--child {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    @include fontNormal(0.9em, 1.2em, $vesti-901);
  }

  &.--small {
    height: 45px !important;
    @include fontMedium(1em, 1em, #fff);
  }
}

button {

  // text-transform: uppercase !important;
  &::first-letter {
    text-transform: uppercase !important;
  }
}

// normal button but with reduced height

.platform_mediumbtn {
  cursor: pointer;
  @include colflexx(flex-start);
  min-width: 30px;
  width: fit-content;
  padding: 0px 40px 0px 40px;
  outline: none;
  border: none;
  height: 46px !important;
  border-radius: 5px !important;
  @include fontMedium(1.1em, 1em, #fff);

  &::first-letter {
    text-transform: uppercase;
    // background-color: red;
  }

  width: 100%;

  background-color: $vesti-700;
  transition: all 0.5s ease;
  font-size: 1.2em;
  // white-space: nowrap;
  @include rowflex(center, center);

  &:hover {
    color: #fff;
    background-color: $vesti-900;
  }

  &:disabled {
    background-color: $gray-500;
    color: #fff;
    cursor: not-allowed;
  }

  @include breakpoint(mobileonly) {
    width: 100%;
    margin-top: 20px;
  }
}