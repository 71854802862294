@import "../../../variables.scss";

.form-input{
    @include colflex(flex-start, space-between);
    flex-direction: column;
    gap: 15px;
    height:fit-content;
    width: 100%;
    @include breakpoint(mobileonly) {
        height:fit-content;
       
    }

    .titlesubtitle {
        &.--smallcolored {
            flex: 1;
        }
    }
    .input-box {
        // width: 100%;
        flex: 3;
    }
    
    input[name="firstName"], input[name="lastName"]  {
        text-transform: capitalize;
    }
    input[type='text'], input[type='password'], input[type='number'] {
        width: 100% !important;
        // margin-top: 30px;
        height: 60px;
        background: #F9F8F9;
        border: 1px solid $gray-400;
        box-sizing: border-box;
        border-radius: 5px;
        outline: none;
        padding-left: 20px;
        position: relative;
        font-size: 16px !important;
        @include fontNormal(1em, 1em, $gray-600);

        @include breakpoint(mobileonly) {
            width: 100%!important;
            height: 55px !important;
            font-size: 16px !important;
        }
        @include breakpoint(tablet) {
            width: 100% !important;
            height: 55px;
            font-size: 16px !important;
        }

        @include breakpoint(phablet) {
            width: 100% !important; //250PX
            // height: 50px;
            font-size: 16px !important;
        }
        

        &:focus {
            border: 1px solid $vesti-700;
            background-color: $vesti-100;
            color: $vesti-901;
        }
        &:disabled {
            cursor: not-allowed;
            color: $gray-800;
        }

       

        // &:valid {
        //     border: 1px solid $blue-500;
        //     background-color: $blue-100;
        //     color: $blue-901;
        // }
    }
}
.eyes{
    position: absolute;
    right: 20px;
    top: 20px;
    width: 20;
    height: 20;
    color: $gray-600;
}