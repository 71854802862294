@import "../../../variables.scss";


.service-detail-container {
    // background-color: red;
    @include colflexx(flex-start);

    .service-detail-title {
        width: 80%;
        text-align: left;
        @include fontBold(1.5em, 1.2em, $vesti-700);
    }
    .service-provider-detail {
        margin-top: 10px;
        width: 100%;
        @include colflexx(flex-start);

        img {
            height: 400px !important;
        }

        div:nth-child(2) {
            margin-top: 20px;
            width: fit-content;
            @include rowflexx(center);

            img {
                width: 50px !important;
                height: 50px !important;
                border-radius: 100% !important;
            }

            p{
                width: 50%;
                text-align: left;
                margin: 0;
                margin-left: 10px;
                @include fontMedium(.6em, 1.2em, $vesti-901);
            }

        }
    }
    .service-provider-description {
        text-align: left;
        @include fontLight(1em, 1.5em, $vesti-902);
    }
    .service-provider-btn {
        margin-top: 30px;
        width: fit-content;
        padding: 20px;
        padding-left: 30px;
        padding-right: 30px;
        height: 60px;
        border-radius: 5px;
        background-color:  $vesti-700;
        @include fontMedium(1em, 1em, #fff);
        @include rowflex(center,center);
        transition: all 0.5s ease;

        &:hover {
            background-color:  $vesti-900;
        }

    }

}