@import "../../../variables.scss";


.recipient {

    width: 100%;
    height: fit-content;
    @include rowflex(center,center);
    width: 400px;
    @include breakpoint(mobileonly){
        width: 75vw;
    }
    &__inner {
        width: 100%;
        height: fit-content;
        @include colflexx(center);
        gap: 20px;
        img {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            object-fit: cover; 
            border-radius: 2px solid $vesti-700;
        }
    }
    &__content {
        width: fit-content;
        margin-top: 30px;
        @include colflexx(center);
        gap: 10px;
        p:nth-child(1){
            margin: 0;
            @include fontBold(1.7em, 1em, $vesti-700);
        }
        p:nth-child(2){
            margin: 0;
            @include fontMedium(1.2em, 1em, $vesti-901)
        }
        p:nth-child(3) {
            margin: 0;
            @include fontNormal(1em, 1em, $gray-500);
        }
    }
    &__balance{
        margin-top: 10px;
        @include fontMedium(1em,1em,$vesti-902);
        strong {
            color: $vesti-900;
        }
    }
    &__bottom {
        width: 100%;
        margin-top: 30px;
        @include colflexx(center);
    }
    &__form {
        // width: 400px;
        width: 100%;

        @include breakpoint(mobileonly){
            width: 100%;
        }
    }
}

.smallbox-container {
    width: 100%;
    height: fit-content;
    padding: 40px;
    background-color: $vesti-100;
    border: 1px solid $vesti-700;
    border-radius: 10px;

    .smallbox-inner-container{
        width: 100%;
        height: fit-content;

        p{
            margin: 0;
            @include fontNormal(1.2em, 1.5em, $vesti-902);

            @include breakpoint(mobileonly){
                @include fontNormal(1em, 1.5em, $vesti-902);
            }
            strong {
                color: $vesti-700;
            }
        }

    }
}