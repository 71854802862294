@import '../../../variables.scss';

.yourcard {
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &__toggle {
    @include rowflex(flex-start, space-between);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__cards {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 2rem;
  }

  &__add {
    width: 10%;
    border: 2px solid red;
    padding: 5px 2px 5px 2px;
  }
}

.cards__tggle p {
  font-size: 12px;
}

.cards__tggle__opt {
  display: block;
}

// .cards__tggle__opt {
//   display: none;
// }

// @media (min-width: 900px) {
//   .cards__tggle p {
//     font-size: 14px;
//   }
//   .cards__tggle__opt {
//     display: block;
//   }
// }

@media (min-width: 1300px) {
  .cards__tggle p {
    font-size: 16px;
  }
}

.GGcardPendingLines {
  width: 6rem;
  height: 2px;
  background-color: #2b5219;
  margin-bottom: 30px;
}

.GGSingleprogress {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  & img {
    width: 30px;
    height: 30px;
  }

  & p {
    color: #67a948;
    font-size: 13px;
    font-weight: 500;
  }
}

.TemporaryPin {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__Header {
    display: flex;
    flex-direction: column;
    gap: 2px;
    & h1 {
      color: #14290a;
      font-size: 24px;
      font-weight: 600;
    }
    & p {
      color: #2b5219;
      font-size: 13px;
      font-weight: 500;
    }
  }
}

.viewTpin {
  border-radius: 5px;
  border: 0.5px dashed #67a948;
  background: #f7fff3;
  display: flex;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  margin-top: 20px;

  & p {
    font-size: 24px;
    color: #67a948;
    font-weight: 300;
  }
}

.activatedCardPage {
  margin-top: 40px;
  width: 100%;

  &__top {
    display: flex;
    gap: 30px;
    width: 100%;
    height: 15rem;

    // height: 16rem;

    &__referral {
      border-radius: 10px;
      border: 1px solid #e7c4ff;
      background: #f9f0ff;
      width: 60%;
      position: relative;
      display: none;

      @media (min-width: 1600px) {
        width: 65%;
      }
      @include breakpoint(desktop) {
        display: block;
      }

      // height: 100%;

      &__img1 {
        position: absolute;
        bottom: 0px;
        right: 2px;
        // width: 12rem;
      }

      &__img2 {
        position: absolute;
        bottom: 0px;
        right: 0px;
        left: 0px;
        top: 0px;
        // width: 12rem;
      }

      & h1 {
        color: #461a64;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      & p {
        color: #461a64;
        font-size: 14px;
        font-weight: 400;
        max-width: 350px;
      }
    }
  }
}

.patternBgGG {
  background: url('../../../assets/physicalCard/GGreferralPattern.svg');
  padding: 20px 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ActiveGGcardToggle {
  border-radius: 10px;
  border: 1px solid #d1d5e0;
  background: #f9fafb;
  display: flex;
  padding: 12px 20px;
  width: 100%;
  justify-content: space-between;
  @include breakpoint(desktop) {
    width: 100%;
  }

  &__article {
    & h1 {
      color: #667085;
      font-weight: 500;
      font-size: 18px;
    }

    & p {
      color: #98a2b3;
      font-size: 13px;
    }
  }
}

.GGcardDisable {
  display: flex;
  gap: 10px;
  flex-direction: column;
  @include breakpoint(desktop) {
    align-items: center;
    justify-content: space-between;
    gap: 0px;
    flex-direction: row;
  }
}

.vestirateVc {
  display: none;
  @include breakpoint(desktop) {
    display: block;
  }
}
