@import '../../../variables.scss';

.stepback-container {
  @include rowflex(center, flex-start);
  margin-bottom: 2rem;
//   margin-top: 2rem;
  cursor: pointer;
  gap: 0.5rem;

  @include breakpoint(mobileonly) {
    // margin-top: 2rem;
    margin-bottom: 0;
  }

  .fas .fa-arrow-left {
    @include fontMedium(1.5em, 1.5em, $vesti-700);

    @include breakpoint(mobileonly) {
      @include fontMedium(1em, 1.5em, $vesti-700);
    }
  }
  p {
    margin: 0;
    @include fontMedium(1.5em, 1.5em, $vesti-700);

    @include breakpoint(mobileonly) {
      @include fontMedium(1em, 1.5em, $vesti-700);
    }
  }
}
