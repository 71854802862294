@import '../../../variables.scss';

.vestirate {
  color: #67a948;
  border-radius: 16px;
  background: #dfffd0;
  padding: 4px 16px;
  font-weight: 700;
  font-size: 12px;
}

@media (min-width: 900px) {
  .vestirate {
    font-size: 14px;
  }
}

@media (min-width: 1300px) {
  .vestirate {
    font-size: 16px;
  }
}

.newvestirate {
  color: #67a948;
  border-radius: 16px;
  background: white;
  padding: 4px 16px;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #67a948;
  z-index: 99;
  position: relative;
}
@media (min-width: 900px) {
  .newvestirate {
    font-size: 13px;
  }
}
