@import '../../../../../variables.scss';

.visaInvoiceNav {
  background: #3e6f26;
  padding: 0px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
}

.invoiceTable {
  padding: 40px;
}

.invoiceHeader {
  padding: 40px 40px 0px 40px;
  display: flex;
  justify-content: space-between;
}
