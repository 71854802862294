@import '../../../variables.scss';

.ovtestimony-container{
    width: 100%;
    @include colflex(center, center);
    background-color: $vestisec-902;
    padding: 1rem;
    
    .ovtestimony-container-inner{
        width: 88%;
        @include colflex(center, center);
        margin-top: 5rem;
        margin-bottom: 5rem;
        gap: 2rem;

        @include breakpoint(mobileonly){   
            width: 90%;
        }

        .ovtestimony-top{
            @include colflex(center, center);
            margin-bottom: 2rem;

            p:nth-child(1){
                @include fontSemiBold(2.5em, 1.5em, $gray-100);

                @include breakpoint(mobileonly){
                    @include fontSemiBold(1.3em, 1.5em, $gray-100);  
                    text-align: center;
                }
                span{
                    color: $vesti-700;
                }
            }
            p:nth-child(2){
                @include fontSemiBold(1.2em, 1em, $gray-100);

                @include breakpoint(mobileonly){
                    @include fontSemiBold(1em, 1.5em, $gray-100);
                    text-align: center;    
                }
            }

        }

        .ovtestimony-bottom{
            @include rowflex(center, space-between);
            flex-wrap: wrap;
            width: 100%;
            


            @include breakpoint(mobileonly){
                @include colflex(center, space-between);    
            }
            @include breakpoint(phablet){
                @include colflex(center, space-between);    
            }
            .userfeedbackbox{
                border: 1px solid grey;
                border-radius: 10px;
                @include colflexx(flex-start);
                margin-bottom: 1rem;
                padding: 2rem;
                width: 33%;    
                height: 345px;
                
                @include breakpoint(mobileonly){   
                    width: 100%;  
                    padding: 1rem;  
                    height: fit-content;
                }
                @include breakpoint(phablet){   
                    width: 100%;    
                }
    
                .avatar{
                    @include rowflex(center, center);
                    gap: 1rem;
                    .rounded-image {
                        display: block;
                        max-width: 100%;
                        height: 55px;
                        border-radius: 100%;
                        width: 55px;
                    }

                    .feedback-header{
                        h5:nth-child(1){
                            @include fontSemiBold(1em, 1em, $vesti-902);
                        }
                        h5:nth-child(2){
                            @include fontNormal(1em, 1em, $vesti-902);
                        }
                        h5:nth-child(3){
                            @include fontNormal(1em, 1em, $vesti-902);
                        }
                    }
    
                }
    
                .userfeedback{
                    // width: 36vw;
                    
                    p:nth-child(1){
                        @include fontNormal(1em, 1.5em, $vesti-902);
    
                    }
                }
            }
        }
        .__link{
            margin-top: 1.5rem;
            a{

                @include fontSemiBold(1em, 1em, $gray-100);
            }
        }
    }
}