@import '../../variables.scss';

.singlesaving {
  width: 100%;
  height: fit-content;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 20px;
  border: 1px solid $gray-300;
  transition: all 0.5s ease-out;
  @include colflex(center, center);
  position: relative;

  @include breakpoint(mobileonly) {
    width: fit-content;
    padding: 30px 40px 30px 20px;
  }

  &.--inactive {
    background-color: $yellow-100;
    border-color: $yellow-700;

    &:hover {
      filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
      border: 1px solid $yellow-700;
    }

    .singlesaving__left {
      p:nth-child(1) {
        color: $yellow-800;
      }

      height: fit-content;
      @include colflexx(flex-start);
      // @include rowflex(flex-start, center);
      gap: 20px;
    }

    .singlesaving__amount {

      p:nth-child(1),
      p:nth-child(3) {
        color: $yellow-900;

        strong {
          color: $yellow-700;
        }
      }

      p:nth-child(2) {
        color: $yellow-700;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    // width: 350px;
    width: 100%;
    height: 200px !important;
    padding: 10px;
  }

  &:hover {
    filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
    border: 1px solid $vesti-700;
  }

  &__inner {
    width: 100%;
    height: 80%;
    @include colflex(flex-start, space-between);

    img {
      width: auto;
      //   margin-right: 20px;

      @media only screen and (max-width: 600px) {
        width: 40px;
      }
    }
  }

  &__bottom {
    width: 100%;
    margin-top: 1rem;
    @include rowflex(center, space-between);

    .button-container {
      width: 100%;
      @include rowflex(center, flex-end);
      z-index: 10;

      p {
        @include rowflex(center, center);
        @include fontMedium(1rem, 1rem, $vesti-100);
        background-color: $vesti-700;
        border-radius: 10px;
        cursor: pointer;
        padding: 1rem;
      }
    }
  }

  &__right {
    @include colflexx(flex-start);

    p:nth-child(1) {
      margin: 0;
      @include fontMedium(1em, 1em, $gray-400);

      @media only screen and (max-width: 600px) {
        @include fontMedium(1em, 1em, $gray-400);
      }
    }

    :last-child {
      margin: 0;
      margin-top: 10px;
      color: $vesti-700;
      font-size: 1em;
    }
  }

  &__amount {
    height: fit-content;
    @include colflexx(flex-start);
    gap: 10px;

    p:nth-child(1) {
      @include fontBold(2em, 1em, $vesti-901);

      @media only screen and (max-width: 600px) {
        @include fontBold(2em, 1em, $vesti-901);
      }
    }
  }

  &__name {
    margin: 0;
    @include fontBold(1em, 1em, $vesti-901);
    @include colflex(flex-start, center);
    gap: 1rem;


    p:nth-child(1) {
      @include fontBold(1em, 1em, $vesti-901);

      @media only screen and (max-width: 600px) {
        @include fontBold(1.1em, 1em, $vesti-901);
      }
    }

    p:nth-child(2) {
      @include fontMedium(1em, 1em, $vesti-901);

      @media only screen and (max-width: 600px) {
        @include fontMedium(0.875rem, 1em, $vesti-901);
      }

      strong {
        color: $vesti-700;
      }
    }
  }

  &__top {
    margin-bottom: 1rem;
    width: 100%;
    @include rowflex(center, space-between);
  }

  &__enddate {
    margin-top: 30px;
    @include rowflexx(center);
    flex-wrap: nowrap;
    gap: 5px;

    img {
      width: auto;
    }

    p {
      margin: 0;
      @include fontNormal(1em, 1em, $gray-500 !important);

      @media only screen and (max-width: 600px) {
        @include fontNormal(1em, 1em, $gray-500 !important);
      }
    }
  }

  &__seemore {
    width: 100%;
    margin: 0;
    cursor: pointer;
    @include fontNormal(1em, 1em, $vesti-700 !important);

    @media only screen and (max-width: 600px) {
      @include fontNormal(1em, 1em, $vesti-700 !important);
    }
  }
}

.savingscard {
  background-color: #060e42;
  padding: 2rem;
  border-radius: 20px;
  width: 100%;

  @include breakpoint(mobileonly) {
    padding: 1rem;
  }

  .savingscard-inner {
    p:nth-child(1) {
      @include fontNormal(0.875rem, 1.25rem, white);
    }

    span {
      margin-top: 2rem;

      p:nth-child(1) {
        @include fontBold(2.5rem, 1em, $vesti-100);

        @include breakpoint(mobileonly) {
          @include fontBold(1.5rem, 1em, $vesti-100)
        }
      }

      .fas.fa-eye-slash {
        font-size: 30px;
        color: #ffffff;

        @include breakpoint(mobileonly) {
          font-size: 15px;
        }

      }

      .fas.fa-eye {
        font-size: 30px;
        color: #ffffff;

        @include breakpoint(mobileonly) {
          font-size: 15px;
        }
      }
    }

    p:nth-child(2) {
      @include fontBold(1.5rem, 2rem, null);

      @include breakpoint(mobileonly) {
        @include fontBold(1.5rem, 2rem, white);

      }

    }

  }

  img {

    @include breakpoint(mobileonly) {
      max-width: 30%;
    }

    @include breakpoint(phablet) {
      max-width: 30%;
    }
  }
}

.savingsyieldcard {
  background-color: #F7FFF3;
  padding: 2rem;
  border-radius: 20px;
  width: 100%;
  border: 1px solid #B0FF8B;

  .yieldinner {

    .yield {
      margin-top: 1.25rem;

      @include breakpoint(mobileonly) {
        margin-top: 1.25rem;

      }

      @include breakpoint(phablet) {
        margin-top: 1.25rem;

      }

      p:nth-child(1) {
        @include fontNormal(1rem, 1em, $vesti-902)
      }

      span {
        margin-top: 2rem;

        p:nth-child(1) {
          @include fontBold(2.5rem, 1em, $vesti-700);

          @include breakpoint(mobileonly) {
            @include fontBold(1.5rem, 1em, $vesti-700)
          }

          @include breakpoint(phablet) {
            @include fontBold(2rem, 1em, $vesti-700)
          }
        }

        .fas.fa-eye-slash {
          font-size: 30px;
          color: #67a948;

          @include breakpoint(mobileonly) {
            font-size: 15px;
          }

          @include breakpoint(phablet) {
            font-size: 20px;
          }

        }

        .fas.fa-eye {
          font-size: 30px;
          color: #67a948;

          @include breakpoint(mobileonly) {
            font-size: 15px;
          }

          @include breakpoint(phablet) {
            font-size: 20px;
          }
        }

        @include breakpoint(phablet) {
          marging-top: 1rem !important;

        }
      }
    }

    img {

      @include breakpoint(mobileonly) {
        max-width: 30%;
      }
    }

  }

  img {

    @include breakpoint(mobileonly) {
      max-width: 30%;
    }

    @include breakpoint(phablet) {
      max-width: 30%;
    }
  }

}

.savingstypecard {
  background-color: #F7FFF3;
  padding: 2rem;
  border-radius: 20px;
  width: 100%;

  @include breakpoint(mobileonly) {
    padding: 1rem;

  }

  .typeinner {

    .titles {

      p:nth-child(1) {
        @include fontBold(1.5rem, 1em, #14290A)
      }

      p:nth-child(2) {
        @include fontNormal(1rem, 1.5em, null)
      }
    }



    span {
      cursor: pointer;
      margin-top: 2rem;
      @include rowflex(center, flex-start);

      .fas .fa-arrow-right {
        font-size: 30px;
        // color: #67a948;
      }
    }
  }

  .smalltypeinner {
    @include rowflex(center, flex-start);
    gap: 1rem;

    img {

      @media screen and (max-width: 380px) {
        display: none;
      }
    }

    .titles {

      p:nth-child(1) {
        @include fontBold(1.2rem, 1em, #14290A);

        @include breakpoint(mobileonly) {
          @include fontBold(1rem, 1em, #14290A)
        }
      }

      p:nth-child(2) {
        @include fontNormal(1rem, 1.5em, null);

        @include breakpoint(mobileonly) {
          @include fontBold(0.8rem, 1em, #14290A)
        }
      }
    }

    span {
      cursor: pointer;
      margin-top: 1rem;
      @include rowflexx(flex-end);
      gap: 10x;

      .fas .fa-arrow-right {
        font-size: 30px;
        // color: #67a948;
      }

    }
  }

}


.savingstabcomponent {
  width: 100%;
  padding: 1rem;
  margin-top: 3rem;
  @include colflex(flex-start, center);

  @include breakpoint (mobileonly) {
    padding: 0.5rem;
    margin-top: 0;
  }

  .tabbutton {
    @include rowflex(center, center);

    @include breakpoint (mobileonly) {
      font-size: 12px !important;
    }
  }

  .tab-content {
    width: 100%;

    .button {
      @include rowflex(center, center);
      min-width: 110px;
      font-size: 15px;

    }
  }

}