@import '../../../variables.scss';

.transreview {
  width: 100%;
  height: fit-content;
  background-color: $vesti-100;
  border: 0.5px solid $vesti-700;
  border-radius: 10px;
  padding: 30px;
  @include colflexx(flex-start);
  gap: 15px;

  @include breakpoint(mobileonly) {
    padding: 10px;
  }

  &__single:not(:last-child) {
    // background-color: red;
    padding: 10px 0px;
    border-bottom: 1px dashed $vesti-700;
  }
  &__single:last-child {
    margin-top: 10px;
  }
  &__single {
    width: 100%;
    // height: 25px;
    @include rowflex(flex-end, space-between);

    p:nth-child(1) {
      width: 100%;
      text-align: left;
      text-transform: uppercase;
      @include fontNormal(1em, 1em, $vesti-901);

      @include breakpoint(mobileonly) {
        @include fontNormal(0.8em, 1em, $vesti-901);
      }
    }
    p:nth-child(2) {
      width: 100%;
      text-align: right;
      text-transform: uppercase;
      @include fontBold(1em, 1em, $vesti-700);

      @include breakpoint(mobileonly) {
        @include fontNormal(0.8em, 1em, $vesti-901);
      }
    }
  }
}
