@import "../../../variables.scss";

.marquee{
  width: 30vw;
  height: 50vh;
  background-color: #ffffff;
  overflow: hidden;
  // margin-right: 30px;
  // margin-bottom: 5rem;
}
.marquee-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -moz-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -moz-animation: scroll-up 15s linear infinite;
  -webkit-animation: scroll-up 15s linear infinite;
  animation: scroll-up 15s linear infinite;
}

.marquee-content:hover {
  -moz-animation-play-state: paused;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

/* for Mozilla */
@-moz-keyframes scroll-up {
  from { -moz-transform: translateY(100%); }
  to { -moz-transform: translateY(-100%); }
}

/* for Chrome */
@-webkit-keyframes scroll-up {
  from { -webkit-transform: translateY(100%); }
  to { -webkit-transform: translateY(-100%); }
}

@keyframes scroll-up {
  from {
    -moz-transform: translateY(100%);
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  to {
    -moz-transform: translateY(-100%);
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
.marquee .marquee-content .userfeedbackbox {
  font-size: smaller;
  width: 100%;
  padding: 1rem 1rem;
  margin-left: 1rem;
  background-color: #FAFAFA;
  border-radius: 1rem;
}

.userfeedbackbox{
  grid-gap: 1rem;
}
.Feedback-section{
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.feedbacktext{
  width: 30vw;
  
}

.feedback-text {
  width: 40vw;

  p:nth-child(1){
    margin: 0;
    @include fontBold(1.4em, 1em, $vesti-902);
  }
  p:nth-child(2){
    margin-top: 5px;
    @include fontNormal(1em, 1em, $gray-500);
  }

  p:nth-child(3){
    margin-top: 5px;
    @include fontNormal(1.2em, 1.7em, $vesti-901);
  }
}

.feedback {
  // padding-top: 2rem;
  // width: 50%;
  p:nth-child(1){
    margin: 0;
    @include fontBold(2.5em, 1em, $vesti-902);
  }
  p:nth-child(2){
    margin-top: 10px;
    @include fontNormal(1.5em, 1.7em, $vesti-901);
  }
  .feedback-btn {
    width: 100px;
    height: 60px;
    background-color: $vesti-700;
    transition: all .5s ease;
    @include rowflex(center,center);
    @include fontNormal(1em, 1em, #fff);

    &:hover {
      background-color: $vesti-900;
    }
  }
}
@media only screen and (min-width: 320px) and (max-width: 600px){
  .marquee{
    width: 100%;
    height: 55vh;
    background-color: #ffffff;
    overflow: hidden;
    @include colflexx(center);
    // margin-right: 30px;
  }
  .marquee-content{
    @include colflexx(center);

    width: fit-content;
  }
  .marquee .marquee-content .userfeedbackbox {
    padding: 1rem 1rem;
    margin-left: 2rem;
    background-color: #FAFAFA;
    border-radius: 1rem;
    width: 90%;
  }
  .Feedback-section{
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;

  }
  .feedbacktext{
    width: 100%;

  }
  
  .feedback {
    padding-top: 2rem;
    width: 80%;
    height: fit-content;
    margin-bottom: 50px;

    @include colflexx(center);
    text-align: center !important;

    p:nth-child(1){
      margin: 0;
      text-align: center;
      @include fontBold(1.2em, 1.5em, $vesti-902);
    }
    p:nth-child(2){
      margin-top: 10px;
      text-align: center !important;
      @include fontNormal(1em, 1.7em, $vesti-901);
    }
    .feedback-btn {
      width: 100px;
      height: 50px;
      margin: 15px 80px;
      background-color: $vesti-700;
      transition: all .5s ease;
      @include rowflex(center,center);
      @include fontNormal(1em, 1em, #fff);
  
      &:hover {
        background-color: $vesti-900;
      }
    }
  }

  .feedback-text {
    width: 95%;
    
  
    p:nth-child(1){
      margin: 0;
      @include fontBold(1.4em, 1em, $vesti-902);
    }
    p:nth-child(2){
      margin-top: 5px;
      @include fontNormal(1em, 1.5em, $gray-500);
    }
  
    p:nth-child(3){
      margin-top: 5px;
      @include fontNormal(1.2em, 1.7em, $vesti-901);
    }
  }
  
}
@media only screen and (min-width: 600px) and (max-width: 800px){
  .marquee{
    width: 95vw;
    height: 40vh;
    background-color: #ffffff;
    overflow: hidden;
    margin-right: 30px;
  }
  .marquee .marquee-content .userfeedbackbox {
    padding: 1rem 1rem;
    margin-left: 2rem;
    background-color: #FAFAFA;
    border-radius: 1rem;
    width: 90%;
  }
  .Feedback-section{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
  .feedbacktext{
    width: 90vw;
  }
  
  .feedback {    
    width: 100%;
    text-align: -webkit-center;

  
    p:nth-child(1){
      margin: 0;
      text-align: center;
      @include fontBold(1.5em, 1em, $vesti-902);
    }
    p:nth-child(2){
      margin-top: 10px;
      @include fontNormal(1.3em, 1.4em, $vesti-901);
    }
    .feedback-btn {
      width: 100px;
      height: 50px;
      margin-bottom: 15px;
      margin-left: 35px;
      background-color: $vesti-700;
      transition: all .5s ease;
      @include rowflex(center,center);
      @include fontNormal(1em, 1em, #fff);
  
      &:hover {
        background-color: $vesti-900;
      }
    }
  }

  .feedback-text {
    width: 100%;
  
    p:nth-child(1){
      margin: 0;
      @include fontBold(1.4em, 1em, $vesti-902);
    }
    p:nth-child(2){
      margin-top: 5px;
      @include fontNormal(1em, 1em, $gray-500);
    }
  
    p:nth-child(3){
      margin-top: 5px;
      @include fontNormal(1.2em, 1.7em, $vesti-901);
    }
  }
}
@media (min-width: 800px) and (max-width: 1224px){
  .marquee{
    width: 95vw;
    height: 40vh;
    background-color: #ffffff;
    overflow: hidden;
    margin-right: 30px;
  }
  .marquee .marquee-content .userfeedbackbox {
    padding: 1rem 1rem;
    margin-left: 2rem;
    background-color: #FAFAFA;
    border-radius: 1rem;
    width: 90%;
  }
  .Feedback-section{
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-around;
    align-items: flex-start;
  }
  .feedbacktext{
    width: 90vw;
  }
  
  .feedback {    
    width: 100%;
    text-align: -webkit-center;

  
    p:nth-child(1){
      margin: 0;
      text-align: center;
      @include fontBold(1.5em, 1em, $vesti-902);
    }
    p:nth-child(2){
      margin-top: 10px;
      @include fontNormal(1.3em, 1.4em, $vesti-901);
    }
    .feedback-btn {
      width: 100px;
      height: 50px;
      margin-bottom: 15px;
      margin-left: 35px;
      background-color: $vesti-700;
      transition: all .5s ease;
      @include rowflex(center,center);
      @include fontNormal(1em, 1em, #fff);
  
      &:hover {
        background-color: $vesti-900;
      }
    }
  }

  .feedback-text {
    width: 100%;
  
    p:nth-child(1){
      margin: 0;
      @include fontBold(1.4em, 1em, $vesti-902);
    }
    p:nth-child(2){
      margin-top: 5px;
      @include fontNormal(1em, 1em, $gray-500);
    }
  
    p:nth-child(3){
      margin-top: 5px;
      @include fontNormal(1.2em, 1.7em, $vesti-901);
    }
  } 
}



