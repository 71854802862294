@import '../../../../variables.scss';

.visaSmallBox {
  width: 100%;
  border: 0.8px solid #d0d5dd;
  border-radius: 10px;
  padding: 10px 20px;
}

.visatypecard {
  height: 13rem;
}

@media (min-width: 800px) {
  .visatypecard {
    height: 14rem;
  }
}

.mainvisaTypesSection {
  padding: 10px 20px;

  @include breakpoint(tablet) {
    padding: 0px 0px;
  }
  @include breakpoint(desktop) {
    padding: 0px 0px;
  }
}

.newmainvisaTypesSection {
  padding: 40px 20px;

  @include breakpoint(desktop) {
    padding: 40px;
  }
}

.visaMainFromSection {
  padding: 10px 20px;
  height: 85svh;
  overflow: auto;
  // @include breakpoint(mobileonly) {
  //   overflow: auto;
  //   height: 95svh;
  // }
  @include breakpoint(tablet) {
    padding: 10px 20px;
    height: 95svh;
  }
  @include breakpoint(desktop) {
    padding: 0px 0px;
    height: 85svh;
  }
}

.VisainputContainer {
  width: 40%;
  @include breakpoint(mobileonly) {
    width: 100%;
  }
}

.visaTypes {
  padding-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  @include breakpoint(mobileonly) {
    grid-template-columns: 1fr;
  }
  @include breakpoint(phablet) {
    grid-template-columns: 1fr 1fr;
  }
}

.visaMobilewanrning {
  margin-top: 13rem;

  @include breakpoint(desktop) {
    display: none;
  }
}

.visaMobilewanrningbtn {
  @include breakpoint(desktop) {
    display: none;
  }
}

.mobilehidden {
  // display: none;
  @include breakpoint(desktop) {
    display: block;
  }
}

.VisarequirementsArticle {
  //  marginTop: 14rem

  margin-top: 13rem;
  // display: none;
  @include breakpoint(mobileonly) {
    margin-top: 13rem;
  }
  @include breakpoint(desktop) {
    display: block;
  }
}

@keyframes smoothLoader {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.VisaFormMainPage {
  margin-top: 2rem;
  display: flex;
  gap: 20px;
  width: 100%;
  flex-direction: column;
  // height: 90%;
  // overflow-y: auto;
  animation: smoothLoader 5s ease-in-out forwards;

  @include breakpoint(desktop) {
    flex-direction: row;
    overflow-y: auto;
  }

  &__formsection {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    /* Customize scrollbar */
    &::-webkit-scrollbar {
      width: 10px; /* Adjust width as needed */
    }

    &::-webkit-scrollbar-thumb {
      background-color: green; /* Set scrollbar thumb color */
    }

    &::-webkit-scrollbar-track {
      background-color: #eeffe6; /* Optional: Set track color */
    }

    @include breakpoint(desktop) {
      width: 60%;
      height: 600px;
      overflow-y: auto;

      /* Customize scrollbar */
      &::-webkit-scrollbar {
        width: 5px; /* Adjust width as needed */
      }

      &::-webkit-scrollbar-thumb {
        background-color: green; /* Set scrollbar thumb color */
      }

      &::-webkit-scrollbar-track {
        background-color: #efffe8; /* Optional: Set track color */
      }
    }
  }

  &__quickreadSubsection {
    height: fit-content !important;
    overflow-y: auto;

    /* Customize scrollbar */
    &::-webkit-scrollbar {
      width: 5px; /* Adjust width as needed */
    }

    &::-webkit-scrollbar-thumb {
      background-color: green; /* Set scrollbar thumb color */
    }

    &::-webkit-scrollbar-track {
      background-color: #efffe8; /* Optional: Set track color */
    }

    @include breakpoint(desktop) {
      width: 40%;
      height: 600px !important;
      overflow-y: auto;

      /* Customize scrollbar */
      &::-webkit-scrollbar {
        width: 5px; /* Adjust width as needed */
      }

      &::-webkit-scrollbar-thumb {
        background-color: green; /* Set scrollbar thumb color */
      }

      &::-webkit-scrollbar-track {
        background-color: #efffe8; /* Optional: Set track color */
      }
    }
    &__sub {
      width: 100%;
      background: #f7fff3;
      border: 0.8px solid #d0d5dd;
      border-radius: 10px;
      display: none;
      @include breakpoint(desktop) {
        // width: 40%;
        display: block;
      }
      &__article {
        background: url('../../../../assets/viasForm/Pattern.svg');
        width: 100%;
        height: 100%;
        padding: 20px;
      }
    }
  }

  &__quickreadsectionMobile {
    width: 100%;
    background: #f7fff3;
    border: 0.8px solid #d0d5dd;
    border-radius: 10px;
    height: 100%;
    @include breakpoint(desktop) {
      display: none;
    }

    &__article {
      background: url('../../../../assets/viasForm/Pattern.svg');
      width: 100%;
      height: 100%;
      padding: 20px;
    }
  }
}

//// payment
.visaPaymentBankGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 40px 0px;

  &__inactive {
    border-radius: 5px;
    border: 1px dashed #936710;
    background: #fff9f0;
    padding: 10px;
    text-align: center;
    font-style: 500;
    cursor: pointer;
  }

  &__active {
    border-radius: 5px;
    border: 0.5px dashed #67a948;
    background: #f7fff3;
    padding: 10px;
    text-align: center;
    font-style: 500;
    cursor: pointer;
  }
}

.rightSectionRequirement {
  display: none;
  @include breakpoint(desktop) {
    display: block;
    // height: 100;
  }
}

.rightSectionRequirementmobile {
  @include breakpoint(desktop) {
    display: none;
  }
}

.flexButton {
  width: 100%;
}

.Visaflexbutton {
  @include breakpoint(mobileonly) {
    flex-direction: column;
  }
}

.uploadSuccessPage {
  box-shadow: 0px 20px 15px -10px #ebebeb;
  border: 1px solid #ebebeb;
  /* Adjust the values as needed */
}

.eligibleContainer {
  width: 100%;
  display: flex;

  @include breakpoint(mobileonly) {
    flex-direction: column;
    width: 100%;
    align-items: left;
    gap: 10px;
  }
  @include breakpoint(desktop) {
    justify-content: space-between;
    align-items: center;
  }

  &__box {
    width: 100%;
    height: 5rem;
    background: url('../../../../assets/viasForm/EligibilityTest/newEligibleBox.png')
      no-repeat;
    display: flex;
    align-items: center;
    // flex-direction: column;
    // justify-content: center;
    padding: 0px 20px;
    border-radius: 8px;

    @include breakpoint(mobileonly) {
      width: 100%;
      border-radius: 8px;
    }
  }
}

.sideH1bstage2 {
  width: 100%;
  @include breakpoint(desktop) {
    width: 40%;
  }
}

.zindex {
  z-index: 10;
}

.eligShare {
  width: 90%;
}
