@import '../../../../../variables.scss';

.somePd {
  @include breakpoint(mobileonly) {
    padding: 0px 20px;
  }
}
.VisaChatFeature {
  display: flex;
  width: 100%;
  gap: 30px;
  height: 85%;
  //   border: 2px solid red;

  @include breakpoint(mobileonly) {
    flex-direction: column;
  }

  &__foldersection {
    width: 30%;
    @include breakpoint(mobileonly) {
      display: none;
    }
  }

  &__chatsection {
    width: 70%;
    // border: 2px solid red;
    height: 100%;
    @include breakpoint(mobileonly) {
      width: 100%;
    }
  }

  &__chatshist {
    // width: 100%;
    // height: 70%;
    // margin-top: 20px;
    width: 100%;
    height: 100%;
    background: url('../../../../../assets/viasForm/chats/Elements.png') center;
    padding: 20px 20px 0px 20px;
    position: relative;
    // overflow-y: auto;

    &__scrollbar {
      overflow-y: scroll;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none;
    }

    &__scrollbar::-webkit-scrollbar {
      display: none; /* For Chrome, Safari, and Opera */
    }

    &__QA {
      display: flex;
      justify-content: right;
      margin-bottom: 20px;
      flex-direction: column;
      align-items: flex-end;
    }
    &__QAContainer {
      background-color: #f7fff3;
      padding: 10px;
      border: 0.5px solid #97e373;
      border-radius: 6px;
      color: #111827;
      font-size: 13px;
      width: fit-content;
      max-width: 75%;
    }

    &__Ans {
      display: flex;
      justify-content: left;
      margin-bottom: 20px;
      flex-direction: column;
      align-items: flex-start;
    }

    &__Bsreply {
      visibility: hidden;
    }

    &__Ans:hover &__Bsreply {
      visibility: visible;
      cursor: pointer;
    }
    &__AnsContainer {
      background-color: #f9fafb;
      padding: 10px;
      border: 0.5px solid #d0d5dd;
      border-radius: 6px;
      color: #111827;
      font-size: 13px;
      width: fit-content;
      max-width: 75%;
    }

    &__replyRef {
      background-color: #f9fafb;
      padding: 10px;
      border: 0.5px solid #d0d5dd;
      border-radius: 6px;
      color: #111827;
      font-size: 11px;
      width: fit-content;
      max-width: 100%;
      margin-bottom: 8px;
    }
  }
}
