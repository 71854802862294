@import "../../../variables.scss";


.corporate-container {
  border-radius: 0px !important;
  padding: 30px 0px 30px 0px;
  padding-top: 50px;
  background-color: #F8FEF5;
  @include colflex(center,center);
  width: 100% !important;
  height: 300px;
  

  .corporate-inner {
    width: 90%;
    height: 90%;
    @include rowflex(center,center);

    p:nth-child(1){
      margin: 0;
      width: 40%;
      text-align: center;
      @include fontSemiBold(2em, 1em, $vesti-902);
    }
  }
  .entities{
    margin-top: 2rem; 
    @include rowflex(center,center);

    img {
      width:250px
    }
  }

}


@media screen and (min-width: 320px) and (max-width: 600px) {
  .corporate-container {
    border-radius: 0px !important;
    padding: 20px 0px 20px 0px !important;
    background-color: #F8FEF5;
    @include colflex(center,center);
    width: 100% !important;
    height: 100%;

    .corporate-inner {
      width: 90%;
      height: 90%;
      @include colflex(center,center);

      p:nth-child(1){
        margin: 0;
        width: 80%;
        @include fontMedium(1.3em, 1.4em, $vesti-901);
      }
    }
    .entities{
        margin-top: 2rem;
        @include colflex(center,center); 
      img:not(:first-child){
        margin-top: 20px;
      }
        img {
          width:150px
        }
    }
    
  }
}
@media screen and (min-width: 600px) and (max-width: 800px) {
  .corporate-container {
    border-radius: 0px !important;
    padding: 20px 0px 20px 0px !important;
    background-color: #F8FEF5;
    @include colflex(center,center);
    width: 100% !important;
    height: 100%;

    .corporate-inner {
      width: 90%;
      height: 90%;
      @include colflex(center,center);

      p:nth-child(1){
        margin: 0;
        width: 70%;
        @include fontMedium(1.5em, 1em, $vesti-902);
      }
    }
    .entities{
        margin-top: 2rem;
        @include rowflex(center,center); 

        img {
          width:150px
        }
    }
  }
}
@media screen and (min-width: 800px) and (max-width: 1224px) {
  .corporate-container {
    border-radius: 0px !important;
    padding: 30px 0px 30px 0px !important;
    background-color: #F8FEF5;
    @include colflex(center,center);
    width: 100% !important;
    height: 100%;

    .corporate-inner {
      width: 90%;
      height: 90%;
      @include colflex(center,center);

      p:nth-child(1){
        margin: 0;
        width: 70%;
        @include fontMedium(2.25em, 1em, $vesti-902);
      }
    }
    .entities{
        margin-top: 2rem;
        @include rowflex(center,center); 

        // img{
        //     padding: 5px;
        //     width: 50%;
        // }
    }
  }
}

