@import '../../../variables.scss';

.statusBox {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 20px;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  @include breakpoint(desktop) {
    width: 40%;
  }

  &__article {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.caseStatusPage {
  @include breakpoint(mobileonly) {
    padding: 0px 40px;
  }
}

.caseStudies {
  width: 50%;
  margin: 1rem auto;
  @include breakpoint(mobileonly) {
    width: 100%;
  }
}
// p-2 border-[0.5px] rounded-md  w-full outline-none focus:bg-[#FCFFF9] focus:border-vestigreen
.usInputField {
  padding: 4px;
  width: 100%;
  outline: none;
  background-color: transparent;
}

// .usInputField:focus {
// }
