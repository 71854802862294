@import "../../variables.scss";

.verification {
    width: 100%;
    height: 100vh;
    @include rowflex(center,center);

    &--inner {
        width: fit-content;
    }
}