@import '../../../variables.scss';

.Profilemembership {
  &__title {
    font-size: 24px;
    font-weight: 600;
  }
  &__plan {
    background-color: #f7fff3;
    border: 2px solid rgb(0, 128, 0, 0.2);
    padding: 20px;
    margin-top: 20px;
    border-radius: 12px;
    cursor: pointer;

    &__cancelComponent {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;

    &__col {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    & span {
      color: $vesti-700;
      margin-left: 10px;
    }

    &__planDetails {
      color: $vesti-700;
      font-size: 14px;
      display: flex;
      gap: 5px;
      cursor: pointer;
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
    }
    @include breakpoint(mobileonly) {
      &__planDetails {
        font-size: 12px;
      }

      &__title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  // Bottom Part

  &__bottom {
    display: flex;
    align-items: center;
    gap: 15px;

    &__subtitle {
      font-size: 16px;
      font-weight: 400;
      color: rgb(93, 95, 101);
    }

    & article {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }

    & span {
      font-weight: 500;
      font-size: 14px;
      color: '#070E42';
    }

    &__title {
      font-size: 18px;
      font-weight: 600;
    }

    @include breakpoint(mobileonly) {
      flex-direction: column;

      // @include colflexx(flex-start);

      & img {
        display: none;
      }

      &__subtitle {
        font-size: 13px;
      }

      &__title {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  &__subscribe {
    background-color: $vesti-700;
    padding: 10px;
    color: #fff;
    border-radius: 10px;
    font-weight: 500;
  }

  @include breakpoint(mobileonly) {
    &__subscribe {
      font-size: 14px;
    }

    &__planDetails {
      font-size: 12px;
    }
  }

  &__cancel {
    background-color: black;
    padding: 10px 20px;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    @include breakpoint(mobileonly) {
      padding: 10px;
      font-size: 12px;
      width: 50%;
      text-align: center;
    }
    @include breakpoint(phablet) {
      padding: 10px;
      font-size: 14px;
      width: 40%;
      text-align: center;
    }
  }
}
