@import '../../../variables.scss';

.wallet-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  /* 4-4-2 portions */
  gap: 20px;
  /* Adjust the gap between grid items as needed */
  // overflow-x: auto;

  @include breakpoint(mobileonly) {
    margin-bottom: 30px;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    // color: #5ec6d2;
  }

  @include breakpoint(phablet) {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  @include breakpoint(tablet) {
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
  }

  .wallets {
    // grid-column: span 5;
    grid-column: span 12;
    position: relative;
    gap: 1rem;

    @include breakpoint(mobileonly) {
      grid-column: span 1;
    }

    // @include breakpoint(phablet) {
    //   grid-column: span 1;
    // }

    @include breakpoint(tablet) {
      grid-column: span 1;
    }

    &__row {
      width: 100%;
      height: 100%;
      @include rowflex(center, space-between);
      gap: 1rem;

      @include breakpoint(mobileonly) {
        @include colflex(center, space-between);
      }
    }

    &__rows {
      width: 100%;
      height: 100%;
      @include rowflex(center, space-between);
      gap: 1rem;

      @include breakpoint(mobileonly) {
        @include colflex(center, space-between);
      }

      @include breakpoint(phablet) {
        width: 50%;
        @include colflex(center, space-between);
      }

      @include breakpoint(tablet) {
        width: 100%;
        @include rowflex(center, space-between);
      }

      // @include breakpoint(desktop) {
      //   width: 65%;
      //   @include colflex(center, space-between);
      // }
    }

    *.wallet {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      @include colflexx(flex-start);
      gap: 34px;
      border-radius: 20px !important;
      padding: 30px;

      &.--Founders,
      &.--Three {
        // @include wallets($vpurp-901, $vpurp-800, $vpurp-300, $vpurp-901);
        @include wallets($yellow-900, $yellow-800, $yellow-300, $yellow-900);
      }

      &.--Naira,
      &.--One {
        @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
        // @include wallets($green-900, $green-800, $green-300, $green-900);
      }

      &.--Usd,
      &.--Two {
        @include wallets(#024750, #9cf4ff, #9cf4ff, #024750);
      }

      &.--Cedis,
      &.--Four {
        @include wallets($yellow-900, $yellow-800, $yellow-300, $yellow-900);
        // @include wallets(255, 231, 159);
      }

      &.--Kwacha {
        @include wallets($green-900, $green-800, $green-300, $green-900);
        // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
      }

      &.--ZMW {
        @include wallets($green-900, $green-800, $green-300, $green-900);
        // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
      }

      &.--Pounds {
        @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
        // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
      }

      &.--GBP {
        @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
        // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
      }

      &.--safelock {
        @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
        // background-color: $vestisec-902;
      }

      &.--analyticscard {
        background-color: #024750;
      }

      @include wallets($green-900, $green-800, $green-300, $green-900);

      @include breakpoint(mobileonly) {
        width: 100%;
        gap: 35px;
        padding: 20px 22px;
        // height: 30vh;
      }

      @include breakpoint(phablet) {
        width: 100%;
        padding: 16px 20px;
        gap: 23px;
      }

      &__details {

        // position: absolute;
        // bottom: 0rem;
        &.--more {
          p {
            color: white;
          }

          i {
            transform: rotate(45deg);
          }
        }
      }
    }

    @media (min-width: '1700px') {
      .wallet__details {
        position: absolute;
        bottom: 2rem;
      }
    }

    .wallet-image {
      position: absolute;
      top: 0px;
      right: 0%;
      width: 100px;

      @include breakpoint(mobileonly) {
        width: 60px;
        right: 0px;
      }

      @include breakpoint(phablet) {
        right: 0%;
        // width: 60px;
      }

      @include breakpoint(tablet) {
        right: 0%;
        // width: 60px;
      }

      @include breakpoint(desktop) {
        // width: 70px;
      }
    }

    &__boxes {
      bottom: -30px;
    }
  }

  .commingsoon img {
    width: 100% !important;
    // max-height: 100px;
  }

  .commingsoon {
    grid-column: span 4;
    overflow: scroll;
    // overflow: hidden;
    position: relative;

    .img {
      width: 100% !important;
    }

    @include breakpoint(mobileonly) {
      display: none;
    }

    @include breakpoint(phablet) {
      grid-column: span 1;
    }

    @include breakpoint(tablet) {
      grid-column: span 1;
    }
  }

  .savings {
    grid-column: span 5;

    background-color: $vpurp-901;
    border-radius: 20px;

    p {
      text-align: center;
      @include fontMain(1.2em, 1.5em, $vesti-100);
      padding: 30px;
    }

    @include breakpoint(mobileonly) {
      display: none;
    }

    @include breakpoint(phablet) {
      display: none;
    }

    @include breakpoint(tablet) {
      display: none;
    }
  }
}

.wallets {
  grid-column: span 5;
  position: relative;
  gap: 1rem;

  @include breakpoint(mobileonly) {
    grid-column: span 1;
  }

  // @include breakpoint(phablet) {
  //   grid-column: span 12 !important;
  // }

  @include breakpoint(tablet) {
    grid-column: span 1;
  }

  &__row {
    width: 100%;
    height: 100%;
    @include rowflex(center, space-between);
  }

  &__rows {
    width: 100%;
    height: 100%;
    @include rowflex(center, space-between);
    gap: 1rem;

    @include breakpoint(mobileonly) {
      @include colflex(center, space-between);
    }

    @include breakpoint(phablet) {
      width: 50%;
      @include colflex(center, space-between);
    }

    @include breakpoint(tablet) {
      width: 100%;
      @include rowflex(center, space-between);
    }

    // @include breakpoint(desktop) {
    //   width: 65%;
    //   @include colflex(center, space-between);
    // }
  }

  *.wallet {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    @include colflexx(flex-start);
    gap: 34px;
    border-radius: 20px !important;
    padding: 30px;

    &.--Founders,
    &.--Three {
      // @include wallets($vpurp-901, $vpurp-800, $vpurp-300, $vpurp-901);
      @include wallets($yellow-900, $yellow-800, $yellow-300, $yellow-900);
    }

    &.--Naira,
    &.--One {
      @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
      // @include wallets($green-900, $green-800, $green-300, $green-900);
    }

    &.--Usd,
    &.--Two {
      @include wallets(#024750, #9cf4ff, #9cf4ff, #024750);
    }

    &.--Cedis,
    &.--Four {
      @include wallets($yellow-900, $yellow-800, $yellow-300, $yellow-900);
      // @include wallets(255, 231, 159);
    }

    &.--Kwacha {
      @include wallets($green-900, $green-800, $green-300, $green-900);
      // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
    }

    &.--ZMW {
      @include wallets($green-900, $green-800, $green-300, $green-900);
      // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
    }

    &.--Pounds {
      @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
      // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
    }

    &.--GBP {
      @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
      // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
    }

    &.--safelock {
      // @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
      background-color: $vestisec-902;

      @include breakpoint(mobileonly) {
        gap: 13px;
      }
    }

    &.--analyticscard {
      background-color: #024750;
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;

      @include breakpoint(mobileonly) {
        display: none;
      }

      @include breakpoint(phablet) {
        display: none;
      }


    }

    @include wallets($green-900, $green-800, $green-300, $green-900);

    @include breakpoint(mobileonly) {
      width: 100%;
      gap: 35px;
      padding: 20px 22px;
    }

    @include breakpoint(phablet) {
      width: 100%;
      padding: 16px 20px;
      gap: 23px;
    }

    &__details {
      &.--more {
        i {
          transform: rotate(45deg);
        }
      }
    }

    &__savingsinterest {
      // background-color: $vestisec-901;
      // padding: 5px;
      // border-radius: 25px;
      font-style: italic;
      @include fontSemiBold(0.8rem, 1.5, $vesti-100)
    }

    &__graphs {
      @include rowflex(center, center);
      gap: 1rem;
      padding: 1rem;

      img {
        // width: 100%;

        @include breakpoint(tablet) {
          width: 35%;
        }
      }
    }
  }

  .wallet-image {
    position: absolute;
    top: 0px;
    right: 0%;
    width: 100px;

    @include breakpoint(mobileonly) {
      width: 60px;
      right: 0px;
    }

    @include breakpoint(phablet) {
      right: 0%;
      // width: 60px;
    }

    @include breakpoint(tablet) {
      right: 0%;
      // width: 60px;
    }

    @include breakpoint(desktop) {
      // width: 70px;
    }
  }

  &__boxes {
    bottom: -30px;
  }
}

@media only screen and (max-width: 468px) {
  .notif_cont {
    width: 50px !important;
    height: 50px !important;
  }
}



// @media only screen and (min-width: 800px) and (max-width: 1256px) {
//   // .wallets .wallet {
//   //   height: 240px !important;
//   // }

//   .wallets *.wallet .wallet__top {
//     margin-top: -22px !important;


//   }
// }


@media only screen and (max-width: 1240px) {
  .spend_analytics {
    display: none;
  }
}

* {
  box-sizing: border-box !important;
}

.wcarousel-nav *.flickity-viewport {
  height: fit-content;
  width: 100%;
  @include rowflexx(flex-start);
  flex-wrap: nowrap !important;
  // gap: 20px !important;
  border-radius: 20px !important;
  // background-color: red !important;
}

.wcarousel-nav {
  width: 100%;
  // background-color: rebeccapurple;
  position: relative;
  @include rowflexx(flex-start);

  // overflow: hidden;
  @include breakpoint(mobileonly) {
    width: 100%;
  }

  @include breakpoint(phablet) {
    // width: 85vw;
    width: 100%;
  }

  @include breakpoint(tablet) {
    width: 100%;
  }
}

.wcarousel-nav *.flickity-slider {
  margin-left: 0px;
  width: 100%;
}

.wcarousel-nav *.flickity-cell {
  // width: 450px;
  // width: calc(50% - 20px + 5px);
  // width: calc(100% / 3);
  // width:fit-content;
  margin-right: 25px;
  margin-left: -5px;
  border-radius: 5px;
  counter-increment: carousel-cell;

  @include breakpoint(mobileonly) {
    width: 90vw !important;
  }
}

.wcarousel-nav .flickity-cell {
  width: 33%;

  @include breakpoint(mobileonly) {
    width: 70vw !important;
  }

  @include breakpoint(phablet) {
    width: 60vw !important;
  }

  @include breakpoint(tablet) {
    width: 45% !important;
  }

  @include breakpoint(laptop) {
    width: 40% !important;
  }
}

.wcarousel-nav *.flickity-cell:first-child:nth-last-child(2),
.wcarousel-nav *.flickity-cell:first-child:nth-last-child(2)~*.flickity-cell {
  width: 48%;

  @include breakpoint(mobileonly) {
    width: 70vw !important;
  }

  @include breakpoint(phablet) {
    width: 60vw !important;
  }

  @include breakpoint(tablet) {
    width: 45% !important;
    width: 50% !important;
  }

  @include breakpoint(laptop) {
    width: 50% !important;
  }
}

.wcarousel-nav *.flickity-cell:first-child:nth-last-child(3),
.wcarousel-nav *.flickity-cell:first-child:nth-last-child(3)~*.flickity-cell {
  width: 33%;

  @include breakpoint(mobileonly) {
    width: 70vw !important;
  }

  @include breakpoint(phablet) {
    width: 60vw !important;
  }

  @include breakpoint(tablet) {
    width: 45% !important;
  }

  @include breakpoint(laptop) {
    width: 40% !important;
  }
}

// .wcarousel-nav *.flickity-cell {
//     @include breakpoint (mobileonly){
//         width: 70vw !important;
//     }
//     @include breakpoint (phablet){
//         width: 60vw !important;
//     }
//     @include breakpoint (tablet){
//         width: 45% !important;
//     }
//     @include breakpoint (laptop){
//         width: 40% !important;
//     }
// }

.wcarousel-nav *.flickity-button {
  min-width: 40px !important;
  width: 40px !important;
  height: 40px !important;
  border-radius: 100% !important;
  background-color: $vesti-700 !important;
  border: 1px solid $vesti-902 !important;
  filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
}

.wcarousel-nav *.flickity-prev-next-button.next {
  right: 25px;
}

.wcarousel-nav *.flickity-prev-next-button.previous {
  left: 25px;
}

.walletflag {
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
}






// @import '../../../variables.scss';

// .wallet-container {
//   display: grid;
//   grid-template-columns: repeat(12, 1fr); /* 4-4-2 portions */
//   gap: 20px; /* Adjust the gap between grid items as needed */

//   @include breakpoint(mobileonly) {
//     margin-bottom: 30px;
//     grid-template-columns: repeat(1, 1fr);
//     gap: 1rem;
//     // color: #5ec6d2;
//   }
//   @include breakpoint(phablet) {
//     grid-template-columns: repeat(2, 1fr);
//     gap: 1rem;
//   }
//   @include breakpoint(tablet) {
//     grid-template-columns: repeat(2, 1fr);
//     gap: 1rem;
//   }

//   .wallets {
//     grid-column: span 5;
//     position: relative;
//     gap: 1rem;

//     @include breakpoint(mobileonly) {
//       grid-column: span 1;
//     }
//     @include breakpoint(phablet) {
//       grid-column: span 1;
//     }
//     @include breakpoint(tablet) {
//       grid-column: span 1;
//     }
//     &__row {
//       width: 100%;
//       height: 100%;
//       @include rowflex(center, space-between);
//     }
//     &__analytics {
//       width: 50%;
//       @include rowflex(center, space-between);

//       @include breakpoint(mobileonly) {
//         width: 100%;
//         @include colflex(flex-start, space-between);
//         flex-wrap: wrap;
//         gap: 1rem;
//       }
//       @include breakpoint(phablet) {
//         width: 100%;
//         @include rowflex(flex-start, space-between);
//         flex-wrap: wrap;
//         gap: 1rem;
//       }
//     }

//     &__rows {
//       width: 100%;
//       height: 100%;
//       @include rowflex(center, space-between);
//       gap: 1rem;

//       @include breakpoint(mobileonly) {
//         @include colflex(center, space-between);
//       }

//       @include breakpoint(phablet) {
//         width: 50%;
//         @include colflex(center, space-between);
//       }

//       @include breakpoint(tablet) {
//         width: 100%;
//         @include rowflex(center, space-between);
//       }

//       // @include breakpoint(desktop) {
//       //   width: 65%;
//       //   @include colflex(center, space-between);
//       // }
//     }

//     *.wallet {
//       position: relative;
//       overflow: hidden;
//       width: 100%;
//       height: 100%;
//       @include colflexx(flex-start);
//       gap: 34px;
//       border-radius: 20px !important;
//       padding: 30px;
//       &.--Founders,
//       &.--Three {
//         // @include wallets($vpurp-901, $vpurp-800, $vpurp-300, $vpurp-901);
//         @include wallets($yellow-900, $yellow-800, $yellow-300, $yellow-900);
//       }
//       &.--Naira,
//       &.--One {
//         @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//         // @include wallets($green-900, $green-800, $green-300, $green-900);
//       }
//       &.--Usd,
//       &.--Two {
//         @include wallets(#024750, #9cf4ff, #9cf4ff, #024750);
//       }
//       &.--Cedis,
//       &.--Four {
//         @include wallets($yellow-900, $yellow-800, $yellow-300, $yellow-900);
//         // @include wallets(255, 231, 159);
//       }
//       &.--Kwacha {
//         @include wallets($green-900, $green-800, $green-300, $green-900);
//         // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//       }
//       &.--ZMW {
//         @include wallets($green-900, $green-800, $green-300, $green-900);
//         // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//       }
//       &.--Pounds {
//         @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
//         // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//       }
//       &.--GBP {
//         @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
//         // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//       }
//       @include wallets($green-900, $green-800, $green-300, $green-900);

//       @include breakpoint(mobileonly) {
//         width: 100%;
//         gap: 35px;
//         padding: 20px 22px;
//         height: 30vh;
//       }
//       @include breakpoint(phablet) {
//         width: 100%;
//         padding: 16px 20px;
//         gap: 23px;
//       }
//       &__details {
//         // position: absolute;
//         // bottom: 0rem;
//         &.--more {
//           p {
//             color: white;
//           }
//           i {
//             transform: rotate(45deg);
//           }
//         }
//       }
//     }

//     @media (min-width: '1700px') {
//       .wallet__details {
//         position: absolute;
//         bottom: 2rem;
//       }
//     }

//     .wallet-image {
//       position: absolute;
//       top: 0px;
//       right: 0%;
//       width: 100px;
//       @include breakpoint(mobileonly) {
//         width: 60px;
//         right: 0px;
//       }
//       @include breakpoint(phablet) {
//         right: 0%;
//         // width: 60px;
//       }
//       @include breakpoint(tablet) {
//         right: 0%;
//         // width: 60px;
//       }
//       @include breakpoint(desktop) {
//         // width: 70px;
//       }
//     }
//     &__boxes {
//       bottom: -30px;
//     }
//   }

//   .commingsoon img {
//     width: 100% !important;
//     // max-height: 100px;
//   }
//   .commingsoon {
//     grid-column: span 5;
//     overflow: hidden;
//     position: relative;

//     .img {
//       width: 100% !important;
//     }

//     @include breakpoint(mobileonly) {
//       display: none;
//     }
//     @include breakpoint(phablet) {
//       grid-column: span 1;
//     }
//     @include breakpoint(tablet) {
//       grid-column: span 1;
//     }
//   }
//   .savings {
//     grid-column: span 2;

//     background-color: $vpurp-901;
//     border-radius: 20px;
//     p {
//       text-align: center;
//       @include fontMain(1.2em, 1.5em, $vesti-100);
//       padding: 30px;
//     }

//     @include breakpoint(mobileonly) {
//       display: none;
//     }
//     @include breakpoint(phablet) {
//       display: none;
//     }
//     @include breakpoint(tablet) {
//       display: none;
//     }
//   }
// }

// .wallets {
//   grid-column: span 5;
//   position: relative;
//   gap: 1rem;

//   @include breakpoint(mobileonly) {
//     grid-column: span 1;
//   }
//   @include breakpoint(phablet) {
//     grid-column: span 1;
//   }
//   @include breakpoint(tablet) {
//     grid-column: span 1;
//   }
//   &__row {
//     width: 100%;
//     height: 100%;
//     @include rowflex(center, space-between);
//   }

//   &__rows {
//     width: 100%;
//     height: 100%;
//     @include rowflex(center, space-between);
//     gap: 1rem;

//     @include breakpoint(mobileonly) {
//       @include colflex(center, space-between);
//     }

//     @include breakpoint(phablet) {
//       width: 50%;
//       @include colflex(center, space-between);
//     }

//     @include breakpoint(tablet) {
//       width: 100%;
//       @include rowflex(center, space-between);
//     }

//     // @include breakpoint(desktop) {
//     //   width: 65%;
//     //   @include colflex(center, space-between);
//     // }
//   }

//   *.wallet {
//     position: relative;
//     overflow: hidden;
//     width: 100%;
//     height: 100%;
//     @include colflexx(flex-start);
//     gap: 34px;
//     border-radius: 20px !important;
//     padding: 30px;
//     &.--Founders,
//     &.--Three {
//       // @include wallets($vpurp-901, $vpurp-800, $vpurp-300, $vpurp-901);
//       @include wallets($yellow-900, $yellow-800, $yellow-300, $yellow-900);
//     }
//     &.--Naira,
//     &.--One {
//       @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//       // @include wallets($green-900, $green-800, $green-300, $green-900);
//     }
//     &.--Usd,
//     &.--Two {
//       @include wallets(#024750, #9cf4ff, #9cf4ff, #024750);
//     }
//     &.--Cedis,
//     &.--Four {
//       @include wallets($yellow-900, $yellow-800, $yellow-300, $yellow-900);
//       // @include wallets(255, 231, 159);
//     }
//     &.--Kwacha {
//       @include wallets($green-900, $green-800, $green-300, $green-900);
//       // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//     }
//     &.--ZMW {
//       @include wallets($green-900, $green-800, $green-300, $green-900);
//       // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//     }
//     &.--Pounds {
//       @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
//       // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//     }
//     &.--GBP {
//       @include wallets($sec-901, $sec-800, $sec-300, $sec-900);
//       // @include wallets($vesti-902, $vesti-901, $vesti-300, $vesti-902);
//     }
//     @include wallets($green-900, $green-800, $green-300, $green-900);

//     @include breakpoint(mobileonly) {
//       width: 100%;
//       gap: 35px;
//       padding: 20px 22px;
//     }
//     @include breakpoint(phablet) {
//       width: 100%;
//       padding: 16px 20px;
//       gap: 23px;
//     }
//     &__details {
//       &.--more {
//         i {
//           transform: rotate(45deg);
//         }
//       }
//     }
//   }

//   .wallet-image {
//     position: absolute;
//     top: 0px;
//     right: 0%;
//     width: 100px;
//     @include breakpoint(mobileonly) {
//       width: 60px;
//       right: 0px;
//     }
//     @include breakpoint(phablet) {
//       right: 0%;
//       // width: 60px;
//     }
//     @include breakpoint(tablet) {
//       right: 0%;
//       // width: 60px;
//     }
//     @include breakpoint(desktop) {
//       // width: 70px;
//     }
//   }
//   &__boxes {
//     bottom: -30px;
//   }
// }

// @media only screen and (max-width: 468px) {
//   .notif_cont {
//     width: 50px !important;
//     height: 50px !important;
//   }

//   // .platform.platform_withicon.--rounded,
//   // .platform.platform_withiconnobg.--rounded {
//   //   margin-left: 20px !important;

//   //   font-size: 15px !important;
//   // }
// }



// @media only screen and (min-width: 800px) and (max-width: 1256px) {
//   // .wallets .wallet {
//   //   height: 240px !important;
//   // }

//   .wallets *.wallet .wallet__top {
//     margin-top: -22px !important;
//   }
// }


// @media only screen and (max-width: 1240px) {
//   .spend_analytics {
//     display: none;
//   }
// }

// * {
//   box-sizing: border-box !important;
// }
// .wcarousel-nav *.flickity-viewport {
//   height: fit-content;
//   width: 100%;
//   @include rowflexx(flex-start);
//   flex-wrap: nowrap !important;
//   // gap: 20px !important;
//   border-radius: 20px !important;
//   // background-color: red !important;
// }
// .wcarousel-nav {
//   width: 100%;
//   // background-color: rebeccapurple;
//   position: relative;
//   @include rowflexx(flex-start);
//   // overflow: hidden;
//   @include breakpoint(mobileonly) {
//     width: 100%;
//   }
//   @include breakpoint(phablet) {
//     // width: 85vw;
//     width: 100%;
//   }
//   @include breakpoint(tablet) {
//     width: 100%;
//   }
// }

// .wcarousel-nav *.flickity-slider {
//   margin-left: 0px;
//   width: 100%;
// }
// .wcarousel-nav *.flickity-cell {
//   // width: 450px;
//   // width: calc(50% - 20px + 5px);
//   // width: calc(100% / 3);
//   // width:fit-content;
//   margin-right: 25px;
//   margin-left: -5px;
//   border-radius: 5px;
//   counter-increment: carousel-cell;

//   @include breakpoint(mobileonly) {
//     width: 90vw !important;
//   }
// }

// .wcarousel-nav .flickity-cell {
//   width: 33%;

//   @include breakpoint(mobileonly) {
//     width: 70vw !important;
//   }
//   @include breakpoint(phablet) {
//     width: 60vw !important;
//   }
//   @include breakpoint(tablet) {
//     width: 45% !important;
//   }
//   @include breakpoint(laptop) {
//     width: 40% !important;
//   }
// }

// .wcarousel-nav *.flickity-cell:first-child:nth-last-child(2),
// .wcarousel-nav *.flickity-cell:first-child:nth-last-child(2) ~ *.flickity-cell {
//   width: 48%;
//   @include breakpoint(mobileonly) {
//     width: 70vw !important;
//   }
//   @include breakpoint(phablet) {
//     width: 60vw !important;
//   }
//   @include breakpoint(tablet) {
//     width: 45% !important;
//     width: 50% !important;
//   }
//   @include breakpoint(laptop) {
//     width: 50% !important;
//   }
// }

// .wcarousel-nav *.flickity-cell:first-child:nth-last-child(3),
// .wcarousel-nav *.flickity-cell:first-child:nth-last-child(3) ~ *.flickity-cell {
//   width: 33%;
//   @include breakpoint(mobileonly) {
//     width: 70vw !important;
//   }
//   @include breakpoint(phablet) {
//     width: 60vw !important;
//   }
//   @include breakpoint(tablet) {
//     width: 45% !important;
//   }
//   @include breakpoint(laptop) {
//     width: 40% !important;
//   }
// }


// .wcarousel-nav *.flickity-button {
//   min-width: 40px !important;
//   width: 40px !important;
//   height: 40px !important;
//   border-radius: 100% !important;
//   background-color: $vesti-700 !important;
//   border: 1px solid $vesti-902 !important;
//   filter: drop-shadow(0px 4px 30px rgba(157, 157, 157, 0.2)) !important;
// }

// .wcarousel-nav *.flickity-prev-next-button.next {
//   right: 25px;
// }
// .wcarousel-nav *.flickity-prev-next-button.previous {
//   left: 25px;
// }

// .walletflag {
//   border-radius: 100%;
//   width: 1.5rem;
//   height: 1.5rem;
// }