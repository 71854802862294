.myFeedsChatAstn {
  overflow-y: scroll;
  /* width: 80vw; */
  height: 100%;
  display: grid;
  justify-content: space-between;
  /* grid-template-columns: repeat(3, 1fr);  */
  /* gap: 10px;  */
  padding: 20px;
}

.myFeedsChatAstnContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  border: 1px solid #1781ba;
  border-radius: 10px;
  background-color: #ecf8ff;
}

@media (min-width: 168px) and (max-width: 700px) {
  .myFeedsChatAstnContainer {
    height: 20rem;
  }
}

@media (min-width: 700px) and (max-width: 1600px) {
  .myFeedsChatAstnContainer {
    height: 19rem;
  }
}

@media (min-width: 1600px) {
  .myFeedsChatAstnContainer {
    height: 20rem;
  }
}

@media (min-width: 1800px) {
  .myFeedsChatAstnContainer {
    height: 22rem;
  }
}

@media (min-width: 2000px) {
  .myFeedsChatAstnContainer {
    height: 28rem;
  }
}

/* Responsiveness for myFeedsChatAstn */

@media (min-width: 1024px) {
  .myFeedsChatAstn {
    width: 80vw;
    grid-template-columns: repeat(2, 1fr);
    /* gap: 10px;  */
    padding: 20px;
  }
}

@media (min-width: 1100px) {
  .myFeedsChatAstn {
    width: 80vw;
    grid-template-columns: repeat(3, 1fr);
    /* gap: 10px;  */
  }
}

.subscribefeedGroupmodal {
  width: 100%;
}

@media (min-width: 1000px) {
  .subscribefeedGroupmodal {
    width: 500px;
  }
}

.othergroupH {
  padding-top: 20px;
}
/* .feedsChatContainer {
  display: block;
} */
/* @media (min-width: 1300px) {
  .feedsChatContainer {
    display: none;
  }
} */