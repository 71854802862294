@import '../../../variables.scss';

.balancecard {
  width: 350px;

  @include breakpoint(mobileonly) {
    width: 350px !important;
  }

  @include breakpoint(tablet) {
    width: 50vw;
  }

  .balance-account {
    width: fit-content;
    margin-top: -5px;
    height: fit-content;
    cursor: pointer;
    transition: all 0.5s ease;
    p:nth-child(1) {
      margin: 0;
      @include fontNormal(1em, 1em, $vesti-902);
    }
    &__subtitle {
      margin-top: 10px;
      @include fontMedium(1.5em, 1em, $vesti-900);
      transition: all 0.5s ease;
    }

    &:hover {
      .balance-account__subtitle {
        transform-origin: center center;
        transform: scale(1.1);
        color: $vesti-700;
      }
    }

    // div:nth-child(3){
    //     margin-top: -10px;
    // }
  }
}
.bank__trbtns {
  @include rowflexx(flex-start);
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  // a:not(:first-child){
  //     margin-left: 10px;
  // }

  a {
    height: 60px;
    min-width: 30px !important;
    width: fit-content;
    padding: 20px 20px 20px 20px;
    font-size: 1em;

    img {
      width: 10px !important;
      margin-right: 10px;
    }
  }

  @include breakpoint(mobileonly) {
    flex-wrap: wrap;
    gap: 10px;

    a:not(:last-child) {
      margin-right: 10px;
    }

    a:last-child {
      margin-top: 10px;
    }
  }

  @include breakpoint(tablet) {
  }

  @include breakpoint(laptop) {
  }
}

.uploadbankform {
  width: 400px;

  @include breakpoint(mobileonly) {
    width: 70vw;
  }
  @include breakpoint(phablet) {
    width: 100%;
  }
}

.bank-cont {
  @include breakpoint(mobileonly) {
    @include colflexx(flex-start);
  }
  @include breakpoint(phablet) {
    @include colflexx(flex-start);
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  @include breakpoint(tablet) {
    @include colflexx(flex-start);
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  @media only screen and (min-width: 1014px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    @include colflexx(flex-start);
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
}

.bank_form {
  margin-bottom: 30px;
  cursor: pointer;
  @include fontMedium(1em, 1em, $vesti-900);

  strong {
    color: $vesti-700;
  }
}

.titleHeader {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;

  @include breakpoint(mobileonly) {
    @include rowflex(center, flex-start);
    width: 100%;
    gap: 1.5rem;
  }
  @include breakpoint(phablet) {
    width: 100%;
  }
  @include breakpoint(tablet) {
    width: 100%;
  }
  .w-full {
    .titlesubtitle {
      margin-bottom: 0;
      gap: 0;
      .titlesubtitle {
        &__title {
          @include fontMedium(1.2em, 1.5em, $gray-800);
        }

        @include breakpoint(mobileonly) {
          @include fontMedium(1em !important, 1.5em, $gray-800);
        }
      }
    }
  }
}

.whatsnewpopupbutton {
  height: 40px;
  width: 30px;
  margin-left: 25px;
}

.popup-header {
  margin-left: 4%;

  @include breakpoint(mobileonly) {
    gap: 43% !important;
  }

  @include breakpoint(phablet) {
    gap: 60% !important;
  }
}

.active-item {
  flex-direction: row !important;
  align-items: center;
  @include breakpoint(mobileonly) {
    flex-direction: column !important;
  }

  @include breakpoint(phablet) {
    flex-direction: column !important;
  }
}

.item-content {
  @include breakpoint(mobileonly) {
    flex-direction: row; /* Revert to horizontal layout for larger screens */
    justify-content: space-between; /* Add space between item-details and whatcircle-container */
  }

  @include breakpoint(phablet) {
    flex-direction: row; /* Revert to horizontal layout for larger screens */
    justify-content: space-between; /* Add space between item-details and whatcircle-container */
  }
}

.number-carousel-whatsnew .active {
  background-color: #67a948;
  color: white;
  width: 60% !important;
  height: 50px;
  border-radius: 10px;
  margin-right: 10%;
  padding: 15px;

  justify-content: center;
  align-items: center;
  gap: 30px;
  cursor: pointer;
}

.number-carousel-whatsnew .inactive {
  background-color: #999;
  color: white;
  width: 60% !important;
  height: 50px;
  border-radius: 10px;
  margin-right: 10%;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 30px;
  cursor: pointer;

  @include breakpoint(mobileonly) {
  }
}

.center-container-what {
  margin-top: 10%;
}

.whatcircle-container,
.whatsimage {
  height: 100%;

  width: 200%;
  border-radius: 50%;

  margin-left: 5%;

  @include breakpoint(mobileonly) {
    margin-top: 10%;

    height: 100%;

    width: 100%;
  }
}

.popup-close-btn-whatnew {
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;

  width: 4% !important;
  height: 20px;

  min-width: 30px !important;
}

.whatsbutton,
.platform.whatnew {
  width: 100%;

  color: #fff;
  background-color: #67a948;
  transition: all 0.5s ease;
  font-size: 1.2em;
  justify-content: center;
  margin-top: 50%;
  border-radius: 10px;

  @include breakpoint(mobileonly) {
    margin-top: 30%;
    margin-bottom: 10%;
  }
}

.whatstitle {
  color: #000;
  font-size: 32px;
  font-style: normal;
  font-weight: bolder;
  line-height: normal;
  margin-bottom: 10%;

  @include breakpoint(mobileonly) {
    font-size: 16px !important;
    margin-bottom: 5% !important;
  }
}

.vloader {
  position: relative;
  width: 100%;
  height: 100vh;
}

.vloaderAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8rem;
  // height: 8rem;
}
