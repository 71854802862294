@import '../../../../variables.scss';

.feedsV2 {
    height: 100vh;
    background-color: #FFFFFF;
    .bothDiv {
        display: flex;
        width: 100%;
        gap: 2%;
        .first-sec {
            width: 70%;
            height: 800px;
            overflow-y: scroll;
            .contentV2 {
                position: relative;
                border: 1px solid #e5e7eb;
                border-radius: 10px;
                margin: 0;
                padding: 0;
            img.relative {
                position: relative;
                width: 100%;
                border-radius: 10px;
    
            }
            img.absolute {
                position: absolute;
                bottom: 42%;
                left: 4%;
            }
            h4 {
                font-weight: bold;
                font-size: 26px;
            }
            .all-details{
                padding: 5% 4% 3% 4%;
    
                .details {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    padding: 6px 0;
                    p {
                    margin: 0;
                    opacity: 0.7;
                    }
                }
                .members {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    font-weight: 600;
                    
                }
            }
            
            .tabs {
                border-top: 2px solid #e5e7eb;
                .links {
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: 20px;
                    color: #4C5366;
                    padding: 4% 7% 4% 7%;
                }
            }
            }
    
            .sec-part {
                display: flex;
                align-items: flex-start;
                margin-top: 4%;
                gap: 2%;
                .onYourMind {
                    position: relative;
                    display: block;
                    width: 100%;
                    .onYourMindP {
                        border: 2px solid #e5e7eb;
                        width: 100%;
                        border-radius: 40px;
                        padding: 12px;
                        background-color: #FAFAFA;
                        box-sizing: border-box
                    }
                    .iconsFlex {
                        display: flex;
                        align-items: center;
                        gap: 4px;
                        // justify-content: space-between;
                        padding-right: 5%;
                        
                    }
                    
                }
            }

            .third-part {
                display: block;
                margin-top: 4%;
                .IG-sec {
                    margin: 4% 0;
                    .nameCard {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        .feeds-Profile {
                            width: 55px;
                            height: 55px;
                            border: 2px solid transparent;
                            border-radius: 50%;
                        }
                        .nameTime {
                            display: block;
                        }
                    }
                    .timiBacImg {
                        width: 100%;
                        margin: 15px 0;
                    }
                    .allIconText {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .icon-text {
                            display: flex;
                            align-items: center;
                            gap: 4px;
                        }
                    }
                    .details {
                        padding: 4% 0;
                    }
                    .addCommentArea {
                        display: flex;
                        gap: 4px;
                        .addComInputCont {
                            position: relative;
                            width: 100%;
                            .addComInput {
                                border: 2px solid #e5e7eb;
                                width: 100%;
                                padding: 10px 80px 10px 10px;
                                box-sizing: border-box;
                                border-radius: 40px;
                                }
                                .linksquare {
                                    position: absolute;
                                    right: 12%;
                                    top: 25%;
                                    // transform: translateY(-50%);
                                    cursor: pointer;
                                }
                                .happyEmoIcon {
                                    position: absolute;
                                    right: 8%;
                                    top: 25%;
                                    // transform: translateY(-50%);
                                    cursor: pointer;
                                }
                                .galleryIcon {
                                    position: absolute;
                                    right: 4%;
                                    top: 25%;
                                    // transform: translateY(-50%);
                                    cursor: pointer;
                                }
                        }
                        
                    }
                }
            }
    
        }
        
        .second-sec {
            width: 30%;
            overflow-y: scroll;
            height: 800px;
            padding: 0rem 1rem 1rem 1rem;
            background-color: #F6F7FF;
            border-radius: 10px;
            .chats {
            padding: 2rem 1.5rem;
            .chat-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                h4 {
                font-size: 20px;
                font-weight: 600;
                }
                .icons {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                }
            }
            .chat-space {
                display: block;
                .allPart {
                display: flex;
                align-items: center;
                }
                .name-chat {
                display: block;
                }
                .chatContent {
                display: flex;
                align-items: center;
                justify-content: space-between;
                }
                .emojis {
                display: flex;
                align-items: center;
                gap: 4px;
                }
            }
            }
        }

        
    }
}
  
.activeFeedsNav{
    // border border-vestigreen py-1 px-8 bg-vestigreen mt-1

    border: 1px solid #67A948;
    padding: 4px 2rem;
    background-color: #67A948;
    margin-top: 4px;
}
.largeModalText {
    width: 100%;
    height: 50%;
    padding: 4px;
    outline: none;
    box-sizing: border-box
    
}
.modalImgName {
    display: flex;
    align-items: center;
    gap: 8px;
}
.modalImgName h4 {
    font-size: 20px;
}

.iconsFlex {
    display: flex;
    align-items: center;
    gap: 2rem;
    // justify-content: space-between;
    padding-right: 5%;
}
.icons {
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 2%;
    // opacity: 0.7;
}
.largeModalPost {
    align-self: flex-end;
    // margin-bottom: 10px;
    border: 2px solid;
    border-radius: 10px;
    // background-color: #4C5366;
    color: #FFFFFF;
    padding: 8px 24px;
}



.image-container {
    &.single {
        display: block;
        img {
            width: 100%;
            height: auto;
        }
        }

        &.double {
        display: flex;
        gap: 10px;
        img {
            width: 50%;
            height: auto;
        }
        }

        &.triple {
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        
        .large {
        grid-row: span 2;
        width: 100%;
        height: auto;
        }

        .small {
            width: 100%;
            height: auto;
        }
        }

        &.quadruple {
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(2, 1fr);
        img {
            width: 100%;
            height: auto;
        }
        }
    }



// .backgroundImgs {
//     display: grid;
//     gap: 10px; 

//     &.single {

//         grid-template-columns: 1fr;
//         margin: 20px 0px;
//     }

//     &.double {
//         display: flex;
//         gap: 2%;
//     }

//     &.triple {
//         grid-template-columns: 2fr 1fr;
  
//     .timiBacImg{
//         &:nth-child(1) {
//         grid-column: span 3;
//         height: auto; 
//         }
//     }
//     }
  
//     .images {
//         width: 100%;
//         height: auto;
//     }
  
//     .full {
//         grid-column: span 2;
//     }
  
//     .half {
//         grid-column: span 1;
//     }
//   }
//   .images {
//     width: 100%;
//     height: auto;
// }
  


  .grid-container {
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr; /* Two columns */
    grid-template-rows: 1fr 1fr; /* Two rows */
    gap: 10px; /* Optional: Adds space between grid items */
    width: 100%;
    margin: 20px 0px;
  }
  
  .item1 {
    grid-column: 1 / 2; /* Span the full height of the first column */
    grid-row: 1 / 3; /* Span both rows */
   
    width: 100%;
    height: 100%;
  }
  
  .item2 {
    grid-column: 2 / 3; /* Second column */
    grid-row: 1 / 2; /* First row of the second column */
   
    width: 100%;
    height: 100%;
  }
  
  .item3 {
    grid-column: 2 / 3; /* Second column */
    grid-row: 2 / 3; /* Second row of the second column */
  
    width: 100%;
    height: 100%;
  }

 